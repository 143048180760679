import React, { useState } from 'react';

import { Modal, ModalActions } from 'components/Modal';
import global from 'styles/global';
import Button from 'components/Button';
import { isAccountNumber, isRoutingNumber } from 'util/validation';
import Card from 'components/Card';
import TextField from 'components/TextField';

const AddBankAccountModal = ({
  open,
  onClose,
  creator,
  handleUpdateCreator,
  updateCreatorState,
}) => {
  const g = global();

  const [accountNumber, setAccountNumber] = useState({ val: '', dirty: false });
  const [routingNumber, setRoutingNumber] = useState({ val: '', dirty: false });

  const { loading: submitting } = updateCreatorState;

  const handleClose = () => {
    setAccountNumber({ val: '', dirty: false });
    setRoutingNumber({ val: '', dirty: false });
    onClose();
  };

  const addBankAccount = async (evt) => {
    evt.preventDefault();
    await handleUpdateCreator({
      id: creator.id,
      selected_payment_method: 'stripe',
      account_number: accountNumber.val,
      routing_number: routingNumber.val,
      paypal_email: '',
      venmo_phone_number: '',
    });
    handleClose();
  };

  const isInputValid =
    isAccountNumber(accountNumber.val) && isRoutingNumber(routingNumber.val);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Card title="Add bank account">
        <TextField
          margin="dense"
          variant="outlined"
          label="Account number"
          value={accountNumber.val}
          error={accountNumber.dirty && !accountNumber.val}
          helperText={
            accountNumber.dirty &&
            !accountNumber.val &&
            'This field is required'
          }
          onChange={(e) =>
            setAccountNumber({ val: e.target.value, dirty: true })
          }
          required
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="Routing number"
          value={routingNumber.val}
          error={routingNumber.dirty && !routingNumber.val}
          helperText={
            routingNumber.dirty &&
            !routingNumber.val &&
            'This field is required'
          }
          onChange={(e) =>
            setRoutingNumber({ val: e.target.value, dirty: true })
          }
          required
          fullWidth
        />
        <ModalActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            className={g.ml_sm}
            loading={submitting}
            onClick={addBankAccount}
            color="primary"
            variant="contained"
            disabled={!isInputValid}
          >
            Add
          </Button>
        </ModalActions>
      </Card>
    </Modal>
  );
};

export { AddBankAccountModal };
