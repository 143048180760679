/*
    Checking against: 
    {
        'email': '',
        'first_name': '',
        'last_name': '',
        'account_number': '',
        'routing_number': '',
        'business_name': '',
        'entity_type': '',
        'phone_number': '',
    }
*/

export const parseBulkCreators = (csvData) => {
  const processingResult = {
    valid: [],
    invalid: [],
  };
  csvData.forEach((row, idx) => {
    if (!!row.email) {
      processingResult.valid.push(row);
    } else {
      processingResult.invalid.push({content: JSON.stringify(row), id: idx});
    }
  });
  return processingResult;
};
