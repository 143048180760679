import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import LoadingOverlay from 'components/LoadingOverlay';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import AccountSettingsSection from 'components/AccountSettingsSection';
import {
  updatePassword as updatePasswordSlice,
  updatePasswordSelector,
} from 'slices/user';
import { notificationsSelector, getNotifications } from 'slices/notifications';
import useTabs from 'hooks/useTabs';
import NotificationsSection from 'components/NotificationsSection';
import { userCreatorsSelector, getUserCreators } from 'euSlices/creators';
import { getOrgs, orgsSelector, orgsByIdSelector } from 'slices/org';
import { setHeaderData } from 'slices/misc';
import PaymentDetailsCard from './PaymentDetailsCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  titleBar: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  value: {
    fontWeight: 600,
    color: theme.palette.primary.cashGreen089,
  },
  label: { fontSize: '10px', textTransform: 'uppercase' },
}));

const allTabs = [
  { label: 'Profile', name: 'profile' },
  { label: 'Notifications', name: 'notifications' },
  { label: 'Payment Details', name: 'paymentDetails' },
];

function Settings({ updatePasswordState, updatePassword }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    allTabs.map((tab) => tab.name),
    '/creator/settings'
  );

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Settings',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Settings' },
        ],
      })
    );

    dispatch(getNotifications());
    dispatch(getUserCreators());
    dispatch(getOrgs());
  }, []);

  const { data: notifications, loading: notificationsLoading } = useSelector(
    notificationsSelector
  );
  const { data: userCreators, loading: creatorsLoading } =
    useSelector(userCreatorsSelector);
  const { loading: orgsLoading } = useSelector(orgsSelector);
  const orgsDict = useSelector(orgsByIdSelector);

  const loading = notificationsLoading || creatorsLoading || orgsLoading;

  const paymentDetailsCards = userCreators.map((creator) => {
    const org = orgsDict[creator.org_id];
    return <PaymentDetailsCard org={org} creator={creator} />;
  });

  return (
    <div className={classes.root}>
      <LoadingOverlay open={loading} />

      <Tabs
        tabLabels={allTabs.map((tab) => tab.label)}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="settings-tabpanel"
      />

      {/* Profile Tab */}
      <TabPanel value={currentTab} index={0}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <AccountSettingsSection
              handleUpdatePassword={async (data) => await updatePassword(data)}
              updatePasswordState={updatePasswordState}
            />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Notifications Tab */}
      <TabPanel value={currentTab} index={1}>
        <NotificationsSection notifications={notifications} />
      </TabPanel>

      {/* Payment Details Tab */}
      <TabPanel value={currentTab} index={2}>
        {paymentDetailsCards}
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  updatePasswordState: updatePasswordSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (data) => dispatch(updatePasswordSlice(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
