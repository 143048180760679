import { createSlice, createSelector } from '@reduxjs/toolkit';

import { getLinkToken as getLinkTokenAPI } from 'api/plaid';
import { emptyPlaidTokenData } from 'fixtures/plaid';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleErrorNoToast,
} from './sliceUtils';

export const initialPlaidState = {
  linkToken: getGenericState(emptyPlaidTokenData),
};

export const plaidSlice = createSlice({
  name: 'plaid',
  initialState: initialPlaidState,
  reducers: {
    getLinkTokenStarted: getGenericStarted('linkToken'),
    getLinkTokenSuccess: getPayloadSuccess('linkToken'),
    getLinkTokenFailure: getGenericFailure('linkToken'),
  },
});

export const { getLinkTokenStarted, getLinkTokenSuccess, getLinkTokenFailure } =
  plaidSlice.actions;

export default plaidSlice.reducer;

export const getPlaidLinkToken = (id) => async (dispatch, getState) => {
  dispatch(getLinkTokenStarted());
  try {
    const res = await getLinkTokenAPI(getState(), id);
    dispatch(getLinkTokenSuccess(res));
  } catch (err) {
    handleErrorNoToast(
      err,
      dispatch,
      getLinkTokenFailure,
      'There was an issue retrieving the plaid link token.'
    );
  }
};

// selectors
const selectPlaid = (state) => state.plaid;

export const plaidLinkTokenSelector = createSelector(
  selectPlaid,
  (plaidState = {}) =>
    plaidState.linkToken || getGenericState(emptyPlaidTokenData)
);
