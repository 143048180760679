import React, { useState, useEffect } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@material-ui/core';

import { activeCreatorsSelector } from 'slices/user';
import { paymentGroupDetailsSelector } from 'slices/paymentGroups';
import {
  deliverablesForCampaignSelector,
  deliverablesByCreatorDictSelector,
  createDeliverables,
  updateDeliverables,
  deleteDeliverables,
  getDeliverables as getDeliverablesSlice,
} from 'slices/deliverables';
import Card from 'components/Card';
import Button from 'components/Button';
import global from 'styles/global';
import NewCreatorRow from './NewCreatorRow';
import { creatorsInCampaignSelector } from 'slices/multiSliceSelectors';
import { getItemsToAdd, getItemsToDelete, getItemsToUpdate } from './utils';

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.custom.payBlue036,
    },
  },
  addIcon: {
    color: theme.palette.shades.white,
  },
}));

export default function CreatorsStep({ handleStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const g = global();

  const query = new URLSearchParams(useLocation().search);
  const campaignId = query.get('campaignId');

  const campaign = useSelector((state) =>
    paymentGroupDetailsSelector(state, campaignId)
  );
  const campaignCreators = useSelector((state) =>
    creatorsInCampaignSelector(state, campaignId)
  );
  const campaignDeliverables = useSelector((state) =>
    deliverablesForCampaignSelector(state, campaignId)
  );
  const campaignDeliverablesByRecipientDict = useSelector(
    deliverablesByCreatorDictSelector
  );

  const activeCreators = useSelector(activeCreatorsSelector);

  const [rows, setRows] = useState([]);
  const [initialIds, setInitialIds] = useState([]);

  const addRow = () => {
    setRows([
      ...rows,
      { id: uuid(), creator: undefined, payments: [], deliverables: [] },
    ]);
  };

  // add deliverable to a row
  const addDeliverable = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          deliverables: [...row.deliverables, { id: uuid() }],
        };
      }
      return row;
    });
    setRows(newRows);
  };

  const getDeliverables = (deliverables, val, key, id) => {
    const newDeliverables = deliverables.map((deliv) => {
      if (deliv.id === id) {
        const newDeliverable = {
          ...deliv,
          [key]: val,
        };
        return newDeliverable;
      }
      return deliv;
    });

    return newDeliverables;
  };
  const updateProp = (id, val, key, subKey, subId) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        let newRow;
        switch (key) {
          case 'creator': {
            newRow = {
              ...row,
              creator: val,
            };
            break;
          }

          case 'deliverables': {
            newRow = {
              ...row,
              deliverables: getDeliverables(
                row.deliverables,
                val,
                subKey,
                subId
              ),
            };
            break;
          }

          default:
            return row;
        }

        return newRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const deleteRow = (id) => {
    let newRows = rows.filter((row) => row.id !== id);
    if (newRows.length === 0) {
      newRows = [{ id: uuid(), creator: undefined, deliverables: [] }];
    }
    setRows(newRows);
  };

  const deleteDeliverable = (rowId, delivId) => {
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        const newRow = {
          ...row,
          deliverables: row.deliverables.filter(
            (deliv) => deliv.id !== delivId
          ),
        };
        return newRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const handleSave = async () => {
    const validRows = rows.filter((row) => !!row.creator);

    // create deliverables
    const deliverables = validRows.reduce((agg, row) => {
      const delivsForRow = row.deliverables
        .filter((deliv) => !!deliv.quantity && !!deliv.description)
        .map((deliv) => {
          const newDeliv = {
            description: deliv.description,
            campaign_id: campaign.id,
            creator_id: row.creator.id,
            quantity: +deliv.quantity,
            post_date: deliv.postDate,
          };
          if (!!deliv.id) {
            newDeliv.id = deliv.id;
          }
          return newDeliv;
        });
      return agg.concat(delivsForRow);
    }, []);

    const delivsToAdd = getItemsToAdd(initialIds, deliverables);
    const delivsToUpdate = getItemsToUpdate(initialIds, deliverables);
    const delivsToDelete = getItemsToDelete(initialIds, deliverables);
    delivsToAdd.length > 0 &&
      (await dispatch(createDeliverables(delivsToAdd, true)));
    delivsToUpdate.length > 0 &&
      (await dispatch(updateDeliverables(delivsToUpdate, true)));
    delivsToDelete.length > 0 &&
      (await dispatch(deleteDeliverables(delivsToDelete, true)));

    dispatch(getDeliverablesSlice({ campaign_id: campaignId }));

    handleStep(3);
  };

  useEffect(() => {
    let initialIds = Object.values(campaignDeliverables).map((del) => del.id);
    setInitialIds(initialIds);
  }, [campaignDeliverables]);

  useEffect(() => {
    let initialRows = Object.values(campaignCreators).map((creator) => ({
      id: creator.id,
      creator,
      deliverables: campaignDeliverablesByRecipientDict[creator.id] || [],
    }));

    if (initialRows.length === 0) {
      initialRows = [{ id: uuid(), creator: undefined, deliverables: [] }];
    }

    setRows(initialRows);
  }, [campaignCreators, campaignDeliverablesByRecipientDict]);

  useEffect(() => {
    // ensure each row has at least 1 payment and 1 deliverable
    for (const row of rows) {
      if (row.deliverables.length === 0) addDeliverable(row.id);
    }
  }, [rows]);

  return (
    <Card title="Deliverables">
      {rows.map((row, idx) => (
        <NewCreatorRow
          idx={idx}
          creators={activeCreators}
          key={`deliverable-row-${row.id}`}
          vals={row}
          updateProp={updateProp}
          deleteRow={deleteRow}
          deleteDeliverable={deleteDeliverable}
          addDeliverable={addDeliverable}
          isPaymentStep={false}
        />
      ))}

      <IconButton
        variant="outlined"
        color="primary"
        onClick={addRow}
        className={classes.addButton}
      >
        <AddIcon className={classes.addIcon} />
      </IconButton>

      <div className={css(g.flexRowEnd, g.mt_md)}>
        <Button variant="contained" onClick={handleSave}>
          Save and continue
        </Button>{' '}
      </div>
    </Card>
  );
}
