const stateWithholdings = {
  'AL': {
    rate: 5,
  },
  'AK': {
    rate: 0,
  },
  'AS': {
    rate: 27,
  },
  'AZ': {
    rate: 2.98,
  },
  'AR': {
    rate: 5.9,
  },
  'CA': {
    rate: 6.6,
  },
  'CO': {
    rate: 4.55,
  },
  'CT': {
    rate: 6.99,
  },
  'DE': {
    rate: 6.6,
  },
  'DC': {
    rate: 8.95,
  },
  'FM': {
    rate: 0,
  },
  'FL': {
    rate: 0,
  },
  'GA': {
    rate: 5.75,
  },
  'GU': {
    rate: 0,
  },
  'HI': {
    rate: 7.9,
  },
  'ID': {
    rate: 6.5,
  },
  'IL': {
    rate: 4.95,
  },
  'IN': {
    rate: 3.23,
  },
  'IA': {
    rate: 8.53,
  },
  'KS': {
    rate: 5,
  },
  'KY': {
    rate: 5,
  },
  'LA': {
    rate: 4.25,
  },
  'ME': {
    rate: 5,
  },
  'MH': {
    rate: 0,
  },
  'MD': {
    rate: 3.2,
  },
  'MA': {
    rate: 5,
  },
  'MI': {
    rate: 4.25,
  },
  'MN': {
    rate: 6.25,
  },
  'MS': {
    rate: 5,
  },
  'MO': {
    rate: 5.3,
  },
  'MT': {
    rate: 6,
  },
  'NE': {
    rate: 5,
  },
  'NV': {
    rate: 0,
  },
  'NH': {
    rate: 0,
  },
  'NJ': {
    rate: 11.8,
  },
  'NM': {
    rate: 5.9,
  },
  'NY': {
    rate: 4.25,
  },
  'NC': {
    rate: 5.09,
  },
  'ND': {
    rate: 1.84,
  },
  'MP': {
    rate: 0,
  },
  'OH': {
    rate: 3.5,
  },
  'OK': {
    rate: 4.75,
  },
  'OR': {
    rate: 8,
  },
  'PW': {
    rate: 0,
  },
  'PA': {
    rate: 3.13,
  },
  'PR': {
    rate: 33,
  },
  'RI': {
    rate: 5.99,
  },
  'SC': {
    rate: 7,
  },
  'SD': {
    rate: 0,
  },
  'TN': {
    rate: 0,
  },
  'TX': {
    rate: 0,
  },
  'UT': {
    rate: 4.95,
  },
  'VT': {
    rate: 6.6,
  },
  'VI': {
    rate: 0,
  },
  'VA': {
    rate: 5.75,
  },
  'WA': {
    rate: 0,
  },
  'WV': {
    rate: 6.5,
  },
  'WI': {
    rate: 7.65,
  },
  'WY': {
    rate: 0,
  },
};

export default stateWithholdings;
