import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import { currentUserSelector, isExternalSelector } from 'slices/user';
import {
  currentOrgSelector,
  getOrg as getOrgSlice,
  getOrgs as getOrgsSlice,
} from 'slices/org';
import { getUser as getUserSlice } from 'slices/user';
import { billingAuthSelector } from 'slices/org';
import { setDemoMode, isDemoModeSelector } from 'slices/misc';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import constants from 'constants/index';

import DelinquentBanner from 'components/DelinquentBanner';
import AppBar from 'components/AppBar';
import NavDrawer from 'components/NavDrawer';
import ProtectedRoute from 'components/ProtectedRoute';
import LoadingOverlay from 'components/LoadingOverlay';

import Home from 'pages/Home';
import SignUp from 'pages/SignUp';
import SignIn from 'pages/SignIn';
import CreateOrg from 'pages/CreateOrg';
import CreateFundAcct from 'pages/CreateFundAcct';
import CreateFundBusiness from 'pages/CreateFundBusiness';
import WalletsHome from 'pages/Wallets';
import CreatorsHome from 'pages/Creators';
import CreatorDetails from 'pages/Creators/Creator';

import PaymentsHome from 'pages/Payments';
import NewPayment from 'pages/Payments/NewPayment';
import PaymentDetails from 'pages/Payments/Payment';

import AgentsMngrsHome from 'pages/AgentsMngrs';
import AgentMngrDetails from 'pages/AgentsMngrs/AgentMngr';

import ContractsHome from 'pages/Contracts';
import NewContract from 'pages/Contracts/NewContract';
import NewContractTemplate from 'pages/Contracts/NewTemplate';
import ContractDetails from 'pages/Contracts/Contract';

import Settings from 'pages/Settings';
import UpdateBankDetails from 'pages/UpdateBankDetails';
import ResetPassword from 'pages/ResetPassword';

import CampaignsHome from 'pages/Campaigns';
import NewCampaign from 'pages/Campaigns/NewCampaign';
import CampaignDetails from 'pages/Campaigns/Campaign';

import InvoicesHome from 'pages/Invoices';
import InvoiceDetails from 'pages/Invoices/Invoice';
import NewInvoice from 'pages/Invoices/NewInvoice';
import PayInvoice from 'pages/Invoices/PayInvoice';

import CustomersHome from 'pages/Customers';
import CustomerDetails from 'pages/Customers/Customer';

import TaxesHome from 'pages/Taxes';

// routes for external users
import CreatorHome from 'euPages/Home';
import CreatorPayments from 'euPages/Payments';
import EndUserInvoices from 'euPages/Invoices';
import CreatorContracts from 'euPages/Contracts';
import CreatorContractDetails from 'euPages/Contracts/Contract';
import CreatorSettings from 'euPages/Settings';

import usePostHog from 'util/posthog';
import { emptyOrgData } from 'fixtures/org';

const useFooterStyles = makeStyles((theme) => ({
  footer: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function Footer() {
  const classes = useFooterStyles();
  return (
    <div className={classes.footer}>
      <Typography variant="subtitle1" color="textSecondary">
        {'Copyright © '}
        <Link color="inherit" href="https://creatorpay.co/">
          Creator Pay
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: theme.palette.shades.white,
  },
  appBarSpacer: theme.mixins.toolbar,
  bannerSpacer: {
    minHeight: constants.bannerSpacer,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    minHeight: `calc(100vh - ${constants.headerHeight}px - 72px)`,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function App({
  userState,
  getOrg,
  getOrgs,
  getUser,
  orgState,
  setDemoMode,
  isDemoMode,
}) {
  const location = useLocation();
  const { data: user, loading: userLoading } = userState;
  const { data: org, loading: orgLoading } = orgState;
  const isExternal = useSelector(isExternalSelector);
  const { isDelinquent } = useSelector(billingAuthSelector);

  // todo orgloading gets stuck for creators sometimes
  const loading = userLoading || (orgLoading && org == emptyOrgData);

  usePostHog(location);

  const classes = useStyles();

  const query = new URLSearchParams(location.search);
  const isDemoQuery = query.get('demo') === 'true';
  if (isDemoQuery && !isDemoMode) {
    setDemoMode(true);
  }

  const refreshData = async () => {
    await getUser();
    if (isExternal) {
      getOrgs();
    } else {
      getOrg();
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const hideNavBar =
    ((location.pathname.includes('/bankDetails') ||
      location.pathname.includes('/invoices/pay') ||
      location.pathname.includes('/resetPassword')) &&
      !user.auth_token) ||
    location.pathname.includes('/createOrg') ||
    location.pathname.includes('/signup-umw01jf14qx7d6') ||
    location.pathname.includes('/signin');

  const hideFooter =
    location.pathname.includes('/createOrg') ||
    location.pathname.includes('/signup-umw01jf14qx7d6') ||
    location.pathname.includes('/signin');

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        draggable={false}
        pauseOnHover
      />
      <LoadingOverlay open={loading} />

      {!hideNavBar && <AppBar />}

      {!hideNavBar && <NavDrawer />}

      {isDelinquent && <DelinquentBanner />}
      <main className={classes.content}>
        {!hideNavBar && <div className={classes.appBarSpacer} />}
        {isDelinquent && <div className={classes.bannerSpacer}>asdf</div>}

        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            {/* Expiring Link Routes */}
            <Route path="/bankDetails">
              <UpdateBankDetails isDemoMode={isDemoMode} />
            </Route>

            <Route user={user} org={org} path="/invoices/pay">
              <PayInvoice isDemoMode={isDemoMode} />
            </Route>

            {/* Logged in Routes */}
            <ProtectedRoute path="/" user={user} org={org} exact>
              <Home isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/settings" user={user} org={org}>
              <Settings isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/signup-umw01jf14qx7d6" user={user} org={org}>
              <SignUp isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/signin" user={user} org={org}>
              <SignIn isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/createOrg" user={user} org={org}>
              <CreateOrg isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/createFundAcct" user={user} org={org}>
              <CreateFundAcct isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/createFundBusiness" user={user} org={org}>
              <CreateFundBusiness isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/wallets" user={user} org={org}>
              <WalletsHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/creators/:id" user={user} org={org}>
              <CreatorDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/creators" user={user} org={org}>
              <CreatorsHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/payments/newPayment" user={user} org={org}>
              <NewPayment isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/payments/:id" user={user} org={org}>
              <PaymentDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/payments" user={user} org={org} exact>
              <PaymentsHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute
              path="/contracts/newContractTemplate"
              user={user}
              org={org}
            >
              <NewContractTemplate isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/contracts/newContract" user={user} org={org}>
              <NewContract isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute
              path="/contracts/:contractId"
              user={user}
              org={org}
              exact
            >
              <ContractDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/contracts" user={user} org={org}>
              <ContractsHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/agentsManagers/:id" user={user} org={org}>
              <AgentMngrDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/agentsManagers" user={user} org={org}>
              <AgentsMngrsHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/campaigns/new" user={user} org={org}>
              <NewCampaign isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute
              path="/campaigns/:groupId"
              user={user}
              org={org}
              exact
            >
              <CampaignDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute user={user} org={org} path="/campaigns">
              <CampaignsHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute user={user} org={org} path="/invoices/newInvoice">
              <NewInvoice isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute user={user} org={org} path="/invoices/:invoiceId">
              <InvoiceDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute user={user} org={org} path="/invoices">
              <InvoicesHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/customers/:id" user={user} org={org}>
              <CustomerDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/customers" user={user} org={org}>
              <CustomersHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/taxes" user={user} org={org}>
              <TaxesHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            {/* End user routes */}
            <ProtectedRoute path="/creator" user={user} org={org} exact>
              <CreatorHome isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute
              path="/creator/contracts/:contractId"
              user={user}
              org={org}
              exact
            >
              <CreatorContractDetails isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute user={user} org={org} path="/creator/contracts">
              <CreatorContracts isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute user={user} org={org} path="/creator/payments">
              <CreatorPayments isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute user={user} org={org} path="/creator/invoices">
              <EndUserInvoices isDemoMode={isDemoMode} />
            </ProtectedRoute>

            <ProtectedRoute path="/creator/settings" user={user} org={org}>
              <CreatorSettings isDemoMode={isDemoMode} />
            </ProtectedRoute>

            {/* Unprotected routes */}
            <Route path="/resetPassword/:linkId">
              <ResetPassword isDemoMode={isDemoMode} />
            </Route>

            {/* Since the path is not exact here, this actually catches all fall-through and reroutes it back to home */}
            <Route path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Container>
        {!hideFooter && (
          <Box pt={4}>
            <Footer />
          </Box>
        )}
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userState: currentUserSelector(state),
  orgState: currentOrgSelector(state),
  isDemoMode: isDemoModeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDemoMode: () => dispatch(setDemoMode(true)),
  getOrg: () => dispatch(getOrgSlice()),
  getOrgs: () => dispatch(getOrgsSlice()),
  getUser: () => dispatch(getUserSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
