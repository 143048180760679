import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  getAccounts as getAccountsAPI,
  getAcctMap as getAcctMapAPI,
  saveAcctMap as saveAcctMapAPI,
  updateAcctMap as updateAcctMapAPI,
  getJournalEntries as getJournalEntriesAPI,
  newJe as newJeApi,
  getVendors as getVendorsAPI,
  getCustomers as getCustomersAPI,
} from 'api/qb';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
  ensureData,
} from './sliceUtils';

export const initialQbAcctMapState = {
  accounts: getGenericState([]),
  saveAcctMap: getGenericState(),
  updateAcctMap: getGenericState(),
  acctMap: getGenericState({}),
  journalEntries: getGenericState([]),
  vendors: getGenericState([]),
  customers: getGenericState([]),
};

export const qbSlice = createSlice({
  name: 'qb',
  initialState: initialQbAcctMapState,
  reducers: {
    getAccountsStarted: getGenericStarted('accounts'),
    getAccountsSuccess: getPayloadSuccess('accounts'),
    getAccountsFailure: getGenericFailure('accounts'),

    saveAcctMapStarted: getGenericStarted('saveAcctMap'),
    saveAcctMapSuccess: getGenericSuccess('saveAcctMap'),
    saveAcctMapFailure: getGenericFailure('saveAcctMap'),

    getAcctMapStarted: getGenericStarted('acctMap'),
    getAcctMapSuccess: getPayloadSuccess('acctMap'),
    getAcctMapFailure: getGenericFailure('acctMap'),

    updateAcctMapStarted: getGenericStarted('updateAcctMap'),
    updateAcctMapSuccess: getGenericSuccess('updateAcctMap'),
    updateAcctMapFailure: getGenericFailure('updateAcctMap'),

    getJournalEntriesStarted: getGenericStarted('journalEntries'),
    getJournalEntriesSuccess: getPayloadSuccess('journalEntries'),
    getJournalEntriesFailure: getGenericFailure('journalEntries'),

    getVendorsStarted: getGenericStarted('vendors'),
    getVendorsSuccess: getPayloadSuccess('vendors'),
    getVendorsFailure: getGenericFailure('vendors'),

    getCustomersStarted: getGenericStarted('customers'),
    getCustomersSuccess: getPayloadSuccess('customers'),
    getCustomersFailure: getGenericFailure('customers'),
  },
});

export const {
  getAccountsStarted,
  getAccountsSuccess,
  getAccountsFailure,

  saveAcctMapStarted,
  saveAcctMapSuccess,
  saveAcctMapFailure,

  getAcctMapStarted,
  getAcctMapSuccess,
  getAcctMapFailure,

  updateAcctMapStarted,
  updateAcctMapSuccess,
  updateAcctMapFailure,

  getJournalEntriesStarted,
  getJournalEntriesSuccess,
  getJournalEntriesFailure,

  getVendorsStarted,
  getVendorsSuccess,
  getVendorsFailure,

  getCustomersStarted,
  getCustomersSuccess,
  getCustomersFailure,
} = qbSlice.actions;

export default qbSlice.reducer;

export const getAccounts = () => async (dispatch, getState) => {
  dispatch(getAccountsStarted());
  try {
    const res = await getAccountsAPI(getState());
    dispatch(getAccountsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getAccountsFailure,
      'There was an issue retrieving your QuickBooks Account'
    );
  }
};

export const getAcctMap = () => async (dispatch, getState) => {
  dispatch(getAcctMapStarted());
  try {
    const res = await getAcctMapAPI(getState());
    dispatch(getAcctMapSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getAcctMapFailure,
      'There was an issue retrieving your QuickBooks Account Map'
    );
  }
};

export const saveAcctMap = (data) => async (dispatch, getState) => {
  dispatch(saveAcctMapStarted());
  try {
    const res = await saveAcctMapAPI(getState(), data);
    dispatch(saveAcctMapSuccess(res));
    dispatch(getAcctMap());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      saveAcctMapFailure,
      'There was an issue creating your QuickBooks Account Map'
    );
  }
};

export const updateAcctMap = (data) => async (dispatch, getState) => {
  dispatch(updateAcctMapStarted());
  try {
    const res = await updateAcctMapAPI(getState(), data);
    dispatch(updateAcctMapSuccess(res));
    dispatch(getAcctMap());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateAcctMapFailure,
      'There was an issue updating your QuickBooks Account Map'
    );
  }
};

export const getJournalEntries = () => async (dispatch, getState) => {
  dispatch(getJournalEntriesStarted());
  try {
    const res = await getJournalEntriesAPI(getState());
    dispatch(getJournalEntriesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getJournalEntriesFailure,
      'There was an issue retrieving your QuickBooks Journal Entries'
    );
  }
};

export const getVendors = () => async (dispatch, getState) => {
  dispatch(getVendorsStarted());
  try {
    const res = await getVendorsAPI(getState());
    dispatch(getVendorsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getVendorsFailure,
      'There was an issue retrieving your QuickBooks Vendors'
    );
  }
};

export const getCustomers = () => async (dispatch, getState) => {
  dispatch(getCustomersStarted());
  try {
    const res = await getCustomersAPI(getState());
    dispatch(getCustomersSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getCustomersFailure,
      'There was an issue retrieving your QuickBooks Customers'
    );
  }
};

export const newJe = () => async (dispatch, getState) => {
  try {
    const res = await newJeApi(getState());
  } catch (err) {}
};

// selectors
const selectQb = (state) => state.qb;
const reflectSecond = (_, second) => second;

export const acctMapSelector = createSelector(
  selectQb,
  (qbState = {}) => qbState.acctMap || getGenericState()
);

export const acctMapParsedSelector = createSelector(
  acctMapSelector,
  (acctMapState) => {
    const acctNames = [
      'income',
      'payable',
      'sales',
      'receivable',
      'clearing',
      'labor',
    ];
    const parsedAcctMap = { ...acctMapState.data };

    for (const acctName of acctNames) {
      try {
        parsedAcctMap[acctName] = JSON.parse(acctMapState.data[acctName]);
      } catch {}
    }
    return parsedAcctMap;
  }
);

export const accountsSelector = createSelector(selectQb, (qbState) =>
  ensureData(qbState, 'accounts', [])
);

export const accountsDictSelector = createSelector(
  accountsSelector,
  (accountsState) => {
    const { data: accounts } = accountsState;
    return accounts.reduce((agg, account) => {
      agg[account.Id] = account;
      return agg;
    }, {});
  }
);

export const journalEntriesSelector = createSelector(selectQb, (qbState) =>
  ensureData(qbState, 'journalEntries', [])
);

export const vendorsSelector = createSelector(selectQb, (qbState) =>
  ensureData(qbState, 'vendors', [])
);

export const vendorsDictSelector = createSelector(
  vendorsSelector,
  (vendorsState) => {
    const { data: vendors } = vendorsState;
    return vendors.reduce((agg, vendor) => {
      agg[vendor.Id] = vendor;
      return agg;
    }, {});
  }
);

export const customersSelector = createSelector(selectQb, (qbState) =>
  ensureData(qbState, 'customers', [])
);

export const customersDictSelector = createSelector(
  customersSelector,
  (customersState) => {
    const { data: customers } = customersState;
    return customers.reduce((agg, customer) => {
      agg[customer.Id] = customer;
      return agg;
    }, {});
  }
);

export const journalEntriesForCampaignSelector = createSelector(
  [journalEntriesSelector, reflectSecond],
  (jeState, campaignId) => {
    const { data: journalEntries } = jeState;
    return journalEntries.filter((je) => je.campaign_id === campaignId);
  }
);
