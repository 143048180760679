import React from 'react';
import css from 'classnames';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { formatCurrency } from 'util/renderStrings';
import global from 'styles/global';
import Button from 'components/Button';
import { emptyPaymentData } from 'fixtures/payments';
import { emptyUserData } from 'fixtures/user';
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import { calculateFee } from 'util/payments';
import { formatCreatorName } from 'util/renderStrings';
import DataGrid from 'components/DataGrid';
import { paymentRecipientsDictSelector } from 'slices/multiSliceSelectors';

const PaymentConfirmationModal = ({
  open,
  handleClose,
  payments,
  paymentsDict,
  executePayments,
  outboundPaymentFees,
  initiatePaymentsState,
}) => {
  const g = global();

  const { loading } = initiatePaymentsState;

  const recipientsDict = useSelector(paymentRecipientsDictSelector);

  const {
    flat_rate: flatRate,
    percentage: pctRate,
    cap = Infinity,
  } = outboundPaymentFees;

  const totalAmount = payments.reduce((agg, pmtId) => {
    const pmt = paymentsDict[pmtId] || emptyPaymentData;
    const fee = calculateFee(pmt.amount, flatRate, pctRate, cap);
    return agg + fee + pmt.amount;
  }, 0);

  const formattedPayments = payments.map((pmt) => {
    const pmtObj = paymentsDict[pmt] || emptyPaymentData;
    const fee = calculateFee(pmtObj.amount, flatRate, pctRate, cap);
    return {
      ...pmtObj,
      creator: recipientsDict[pmtObj.recipient] || emptyUserData,
      fee,
      total: fee + pmtObj.amount,
    };
  });

  const columns = [
    {
      field: 'id',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => formatCreatorName(params.row.creator),
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      disableColumnSelector: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => {
        const creator = recipientsDict[params.row.recipient] || emptyUserData;
        return creator.email;
      },
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: 'memo',
      headerName: 'Memo',
      flex: 1,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      disableColumnSelector: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => (
        <>
          <Typography variant="h5">{formatCurrency(params.value)}</Typography>
          <Typography variant="subtitle1">
            (+ {formatCurrency(params.row.fee)} fee)
          </Typography>
        </>
      ),
      flex: 1,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
  ];

  return (
    <Modal open={open} handleClose={handleClose} fullWidth maxWidth="lg">
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Pay Creators</Typography>
      </ModalTitle>
      <ModalContent>
        <DataGrid
          autoHeight
          rows={formattedPayments}
          columns={columns}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
        <div className={css(g.flexRow, g.justifyEnd, g.mt_sm)}>
          <Typography variant="h3">
            Total: {formatCurrency(totalAmount)}
          </Typography>
        </div>
      </ModalContent>
      <ModalActions>
        <Button
          onClick={executePayments}
          color="primary"
          variant="contained"
          loading={loading}
        >
          Execute Payments
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default PaymentConfirmationModal;
