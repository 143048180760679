import React, { useEffect, useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { useDispatch } from 'react-redux';

import EditCard from 'components/EditCard';

const Notes = ({ creator, updateCreator }) => {
  const dispatch = useDispatch();

  const { notes } = creator;

  const [newNotes, setNewNotes] = useState('');
  useEffect(() => {
    setNewNotes(notes);
  }, [notes]);

  const handleUpdateCreator = async () => {
    const updateObj = { id: creator.id, notes: newNotes };
    await dispatch(updateCreator(updateObj));
  };

  return (
    <EditCard
      title="Notes"
      handleCancel={() => setNewNotes(notes)}
      handleSubmit={handleUpdateCreator}

      staticContent={<MDEditor.Markdown source={notes || 'No notes'} />}
    >
      <MDEditor autoFocus value={newNotes} onChange={setNewNotes} />
    </EditCard>
  );
};

export default Notes;
