import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import global from 'styles/global';
import css from 'classnames';

import Button from 'components/Button';

const IconWithInfo = ({
  icon,
  text,
  subtext,
  buttonIcon,
  buttonAction,
  submitting,
}) => {
  const g = global();

  return (
    <div className={css(g.flexRow, g.centered)}>
      {icon}
      <Grid
        item
        xs={12}
        className={css(g.ml_sm, g.flexRowSpacing, g.full_width)}
      >
        <div>
          <Typography variant="h4">{text}</Typography>
          <Typography variant="subtitle1">{subtext}</Typography>
        </div>

        {!!buttonIcon && (
          <Button
            className={css(g.error, g.p_zero)}
            onClick={buttonAction}
            loading={submitting}
            isIcon
          >
            {buttonIcon}
          </Button>
        )}
      </Grid>
    </div>
  );
};

export default IconWithInfo;
