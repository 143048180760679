import React from 'react';
import css from 'classnames';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { formatCurrency } from 'util/renderStrings';
import global from 'styles/global';
import { ReactComponent as DarkLogo } from 'assets/darkLogo.svg';
import Button from 'components/Button';
import KeyValuePair from 'components/KeyValuePair';
import { renderTerms, shortenUUID } from 'util/renderStrings';
import { addDaysToTime, getDelimDateFromDateObj } from 'util/time';

const useStyles = makeStyles((theme) => ({
  border: {
    border: `1px solid ${theme.palette.shades.charcoal020}`,
  },
  hr: {
    borderBottom: `1px solid ${theme.palette.shades.charcoal020}`,
  },
}));

const PayInvoiceConfirm = ({
  expiringLink,
  updateObj,
  sendPayment,
  setScreenState,
  submitting,
}) => {
  const classes = useStyles();
  const g = global();

  const {
    id: invoiceId,
    amount,
    org_name: orgName,
    title,
    description,
    org_address = {},
    recipient = {},
    created_at,
    net = '',
  } = expiringLink;

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item sm={12} md={8}>
        <DarkLogo />
        <div className={css(classes.border, g.p_lg)}>
          <Typography
            className={g.textRight}
            variant="subtitle1"
          >{`Invoice ID ${shortenUUID(invoiceId || '')}`}</Typography>
          <Typography variant="h1" className={g.mb_md}>
            {formatCurrency(amount)}
          </Typography>
          {!!net && (
            <Typography variant="h4" className={g.mb_md}>
              {`Due ${getDelimDateFromDateObj(
                addDaysToTime(created_at, net)
              )} • 
                ${renderTerms(net)}`}{' '}
            </Typography>
          )}
          <div className={g.mt_xxl} />
          <KeyValuePair label="Title" value={title} />
          {!!description && (
            <KeyValuePair label="Description" value={description} />
          )}
          <div className={css(classes.hr, g.mb_sm)} />
          <KeyValuePair
            label="From"
            value={
              <>
                <Typography variant="body1">{orgName}</Typography>
                <Typography variant="subtitle1">{org_address.line1}</Typography>
                {!!org_address.line2 && (
                  <Typography variant="subtitle1">
                    {org_address.line2}
                  </Typography>
                )}
                <Typography variant="subtitle1">
                  {`${org_address.city}, ${org_address.state} ${org_address.zip}`}
                </Typography>
              </>
            }
          />
          <KeyValuePair
            label="Billed to"
            value={
              <>
                <Typography variant="body1">{recipient.name}</Typography>
                <Typography variant="subtitle1">{recipient.email}</Typography>
              </>
            }
          />
          <div className={css(classes.hr, g.mb_sm)} />
          <KeyValuePair
            label="Payment method"
            value={updateObj.type === 'bank' ? 'Bank transfer (ACH)' : 'Card'}
          />
          <Button
            onClick={sendPayment}
            color="primary"
            variant="contained"
            className={css(g.mt_xxl, g.mb_md)}
            loading={submitting}
            fullWidth
          >
            Confirm payment
          </Button>
          <Button
            onClick={() => setScreenState('form')}
            color="primary"
            variant="outlined"
            className={classes.submitBtn}
            fullWidth
          >
            Back
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default PayInvoiceConfirm;
