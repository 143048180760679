import { useEffect } from 'react';
import posthog from 'posthog-js';
import { getIsProd } from 'util/env';

export default function usePostHog(location) {
  const isProd = getIsProd();

  useEffect(() => {
    if (!isProd) return;
    posthog.init('phc_Hp01V7m05yHf8OH8tnbYOCQ7T5TD8hTQAiSXOjOzqSP', {
      api_host: 'https://app.posthog.com',
      autocapture: false,
      capture_pageview: false,
    });
  }, []);

  useEffect(() => {
    if (isProd) {
      posthog.capture('$pageview');
    }
  }, [location]);
}
