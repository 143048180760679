import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import css from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { getIsDev } from 'util/env';
import moment from 'moment';
import { isEmpty } from 'ramda';

import PayInvoiceSuccess from './PayInvoiceSuccess';
import { ReactComponent as DarkLogo } from 'assets/darkLogo.svg';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import { ButtonGroup, Typography, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { formatCurrency, shortenUUID } from 'util/renderStrings';
import global from 'styles/global';
import {
  payInvoiceSelector,
  payInvoice as payInvoiceSlice,
} from 'slices/invoices';
import {
  getExpiringLink as getExpiringLinkSlice,
  expiringLinkSelector,
} from 'slices/expiringLink';
import { isCreditCard, isInt } from 'validator';
import {
  plaidLinkTokenSelector,
  getPlaidLinkToken as getPlaidLinkTokenSlice,
} from 'slices/plaid';
import PlaidLinkButton from 'components/PlaidLinkButton';
import MonthYearInput from 'components/MonthYearInput';
import ExpiringLinkInvalid from 'components/ExpiringLinkInvalid';
import objectStates from 'constants/objectStates';
import TextField from 'components/TextField';
import KeyValuePair from 'components/KeyValuePair';
import { addDaysToTime, getDelimDateFromDateObj } from 'util/time';
import { renderTerms } from 'util/renderStrings';
import PayInvoiceConfirm from './PayInvoiceConfirm';

const useStyles = makeStyles((theme) => ({
  border: {
    border: `1px solid ${theme.palette.shades.charcoal020}`,
  },
  hr: {
    borderBottom: `1px solid ${theme.palette.shades.charcoal020}`,
  },
}));

const PayInvoice = ({
  payInvoice,
  payInvoiceState,
  getExpiringLink,
  expiringLinkState,
  plaidLinkTokenState,
  getPlaidLinkToken,
}) => {
  const classes = useStyles();
  const g = global();

  const isDev = getIsDev();

  const { data: expiringLink = {}, loading, error } = expiringLinkState;
  const { data: plaidData = {}, loading: tokenLoading } = plaidLinkTokenState;
  const {
    id: invoiceId,
    amount,
    org_name: orgName,
    title,
    description,
    disallowed_methods = [],
    status,
    org_address = {},
    recipient = {},
    created_at,
    net = '',
  } = expiringLink;

  const query = new URLSearchParams(useLocation().search);
  const linkId = query.get('id');

  const cardAllowed = !disallowed_methods.includes('card');
  const bankAllowed = !disallowed_methods.includes('bank');

  useEffect(() => {
    getExpiringLink(linkId);
    getPlaidLinkToken(linkId);
  }, []);

  useEffect(() => {
    setIsBankAcct(bankAllowed);
  }, [cardAllowed, bankAllowed]);

  const { loading: submitting } = payInvoiceState;

  const [state, setState] = useState({
    cardNum: '',
    cvc: '',
    expDate: '',
  });
  const { cardNum, expDate, cvc } = state;

  const handlePayCard = async () => {
    const { cardNum, cvc, expDate } = state;
    const updateObj = {
      card: {
        number: cardNum,
        cvc,
        exp_month: expDate.slice(0, 2),
        exp_year: expDate.slice(2, 4),
      },
      linkId,
      type: 'card',
    };
    setUpdateObj(updateObj);
    setScreenState('confirm');
  };

  const handlePayBank = async (token) => {
    const updateObj = {
      plaid: {
        public_token: token,
      },
      linkId,
      type: 'bank',
    };
    setUpdateObj(updateObj);
    setScreenState('confirm');
  };

  const sendPayment = async (token) => {
    await payInvoice(updateObj, () => {
      setScreenState('success');
    });
  };

  const setField = (e, field) => {
    setState({
      ...state,
      [field]: e.target.value,
    });
  };

  const [isBankAcct, setIsBankAcct] = useState(bankAllowed);
  const [screenState, setScreenState] = useState('form');
  const [updateObj, setUpdateObj] = useState({});

  const getIsValid = () => {
    const expDateMoment = moment(expDate, 'MMYY', true);
    const cardValuesValid = () =>
      (isDev || isCreditCard(cardNum)) && // must be real card number in production
      !isEmpty(cardNum) && // card number required
      !isEmpty(cvc) && // cvc requird
      isInt(cvc, { min: 0, max: 9999 }) && // digits only, 3 or 4 characters
      expDateMoment.isValid() && // parsed moment is valid
      expDateMoment.isAfter(moment()); // parsed moment is in the future
    return cardValuesValid();
  };

  const isValid = getIsValid();

  const CanceledInvoice = () => {
    const g = global();

    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item sm={12} md={10}>
          <Grid container className={g.p_lg} spacing={6}>
            <Grid item xs={12} className={g.centerChildren}>
              <Typography variant="h5">
                Sorry! This invoice has been canceled by the requestor.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (status === objectStates.canceled) {
    return <CanceledInvoice />;
  }

  if (error !== '') {
    return <ExpiringLinkInvalid />;
  }

  if (screenState === 'success') {
    return <PayInvoiceSuccess amount={amount} />;
  } else if (screenState === 'confirm') {
    return (
      <PayInvoiceConfirm
        updateObj={updateObj}
        expiringLink={expiringLink}
        sendPayment={sendPayment}
        setScreenState={setScreenState}
        submitting={submitting}
      />
    );
  } else {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <LoadingOverlay open={loading || tokenLoading} />
        <Grid item sm={12} md={8}>
          <DarkLogo />
          <div className={css(classes.border, g.p_lg)}>
            <Typography
              className={g.textRight}
              variant="subtitle1"
            >{`Invoice ID ${shortenUUID(invoiceId || '')}`}</Typography>
            <Typography variant="h1" className={g.mb_md}>
              {formatCurrency(amount)}
            </Typography>
            {!!net && (
              <Typography variant="h4" className={g.mb_md}>
                {`Due ${getDelimDateFromDateObj(
                  addDaysToTime(created_at, net)
                )} • 
                ${renderTerms(net)}`}{' '}
              </Typography>
            )}
            <div className={g.mt_xxl} />
            <KeyValuePair label="Title" value={title} />
            {!!description && (
              <KeyValuePair label="Description" value={description} />
            )}
            <div className={css(classes.hr, g.mb_sm)} />
            <KeyValuePair
              label="From"
              value={
                <>
                  <Typography variant="body1">{orgName}</Typography>
                  <Typography variant="subtitle1">
                    {org_address.line1}
                  </Typography>
                  {!!org_address.line2 && (
                    <Typography variant="subtitle1">
                      {org_address.line2}
                    </Typography>
                  )}
                  <Typography variant="subtitle1">
                    {`${org_address.city}, ${org_address.state} ${org_address.zip}`}
                  </Typography>
                </>
              }
            />
            <KeyValuePair
              label="Billed to"
              value={
                <>
                  <Typography variant="body1">{recipient.name}</Typography>
                  <Typography variant="subtitle1">{recipient.email}</Typography>
                </>
              }
            />
            <div className={css(classes.hr, g.mb_sm)} />

            {/* input forms */}
            <Typography variant="subtitle1" className={g.mb_sm}>
              Select your payment method:
            </Typography>
            <ButtonGroup fullWidth variant="outlined" className={g.mb_xl}>
              {bankAllowed && (
                <Button
                  color="primary"
                  variant={isBankAcct ? 'outlined' : 'text'}
                  onClick={() => setIsBankAcct(true)}
                >
                  Bank account
                </Button>
              )}
              {cardAllowed && (
                <Button
                  color="primary"
                  variant={!isBankAcct ? 'outlined' : 'text'}
                  onClick={() => setIsBankAcct(false)}
                >
                  Credit card
                </Button>
              )}
            </ButtonGroup>
            {!isBankAcct ? (
              <>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Card number"
                  helperText=""
                  error={false}
                  onChange={(evt) => {
                    setField(evt, 'cardNum');
                  }}
                  value={cardNum}
                  autoFocus
                  fullWidth
                  required
                />
                <Grid container spacing={1}>
                  <Grid item sm={8} xs={12} className={g.mb_md}>
                    <MonthYearInput
                      margin="dense"
                      variant="outlined"
                      label="Expiration"
                      helperText=""
                      error={false}
                      value={expDate}
                      onChange={(evt) => {
                        setField(evt, 'expDate');
                      }}
                      fullWidth
                      required
                      placeholder="MM/YY"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12} className={g.mb_md}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="CVC"
                      helperText=""
                      error={false}
                      value={cvc}
                      onChange={(evt) => {
                        setField(evt, 'cvc');
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
                <Button
                  onClick={handlePayCard}
                  disabled={!isValid}
                  color="primary"
                  fullWidth
                  variant="contained"
                  className={css(g.flexRow, g.alignEnd)}
                  loading={submitting}
                >
                  Continue
                </Button>
              </>
            ) : (
              <Grid item xs={12}>
                <PlaidLinkButton
                  token={plaidData.token}
                  successFunc={handlePayBank}
                />
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
};

const mapStateToProps = (state) => ({
  payInvoiceState: payInvoiceSelector(state),
  expiringLinkState: expiringLinkSelector(state),
  plaidLinkTokenState: plaidLinkTokenSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  payInvoice: (data, callback) => dispatch(payInvoiceSlice(data, callback)),
  getExpiringLink: (id) => dispatch(getExpiringLinkSlice(id)),
  getPlaidLinkToken: (expiringLinkID) =>
    dispatch(getPlaidLinkTokenSlice(expiringLinkID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayInvoice);
