import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';

import useTabs from 'hooks/useTabs';
import TabPanel from 'components/TabPanel';
import LoadingOverlay from 'components/LoadingOverlay';
import Tabs from 'components/Tabs';
import ProfileTab from './ProfileTab';
import CampaignsTab from './CampaignsTab';
import ContractsTab from './ContractsTab';
import PaymentsTab from 'components/PaymentsTab';

import {
  getAgentsMngrs,
  agentMngrDetailsSelector,
  agentsMngrsSelector,
} from 'slices/agentsMngrs';
import { formatCreatorName } from 'util/renderStrings';
import { getPayments, paymentsSelector } from 'slices/payments';
import { getPaymentGroups, paymentGroupsSelector } from 'slices/paymentGroups';
import { customersSelector, getCustomers } from 'slices/customers';
import { setHeaderData } from 'slices/misc';

const tabs = [
  { label: 'Profile', name: 'profile' },
  { label: 'Campaigns', name: 'campaigns' },
  { label: 'Payments', name: 'payments' },
  // { label: 'Contracts', name: 'contracts' }, todo: add this later
];

export default function AgentMngr() {
  const { id } = useParams();

  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const agentMngr = useSelector((state) => agentMngrDetailsSelector(state, id));
  const { loading: agentsMngrsLoading } = useSelector(agentsMngrsSelector);
  const { loading: paymentsLoading } = useSelector(paymentsSelector);
  const { loading: paymentGroupsLoading } = useSelector(paymentGroupsSelector);
  const { loading: customersLoading } = useSelector(customersSelector);

  const loading =
    agentsMngrsLoading ||
    paymentsLoading ||
    paymentGroupsLoading ||
    customersLoading;

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: formatCreatorName(agentMngr),
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Agents and Managers', link: '/agentsManagers' },
          { label: formatCreatorName(agentMngr) },
        ],
      })
    );
  }, [agentMngr]);

  useEffect(() => {
    dispatch(getAgentsMngrs());
    dispatch(getPayments());
    dispatch(getPaymentGroups());
    dispatch(getCustomers());
  }, []);

  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    tabs.map((tab) => tab.name),
    `/agentsManagers/${id}`
  );

  return (
    <div>
      <LoadingOverlay open={loading} />

      <Tabs
        tabLabels={tabs.map((tab) => tab.label)}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="agent-manager-tabpanel"
      />
      <TabPanel value={currentTab} index={0}>
        <ProfileTab agentMngr={agentMngr} />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <CampaignsTab agentMngr={agentMngr} />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <PaymentsTab recipient={agentMngr} />
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <ContractsTab agentMngr={agentMngr} />
      </TabPanel>
    </div>
  );
}
