import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  getCustomers as getCustomersAPI,
  updateCustomer as updateCustomerAPI,
  addCustomer as addCustomerAPI,
} from 'api/customers';

export const initialCustomersState = {
  getCustomers: getGenericState([]),
  addCustomer: getGenericState(),
  updateCustomer: getGenericState(),
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState: initialCustomersState,
  reducers: {
    getCustomersStarted: getGenericStarted('getCustomers'),
    getCustomersSuccess: getPayloadSuccess('getCustomers'),
    getCustomersFailure: getGenericFailure('getCustomers'),

    updateCustomerStarted: getGenericStarted('updateCustomer'),
    updateCustomerSuccess: getPayloadSuccess('updateCustomer'),
    updateCustomerFailure: getGenericFailure('updateCustomer'),

    addCustomerStarted: getGenericStarted('addCustomer'),
    addCustomerSuccess: getPayloadSuccess('addCustomer'),
    addCustomerFailure: getGenericFailure('addCustomer'),
  },
});

export const {
  getCustomersStarted,
  getCustomersSuccess,
  getCustomersFailure,
  updateCustomerStarted,
  updateCustomerSuccess,
  updateCustomerFailure,
  addCustomerStarted,
  addCustomerSuccess,
  addCustomerFailure,
} = customersSlice.actions;

export default customersSlice.reducer;

export const getCustomers = (data) => async (dispatch, getState) => {
  dispatch(getCustomersStarted());
  try {
    const res = await getCustomersAPI(getState(), data);
    dispatch(getCustomersSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getCustomersFailure,
      'There was an issue retrieving your customers'
    );
  }
};

export const updateCustomer =
  (data, callback) => async (dispatch, getState) => {
    dispatch(updateCustomerStarted());
    try {
      const res = await updateCustomerAPI(getState(), data);
      dispatch(updateCustomerSuccess(res));
      dispatch(getCustomers());
      !!callback && callback();
    } catch (err) {
      handleError(
        err,
        dispatch,
        updateCustomerFailure,
        'There was an issue updating this customer'
      );
    }
  };

export const addCustomer = (data) => async (dispatch, getState) => {
  dispatch(addCustomerStarted());
  try {
    const res = await addCustomerAPI(getState(), data);
    dispatch(addCustomerSuccess(res));
    dispatch(getCustomers());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      addCustomerFailure,
      'There was an issue adding this customer'
    );
  }
};

// selectors
const selectCustomers = (state) => state.customers;

export const customersSelector = createSelector(
  selectCustomers,
  (customersState = {}) => customersState.getCustomers || getGenericState([])
);

export const customersDictSelector = createSelector(
  customersSelector,
  (customersState) => {
    const { data: customers } = customersState;
    return customers.reduce((agg, customer) => {
      agg[customer.id] = customer;
      return agg;
    }, {});
  }
);

export const sortedCustomersSelector = createSelector(
  customersSelector,
  (customersState) => {
    const { data } = customersState;
    const customers = [...data];
    return customers.sort((a, b) => a.email.localeCompare(b.email));
  }
);

export const updateCustomerSelector = createSelector(
  selectCustomers,
  (customerState) => customerState.updateCustomer || getGenericState()
);

export const addCustomerSelector = createSelector(
  selectCustomers,
  (customerState) => customerState.addCustomer || getGenericState()
);
