import css from 'classnames';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import global from 'styles/global';
import DollarInput from 'components/DollarInput';
import PercentInput from 'components/PercentInput';
import Button from 'components/Button';
import { formatCreatorName, formatCurrency } from 'util/renderStrings';
import { splitSourceLabels } from 'constants/labelMaps';
import TextField from 'components/TextField';

export default function ManagerPayment({
  rowId,
  managerPayment,
  updateManagerPayment,
  deleteManagerPayment,
  addManagerPayment,
  managerSplitSource,
}) {
  const g = global();
  return (
    <div className={css(g.mb_lg)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">Manager Payment</Typography>
        </Grid>

        {!!managerPayment ? (
          <>
            {/* earnings input */}
            <Grid item sm={3} className={css(g.flexRow, g.alignStart)}>
              <TextField
                margin="dense"
                className={g.m_zero}
                variant="outlined"
                label="Manager"
                value={formatCreatorName(managerPayment.recipientData)}
                disabled
                fullWidth
              />
            </Grid>

            <Grid item sm={2} className={css(g.flexRow, g.alignStart)}>
              <DollarInput
                value={managerPayment.earnings}
                margin="dense"
                className={g.m_zero}
                variant="outlined"
                label="Source income"
                helperText={`Source income is ${splitSourceLabels[managerSplitSource]}`}
                disabled
                fullWidth
              />
            </Grid>

            {/* split input */}
            <Grid item sm={2} className={css(g.flexRow, g.alignStart)}>
              <PercentInput
                label="Split"
                required
                className={g.m_zero}
                value={managerPayment.split}
                onChange={(evt) =>
                  updateManagerPayment(rowId, evt.target.value, 'split')
                }
                margin="dense"
                fullWidth
              />
            </Grid>

            {/* memo input */}
            <Grid item sm={3} className={css(g.flexRow, g.alignStart)}>
              <TextField
                margin="dense"
                className={g.m_zero}
                variant="outlined"
                label="Memo"
                value={managerPayment.memo}
                onChange={(evt) =>
                  updateManagerPayment(rowId, evt.target.value, 'memo')
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={2} className={css(g.flexRow, g.alignStart)}>
              <DollarInput
                value={managerPayment.amount}
                margin="dense"
                disabled
                className={g.m_zero}
                variant="outlined"
                label="Amount"
                required
                fullWidth
                helperText={
                  managerPayment.state_withholdings +
                    managerPayment.federal_withholdings >
                    0 &&
                  `Withholding: ${formatCurrency(
                    100 *
                      (managerPayment.state_withholdings +
                        managerPayment.federal_withholdings)
                  )}`
                }
              />
              <IconButton
                className={g.ml_zero}
                onClick={() => deleteManagerPayment(rowId)}
              >
                <CloseIcon className={g.error} />
              </IconButton>
            </Grid>
          </>
        ) : (
          <div className={css(g.full_width, g.flexRowEnd)}>
            <Button
              size="small"
              onClick={() => addManagerPayment(rowId)}
              color="primary"
              variant="outlined"
            >
              Add Manager Payment
            </Button>
          </div>
        )}
      </Grid>
    </div>
  );
}
