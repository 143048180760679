import React, { useState } from 'react';
import Select from '../Select';
import Card from '../Card';
import TextField from '../TextField';
import Button from '../Button';
import global from '../../styles/global';

import { countries } from '../../constants/countries';
import { currencies } from '../../constants/currencies';

import {
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  ButtonGroup,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import css from 'classnames';

import USBankPayoutForm from './USBankPayoutForm';
import IntlBankPayoutForm from './IntlBankPayoutForm';
import CardPayoutForm from './CardPayoutForm';
import CashPayoutForm from './CashPayoutForm';

const steps = ['Beneficiaries Information', 'Payment info'];
const locations = [
  { name: 'Location 1' },
  { name: 'Location 2' },
  { name: 'Location 3' },
];

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.shades.charcoal004,
    border: '1px solid',
    borderColor: theme.palette.card.blue2,
  },
  stepper: {
    backgroundColor: theme.palette.shades.charcoal004,
  },
  margin: {
    marginTop: 15,
    marginBottom: 10,
  },
}));

const AddPaymentMethod = ({ userID }) => {
  const classes = useStyles();
  const g = global();

  const [activeStep, setActiveStep] = useState(0);

  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zip, setZip] = useState('');

  const [currency, setCurrency] = useState({ val: '', dirty: false });

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');

  const handleNext = () => {
    if (activeStep == 0) {
      console.log('Get payment methods');
      getPayoutTypes();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getPayoutTypes = () => {
    console.log('Getting types');
    setPaymentTypes(['International Bank', 'US Bank', 'Card', 'OTC Cash']);
  };

  const renderGetBeneficiariesInformation = () => {
    return (
      <>
        <Typography variant="h4">Beneficiaries information</Typography>
        <FormControl
          size="small"
          variant="outlined"
          className={css(g.full_width, g.mt_xs)}
        >
          <InputLabel id="select-country-label">Country</InputLabel>
          <Select
            labelId="select-country-label"
            label="Country"
            id="country"
            value={country}
            onChange={(evt) => setCountry(evt.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          variant="outlined"
          label="Address 1"
          value={address.val}
          error={address.dirty && !address.val}
          helperText={address.dirty && !address.val && 'This field is required'}
          onChange={(e) => setAddress({ val: e.target.value, dirty: true })}
          required
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="Address 2"
          value={address2.val}
          error={address2.dirty && !address2.val}
          helperText={
            address2.dirty && !address2.val && 'This field is required'
          }
          onChange={(e) => setAddress2({ val: e.target.value, dirty: true })}
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="City"
          value={city.val}
          error={city.dirty && !city.val}
          helperText={city.dirty && !city.val && 'This field is required'}
          onChange={(e) => setCity({ val: e.target.value, dirty: true })}
          required
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="State"
          value={state.val}
          error={state.dirty && !state.val}
          helperText={state.dirty && !state.val && 'This field is required'}
          onChange={(e) => setState({ val: e.target.value, dirty: true })}
          required
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="Zip"
          value={zip.val}
          error={zip.dirty && !zip.val}
          helperText={zip.dirty && !zip.val && 'This field is required'}
          onChange={(e) => setZip({ val: e.target.value, dirty: true })}
          required
          fullWidth
        />
        <FormControl
          size="small"
          variant="outlined"
          className={css(g.full_width, g.mt_xs)}
        >
          <InputLabel id="select-currency-label">Currency</InputLabel>
          <Select
            labelId="select-currency-label"
            label="Currency"
            id="currency"
            value={currency}
            onChange={(evt) => setCurrency(evt.target.value)}
          >
            {currencies.map((currency) => (
              <MenuItem key={currency.code} value={currency.code}>
                {currency.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  const renderSelectedPaymentType = (type) => {
    switch (type) {
      case 'US Bank':
        return <USBankPayoutForm />;
      case 'International Bank':
        return <IntlBankPayoutForm />;
      case 'Card':
        return <CardPayoutForm />;
      case 'OTC Cash':
        return <CashPayoutForm locations={locations} />;
      default:
        return <Card title={type}></Card>;
    }
  };

  return (
    <Card title="Add Payment Method" className={classes.card}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography>All steps completed</Typography>
          <Button onClick={handleReset}>Reset</Button>
        </>
      ) : (
        <>
          {activeStep == 0 ? (
            renderGetBeneficiariesInformation()
          ) : (
            <>
              {paymentTypes.length > 0 ? (
                <div className={classes.margin}>
                  <ButtonGroup fullWidth variant="outlined">
                    {paymentTypes.map((type) => (
                      <Button
                        color="primary"
                        variant={
                          selectedType === type ? 'contained' : 'outlined'
                        }
                        onClick={() => setSelectedType(type)}
                      >
                        {type}
                      </Button>
                    ))}
                  </ButtonGroup>
                  {renderSelectedPaymentType(selectedType)}
                </div>
              ) : (
                <CircularProgress color="inherit" />
              )}
            </>
          )}
          <div class={g.flexRowEnd}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default AddPaymentMethod;
