export const emptyUserData = {
  id: '',
  created_at: '',
  updated_at: '',
  api_key: '',
  email: '',
  auth_token: '',
  org_id: '',
  role: '',
};

export const demoUserData = {
  id: 'demo',
  created_at: '',
  updated_at: '',
  api_key: 'demo',
  email: 'demo@creatorpay.co',
  auth_token: 'demo',
  org_id: 'demo',
  role: 'admin',
};
