import React, { useRef, useState, useEffect } from 'react';
import css from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import global from 'styles/global';
import Card from 'components/Card';
import Button from 'components/Button';

const TWENTY_FIVE_MB = 25 * 1000 * 1000;

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    padding: theme.spacing(2),
  },
  docHeader: {
    paddingBottom: '0 !important',
    marginBottom: '-12px',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8),
    borderWidth: '2px',
    borderRadius: 0,
    borderColor: theme.palette.shades.charcoal036,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.shades.charcoal004,
    color: theme.palette.shades.charcoal036,
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
  },
  dropzoneContainer: {
    padding: theme.spacing(3),
  },
  sigBlock: {
    position: 'absolute',
    cursor: 'move',
    color: 'red',
    maxWidth: '215px',
    borderRadius: '5px',
    margin: 'auto',
    userSelect: 'none',
  },
  sigContainer: {
    height: '120px',
  },
  docContainer: {
    border: '1px solid black',
  },
  flowRoot: {
    display: 'flow-root',
  },
  removeSig: {
    position: 'absolute',
    top: -20,
    right: -20,
  },
  pdfCtrBar: {
    padding: theme.spacing(1, 2, 0, 2),
  },
}));

export default function PlainDocument({
  plainFile,
  setPlainFile,
  acceptedFiles,
  getRootProps,
  getInputProps,
}) {
  const classes = useStyles();
  const g = global();

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [dropErr, setDropErr] = useState('');

  useEffect(() => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    setPlainFile({
      file: newFile[0],
      preview: URL.createObjectURL(newFile),
    });
    setDropErr('');
  }, [acceptedFiles, setPlainFile]);

  const handleNextPage = () => {
    setPageNumber(Math.min(numPages, pageNumber + 1));
  };

  const handleBackPage = () => {
    setPageNumber(Math.max(1, pageNumber - 1));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card noHeader>
          {!plainFile && (
            <section className={classes.dropzoneContainer}>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <AddIcon style={{ fontSize: 60 }} className={g.charcoal036} />
                <Typography variant="body1">
                  Drag and drop a PDF to get started
                </Typography>
              </div>
              <aside>
                <Typography variant="body1" className={css(g.error, g.mt_sm)}>
                  {dropErr}
                </Typography>
              </aside>
            </section>
          )}
          {!!plainFile && (
            <>
              <div
                className={css(
                  classes.pdfCtrBar,
                  g.flexRowSpacing,
                  g.alignCenter
                )}
              >
                <div className={css(g.alignCenter, g.flexRow)}>
                  <Typography variant="body2" className={g.alignStart}>
                    Page {pageNumber} of {numPages}
                  </Typography>
                  <Button disabled={pageNumber === 1} onClick={handleBackPage}>
                    Back
                  </Button>
                  <Button
                    onClick={handleNextPage}
                    disabled={pageNumber === numPages}
                  >
                    Next
                  </Button>
                </div>
                <CloseIcon
                  className={css(g.clickable, g.error)}
                  onClick={() => setPlainFile(null)}
                />
              </div>
              <div className={classes.flowRoot}>
                <Document
                  file={plainFile.preview}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className={css(classes.docContainer, g.m_md, g.mt_zero)}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
            </>
          )}
        </Card>
      </Grid>
    </>
  );
}
