import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import Button from 'components/Button';
import global from 'styles/global';

export default function PlaidLinkButton({
    token,
    successFunc,
    submitting
}) {
  const g = global();

  const [plaidLoading, setPlaidLoading] = useState(false);

  // define onSuccess, onExit and onEvent functions as configs for Plaid Link creation
  const onSuccess = async (publicToken, metadata) => {
      successFunc(publicToken);
      setPlaidLoading(false);
  };

  const onExit = async (error, metadata) => {
    setPlaidLoading(false);
  };

  const onEvent = async (eventName, metadata) => {

  };

  const config = {
    onSuccess,
    onExit,
    onEvent,
    token,
  };


  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
  }, [ready, open]);

  const handleClick = () => {
    setPlaidLoading(true);
    open();
  };

  return (
    <Button
      fullWidth
      onClick={handleClick}
      color="primary"
      variant="contained"
      className={g.mb_xl}
      loading={plaidLoading}
    >
      Connect to Plaid
    </Button>
  );
}
