import { createParams, handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getInvoices = async (state, params) => {
  const qStr = createParams(params);
  return createRequest(`/v1/invoices${qStr}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const getInvoicePreview = async (state, params) => {
  const qStr = createParams(params);
  return createRequest(`/v1/invoices/preview${qStr}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const remindInvoice = async (state, ids, reminderType) => {
  return createRequest(`/v1/invoices/reminders`, state, {
    method: 'POST',
    body: {
      reminder_type: reminderType,
      ids: ids.join(','),
    },
  }).then((res) => handleError(res));
};

export const invalidateInvoice = async (state, id) => {
  return createRequest(`/v1/invoices/${id}/cancel`, state, {
    method: 'POST',
  }).then((res) => handleError(res));
};

export const createInvoice = async (state, body) => {
  return createRequest(`/v1/invoices`, state, {
    method: 'POST',
    body,
  }).then((res) => handleError(res));
};

export const payInvoice = async (state, data) => {
  const { linkId, ...body } = data;
  const qStr = createParams({ id: linkId });
  return createRequest(`/v1/expiring_link${qStr}`, state, {
    method: 'POST',
    body,
  }).then((res) => handleError(res));
};

export const updateInvoice = async (state, id, body) => {
  return createRequest(`/v1/invoices/${id}`, state, {
    method: 'PUT',
    body,
  }).then((res) => handleError(res));
};

export const uploadAttachment = async (state, body) => {
  return createRequest(`/v1/invoices/attachment`, state, {
    method: 'POST',
    body,
    contentType: 'multipart/form-data',
  }).then((res) => handleError(res));
};
