import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Paper } from '@material-ui/core';
import css from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import OrgLink from 'components/OrgLink';
import global from 'styles/global';
import objectStates from 'constants/objectStates';
import { download } from 'util/download';
import { renderContractStatusChip } from 'util/table';
import {
  contractsSelector,
  getContracts as getContractsSlice,
  getContractPreview as getContractPreviewSlice,
  contractSelector,
  contractPreviewSelector,
} from 'slices/contracts';
import { getDateAndTimeFromIso } from 'util/time';
import { orgsSelector } from 'slices/org';
import { setHeaderData } from 'slices/misc';
import KeyValuePair from 'components/KeyValuePair';
import Card from 'components/Card';
import { getDelimitedDateFromIso } from 'util/time';

const useStyles = makeStyles((theme) => ({
  flowRoot: {
    display: 'flow-root',
  },
}));

function Contract({
  contractsState,
  getContractPreview,
  getContracts,
  orgsState,
}) {
  const g = global();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contractId } = useParams();

  const docRef = useRef();

  const { loading: contractsLoading } = contractsState;
  const { data: contractPreview, loading: contractPreviewLoading } =
    useSelector((state) => contractPreviewSelector(state, contractId));

  const { loading: orgsLoading, data: orgs } = orgsState;

  const pageLoading = contractsLoading || contractPreviewLoading || orgsLoading;

  const contractPreviewPending = contractPreview === 'PENDING';

  const contract = useSelector((state) => contractSelector(state, contractId));

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: contract.title,
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Contracts', link: '/creator/contracts' },
          { label: contract.title },
        ],
      })
    );
  }, [contract]);

  useEffect(() => {
    getContracts();
    getContractPreview(contractId);
  }, []);

  const downloadContract = async () => {
    download(contractPreview, `${contract.title}.pdf`);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const handleNextPage = () => {
    setPageNumber(Math.min(numPages, pageNumber + 1));
  };

  const handleBackPage = () => {
    setPageNumber(Math.max(1, pageNumber - 1));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <LoadingOverlay open={pageLoading} />

      <div className={css(g.flexRow, g.justifyEnd, g.mb_sm)}>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          disabled={contract.status === objectStates.canceled}
          className={g.ml_xs}
          onClick={downloadContract}
        >
          Download
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item md={5}>
          <Card customClassName={g.mb_xl} title="Details">
            <KeyValuePair label="Contract title" value={contract.title} />
            <KeyValuePair
              label="Company"
              value={<OrgLink orgs={orgs} id={contract.org_id} />}
            />
            <KeyValuePair
              label="Signed on"
              value={
                !!contract.signature_date
                  ? getDateAndTimeFromIso(contract.signature_date)
                  : '-'
              }
            />
            <KeyValuePair
              label="Expiration date"
              value={
                contract.expiration_date
                  ? getDelimitedDateFromIso(contract.expiration_date)
                  : '-'
              }
            />
            <KeyValuePair
              label="Status"
              value={renderContractStatusChip(contract.type, contract.status)}
            />
          </Card>
        </Grid>
        <Grid item md={7}>
          <Paper className={css(g.paper, g.mb_lg)}>
            {contract.status === objectStates.canceled && (
              <div className={css(g.alignCenter, g.flexRow)}>
                <Typography variant="body2" className={g.alignStart}>
                  This contract has been invalidated
                </Typography>
              </div>
            )}
            {contractPreviewPending && (
              <div className={css(g.centerChildren, g.p_lg)}>
                <Typography variant="h3">
                  This document is still being created
                </Typography>
                <Typography
                  variant="h5"
                  className={css(g.charcoal036, g.p_sm, g.mb_md)}
                >
                  Please refresh this page in a few moments
                </Typography>
                <Button
                  onClick={() =>
                    getContractPreview(contract.signature_request_id)
                  }
                  variant="outlined"
                >
                  Refresh
                </Button>
              </div>
            )}
            {contractPreview && !contractPreviewPending && (
              <>
                <div
                  className={css(
                    classes.pdfCtrBar,
                    g.flexRowSpacing,
                    g.alignCenter
                  )}
                >
                  <div className={css(g.alignCenter, g.flexRow)}>
                    <Typography variant="body2" className={g.alignStart}>
                      Page {pageNumber} of {numPages}
                    </Typography>
                    <Button
                      disabled={pageNumber === 1}
                      onClick={handleBackPage}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={handleNextPage}
                      disabled={pageNumber === numPages}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                <div className={classes.flowRoot} ref={docRef}>
                  <Document
                    file={contractPreview}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={css(classes.docContainer, g.m_md, g.mt_zero)}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contractsState: contractsSelector(state),
  contractPreviewState: contractPreviewSelector(state),
  orgsState: orgsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContracts: (data) => dispatch(getContractsSlice(data)),
  getContractPreview: (id) => dispatch(getContractPreviewSlice(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
