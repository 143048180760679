export const emptyInvoiceData = {
  id: '',
  created_at: '',
  updated_at: '',
  org_id: '',
  customer_id: '',
  title: '',
  description: '',
  amount: 0,
  customer_name: '',
  customer_email: '',
  charges: [],
};
