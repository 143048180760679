import css from 'classnames';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

import global from 'styles/global';
import DollarInput from 'components/DollarInput';
import PercentInput from 'components/PercentInput';
import Button from 'components/Button';
import { splitSourceLabels } from 'constants/labelMaps';
import { formatCreatorName, formatCurrency } from 'util/renderStrings';
import TextField from 'components/TextField';
import { getTotalWithholdingsForPayment } from './utils';
import { useSelector } from 'react-redux';
import { paymentRecipientTotalsSelector } from 'slices/payments';

export default function AgentPayment({
  agentPayment,
  setAgentPayment,
  agentSplitSource,
  addAgentPayment,
}) {
  const g = global();

  const deleteAgentPayment = () => {
    setAgentPayment(false);
  };

  const { data: paymentRecipientTotals } = useSelector(
    paymentRecipientTotalsSelector
  );

  const updateAgentPayment = (value, key) => {
    setAgentPayment((oldPmt) => {
      const updated = {
        ...oldPmt,
        [key]: value,
      };
      const split = updated.split;
      const amount = (updated.earnings * split) / 100;
      updated.amount = amount;
      const { stateWithholdings, federalWithholdings } =
        getTotalWithholdingsForPayment(
          updated,
          updated.recipientData,
          paymentRecipientTotals[updated.recipient] || 0
        ) || 0;

      setAgentPayment({
        ...updated,
        amount: amount - stateWithholdings - federalWithholdings,
        state_withholdings: stateWithholdings,
        federal_withholdings: federalWithholdings,
      });
    });
  };

  const currentWithholding = Math.floor(
    (agentPayment.state_withholdings + agentPayment.federal_withholdings) * 100
  );

  return (
    <div className={g.mt_lg}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">Agent Payment</Typography>
        </Grid>

        {!!agentPayment ? (
          <>
            <Grid item sm={3} className={css(g.flexRow, g.alignStart)}>
              <TextField
                margin="dense"
                className={g.m_zero}
                variant="outlined"
                label="Agent"
                value={formatCreatorName(agentPayment.recipientData)}
                disabled
                fullWidth
              />
            </Grid>

            {/* earnings input */}
            <Grid item sm={2} className={css(g.flexRow, g.alignStart)}>
              <DollarInput
                value={agentPayment.earnings}
                margin="dense"
                className={g.m_zero}
                variant="outlined"
                label="Source income"
                helperText={`Source income is ${splitSourceLabels[agentSplitSource]}`}
                disabled
                fullWidth
              />
            </Grid>

            {/* split input */}
            <Grid item sm={2} className={css(g.flexRow, g.alignStart)}>
              <PercentInput
                label="Split"
                required
                className={g.m_zero}
                value={agentPayment.split}
                onChange={(evt) =>
                  updateAgentPayment(evt.target.value, 'split')
                }
                margin="dense"
                fullWidth
              />
            </Grid>

            {/* memo input */}
            <Grid item sm={3} className={css(g.flexRow, g.alignStart)}>
              <TextField
                margin="dense"
                className={g.m_zero}
                variant="outlined"
                label="Memo"
                value={agentPayment.memo}
                onChange={(evt) => updateAgentPayment(evt.target.value, 'memo')}
                fullWidth
              />
            </Grid>

            <Grid item xs={2} className={css(g.flexRow, g.alignStart)}>
              <DollarInput
                value={agentPayment.amount}
                margin="dense"
                disabled
                className={g.m_zero}
                variant="outlined"
                label="Amount"
                required
                fullWidth
                helperText={
                  currentWithholding > 0 &&
                  `Withholding: ${formatCurrency(currentWithholding)}`
                }
              />
              <IconButton className={g.ml_zero} onClick={deleteAgentPayment}>
                <CloseIcon className={g.error} />
              </IconButton>
            </Grid>
          </>
        ) : (
          <div className={css(g.full_width, g.flexRowEnd)}>
            <Button
              size="small"
              onClick={addAgentPayment}
              color="primary"
              variant="outlined"
            >
              Add Agent Payment
            </Button>
          </div>
        )}
      </Grid>
    </div>
  );
}
