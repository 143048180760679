import { useSelector } from 'react-redux';

import { formatCreatorName } from 'util/renderStrings';
import TableHeader from 'components/TableHeader';
import { renderCustomer, renderGroupTitle } from 'util/table';
import { formatCurrency } from 'util/renderStrings';
import { customersDictSelector } from 'slices/customers';
import DataGrid from 'components/DataGrid';
import { paymentGroupsByAgentSelector } from 'slices/paymentGroups';

export default function CampaignsTab({ agentMngr }) {

  const customersDict = useSelector(customersDictSelector);
  const paymentGroupsByAgent = useSelector(paymentGroupsByAgentSelector);

  const campaigns = paymentGroupsByAgent[agentMngr.id] || [];

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => renderGroupTitle(params, params.row.id),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'customer_id',
      headerName: 'Customer',
      renderCell: (params) => renderCustomer(customersDict[params.value]),
      flex: 1,
    },
    {
      field: 'deal_size',
      headerName: 'Deal Size',
      renderCell: (params) => formatCurrency(params.value),
      flex: 1,
    },
  ];

  return (
    <div>

    <TableHeader title={`Campaigns for ${formatCreatorName(agentMngr)}`} />

    <DataGrid
        autoHeight
        autoPageSize
        rows={campaigns}
        columns={columns}
        // columnDependencies={[paymentGroupsStats, invoicesByPaymentGroup, customersDict]}
      />
  </div>
  );
}
