import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import {
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';
import { useHistory, useLocation } from 'react-router-dom';

import TabPanel from 'components/TabPanel';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import Tabs from 'components/Tabs';
import global from 'styles/global';

import useTabs from 'hooks/useTabs';
import {
  invoicesSelector,
  openInvoicesSelector,
  canceledInvoicesSelector,
  paidInvoicesSelector,
  invalidateInvoicesSelector,
  remindInvoicesSelector,
  getInvoices as getInvoicesSlice,
  invalidateInvoices as invalidateInvoicesSlice,
  remindInvoices as remindInvoicesSlice,
} from 'slices/invoices';
import { setHeaderData } from 'slices/misc';
import {
  getCustomers as getCustomersSlice,
  customersSelector,
  customersDictSelector,
} from 'slices/customers';
import { emptyCustomerData } from 'fixtures/customer';
import { hasTreasurySelector } from 'slices/org';
import {
  paymentGroupsSelector,
  getPaymentGroups as getPaymentGroupsSlice,
} from 'slices/paymentGroups';
import ModalConfirm from 'components/ModalConfirm';
import objectStates from 'constants/objectStates';
import { paymentGroupTypes } from 'constants/paymentGroups';
import InvoicesTable from 'components/InvoicesTable';

const allTabs = [
  { label: 'Open', name: 'open' },
  { label: 'Paid', name: 'paid' },
  { label: 'Canceled', name: 'canceled' },
  { label: 'All', name: 'all' },
];

function Invoices({
  invalidateInvoicesState,
  remindInvoicesState,
  invoicesState,
  openInvoices,
  paidInvoices,
  canceledInvoices,
  getInvoices,
  invalidateInvoices,
  sendReminders,
  customersDict,
  getCustomers,
  customersState,
  hasTreasury,
  paymentGroupsState,
  getPaymentGroups,
}) {
  const g = global();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { loading: invalidateLoading } = invalidateInvoicesState;
  const { loading: remindLoading } = remindInvoicesState;

  const { data: invoices, loading: invoicesLoading } = invoicesState;
  const { loading: customersLoading } = customersState;
  const { loading: paymentGroupsLoading, data: paymentGroups } =
    paymentGroupsState;
  const loading = invoicesLoading || customersLoading || paymentGroupsLoading;

  const navToNewInvoice = () => {
    history.push('/invoices/newInvoice');
  };

  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    allTabs.map((tab) => tab.name),
    '/invoices'
  );

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [sendSmsButtonEnabled, setSendSmsButtonEnabled] = useState(false);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Invoices',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Invoices' },
        ],
      })
    );

    getInvoices();
    getCustomers();
    getPaymentGroups({ type: paymentGroupTypes.campaign });
  }, []);

  useEffect(() => {
    let isEnabled = true;
    if (selectedInvoices.length === 0) {
      return;
    }
    selectedInvoices.forEach((invoiceId) => {
      let customer_id = invoices.find((c) => c.id === invoiceId).customer_id;
      let customer = customersDict[customer_id] || emptyCustomerData;
      if (!customer.phone_number) {
        isEnabled = false;
      }
    });
    setSendSmsButtonEnabled(isEnabled);
  }, [selectedInvoices, invoices, customersDict]);

  const handleSendEmailReminders = () => {
    sendReminders(selectedInvoices, 'email');
  };
  const handleSendSmsReminders = () => {
    sendReminders(selectedInvoices, 'sms');
  };

  const handleInvalidateInvoices = () => {
    invalidateInvoices(selectedInvoices);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: 'invoices_report' }} />
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <ModalConfirm
        open={cancelModalVisible}
        handleClose={() => setCancelModalVisible(false)}
        callback={handleInvalidateInvoices}
        title="Are you sure?"
        bodyText={`Are you sure you want to cancel ${
          selectedInvoices.length > 1
            ? `these ${selectedInvoices.length} invoices?`
            : 'this invoice?'
        }`}
        buttonText="Confirm"
      />

      <LoadingOverlay open={loading} />

      <Tabs
        tabLabels={['Open', 'Paid', 'Canceled', 'All']}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="payments-tabpanel"
      />

      <TabPanel value={currentTab} index={0}>
        <TableHeader title="All Invoices">
          <Button
            size="small"
            variant="contained"
            className={g.ml_xs}
            color="primary"
            onClick={navToNewInvoice}
            disabled={!hasTreasury}
          >
            New Invoice
          </Button>
        </TableHeader>
        <div className={g.tableWrapper}>
          <InvoicesTable
            autoHeight
            autoPageSize
            invoices={invoices}
            columnsToDisplay={[
              'title',
              'customer',
              'amount',
              'net',
              'due_date',
              'status',
              'payment_group_id',
            ]}
            onSelectionModelChange={setSelectedInvoices}
            checkboxSelection
            isRowSelectable={(params) =>
              params.row.status !== objectStates.canceled
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <TableHeader title="Open Invoices">
          <div>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              disabled={selectedInvoices.length === 0}
              onClick={() => setCancelModalVisible(true)}
              loading={invalidateLoading}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={g.ml_xs}
              disabled={!sendSmsButtonEnabled}
              onClick={handleSendSmsReminders}
              loading={remindLoading}
            >
              Send SMS reminder
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={g.ml_xs}
              disabled={selectedInvoices.length === 0}
              onClick={handleSendEmailReminders}
              loading={remindLoading}
            >
              Send email reminder
            </Button>
            <Button
              size="small"
              variant="contained"
              className={g.ml_xs}
              color="primary"
              onClick={navToNewInvoice}
              disabled={!hasTreasury}
            >
              New Invoice
            </Button>
          </div>
        </TableHeader>
        <div className={g.tableWrapper}>
          <InvoicesTable
            autoHeight
            autoPageSize
            invoices={openInvoices}
            columnsToDisplay={[
              'title',
              'customer',
              'amount',
              'net',
              'due_date',
              'status',
              'payment_group_id',
            ]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={setSelectedInvoices}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <TableHeader title="Canceled Invoices">
          <div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={navToNewInvoice}
              disabled={!hasTreasury}
            >
              New Invoice
            </Button>
          </div>
        </TableHeader>

        <div className={g.tableWrapper}>
          <InvoicesTable
            autoHeight
            autoPageSize
            invoices={canceledInvoices}
            columnsToDisplay={[
              'title',
              'customer',
              'amount',
              'net',
              'due_date',
              'status',
              'payment_group_id',
            ]}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <TableHeader title="Paid Invoices">
          <div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={navToNewInvoice}
              disabled={!hasTreasury}
            >
              New Invoice
            </Button>
          </div>
        </TableHeader>

        <div className={g.tableWrapper}>
          <InvoicesTable
            autoHeight
            autoPageSize
            invoices={paidInvoices}
            columnsToDisplay={[
              'title',
              'customer',
              'amount',
              'net',
              'due_date',
              'status',
              'payment_group_id',
            ]}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  invoicesState: invoicesSelector(state),
  openInvoices: openInvoicesSelector(state),
  canceledInvoices: canceledInvoicesSelector(state),
  paidInvoices: paidInvoicesSelector(state),
  invalidateInvoicesState: invalidateInvoicesSelector(state),
  remindInvoicesState: remindInvoicesSelector(state),

  customersState: customersSelector(state),
  customersDict: customersDictSelector(state),
  hasTreasury: hasTreasurySelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getInvoices: () => dispatch(getInvoicesSlice()),
  getCustomers: () => dispatch(getCustomersSlice()),
  invalidateInvoices: (data) => dispatch(invalidateInvoicesSlice(data)),
  sendReminders: (ids, method) => dispatch(remindInvoicesSlice(ids, method)),

  getPaymentGroups: (params) => dispatch(getPaymentGroupsSlice(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
