import { Chip } from '@material-ui/core';

import { colors } from 'styles/global';

export const statuses = {
  preflight: 'preflight',
  active: 'active',
  completed: 'completed',
  archived: 'archived',
};
export const renderCampaignStatusChip = (status) => {
  switch (status) {
    case statuses.preflight:
      return <Chip variant="outlined" label="Preflight" />;
    case statuses.active:
      return (
        <Chip
          variant="outlined"
          label="Active"
          style={{
            color: colors.success.main,
            borderColor: colors.success.main,
          }}
        />
      );
    case statuses.completed:
      return <Chip variant="outlined" label="Completed" color="primary" />;
    case statuses.archived:
      return <Chip variant="outlined" label="Archived" />;
    default:
      return <Chip variant="outlined" label="Preflight" />;
  }
};
