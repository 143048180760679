const objectStates = {
  draft: 'draft',
  requires_info: 'requires_info',
  processing: 'processing',
  succeeded: 'succeeded',
  canceled: 'canceled',
  returned: 'returned',
  failed: 'failed',
};

export default objectStates;
