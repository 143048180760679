import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { paypalEnabledSelector } from 'slices/paypal';
import { updateAgentMngr, updateAgentMngrSelector } from 'slices/agentsMngrs';
import UserDetails from 'components/UserDetails';
import PayoutMethods from 'components/PayoutMethods';
import Card from 'components/Card';
import PaymentInfo from './PaymentInfo';
import Contact from './Contact';

export default function GeneralTab({ agentMngr }) {
  const isPayPalEnabled = useSelector(paypalEnabledSelector);
  const updateState = useSelector(updateAgentMngrSelector);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <UserDetails
          creator={agentMngr}
          updateCreator={updateAgentMngr}
          isAgentMangerDetail
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Card title="Payout methods">
          <PayoutMethods
            creator={agentMngr}
            updateCreator={updateAgentMngr}
            updateCreatorState={updateState}
            isPayPalEnabled={isPayPalEnabled}
          />
        </Card>
      </Grid>

      <Grid item xs={12} sm={8}>
        <PaymentInfo agentMngr={agentMngr} />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Contact agentMngr={agentMngr} />
      </Grid>
    </Grid>
  );
}
