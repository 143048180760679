import React, { useState } from 'react';

import Button from 'components/Button';
import { Modal, ModalActions } from 'components/Modal';
import global from 'styles/global';
import isMobilePhone from 'validator/lib/isMobilePhone';
import PhoneInput from 'components/PhoneInput';
import Card from 'components/Card';

const AddVenmoModal = ({
  open,
  onClose,
  creator,
  handleUpdateCreator,
  updateCreatorState,
}) => {
  const g = global();

  const [phone, setPhone] = useState({ val: '', dirty: false });
  const { loading: submitting } = updateCreatorState;

  const handleClose = () => {
    setPhone({ val: '', dirty: false });
    onClose();
  };

  const addVenmoPhone = async (evt) => {
    evt.preventDefault();
    await handleUpdateCreator({
      id: creator.id,
      selected_payment_method: 'venmo',
      venmo_phone_number: phone.val,
      paypal_email: '',
      account_number: '',
      routing_number: '',
    });
    handleClose();
  };

  const isInputValid = isMobilePhone(phone.val, 'en-US');

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <Card title="Connect Venmo">
        <PhoneInput
          margin="dense"
          variant="outlined"
          label="Venmo phone number"
          required
          value={phone.val}
          error={phone.dirty && !isInputValid}
          helperText={
            phone.dirty &&
            !isInputValid &&
            'This field is required and must be a valid US phone number'
          }
          onChange={(e) => setPhone({ val: e.target.value, dirty: true })}
          fullWidth
        />
        <ModalActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            className={g.ml_sm}
            loading={submitting}
            onClick={addVenmoPhone}
            color="primary"
            variant="contained"
            disabled={!isInputValid}
          >
            Add
          </Button>
        </ModalActions>
      </Card>
    </Modal>
  );
};

export { AddVenmoModal };
