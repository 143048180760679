import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { find, propEq } from 'ramda';

import TabPanel from 'components/TabPanel';
import LoadingOverlay from 'components/LoadingOverlay';
import Tabs from 'components/Tabs';

import { setHeaderData } from 'slices/misc';
import {
  creatorsSelector,
  getCreators as getCreatorsSlice,
  creatorsDictSelector,
} from 'slices/user';
import {
  contractsSelector,
  getContracts as getContractsSlice,
} from 'slices/contracts';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
} from 'slices/payments';
import { emptyUserData } from 'fixtures/user';
import { formatCreatorName } from 'util/renderStrings';
import {
  getPaypalOauth as getPaypalOauthSlice,
  paypalOauthSelector,
} from 'slices/paypal';
import SplitsTabNew from './SplitsTabNew';
import GeneralTab from './GeneralTab';
import PaymentsTab from 'components/PaymentsTab';
import ContractsTab from './ContractsTab';
import useTabs from 'hooks/useTabs';
import {
  accountsSelector,
  getAccounts as getAccountsSlice,
  vendorsSelector,
  getVendors as getVendorsSlice,
} from 'slices/qb';
import {
  quickbooksSelector,
  getConnections as getConnectionsSlice,
} from 'slices/orgOauth';
import { getTagOptions, getCreatorTags } from 'slices/tags';

const tabs = [
  { label: 'General', name: 'general' },
  { label: 'Payments', name: 'payments' },
  { label: 'Contracts', name: 'contracts' },
  { label: 'Splits', name: 'splits' },
];

function Creator({
  contractsState,
  creatorsState,
  getContracts,
  getCreators,
  getPayments,
  paymentsState,
  getPaypalOauth,
  paypalOauth,
  getQbAccounts,
  qbAccountsState,
  getConnections,
  getQbVendors,
  qbVendorsState,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading: creatorsLoading, data: creators } = creatorsState;
  const { loading: paymentsLoading } = paymentsState;
  const { loading: contractsLoading } = contractsState;
  const { loading: paypalLoading } = paypalOauth;
  const { loading: qbAccountsLoading } = qbAccountsState;
  const { loading: qbVendorsLoading } = qbVendorsState;

  const loading =
    creatorsLoading ||
    paymentsLoading ||
    contractsLoading ||
    paypalLoading ||
    qbAccountsLoading ||
    qbVendorsLoading;

  const creator = find(propEq('id', id), creators) || emptyUserData;

  const { search } = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    tabs.map((tab) => tab.name),
    `/creators/${id}`
  );

  const qboConnection = useSelector(quickbooksSelector);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: formatCreatorName(creator),
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Creators', link: '/creators' },
          { label: formatCreatorName(creator) },
        ],
      })
    );
  }, [creator]);

  useEffect(() => {
    getCreators();
    getContracts();
    getPayments();
    getPaypalOauth();
    getConnections();
    dispatch(getTagOptions());
    dispatch(getCreatorTags({ creator_id: id }));
  }, []);

  useEffect(() => {
    if (qboConnection?.id) {
      getQbAccounts();
      getQbVendors();
    }
  }, [qboConnection]);

  return (
    <div>
      <LoadingOverlay open={loading} />
      <Tabs
        tabLabels={tabs.map((tab) => tab.label)}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="creator-tabpanel"
      />
      <TabPanel value={currentTab} index={0}>
        <GeneralTab />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <PaymentsTab recipient={creator} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <ContractsTab />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <SplitsTabNew />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  creatorsDict: creatorsDictSelector(state),
  creatorsState: creatorsSelector(state),
  contractsState: contractsSelector(state),
  paymentsState: paymentsSelector(state),
  paypalOauth: paypalOauthSelector(state),
  qbAccountsState: accountsSelector(state),
  qbVendorsState: vendorsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCreators: (data) => dispatch(getCreatorsSlice(data)),
  getContracts: (data) => dispatch(getContractsSlice(data)),
  getPayments: () => dispatch(getPaymentsSlice()),
  getPaypalOauth: (data) => dispatch(getPaypalOauthSlice(data)),
  getQbAccounts: () => dispatch(getAccountsSlice()),
  getConnections: () => dispatch(getConnectionsSlice()),
  getQbVendors: () => dispatch(getVendorsSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Creator);
