import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import css from 'classnames';
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Button';
import {
  createFundBusinessSelector,
  createFundBusiness as createFundBusinessSlice,
} from 'slices/org';

import { USStates } from 'constants/usStates';
import global from 'styles/global';
import Autocomplete from 'components/Autocomplete';
import PhoneInput from 'components/PhoneInput';
import Card from 'components/Card';
import { businessAddressSelector } from 'slices/misc';
import TextField from 'components/TextField';
import { getIsDemo, getIsDev } from 'util/env';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.custom.payBlue}`,
    width: '75%',
  },
}));

function CreateFundBusiness({ createFundBusiness, createFundBusinessState }) {
  const [ein, setEIN] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [stateInput, setStateInput] = useState('');
  const [zip, setZip] = useState('');
  const [terms, setTerms] = useState(false);
  const { loading } = createFundBusinessState;

  const isDemo = getIsDemo();
  const isDev = getIsDev();

  const businessAddress = useSelector(businessAddressSelector);
  useEffect(() => {
    const {
      address: bizAddress,
      address2: bizAddress2,
      city: bizCity,
      state: bizState,
      zip: bizZip,
    } = businessAddress;
    setAddress(bizAddress);
    setAddress2(bizAddress2);
    setCity(bizCity);
    setState(bizState);
    setZip(bizZip);
  }, [businessAddress]);

  useEffect(() => {
    if (isDemo || isDev) {
      setEIN('000000000');
      setDescription('Demo business');
      setPhone('4155555555');
      setAddress('1150 Market St');
      setCity('San Francisco');
      setState({ name: 'California', abbreviation: 'CA' });
      setZip('94102');
      setTerms(true);
    }
  }, [isDemo, isDev]);

  const g = global();
  const history = useHistory();
  const classes = useStyles();

  const isInputInvalid =
    ein === '' ||
    phone === '' ||
    description === '' ||
    address === '' ||
    city === '' ||
    zip === '' ||
    state === '' ||
    terms === false;

  const handleCreateFundBusiness = async (evt) => {
    await createFundBusiness(
      {
        ein,
        phone,
        product_description: description,
        address,
        address2,
        city,
        state: state.abbreviation,
        zip_code: zip,
      },
      () => history.push('/')
    );
  };

  const handleStateChange = (_, value) => {
    setState(value);
    if (!!value) setStateInput(value);
  };

  const handleStateInputChange = (_, val) => {
    setStateInput(val);
  };

  return (
    <Card noHeader>
      <Grid container spacing={2}>
        {/* Top text section */}
        <Grid item xs={12}>
          <Typography variant="h6" className={g.mb_xxs}>
            Step 2 of 2
          </Typography>
          <Typography variant="h4" className={g.mb_sm}>
            Activate your account
          </Typography>
          <Typography variant="h2" className={g.mb_xs}>
            Tell us about your business
          </Typography>
          <div className={css(classes.divider, g.mb_sm)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                label="EIN"
                fullWidth
                autoFocus
                margin="dense"
                value={ein}
                onChange={(evt) => setEIN(evt.target.value)}
                onPaste={(evt) => {
                  // remove hypens from pasted eins
                  evt.target.value = evt.clipboardData
                    .getData('Text')
                    .replace('-', '');
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                variant="outlined"
                label="Phone Number"
                required
                fullWidth
                margin="dense"
                value={phone}
                onChange={(evt) => setPhone(evt.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                label="Product/service description"
                fullWidth
                autoFocus
                margin="dense"
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                label="Street address"
                fullWidth
                margin="dense"
                value={address}
                onChange={(evt) => setAddress(evt.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Street address 2"
                fullWidth
                margin="dense"
                value={address2}
                onChange={(evt) => setAddress2(evt.target.value)}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                margin="dense"
                required
                value={city}
                onChange={(evt) => setCity(evt.target.value)}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Autocomplete
                options={USStates}
                className={g.mb_zero}
                getOptionLabel={(option) => `${option.abbreviation}`}
                value={state}
                inputValue={stateInput}
                onChange={handleStateChange}
                onInputChange={handleStateInputChange}
                textInputProps={{
                  margin: 'dense',
                  label: 'State',
                  required: true,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Zip code"
                fullWidth
                margin="dense"
                value={zip}
                onChange={(evt) => setZip(evt.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            onChange={(evt) => setTerms(evt.target.checked)}
            label={
              <div>
                <span>
                  This Agreement is between you and Creator Pay and
                  ChannelMeter, Inc. Creator Pay and ChannelMeter, Inc. is a
                  financial technology company. Services provided by
                </span>
                <a href={'https://stripe.com/'}> Stripe Payments Company </a>
                <span>
                  Banking services provided by Evolve Bank & Trust, Member FDIC.
                </span>
              </div>
            }
          />
        </Grid>

        <Grid item xs={12} className={g.textRight}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isInputInvalid}
            loading={loading}
            onClick={handleCreateFundBusiness}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  createFundBusinessState: createFundBusinessSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createFundBusiness: (data, callback) =>
    dispatch(createFundBusinessSlice(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFundBusiness);
