import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from '../slices/sliceUtils';
import {
    getUserCreators as getUserCreatorsAPI,
    updateCreator as updateCreatorAPI,
} from 'euApi/creators';
export const initialContractTemplatesState = {
  getUserCreators: getGenericState([]),
  updateCreator: getGenericState(),
};

export const contractsSlice = createSlice({
  name: 'creators',
  initialState: initialContractTemplatesState,
  reducers: {
    getUserCreatorsStarted: getGenericStarted('getUserCreators'),
    getUserCreatorsSuccess: getPayloadSuccess('getUserCreators'),
    getUserCreatorsFailure: getGenericFailure('getUserCreators'),

    updateCreatorStarted: getGenericStarted('updateCreator'),
    updateCreatorSuccess: getGenericSuccess('updateCreator'),
    updateCreatorFailure: getGenericFailure('updateCreator'),
  },
});

export const {
  getUserCreatorsStarted,
  getUserCreatorsSuccess,
  getUserCreatorsFailure,

  updateCreatorStarted,
  updateCreatorSuccess,
  updateCreatorFailure,
} = contractsSlice.actions;

export default contractsSlice.reducer;

export const getUserCreators = () => async (dispatch, getState) => {
  dispatch(getUserCreatorsStarted());
  try {
    const res = await getUserCreatorsAPI(getState());
    dispatch(getUserCreatorsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getUserCreatorsFailure,
      'There was an issue fetching the creators for your user.'
    );
  }
};

export const updateCreator = (data) => async (dispatch, getState) => {
  dispatch(updateCreatorStarted());
  try {
    const res = await updateCreatorAPI(getState(), data);
    dispatch(updateCreatorSuccess(res));
    dispatch(getUserCreators());
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateCreatorFailure,
      'There was an issue updating the creator'
    );
  }
};

// selectors
const selectCreators = (state) => state.creators;

export const userCreatorsSelector = createSelector(
  selectCreators,
  (creatorsState = {}) =>
    creatorsState.getUserCreators || getGenericState([])
);

export const updateCreatorSelector = createSelector(
  selectCreators,
  (creatorsState = {}) =>
    creatorsState.updateCreator || getGenericState()
);
