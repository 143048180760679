import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'ramda';
import isEmail from 'validator/lib/isEmail';
import { Typography, Grid, Chip, ButtonGroup } from '@material-ui/core';

import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import Button from 'components/Button';
import global from 'styles/global';
import InfoTooltipIcon from 'components/InfoIcon';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  personalSection: {
    marginBottom: theme.spacing(2),
  },
  notification: { marginLeft: theme.spacing(2) },
}));

const AddCreatorModal = ({ open, onClose, addCreators, addCreatorsState }) => {
  const classes = useStyles();
  const g = global();

  const [firstName, setFirstName] = useState({ val: '', dirty: false });
  const [lastName, setLastName] = useState({ val: '', dirty: false });
  const [bizName, setBizName] = useState({ val: '', dirty: false });
  const [email, setEmail] = useState({ val: '', dirty: false });
  const [acct, setAcct] = useState('');
  const [routing, setRouting] = useState('');
  const [phone, setPhone] = useState('');

  const { loading: submitting } = addCreatorsState;

  const bumpNumAdded = () => setNumAdded(numAdded + 1);
  const [numAdded, setNumAdded] = useState(0);
  const [isBiz, setIsBiz] = useState(false);

  const resetCreatorInfoFields = () => {
    setFirstName({ val: '', dirty: false });
    setLastName({ val: '', dirty: false });
    setBizName({ val: '', dirty: false });
    setEmail({ val: '', dirty: false });
    setAcct('');
    setRouting('');
    setPhone('');
  };

  const handleAddCreator = async () => {
    const creatorObj = {
      email: email.val,
      account_number: acct,
      routing_number: routing,
      phone_number: phone,
    };
    if (isBiz) {
      creatorObj.business_name = bizName.val;
    } else {
      creatorObj.first_name = firstName.val;
      creatorObj.last_name = lastName.val;
      creatorObj.entity_type = 'individual';
    }
    const success = await addCreators([creatorObj]);
    if (success) {
      resetCreatorInfoFields();
      bumpNumAdded();
      if (isBiz) {
        bizNameInput.current.focus();
      } else {
        firstNameInput.current.focus();
      }
    }
  };

  const handleClose = () => {
    resetCreatorInfoFields();
    setNumAdded(0);
    onClose();
  };

  const notificationText =
    numAdded === 1
      ? `${numAdded} Creator Added!`
      : `${numAdded} Creators Added!`;

  const isInputValid =
    ((isBiz && !isEmpty(bizName.val)) ||
      (!isBiz && !isEmpty(firstName.val) && !isEmpty(lastName.val))) &&
    isEmail(email.val);

  const firstNameInput = React.useRef(null);
  const bizNameInput = React.useRef(null);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Add Creator</Typography>
        {numAdded > 0 && (
          <Chip
            variant="outlined"
            color="primary"
            label={notificationText}
            className={classes.notification}
          ></Chip>
        )}
      </ModalTitle>

      <ModalContent>
        <div item xs={12} className={g.mb_lg}>
          <ButtonGroup fullWidth variant="outlined">
            <Button
              color="primary"
              variant={!isBiz ? 'contained' : 'outlined'}
              onClick={() => setIsBiz(false)}
            >
              Individual
            </Button>
            <Button
              color="primary"
              variant={isBiz ? 'contained' : 'outlined'}
              onClick={() => setIsBiz(true)}
            >
              Business
            </Button>
          </ButtonGroup>
        </div>

        <Grid container spacing={1} className={classes.personalSection}>
          {/* NAME */}
          {!isBiz ? (
            <>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  label="First name"
                  value={firstName.val}
                  error={firstName.dirty && isEmpty(firstName.val)}
                  helperText={
                    firstName.dirty &&
                    isEmpty(firstName.val) &&
                    'This field is required'
                  }
                  onChange={(e) =>
                    setFirstName({ val: e.target.value, dirty: true })
                  }
                  inputRef={firstNameInput}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Last name"
                  value={lastName.val}
                  error={lastName.dirty && isEmpty(lastName.val)}
                  helperText={
                    lastName.dirty &&
                    isEmpty(lastName.val) &&
                    'This field is required'
                  }
                  onChange={(e) =>
                    setLastName({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  label="Business name"
                  value={bizName.val}
                  error={bizName.dirty && isEmpty(bizName.val)}
                  helperText={
                    bizName.dirty &&
                    isEmpty(bizName.val) &&
                    'This field is required'
                  }
                  onChange={(e) =>
                    setBizName({ val: e.target.value, dirty: true })
                  }
                  inputRef={bizNameInput}
                  required
                  fullWidth
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Email address"
              value={email.val}
              error={email.dirty && !isEmail(email.val)}
              helperText={
                email.dirty &&
                !isEmail(email.val) &&
                'This field is required and must be an email'
              }
              onChange={(e) => setEmail({ val: e.target.value, dirty: true })}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Phone number (optional)"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* BANK INFO */}
        <Typography variant="body2">Bank info (optional)</Typography>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Account number"
              value={acct}
              onChange={(e) => setAcct(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Routing number"
              value={routing}
              onChange={(e) => setRouting(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="subtitle2">
          Don't worry, bank info is not required to pay Creators!
          <InfoTooltipIcon
            height={14}
            title="You can create a Creator without these details and fill them in later. Or if you send a payment to Creator without details, Creator Pay will fetch them on your behalf!"
          />
        </Typography>
      </ModalContent>

      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          loading={submitting}
          onClick={handleAddCreator}
          color="primary"
          variant="contained"
          disabled={!isInputValid}
        >
          Add
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default AddCreatorModal;
