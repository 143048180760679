import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const createOrg = async (state, data) => {
  return createRequest(`/v1/org`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const createFundAcct = async (state, data) => {
  return createRequest(`/v1/org/owner`, state, {
    method: 'POST',
    body: data,
    vgs: true,
  }).then((res) => handleError(res));
};

export const createFundBusiness = async (state, data) => {
  return createRequest(`/v1/org/bank`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const getOrg = async (state) => {
  return createRequest(`/v1/org`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const getOrgs = async (state) => {
  return createRequest(`/v1/orgs`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const updateOrg = async (state, data) => {
  return createRequest(`/v1/org`, state, {
    method: 'PUT',
    body: data,
  }).then((res) => handleError(res));
};

export const changeOrgPlan = async (state, data) => {
  return createRequest(`/v1/org/plan`, state, {
    method: 'PUT',
    body: data,
  }).then((res) => handleError(res));
};

export const updateOrgLogo = async (state, data) => {
  return createRequest(`/v1/org/logo`, state, {
    method: 'PUT',
    body: data,
    contentType: 'multipart/form-data',
  }).then((res) => handleError(res));
};
