import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import css from 'classnames';

import { ReactComponent as LogoFull } from 'assets/cpay_logo_text.svg';
import { signupStateSelector } from 'slices/user';
import WelcomeSplash from 'components/WelcomeSplash';
import global from 'styles/global';
import WelcomeBackground from 'components/WelcomeBackground';
import { signup as signupSlice } from 'slices/user';
import { colors } from 'styles/global';
import Button from 'components/Button';
import TextField from 'components/TextFieldDark';
import Card from 'components/Card';
import { validatePassword } from 'util/validation';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '60%',
    height: 'auto',
    padding: 0,
  },
  fullHeight: {
    height: 'calc(100vh - 72px)',
  },
  welcomeCard: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 28,
    paddingRight: 28,
  },
  invalidPassword: {
    color: theme.palette.error.main,
  },
}));

function SignUp({ signupState }) {
  const g = global();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading: submitting } = signupState;

  const [email, setEmail] = useState({ val: '', dirty: false });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(true);

  const handleSignup = async (evt) => {
    evt.preventDefault();

    const isPasswordValid = validatePassword(password);
    if (!isPasswordValid) {
      setPasswordIsValid(false);
      return;
    } else {
      setPasswordIsValid(true);
    }

    await dispatch(
      signupSlice({ email: email.val.toLowerCase(), password }, () =>
        history.push('/')
      )
    );
  };

  const isInputInvalid =
    email.val === '' ||
    !isEmail(email.val) ||
    password === '' ||
    confirmPassword === '' ||
    password !== confirmPassword ||
    !agreed;

  return (
    <>
      <WelcomeBackground noEarth />
      <Grid
        container
        spacing={2}
        className={css(g.centered, classes.fullHeight)}
      >
        <Grid item sm={12} md={8} className={g.centerChildren}>
          <WelcomeSplash />
        </Grid>
        <Grid item sm={12} md={4} className={g.z_index_1}>
          <Card bgColor={colors.brand.payBlack} className={classes.welcomeCard}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={css(g.centerChildren, g.mb_xxl)}>
                  <LogoFull className={classes.logo} />
                </div>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  title="Email address"
                  value={email.val}
                  error={email.dirty && !isEmail(email.val)}
                  formHelperText={
                    email.dirty &&
                    !isEmail(email.val) &&
                    'This field is required and must be an email'
                  }
                  onChange={(e) =>
                    setEmail({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={passwordIsValid ? classes.invalidPassword : ''}
                  margin="dense"
                  variant="outlined"
                  title="Password"
                  type="password"
                  value={password}
                  hasError={!passwordIsValid}
                  formHelperText="Must be at least 8 characters long with a letter, digit, and a special character"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  title="Confirm password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox className={g.white} color="primary" />}
                  onChange={(evt) => setAgreed(evt.target.checked)}
                  label={
                    <div className={g.white}>
                      <span>I have read and agree to Creator Pay’s </span>
                      <a
                        href={'https://creatorpay.co/terms-of-service'}
                        className={g.white}
                      >
                        Terms of Service
                      </a>
                    </div>
                  }
                />
              </Grid>

              <Grid item xs={12} className={g.mb_xl_i}>
                <Button
                  onClick={handleSignup}
                  loading={submitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isInputInvalid}
                  fullWidth
                >
                  Sign up
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography className={css(g.mb_xs, g.white)} variant="h5">
                  Already have an account?
                </Typography>
                <Button
                  onClick={() => history.push('/signin')}
                  type="submit"
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Sign in
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  signupState: signupStateSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
