import { createParams, handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const createPaymentGroup = async (state, data) => {
  return createRequest(`/v1/campaigns`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const getPaymentGroups = async (state, params)  => {
  const qStr = createParams(params);
  return createRequest(`/v1/campaigns${qStr}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const updatePaymentGroup = async (state, data) => {
  const { id, ...body } = data;
  return createRequest(`/v1/campaigns/${id}`, state, {
    method: 'PUT',
    body,
  }).then((res) => handleError(res));
};
