export const LegalEntityTypes = [
  {
    value: 'individual',
    label: 'Individual/Sole Proprietor or Single-member LLC',
  },
  { value: 'c_corp', label: 'C Corp' },
  { value: 's_corp', label: 'S Corp' },
  { value: 'partnership', label: 'Partnership' },
  { value: 'trust', label: 'Trust/Estate' },
  { value: 'llc_c_corp', label: 'LLC - C Corp' },
  { value: 'llc_s_corp', label: 'LLC - S Corp' },
  { value: 'llc_partnership', label: 'LLC - Partnership' },
];
