import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import global from 'styles/global';
import TableHeader from 'components/TableHeader';
import objectStates from 'constants/objectStates';
import Button from 'components/Button';
import ModalConfirm from 'components/ModalConfirm';
import { hasTreasurySelector } from 'slices/org';
import InvoicesTable from 'components/InvoicesTable';

function Details({
  invoices,
  sendEmailReminders,
  invalidateLoading,
  remindLoading,
  invalidateInvoices,
  customer,
}) {
  const g = global();
  const history = useHistory();

  const hasTreasury = useSelector(hasTreasurySelector);

  const { name, id } = customer;

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const navToNewInvoice = () => {
    history.push(`/invoices/newInvoice?customerId=${id}`);
  };

  const handleSendReminders = () => {
    sendEmailReminders(selectedInvoices, 'email');
  };

  const handleInvalidateInvoices = () => {
    invalidateInvoices(selectedInvoices);
  };

  return (
    <>
      <ModalConfirm
        open={cancelModalVisible}
        handleClose={() => setCancelModalVisible(false)}
        callback={handleInvalidateInvoices}
        title="Are you sure?"
        bodyText={`Are you sure you want to cancel ${
          selectedInvoices.length > 1
            ? `these ${selectedInvoices.length} invoices?`
            : 'this invoice?'
        }`}
        buttonText="Confirm"
      />

      <TableHeader title={`Invoices for ${name}`}>
        <div>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            disabled={selectedInvoices.length === 0}
            onClick={() => setCancelModalVisible(true)}
            loading={invalidateLoading}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={g.ml_xs}
            disabled={selectedInvoices.length === 0}
            onClick={handleSendReminders}
            loading={remindLoading}
          >
            Send email reminder
          </Button>
          <Button
            size="small"
            variant="contained"
            className={g.ml_xs}
            color="primary"
            onClick={navToNewInvoice}
            disabled={!hasTreasury}
          >
            New Invoice
          </Button>
        </div>
      </TableHeader>

      <InvoicesTable
        autoHeight
        autoPageSize
        invoices={invoices}
        columnsToDisplay={[
          'title',
          'amount',
          'net',
          'due_date',
          'status',
          'payment_group_id',
        ]}
        onSelectionModelChange={setSelectedInvoices}
        checkboxSelection
        isRowSelectable={(params) =>
          params.row.status !== objectStates.canceled
        }
      />
    </>
  );
}

export default Details;
