import React, { useDispatch, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import css from 'classnames';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';
import HelloSign from 'hellosign-embedded';
import { isEmpty } from 'ramda';
import { getIsProd } from 'util/env';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import {
  createContractTemplate as createContractTemplateSlice,
  createContractTemplateSelector,
  createEmbeddedLinkSelector,
  createEmbeddedLink as createEmbeddedLinkSlice,
} from 'slices/contractTemplates';
import { setHeaderData } from 'slices/misc';
import global from 'styles/global';
import Button from 'components/Button';
import TableHeader from 'components/TableHeader';
import LoadingOverlay from 'components/LoadingOverlay';
import Card from 'components/Card';
import TextField from 'components/TextField';

const TWENTY_FIVE_MB = 25 * 1000 * 1000;

const isProd = getIsProd();
const client = new HelloSign({
  clientId: isProd
    ? '228994a7d508112f13cff4ca3a0645e2'
    : 'ae06382cacd5d013795c1a6ef884a786',
});

const useStyles = makeStyles((theme) => ({
  docHeader: {
    paddingBottom: '0 !important',
    marginBottom: '-12px',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8),
    borderWidth: '2px',
    borderRadius: 0,
    borderColor: theme.palette.shades.charcoal036,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.shades.charcoal004,
    color: theme.palette.shades.charcoal036,
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
  },
  dropzoneContainer: {
    padding: theme.spacing(3),
  },
  docContainer: {
    border: '1px solid black',
  },
  flowRoot: {
    display: 'flow-root',
  },
  removeSig: {
    position: 'absolute',
    top: -20,
    right: -20,
  },
  pdfCtrBar: {
    padding: theme.spacing(1, 2, 0, 2),
  },
}));

const NewContractTemplate = ({
  createContractTemplate,
  createContractTemplateState,
  createEmbeddedLink,
  createEmbeddedLinkState,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const g = global();
  const history = useHistory();

  const { loading: createContractTemplateLoading } =
    createContractTemplateState;
  const { loading: createEmbeddedLinkLoading } = createEmbeddedLinkState;
  const loading = createContractTemplateLoading || createEmbeddedLinkLoading;

  const [title, setTitle] = useState('');
  const [file, setFile] = useState();
  const [dropErr, setDropErr] = useState('');
  const [template, setTemplate] = useState({});

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'New Contract Template',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Contracts', link: '/contracts' },
          { label: 'New Contract Template' },
        ],
      })
    );
  }, []);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({ accept: 'application/pdf', maxSize: TWENTY_FIVE_MB });

  useEffect(() => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    setFile({
      file: newFile[0],
      preview: URL.createObjectURL(newFile),
    });
    setDropErr('');
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      setDropErr('Contract must be a PDF under 25 MB');
    }
  }, [fileRejections]);

  useEffect(() => {
    if (!isEmpty(template)) {
      createContractTemplate(
        {
          template_id: template.templateId,
          title: template.templateInfo.title,
        },
        () => history.push('/contracts')
      );
    }
  }, [history, createContractTemplate, template]);

  const createEmbeddedLinkAndOpen = async () => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0], acceptedFiles[0].name);
    formData.append('title', title);
    await createEmbeddedLink(formData, (res) => {
      client.open(res.edit_url, { skipDomainVerification: true });
    });
  };

  client.on('createTemplate', (data) => {
    setTemplate(data);
  });

  const isValid = title !== '' && !!file;

  return (
    <div>
      <LoadingOverlay open={loading} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableHeader title="Contract Template details">
            <div>
              <Button
                size="small"
                onClick={createEmbeddedLinkAndOpen}
                variant="contained"
                color="primary"
                loading={loading}
                disabled={!isValid}
              >
                Create Contract Template
              </Button>
            </div>
          </TableHeader>

          <Card noHeader>
            <Grid container spacing={3}>
              <Grid item md={3} sm={12}>
                <TextField
                  size="small"
                  className={g.m_zero}
                  variant="outlined"
                  label="Title"
                  required
                  onChange={(evt) => setTitle(evt.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} className={classes.docHeader}>
          <TableHeader title="Add a document"></TableHeader>
        </Grid>

        <Grid item xs={12}>
          <Card noHeader>
            {!file && (
              <section className={classes.dropzoneContainer}>
                <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  <AddIcon style={{ fontSize: 60 }} className={g.charcoal036} />
                  <Typography variant="body1">
                    Drag and drop a PDF to get started
                  </Typography>
                </div>
                <aside>
                  <Typography variant="body1" className={css(g.error, g.mt_sm)}>
                    {dropErr}
                  </Typography>
                </aside>
              </section>
            )}
            {!!file && (
              <>
                <div className={css(classes.pdfCtrBar, g.flexRow)}>
                  <FilePresentIcon />
                  <Typography className={g.pl_xs} type="h1">
                    {acceptedFiles[0]?.name}
                  </Typography>
                </div>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  createEmbeddedLinkState: createEmbeddedLinkSelector(state),
  createContractTemplateState: createContractTemplateSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createContractTemplate: (data, db) =>
    dispatch(createContractTemplateSlice(data, db)),
  createEmbeddedLink: (data, cb) => dispatch(createEmbeddedLinkSlice(data, cb)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewContractTemplate);
