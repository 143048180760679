import { handleError } from 'api/utils';
import { createRequest } from 'api/request';
import { createParams } from './utils';

export const getWallets = async (state) => {
  return createRequest(`/v1/wallets`, state, {
    method: 'GET',
    vgs: true,
  }).then((res) => handleError(res));
};

export const getWalletDetails = async (state, type) => {
  return createRequest(`/v1/wallets/${type}`, state, {
    method: 'GET',
    vgs: true,
  }).then((res) => handleError(res));
};

export const onboardWallet = async (state, type, step, data) => {
  const qStr = createParams({ step: step });
  return createRequest(`/v1/wallets/${type}${qStr}`, state, {
    method: 'POST',
    body: data,
    vgs: true,
  }).then((res) => handleError(res));
};

export const getTxs = async (state, type) => {
  return createRequest(`/v1/wallets/${type}/transactions`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};
