import React from 'react';
import { Link } from 'react-router-dom';

import { formatCurrency } from 'util/renderStrings';

const PaymentLinkCreator = ({ payment }) => {
  return payment ? (
    <Link to={`/creator/payments`}>
        {formatCurrency(payment.amount)}
    </Link>
  ) : (
    '-'
  );
};

export default PaymentLinkCreator;
