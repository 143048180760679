import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import css from 'classnames';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import global from 'styles/global';
import Card from 'components/Card';
import RadioDescription from 'components/RadioDescription';
import ModalConfirm from 'components/ModalConfirm';
import { currentUserSelector, updateUser } from 'slices/user';
import { billingAuthSelector } from 'slices/org';
import NewAgentMngrModal from 'components/NewAgentMngrModal';
import Button from 'components/Button';
import BillingAuthModal from 'components/BillingAuthModal';

const roleLabels = {
  admin: 'Admin',
  collaborator: 'Team member',
  owner: 'Owner',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: { textTransform: 'uppercase' },
}));

const options = [
  {
    title: 'Team member',
    description: `Team members can do most things, but they can't see the
    Wallets page or execute payments`,
    value: 'collaborator',
  },
  {
    title: 'Admin',
    description: `Admins can do everything except adding or removing other
    Admins`,
    value: 'admin',
  },
];

function OrgMembersSection({ adminsState, deleteMember }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const g = global();

  const [isAddAdminModalVisible, setAddAdminModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');
  const [userToEdit, setUserToEdit] = useState('');
  const [billingModalVisible, setBillingModalVisible] = useState(false);

  // edit member state
  const [newIsAgent, setNewIsAgent] = useState('');
  const [newIsManager, setNewIsManager] = useState('');
  const [newRole, setNewRole] = useState('');

  const { data: currentUser } = useSelector(currentUserSelector);
  const { isFree } = useSelector(billingAuthSelector);
  const { data: admins } = adminsState;

  const handleDelete = (id) => {
    deleteMember(userToDelete);
    setUserToDelete('');
  };

  const handleEditUser = (member) => {
    setUserToEdit(member.id);
    setNewIsAgent(member.is_agent);
    setNewIsManager(member.is_manager);
    setNewRole(member.role);
  };

  const handleAddUserClick = () => {
    if (isFree) {
      setBillingModalVisible(true);
      return;
    }

    setAddAdminModalVisible(true);
  };

  const handleCancel = (id) => {
    setUserToEdit('');
  };

  const handleUpdateMember = async () => {
    const updateObj = {
      id: userToEdit,
      is_agent: newIsAgent,
      is_manager: newIsManager,
    };
    if (currentUser.role === 'admin' || currentUser.role === 'owner') {
      updateObj.role = newRole;
    }
    const success = await dispatch(updateUser(updateObj));
    if (success) {
      handleCancel();
    }
  };

  const submitDisabled = false;

  const getIsDeleteDisabled = (member, currentUser) => {
    if (member.id === currentUser.id) return true;
    if (currentUser.role === 'collaborator') return true;
    if (currentUser.role !== 'owner' && member.role === 'admin') return true;
    if (!!userToEdit && member.id !== userToEdit) return true;
  };

  return (
    <>
      <NewAgentMngrModal
        open={isAddAdminModalVisible}
        handleClose={() => {
          setAddAdminModalVisible(false);
        }}
      />

      <BillingAuthModal
        open={billingModalVisible}
        onClose={() => setBillingModalVisible(false)}
      />

      <ModalConfirm
        open={isDeleteModalVisible}
        handleClose={() => {
          setDeleteModalVisible(false);
          setUserToDelete('');
        }}
        callback={handleDelete}
        title="Confirm deletion?"
        bodyText="Are you sure you want to delete this user?"
        buttonText="Confirm"
      />

      <Card title="Organization members" variant="outlined">
        <div className={classes.content}>
          <Grid container spacing={1}>
            {admins?.map((member) => (
              <Grid item xs={12} key={member.id}>
                <div className={css(g.flexRowSpacing, g.alignCenter)}>
                  <div>
                    <Typography variant="h5">{member.email}</Typography>
                    <Typography variant="subtitle1">
                      {`${roleLabels[member.role]}${
                        member.is_manager ? ', Manager' : ''
                      }${member.is_agent ? ', Agent' : ''}`}
                    </Typography>
                  </div>

                  <div>
                    {userToEdit === member.id ? (
                      <div>
                        <IconButton onClick={handleCancel}>
                          <CloseIcon fontSize="small" className={g.error} />
                        </IconButton>
                        <IconButton
                          onClick={handleUpdateMember}
                          disabled={submitDisabled}
                        >
                          <DoneIcon
                            fontSize="small"
                            className={css(
                              g.cashGreen1,
                              submitDisabled && g.disabledOpacity
                            )}
                          />
                        </IconButton>
                      </div>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => handleEditUser(member)}
                          disabled={getIsDeleteDisabled(member, currentUser)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setUserToDelete(member.id);
                            setDeleteModalVisible(true);
                          }}
                          disabled={getIsDeleteDisabled(member, currentUser)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </div>
                </div>

                {/* Editing member fields */}
                {userToEdit === member.id && (
                  <div className={css(g.p_md, g.border1)}>
                    <Grid item xs={12}>
                      <RadioDescription
                        title="Permissions"
                        value={newRole}
                        setValue={setNewRole}
                        options={options}
                      />
                    </Grid>

                    <div className={g.mt_md}></div>

                    <Typography variant="h5">Roles</Typography>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={newIsAgent} color="primary" />
                        }
                        onChange={(evt) => setNewIsAgent(evt.target.checked)}
                        label={<span>Agent</span>}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={newIsManager} color="primary" />
                        }
                        onChange={(evt) => setNewIsManager(evt.target.checked)}
                        label={<span>Manager</span>}
                      />
                    </Grid>
                  </div>
                )}
              </Grid>
            ))}
            <Grid item xs={12} className={g.centerChildren}>
              <Button
                onClick={handleAddUserClick}
                color="primary"
                variant="text"
                disabled={currentUser.role === 'collaborator'}
              >
                Invite others
              </Button>
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
}

export default OrgMembersSection;
