import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
import ModalConfirm from 'components/ModalConfirm';
import {
  getApiKeys,
  getApiKeysSelector,
  addApiKey,
  deleteApiKey,
} from 'slices/orgApiKeys';
import Card from 'components/Card';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import global from 'styles/global';

function APIKeySection() {
  const dispatch = useDispatch();
  const orgApiKeysState = useSelector(getApiKeysSelector);

  const { data: apiKeys } = orgApiKeysState;
  const g = global();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [apiKeyToDelete, setApiKeyToDelete] = useState();

  useEffect(() => {
    dispatch(getApiKeys());
  }, []);

  const handleAddApiKey = () => {
    dispatch(addApiKey());
  };
  const handleDeleteApiKey = (id) => {
    dispatch(deleteApiKey(id));
    setApiKeyToDelete('');
  };

  return (
    <>
      <ModalConfirm
        open={deleteModalVisible}
        handleClose={() => {
          setDeleteModalVisible(false);
          setApiKeyToDelete('');
        }}
        title="Delete API key"
        bodyText="Are you sure you want to delete the API key?"
        buttonText="Delete"
        callback={() => handleDeleteApiKey(apiKeyToDelete)}
      />

      <Card title="API">
        <Alert severity="info">
          This is your API key for production, for a sandbox API key use the{' '}
          <a
            href="https://trial.creatorpay.co/"
            target="_blank"
            rel="noreferrer noopener"
          >
            trial site
          </a>
        </Alert>

        <div className={g.mt_lg}></div>

        {!apiKeys ? (
          <div className={g.centerChildren}>
            <Typography variant="body1">You don't have an API key</Typography>
            <Button
              color="primary"
              variant="contained"
              className={g.mt_md}
              onClick={handleAddApiKey}
            >
              New API Key
            </Button>
          </div>
        ) : (
          apiKeys.map((apiKey, i) => {
            return (
              <div className={css(g.flexRowSpacing, g.alignCenter)} key={i}>
                <div>
                  <Typography variant="subtitle1">Your API key</Typography>
                  <Typography variant="body1">{apiKey}</Typography>
                </div>
                <div>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setApiKeyToDelete(apiKey);
                      setDeleteModalVisible(true);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                  <IconButton color="primary" onClick={handleAddApiKey}>
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            );
          })
        )}

        <Typography className={g.mt_xl} variant="body2">
          To get started using the Creator Pay API, check out out our{' '}
          <a
            href="https://docs.creatorpay.co/"
            target="_blank"
            rel="noreferrer noopener"
          >
            documentation!
          </a>
        </Typography>
      </Card>
    </>
  );
}

export default APIKeySection;
