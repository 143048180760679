import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import css from 'classnames';

import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import { creatorsSelector } from 'slices/user';
import DataGrid from 'components/DataGrid';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import { renderCreatorCell, getCreatorValue, renderDateValue } from 'util/table';
import global from 'styles/global';
import { deliverablesSelector } from 'slices/deliverables';
import NewDeliverableModal from 'components/NewDeliverableModal';

export default function DeliverablesTab() {
  const g = global();
  const { groupId } = useParams();
  const group = useSelector((state) => {
    return (
      state.paymentGroups.paymentGroups.data.find(
        (grp) => grp.id === groupId
      ) || emptyPaymentGroupData
    );
  });

  const [modalVisible, setModalVisible] = useState(false);

  const { data: creators } = useSelector(creatorsSelector);
  const { data: deliverables } = useSelector(deliverablesSelector);

  const columns = [
    {
        field: 'creator_id',
        headerName: 'Creator',
        flex: 2,
        renderCell: (params) => renderCreatorCell(params, params.value),
        valueGetter: (params) => getCreatorValue(params.value, creators),
      },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
    },
    {
      field: 'post_date',
      disableColumnMenu: true,
      headerName: 'Post Date',
      renderCell: renderDateValue,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
    },
  ];

  return (
    <>
      <NewDeliverableModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
        campaignId={groupId}
        // setNewCreatorId={setNewCreatorId}
      />

      <TableHeader
        title={`Deliverables for ${group.title}`}
        className={g.mt_lg}
      >
        <div>
          <Button
            size="small"
            className={g.ml_xs}
            onClick={() => setModalVisible(true)}
            type="submit"
            variant="contained"
            color="primary"
          >
            New Deliverable
          </Button>
        </div>
      </TableHeader>
      <div className={css(g.tableWrapper, g.mb_xl)}>
        <DataGrid
          variant="outlined"
          autoHeight
          autoPageSize
          rows={deliverables}
          columns={columns}
          columnDependencies={[creators, deliverables]}
        />
      </div>
    </>
  );
}
