import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import global from 'styles/global';
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import Autocomplete from 'components/Autocomplete';

const LinkPaymentGroupModal = ({
  open,
  handleClose,
  updateInvoices,
  paymentGroups,
}) => {
  const g = global();
  const history = useHistory();

  const [paymentGroup, setPaymentGroup] = useState('');
  const [paymentGroupInput, setPaymentGroupInput] = useState('');

  const handlePaymentGroupChange = (_, value) => {
    setPaymentGroup(value || '');
    if (!!value) setPaymentGroupInput(value);
  };

  const handlePaymentGroupInput = (_, val) => {
    setPaymentGroupInput(val);
  };

  const onClose = () => {
    setPaymentGroup('');
    handleClose();
  };

  const onSave = () => {
    setPaymentGroup('');
    updateInvoices(paymentGroup);
  };

  const onNewCampaign = () => {
    setPaymentGroup('');
    handleClose();
    history.push('/campaigns/new');
  };

  return (
    <Modal open={open} handleClose={onClose} fullWidth maxWidth="sm">
      <ModalTitle onClose={onClose}>
        <Typography variant="h3">Link a Campaign</Typography>
      </ModalTitle>
      <ModalContent>
        <Autocomplete
          isForwardRef
          className={g.mb_md}
          options={paymentGroups}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option.title;
          }}
          value={paymentGroup}
          inputValue={paymentGroupInput}
          onChange={handlePaymentGroupChange}
          onInputChange={handlePaymentGroupInput}
          textInputProps={{
            label: 'Campaign',
          }}
        />
      </ModalContent>
      <ModalActions>
        <Button color="primary" variant="outlined" onClick={onNewCampaign}>
          Create a new Campaign
        </Button>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={!paymentGroup}
        >
          Save link
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default LinkPaymentGroupModal;
