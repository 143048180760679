import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getContractTemplates = async (state) => {
  return createRequest(`/v1/contract_templates`, state, {
      method: 'GET',
    }).then((res) => handleError(res));
};


export const createEmbeddedLink = async (state, body) => {
  return createRequest(`/v1/contract_templates/embedded`, state, {
    method: 'POST',
    body,
    contentType: 'multipart/form-data',
  }).then((res) => handleError(res));
};


export const createContractTemplate = async (state, data) => {
    return createRequest(`/v1/contract_templates`, state, {
        method: 'POST',
        body: data,
      }).then((res) => handleError(res));
};
