import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const sendAch = async (state, data) => {
  return createRequest(`/v1/treasury/ach`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const _addFundsDev = async (state, data) => {
  return createRequest(`/v1/treasury/load`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const attachBank = async (state, data) => {
  return createRequest(`/v1/treasury/account`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const sendAchDebit = async (state, data) => {
  return createRequest(`/v1/treasury/ach_debit`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};
