import { handleError, createParams } from 'api/utils';
import { createRequest } from 'api/request';

export const getConnections = async (state) => {
  return createRequest(`/api/orgOauth`, state, {
    method: 'GET',
    isNode: true,
  }).then((res) => handleError(res));
};

export const deleteConnection = async (state, id) => {
  const params = createParams({ id });
  return createRequest(`/api/orgOauth${params}`, state, {
    method: 'DELETE',
    isNode: true,
  }).then((res) => handleError(res));
};
