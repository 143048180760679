import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import global from 'styles/global';
import CreateTaxDocsModal from 'components/CreateTaxDocsModal';
import UsersTable from 'components/UsersTable';
import { creatorsSelector, getCreators } from 'slices/user';
import { setHeaderData } from 'slices/misc';
import { paymentRecipientsDictSelector } from 'slices/multiSliceSelectors';
import { adminsSelector, getMembers } from 'slices/user';

function Taxes() {
  const g = global();
  const dispatch = useDispatch();

  const { loading: adminsLoading } = useSelector(adminsSelector);
  const { loading: creatorsLoading } = useSelector(creatorsSelector);

  const paymentRecipientsDict = useSelector(paymentRecipientsDictSelector);

  const loading = adminsLoading || creatorsLoading;

  const [isModalOpen, setModalIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Taxes',
        breadcrumbs: [{ label: 'Creator Pay', link: '/' }, { label: 'Taxes' }],
      })
    );

    dispatch(getMembers());
    dispatch(getCreators());
  }, []);

  return (
    <div>
      <LoadingOverlay open={loading} />
      <CreateTaxDocsModal
        open={isModalOpen}
        onClose={() => setModalIsOpen(false)}
        users={selectedUsers}
      />

      {/* All */}
      <TableHeader title="Paid Users">
        <div>
          <Button
            size="small"
            variant="contained"
            className={g.ml_xs}
            color="primary"
            onClick={() => setModalIsOpen(true)}
            disabled={selectedUsers.length === 0}
          >
            Generate Tax Documents
          </Button>
        </div>
      </TableHeader>

      <UsersTable
        users={Object.values(paymentRecipientsDict)}
        columnsToDisplay={['emailRecipient', 'name', 'isW2']}
        checkboxEnabled
        onSelectionModelChange={setSelectedUsers}
      />
    </div>
  );
}

export default Taxes;
