import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getTaxDocs = async (state, data) => {
  return createRequest(`/api/taxes`, state, {
    method: 'POST',
    body: data,
    isPdfRenderer: true,
  }).then((res) => handleError(res, false));
};
