import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';

import { updateAgentMngr } from 'slices/agentsMngrs';
import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import PercentInput from 'components/PercentInput';

function PaymentInfo({ agentMngr }) {
  const dispatch = useDispatch();

  const { agent_split, is_w2, custom_withholding_rate } = agentMngr;

  const [newSplit, setNewSplit] = useState(10);
  const [newIsW2, setNewIsW2] = useState(false);
  const [newWithholding, setNewWithholding] = useState(null);

  useEffect(() => {
    setNewSplit(agent_split);
    setNewIsW2(is_w2);
  }, [agent_split, is_w2]);

  const cancelEditing = () => {
    setNewSplit(agent_split);
    setNewIsW2(is_w2);
  };

  const handleUpdate = async () => {
    const rate =
      !!newWithholding && isFinite(newWithholding) ? +newWithholding : null;
    const updateObj = {
      id: agentMngr.id,
      agent_split: +newSplit,
      is_w2: newIsW2,
      custom_withholding_rate: rate,
    };
    await dispatch(updateAgentMngr(updateObj));
  };

  return (
    <EditCard
      title="Payment information"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdate}
      staticContent={
        <Grid container spacing={1}>
          {agentMngr.is_agent && (
            <Grid item xs={12}>
              <KeyValuePair label="Agent's split" value={newSplit + '%'} />
            </Grid>
          )}
          <Grid item xs={12}>
            <KeyValuePair
              label="Is W2 employee?"
              value={is_w2 ? 'Yes' : 'No'}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyValuePair
              label="Custom state withholding"
              value={
                isFinite(custom_withholding_rate)
                  ? `${custom_withholding_rate}%`
                  : 'Use default rate'
              }
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={1}>
        {agentMngr.is_agent && (
          <Grid item xs={12} sm={8}>
            <PercentInput
              label="Agent's split"
              variant="outlined"
              margin="dense"
              value={newSplit}
              onChange={(evt) => setNewSplit(evt.target.value)}
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12} sm={8}>
          <FormControlLabel
            control={
              <Checkbox
                checked={newIsW2}
                onChange={(evt) => {
                  setNewIsW2(evt.target.checked);
                }}
                color="primary"
              />
            }
            label="Is W2 Employee?"
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <PercentInput
            label="Custom state withholding"
            variant="outlined"
            margin="dense"
            value={newWithholding}
            onChange={(evt) => setNewWithholding(evt.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default PaymentInfo;
