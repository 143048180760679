import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  getTagOptions as getTagOptionsAPI,
  getCreatorTags as getCreatorTagsAPI,
  addTagToCreator as addTagToCreatorAPI,
  createTag as createTagAPI,
  removeTagFromCreator as removeTagFromCreatorAPI,
} from 'api/tags';

export const initialTagsState = {
  tagOptions: getGenericState([]),
  addTagToCreator: getGenericState(),
  creatorTags: getGenericState({}),
  createTag: getGenericState(),
  removeTagFromCreator: getGenericState(),
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: initialTagsState,
  reducers: {
    getTagOptionsStarted: getGenericStarted('tagOptions'),
    getTagOptionsSuccess: getPayloadSuccess('tagOptions'),
    getTagOptionsFailure: getGenericFailure('tagOptions'),

    getCreatorTagsStarted: getGenericStarted('creatorTags'),
    getCreatorTagsSuccess: (state, action) => {
      state.creatorTags.data[action.payload.creator_id] = action.payload.data;
    },
    getCreatorTagsFailure: getGenericFailure('creatorTags'),

    addTagToCreatorStarted: getGenericStarted('addTagToCreator'),
    addTagToCreatorSuccess: getPayloadSuccess('addTagToCreator'),
    addTagToCreatorFailure: getGenericFailure('addTagToCreator'),

    createTagStarted: getGenericStarted('createTag'),
    createTagSuccess: getPayloadSuccess('createTag'),
    createTagFailure: getGenericFailure('createTag'),

    removeTagFromCreatorStarted: getGenericStarted('removeTagFromCreator'),
    removeTagFromCreatorSuccess: getPayloadSuccess('removeTagFromCreator'),
    removeTagFromCreatorFailure: getGenericFailure('removeTagFromCreator'),
  },
});

export const {
  getTagOptionsStarted,
  getTagOptionsSuccess,
  getTagOptionsFailure,
  getCreatorTagsStarted,
  getCreatorTagsSuccess,
  getCreatorTagsFailure,
  addTagToCreatorStarted,
  addTagToCreatorSuccess,
  addTagToCreatorFailure,
  createTagStarted,
  createTagSuccess,
  createTagFailure,
  removeTagFromCreatorStarted,
  removeTagFromCreatorSuccess,
  removeTagFromCreatorFailure,
} = tagsSlice.actions;

export default tagsSlice.reducer;

export const getTagOptions = (data) => async (dispatch, getState) => {
  dispatch(getTagOptionsStarted());
  try {
    const res = await getTagOptionsAPI(getState(), data);
    dispatch(getTagOptionsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getTagOptionsFailure,
      'There was an issue retrieving your Tags'
    );
  }
};

export const getCreatorTags = (data) => async (dispatch, getState) => {
  dispatch(getCreatorTagsStarted());
  try {
    const res = await getCreatorTagsAPI(getState(), data);
    dispatch(getCreatorTagsSuccess({ data: res, creator_id: data.creator_id }));
    dispatch(getTagOptions());
  } catch (err) {
    handleError(
      err,
      dispatch,
      getCreatorTagsFailure,
      "There was an issue getting this Creator's Tags"
    );
  }
};

export const addTagToCreator = (data) => async (dispatch, getState) => {
  dispatch(addTagToCreatorStarted());
  try {
    const res = await addTagToCreatorAPI(getState(), data);
    dispatch(addTagToCreatorSuccess(res));
    dispatch(getTagOptions());
    dispatch(getCreatorTags(data[0]));
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      addTagToCreatorFailure,
      'There was an issue adding this Tag'
    );
  }
};

export const createTag = (data) => async (dispatch, getState) => {
  if (!data) return;
  dispatch(createTagStarted());
  try {
    const res = await createTagAPI(getState(), data);
    dispatch(createTagSuccess(res));
    dispatch(getTagOptions());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      createTagFailure,
      'There was an issue creating this Tag'
    );
  }
};

export const removeTagFromCreator = (data, creatorId) => async (dispatch, getState) => {
  dispatch(removeTagFromCreatorStarted());
  try {
    const res = await removeTagFromCreatorAPI(getState(), data);
    dispatch(removeTagFromCreatorSuccess(res));
    dispatch(getCreatorTags({ creator_id: creatorId }));
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      removeTagFromCreatorFailure,
      'There was an issue removing this Tag'
    );
  }
};


// selectors
const selectTags = (state) => state.tags;

export const tagOptionsSelector = createSelector(
  selectTags,
  (tagsState = {}) => tagsState.tagOptions || getGenericState([])
);

export const createTagSelector = createSelector(
  selectTags,
  (tagsState) => tagsState.createTag || getGenericState()
);

export const removeTagFromCreatorSelector = createSelector(
  selectTags,
  (tagsState) => tagsState.removeTagFromCreator || getGenericState()
);

export const addTagToCreatorSelector = createSelector(
  selectTags,
  (tagsState) => tagsState.addTagToCreator || getGenericState()
);

const reflectSecond = (_, second) => second;
export const creatorTagsSelector = createSelector(
  [selectTags, reflectSecond],
  (tagsState, creatorId) => tagsState.creatorTags.data[creatorId] || []
);
