import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { isEmpty } from 'ramda';
import {
  Grid,
  IconButton,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import global from 'styles/global';
import DollarInput from 'components/DollarInput';
import PercentInput from 'components/PercentInput';
import constants from 'constants/index';
import { formatCreatorName, formatCurrency } from 'util/renderStrings';
import { paymentGroupTypes } from 'constants/paymentGroups';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  destructive: {
    color: theme.palette.error.main,
  },
  paymentRow: {
    alignItems: 'start',
  },
  closeIcon: {
    height: '40px',
  },
}));

const NewPaymentRow = ({
  vals,
  updateProp,
  creators,
  paymentGroups,
  handleDelete,
  suppressGroup,
  idx,
}) => {
  const g = global();
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState('');

  const validateFields = () => {
    const { recipient, amount } = vals;
    let error = '';
    if (!recipient) {
      error = 'No Recipent specified';
    } else if (!amount) {
      error = 'No amount provided';
    } else if (amount * 100 <= constants.paymentAmtThreshold) {
      error = `Amount to be paid should be more than ${formatCurrency(
        constants.paymentAmtThreshold
      )}`;
    }

    return error;
  };

  const handleUpdateProp = (id, val, key) => {
    if (!dirty) {
      setDirty(true);
    }

    updateProp(id, val, key);
  };

  useEffect(() => {
    const error = validateFields();
    setError(error);
  }, [vals.earnings, vals.split, vals.recipient, vals.memo, vals.group]);

  return (
    <div className={css(classes.paper)}>
      <Grid container spacing={1} className={classes.paymentRow}>
        <Grid
          item
          sm={suppressGroup ? 3 : 2}
          className={css(g.flexRow, g.alignCenter, g.mb_zero)}
        >
          {/* creator selection */}
          <Typography variant="h5" className={g.mr_sm}>
            {idx + 1}
          </Typography>
          {creators.length === 0 ? (
            <Link to="/creators">
              <Typography variant="h5">Add a Creator</Typography>
            </Link>
          ) : (
            <Autocomplete
              options={creators}
              // Disabling inactive creators if there is a campaign selected
              // Not disabling managers/agents
              getOptionDisabled={(creator) =>
                creator.is_external
                  ? !isEmpty(vals.group) && !creator.is_active
                  : false
              }
              getOptionLabel={formatCreatorName}
              value={vals.recipient}
              onChange={(_, value) =>
                handleUpdateProp(vals.id, value, 'recipient')
              }
              textInputProps={{
                label: 'Recipient',
                required: true,
              }}
            />
          )}
        </Grid>

        {/* campaign selection */}
        {!suppressGroup && (
          <Grid item xs={2} className={css(g.flexRow, g.alignCenter)}>
            <Autocomplete
              options={paymentGroups.filter(
                (x) => x.type === paymentGroupTypes.campaign
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.title}
              onChange={(_, value) => handleUpdateProp(vals.id, value, 'group')}
              value={vals.group}
              textInputProps={{
                label: 'Campaign',
              }}
              disabled={!!vals.recipient && !vals.recipient.is_active}
            />
          </Grid>
        )}

        {/* memo input */}
        <Grid item sm={2} className={css(g.flexRow, g.alignCenter)}>
          <TextField
            margin="dense"
            className={g.m_zero_i}
            variant="outlined"
            label="Memo"
            onChange={(evt) =>
              handleUpdateProp(vals.id, evt.target.value, 'memo')
            }
            inputProps={{ maxLength: constants.maxMemoLength }}
            fullWidth
          />
        </Grid>

        {/* earnings input */}
        <Grid item sm={2} className={css(g.flexRow, g.alignCenter)}>
          <DollarInput
            value={vals.earnings}
            margin="dense"
            className={g.m_zero_i}
            variant="outlined"
            label="Earnings"
            onChange={(evt) =>
              handleUpdateProp(vals.id, evt.target.value, 'earnings')
            }
            required
            fullWidth
          />
        </Grid>

        {/* split input */}
        <Grid item sm={2} className={css(g.flexRow, g.alignCenter)}>
          <PercentInput
            label="Split"
            className={g.m_zero_i}
            value={vals.split}
            onChange={(evt) =>
              handleUpdateProp(vals.id, evt.target.value, 'split')
            }
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item sm={suppressGroup ? 3 : 2} className={css(g.flexRow)}>
          <DollarInput
            value={vals.amount}
            margin="dense"
            disabled
            className={g.m_zero_i}
            variant="outlined"
            label="Amount"
            required
            fullWidth
            helperText={
              vals.state_withholdings + vals.federal_withholdings > 0 &&
              `Withholding: ${formatCurrency(
                (vals.state_withholdings + vals.federal_withholdings) * 100
              )}`
            }
          />
          <IconButton
            className={css(g.p_zero, g.pl_xs, classes.closeIcon)}
            onClick={() => handleDelete(vals.id)}
          >
            <CloseIcon className={classes.destructive} />
          </IconButton>
        </Grid>
        <FormHelperText error className={g.ml_lg}>
          {dirty && error}
        </FormHelperText>
      </Grid>
    </div>
  );
};

export default NewPaymentRow;
