import React, { useState, useEffect } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepButton } from '@material-ui/core';

import LoadingOverlay from 'components/LoadingOverlay';
import { getCreators, creatorsSelector } from 'slices/user';
import { agentsMngrsSelector } from 'slices/agentsMngrs';
import { getPaymentGroups, paymentGroupsSelector } from 'slices/paymentGroups';
import global from 'styles/global';
import StepWrapper from './StepWrapper';
import { setHeaderData } from 'slices/misc';
import {
  getPayments,
  paymentsSelector,
  paymentRecipientTotalsSelector,
  getRecipientPaymentTotals,
} from 'slices/payments';
import { getDeliverables, deliverablesSelector } from 'slices/deliverables';

const useStyles = makeStyles((theme) => ({
  stepper: {
    '& > .MuiStepLabel-root': {
      '& > .MuiStepLabel-labelContainer': {
        '& > .MuiStepLabel-active': {
          fontWeight: 600,
        },
      },
    },
  },
}));

export default function NewCampaign() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const g = global();

  const query = new URLSearchParams(useLocation().search);
  const campaignId = query.get('campaignId');

  const steps = ['General', 'Payments', 'Deliverables', 'Contract'];

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'New Campaign',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Campaigns', link: '/campaigns' },
          { label: 'New Campaign' },
        ],
      })
    );

    dispatch(getCreators());
    dispatch(getPaymentGroups());
    dispatch(getRecipientPaymentTotals());
  }, []);

  useEffect(() => {
    if (!!campaignId) {
      dispatch(
        getPayments({
          payment_group_id: campaignId,
        })
      );
      dispatch(getDeliverables({ campaign_id: campaignId }));
    }
  }, [campaignId]);

  const { loading: creatorsLoading } = useSelector(creatorsSelector);
  const { loading: payGroupsLoading } = useSelector(paymentGroupsSelector);
  const { loading: agentsMngrsLoading } = useSelector(agentsMngrsSelector);
  const { loading: paymentsLoading } = useSelector(paymentsSelector);
  const { loading: deliverablesLoading } = useSelector(deliverablesSelector);
  const { loading: totalsLoading } = useSelector(
    paymentRecipientTotalsSelector
  );
  const loading =
    creatorsLoading ||
    payGroupsLoading ||
    agentsMngrsLoading ||
    paymentsLoading ||
    deliverablesLoading ||
    totalsLoading;

  const handleStep = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      <LoadingOverlay open={loading} />

      <Stepper nonLinear activeStep={activeStep} className={g.bg_offWhite}>
        {steps.map((step, index) => (
          <Step className={css({ [g.clickable]: !!campaignId })} key={step}>
            <StepButton
              disabled={!campaignId}
              onClick={() => handleStep(index)}
              classes={{
                root: classes.stepper,
              }}
            >
              {step}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <StepWrapper
        step={steps[activeStep]}
        handleStep={handleStep}
      ></StepWrapper>
    </div>
  );
}
