import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'ramda';
import isEmail from 'validator/lib/isEmail';
import { Typography, Grid, Chip } from '@material-ui/core';

import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import { addCustomerSelector, addCustomer } from 'slices/customers';
import Button from 'components/Button';
import global from 'styles/global';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  notification: { marginLeft: theme.spacing(2) },
}));

const AddCustomerModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const g = global();

  const [name, setName] = useState({ val: '', dirty: false });
  const [email, setEmail] = useState({ val: '', dirty: false });
  const [attn, setAttn] = useState({ val: '', dirty: false });
  const [ccEmails, setCCEmails] = useState({ val: '', dirty: false });

  const { loading: submitting } = useSelector(addCustomerSelector);

  const bumpNumAdded = () => setNumAdded(numAdded + 1);
  const [numAdded, setNumAdded] = useState(0);

  const resetCustomerFields = () => {
    setName({ val: '', dirty: false });
    setEmail({ val: '', dirty: false });
    setAttn({ val: '', dirty: false });
    setCCEmails({ val: '', dirty: false });
  };

  const handleAddCustomer = async () => {
    const customerObj = {
      email: email.val,
      name: name.val,
      attn: attn.val,
      cc_emails: ccEmails.val.split(','),
    };
    const success = await dispatch(addCustomer(customerObj));
    if (success) {
      resetCustomerFields();
      bumpNumAdded();
    }
  };

  const handleClose = () => {
    resetCustomerFields();
    onClose();
  };

  const notificationText =
    numAdded === 1
      ? `${numAdded} Customer Added!`
      : `${numAdded} Customers Added!`;

  const isInputValid =
    !isEmpty(name.val) && // name required
    (!isEmpty(email.val) || isEmail(email.val)) && // email required
    (ccEmails.val === '' ||
      ccEmails.val.split(',').every((ccEmail) => isEmail(ccEmail))); // cc emails must be empty or all valid emails

  const nameInput = React.useRef(null);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Add Customer</Typography>
        {numAdded > 0 && (
          <Chip
            variant="outlined"
            color="primary"
            label={notificationText}
            className={classes.notification}
          ></Chip>
        )}
      </ModalTitle>

      <ModalContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="Name"
              value={name.val}
              error={name.dirty && isEmpty(name.val)}
              helperText={
                name.dirty && isEmpty(name.val) && 'This field is required'
              }
              onChange={(e) => setName({ val: e.target.value, dirty: true })}
              required
              fullWidth
              inputRef={nameInput}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Email address"
              value={email.val}
              error={email.dirty && !isEmail(email.val)}
              helperText={
                email.dirty &&
                !isEmail(email.val) &&
                'This field is required and must be an email'
              }
              onChange={(e) => setEmail({ val: e.target.value, dirty: true })}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Attn"
              value={attn.val}
              onChange={(e) => setAttn({ val: e.target.value, dirty: true })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="CC emails"
              value={ccEmails.val}
              // error={email.dirty && !isEmail(email.val)}
              // helperText={
              //   email.dirty &&
              //   !isEmail(email.val) &&
              //   'This field is required and must be an email'
              // }
              onChange={(e) =>
                setCCEmails({ val: e.target.value, dirty: true })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          loading={submitting}
          onClick={handleAddCustomer}
          color="primary"
          variant="contained"
          disabled={!isInputValid}
        >
          Add
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default AddCustomerModal;
