import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import objectStates from 'constants/objectStates';
import {
  getPayments as getPaymentsAPI,
  createPayments as createPaymentsAPI,
  initiatePayments as initiatePaymentsAPI,
  remindPayment as remindPaymentAPI,
  deletePayments as deletePaymentsAPI,
  updatePayments as updatePaymentsAPI,
  getRecipientPaymentTotals as getRecipientPaymentTotalsAPI,
} from 'api/payments';
import { emptyPaymentData } from 'fixtures/payments';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialOrgState = {
  createPayments: getGenericState(),
  getPayments: getGenericState(),
  payments: getGenericState([]),
  remindPayments: getGenericState(),
  deletePayments: getGenericState(),
  updatePayments: getGenericState(),
  getRecipientPaymentTotals: getGenericState({}),
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: initialOrgState,
  reducers: {
    createPaymentsStarted: getGenericStarted('createsPayment'),
    createPaymentsSuccess: getGenericSuccess('createsPayment'),
    createPaymentsFailure: getGenericFailure('createsPayment'),

    getPaymentsStarted: getGenericStarted('payments'),
    getPaymentsSuccess: getPayloadSuccess('payments'),
    getPaymentsFailure: getGenericFailure('payments'),

    initiatePaymentsStarted: getGenericStarted('initiatePayments'),
    initiatePaymentsSuccess: getPayloadSuccess('initiatePayments'),
    initiatePaymentsFailure: getGenericFailure('initiatePayments'),

    remindPaymentsStarted: getGenericStarted('remindPayments'),
    remindPaymentsSuccess: getPayloadSuccess('remindPayments'),
    remindPaymentsFailure: getGenericFailure('remindPayments'),

    deletePaymentsStarted: getGenericStarted('deletePayments'),
    deletePaymentsSuccess: getPayloadSuccess('deletePayments'),
    deletePaymentsFailure: getGenericFailure('deletePayments'),

    updatePaymentsStarted: getGenericStarted('updatePayments'),
    updatePaymentsSuccess: getGenericSuccess('updatePayments'),
    updatePaymentsFailure: getGenericFailure('updatePayments'),

    getRecipientPaymentTotalsStarted: getGenericStarted(
      'getRecipientPaymentTotals'
    ),
    getRecipientPaymentTotalsSuccess: getPayloadSuccess(
      'getRecipientPaymentTotals'
    ),
    getRecipientPaymentTotalsFailure: getGenericFailure(
      'getRecipientPaymentTotals'
    ),
  },
});

export const {
  createPaymentsStarted,
  createPaymentsSuccess,
  createPaymentsFailure,

  getPaymentsStarted,
  getPaymentsSuccess,
  getPaymentsFailure,

  initiatePaymentsStarted,
  initiatePaymentsSuccess,
  initiatePaymentsFailure,

  remindPaymentsStarted,
  remindPaymentsSuccess,
  remindPaymentsFailure,

  deletePaymentsStarted,
  deletePaymentsSuccess,
  deletePaymentsFailure,

  updatePaymentsStarted,
  updatePaymentsSuccess,
  updatePaymentsFailure,

  getRecipientPaymentTotalsStarted,
  getRecipientPaymentTotalsSuccess,
  getRecipientPaymentTotalsFailure,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;

export const getPayments = (params) => async (dispatch, getState) => {
  dispatch(getPaymentsStarted());
  try {
    const res = await getPaymentsAPI(getState(), params);
    dispatch(getPaymentsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getPaymentsFailure,
      'There was an issue retrieving your payments'
    );
  }
};

export const createPayments =
  (data, cb, suppressRefetch) => async (dispatch, getState) => {
    dispatch(createPaymentsStarted());
    try {
      const res = await createPaymentsAPI(getState(), data);
      dispatch(createPaymentsSuccess(res));
      !suppressRefetch && dispatch(getPayments());
      !!cb && cb();
      return res;
    } catch (err) {
      handleError(
        err,
        dispatch,
        createPaymentsFailure,
        'There was an issue creating your payments'
      );
    }
  };

export const initiatePayments =
  (data, cb, refetchParams) => async (dispatch, getState) => {
    dispatch(initiatePaymentsStarted());
    try {
      const res = await initiatePaymentsAPI(getState(), data);
      dispatch(initiatePaymentsSuccess(res));
      dispatch(getPayments(refetchParams));
      !!cb && cb();
    } catch (err) {
      handleError(
        err,
        dispatch,
        initiatePaymentsFailure,
        'There was an issue initiating your payment(s)'
      );
    }
  };

export const remindPayments = (ids, method) => async (dispatch, getState) => {
  dispatch(remindPaymentsStarted());
  try {
    await remindPaymentAPI(getState(), ids, method);
    dispatch(remindPaymentsSuccess());
    toast.success(`Reminder(s) have been sent!`);
  } catch (err) {
    handleError(
      err,
      dispatch,
      remindPaymentsFailure,
      'There was an issue sending your Payment reminder(s)'
    );
  }
};

export const deletePayments =
  (data, suppressRefetch, suppressToast) => async (dispatch, getState) => {
    dispatch(deletePaymentsStarted());
    try {
      await deletePaymentsAPI(getState(), data);
      dispatch(deletePaymentsSuccess());
      !suppressRefetch && dispatch(getPayments());
      !suppressToast && toast.success('Payments have been deleted!');
    } catch (err) {
      handleError(
        err,
        dispatch,
        deletePaymentsFailure,
        'There was an issue deleting your payment(s)'
      );
    }
  };

export const updatePayments =
  (data, suppressRefetch, suppressToast) => async (dispatch, getState) => {
    dispatch(updatePaymentsStarted());
    try {
      await updatePaymentsAPI(getState(), data);
      dispatch(updatePaymentsSuccess());
      !suppressRefetch && dispatch(getPayments());
      !suppressToast && toast.success('Payment updated!');
    } catch (err) {
      handleError(
        err,
        dispatch,
        updatePaymentsFailure,
        'There was an issue updating your payment'
      );
    }
  };

export const getRecipientPaymentTotals = () => async (dispatch, getState) => {
  dispatch(getRecipientPaymentTotalsStarted());
  try {
    const res = await getRecipientPaymentTotalsAPI(getState());
    dispatch(getRecipientPaymentTotalsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getRecipientPaymentTotalsFailure,
      'There was an issue retrieving your toal payments'
    );
  }
};

// selectors
const selectPayments = (state) => state.payments;
const selectId = (_, id) => id;

export const createPaymentsSelector = createSelector(
  selectPayments,
  (paymentsState = {}) => paymentsState.createPayments || getGenericState()
);

export const paymentsSelector = createSelector(
  selectPayments,
  (paymentsState = {}) => paymentsState.payments || getGenericState([])
);

export const paymentTotalsSelector = createSelector(
  paymentsSelector,
  (paymentsState) =>
    paymentsState.data.reduce(
      (agg, payment) => {
        if (
          payment.status === objectStates.requires_info ||
          payment.status === objectStates.processing ||
          payment.status === objectStates.draft
        ) {
          agg.pending += payment.amount;
        } else if (payment.status === objectStates.succeeded) {
          agg.completed += payment.amount;
        }
        return agg;
      },
      { pending: 0, completed: 0 }
    )
);

export const initiatePaymentsSelector = createSelector(
  selectPayments,
  (paymentsState = {}) => paymentsState.initiatePayments || getGenericState()
);

export const remindPaymentsSelector = createSelector(
  selectPayments,
  (paymentsState = {}) => paymentsState.remindPayments || getGenericState()
);

export const deletePaymentsSelector = createSelector(
  selectPayments,
  (paymentsState = {}) => paymentsState.deletePayments || getGenericState()
);

export const paymentsDictSelector = createSelector(
  paymentsSelector,
  (paymentsState = {}) => {
    const { data: pmts } = paymentsState;
    return pmts.reduce((agg, pmt) => {
      agg[pmt.id] = pmt;
      return agg;
    }, {});
  }
);

export const paymentsByRecipientDictSelector = createSelector(
  paymentsSelector,
  (paymentsState = {}) => {
    const { data: pmts } = paymentsState;
    return pmts.reduce((agg, pmt) => {
      if (!agg[pmt.recipient]) agg[pmt.recipient] = [];
      agg[pmt.recipient].push(pmt);
      return agg;
    }, {});
  }
);

export const paymentsForCampaignSelector = createSelector(
  [paymentsSelector, selectId],
  (paymentsState = {}, id) => {
    const { data: payments } = paymentsState;
    return payments.filter((pmt) => pmt.payment_group_id === id);
  }
);

export const campaignPaymentsByRecipientDictSelector = createSelector(
  paymentsForCampaignSelector,
  (payments) => {
    return payments.reduce((agg, pmt) => {
      if (!agg[pmt.recipient]) agg[pmt.recipient] = [];
      agg[pmt.recipient].push(pmt);
      return agg;
    }, {});
  }
);

export const campaignPaymentsByManagedCreatorSelector = createSelector(
  paymentsForCampaignSelector,
  (payments) => {
    return payments.reduce((agg, pmt) => {
      if (pmt.recipient_role === 'manager') {
        agg[pmt.managed_creator_id] = pmt;
      }
      return agg;
    }, {});
  }
);

export const agentPaymentForCampaignSelector = createSelector(
  paymentsForCampaignSelector,
  (payments) => {
    return payments.find((pmt) => pmt.recipient_role === 'agent');
  }
);

export const paymentsByRecipientSelector = createSelector(
  [paymentsSelector, selectId],
  (paymentsState, id) => {
    const { data: payments } = paymentsState;
    return payments.filter((pmt) => pmt.recipient === id);
  }
);

export const draftPaymentsSelector = createSelector(
  paymentsSelector,
  (paymentsState) => {
    const { data: payments } = paymentsState;
    return payments.filter((pmt) => pmt.status === objectStates.draft);
  }
);

export const nonDraftPaymentsSelector = createSelector(
  paymentsSelector,
  (paymentsState) => {
    const { data: payments } = paymentsState;
    return payments.filter((pmt) => pmt.status !== objectStates.draft);
  }
);

export const initiatedPaymentsSelector = createSelector(
  paymentsSelector,
  (paymentsState) => {
    const { data: payments } = paymentsState;
    return payments.filter(
      (pmt) =>
        pmt.status === objectStates.requires_info ||
        pmt.status === objectStates.processing
    );
  }
);

export const erroredPaymentsSelector = createSelector(
  paymentsSelector,
  (paymentsState) => {
    const { data: payments } = paymentsState;
    return payments.filter(
      (pmt) =>
        pmt.status === objectStates.failed ||
        pmt.status === objectStates.canceled ||
        pmt.status === objectStates.requires_info
    );
  }
);

export const postedPaymentsSelector = createSelector(
  paymentsSelector,
  (paymentsState) => {
    const { data: payments } = paymentsState;
    return payments.filter((pmt) => pmt.status === objectStates.succeeded);
  }
);

export const paymentDetailsSelect = createSelector(
  [selectPayments, selectId],
  (paymentsState, id) =>
    paymentsState.paymentDetails[id] || getGenericState(emptyPaymentData)
);

export const updatePaymentSelector = createSelector(
  selectPayments,
  (paymentsState = {}) => paymentsState.updatePayment || getGenericState()
);

export const paymentRecipientTotalsSelector = createSelector(
  selectPayments,
  (paymentsState = {}) =>
    paymentsState.getRecipientPaymentTotals || getGenericState({})
);
