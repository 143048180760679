import { createSlice, createSelector } from '@reduxjs/toolkit';
import { groupBy, prop } from 'ramda';

import {
  getPaymentGroups as getPaymentGroupsAPI,
  createPaymentGroup as createPaymentGroupAPI,
  updatePaymentGroup as updatePaymentGroupAPI,
} from 'api/paymentGroup';
import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import moment from 'moment';
import { statuses } from 'util/campaigns';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialPaymentGroupsState = {
  createPaymentGroup: getGenericState(),
  getPaymentGroups: getGenericState(),
  paymentGroups: getGenericState([]),
};

export const paymentGroupsSlice = createSlice({
  name: 'paymentGroups',
  initialState: initialPaymentGroupsState,
  reducers: {
    createPaymentGroupStarted: getGenericStarted('createPaymentGroup'),
    createPaymentGroupSuccess: getGenericSuccess('createPaymentGroup'),
    createPaymentGroupFailure: getGenericFailure('createPaymentGroup'),

    getPaymentGroupsStarted: getGenericStarted('paymentGroups'),
    getPaymentGroupsSuccess: getPayloadSuccess('paymentGroups'),
    getPaymentGroupsFailure: getGenericFailure('paymentGroups'),

    updatePaymentGroupStarted: getGenericStarted('updatePaymentGroup'),
    updatePaymentGroupSuccess: getGenericSuccess('updatePaymentGroup'),
    updatePaymentGroupFailure: getGenericFailure('updatePaymentGroup'),
  },
});

export const {
  createPaymentGroupStarted,
  createPaymentGroupSuccess,
  createPaymentGroupFailure,

  getPaymentGroupsStarted,
  getPaymentGroupsSuccess,
  getPaymentGroupsFailure,

  updatePaymentGroupStarted,
  updatePaymentGroupSuccess,
  updatePaymentGroupFailure,
} = paymentGroupsSlice.actions;

export default paymentGroupsSlice.reducer;

export const getPaymentGroups = (params) => async (dispatch, getState) => {
  dispatch(getPaymentGroupsStarted());
  try {
    const res = await getPaymentGroupsAPI(getState(), params);
    dispatch(getPaymentGroupsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getPaymentGroupsFailure,
      'There was an issue retrieving your payment groups'
    );
  }
};

export const createPaymentGroup = (data) => async (dispatch, getState) => {
  dispatch(createPaymentGroupStarted());
  try {
    const res = await createPaymentGroupAPI(getState(), data);
    dispatch(createPaymentGroupSuccess(res));
    dispatch(getPaymentGroups());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      createPaymentGroupFailure,
      'There was an issue creating your payment group'
    );
  }
};

export const updatePaymentGroup = (data) => async (dispatch, getState) => {
  dispatch(updatePaymentGroupStarted());
  try {
    const res = await updatePaymentGroupAPI(getState(), data);
    dispatch(updatePaymentGroupSuccess(res));
    dispatch(getPaymentGroups());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      updatePaymentGroupFailure,
      'There was an issue updating your payment group'
    );
  }
};

// selectors
const selectPaymentGroups = (state) => state.paymentGroups;
const reflectSecond = (_, id) => id;
const reflectThird = (_, __, third) => third;

export const createPaymentGroupSelector = createSelector(
  selectPaymentGroups,
  (pGroupState = {}) => pGroupState.createPaymentGroup || getGenericState()
);

export const paymentGroupsSelector = createSelector(
  selectPaymentGroups,
  (pGroupState = {}) => pGroupState.paymentGroups || getGenericState([])
);

export const updatePaymentGroupSelector = createSelector(
  selectPaymentGroups,
  (pGroupState = {}) => pGroupState.updatePaymentGroup || getGenericState()
);

export const paymentGroupSelector = createSelector(
  [paymentGroupsSelector, reflectSecond, reflectThird],
  (paymentGroupsState, id) =>
    paymentGroupsState.data.find((paymentGroup) => paymentGroup.id === id) ||
    emptyPaymentGroupData
);

export const paymentGroupsSortedSelector = createSelector(
  [paymentGroupsSelector, reflectSecond],
  (pGroupState, shouldBeArchived = false) => {
    const { data } = pGroupState;
    const paymentGroups = [...data];
    return paymentGroups
      .filter((pg) => {
        if (shouldBeArchived) return pg.campaign_status === statuses.archived;
        return pg.campaign_status !== statuses.archived;
      })
      .sort(
        (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
      );
  }
);

export const paymentGroupDictSelector = createSelector(
  paymentGroupsSelector,
  (paymentGroupState) => {
    const { data: paymentGroups } = paymentGroupState;
    return paymentGroups.reduce((agg, paymentGroup) => {
      agg[paymentGroup.id] = paymentGroup;
      return agg;
    }, {});
  }
);

export const paymentGroupDetailsSelector = createSelector(
  [paymentGroupDictSelector, reflectSecond],
  (payGroupDict, id) => {
    return payGroupDict[id] || emptyPaymentGroupData;
  }
);

export const paymentGroupsByAgentSelector = createSelector(
  paymentGroupsSelector,
  (paymentGroupState) => {
    const { data: paymentGroups } = paymentGroupState;
    return groupBy(prop('agent_id'), paymentGroups);
  }
);