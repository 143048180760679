import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import isEmail from 'validator/lib/isEmail';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  ButtonGroup,
} from '@material-ui/core';

import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import Button from 'components/Button';
import RadioDescription from 'components/RadioDescription';
import global from 'styles/global';
import InfoTooltipIcon from 'components/InfoIcon';
import { inviteMemberSelector, inviteMember } from 'slices/user';
import TextField from 'components/TextField';

const options = [
  {
    title: 'Team member',
    description: `Team members can do most things, but they can't see the
    Wallets page or execute payments`,
    value: 'collaborator',
  },
  {
    title: 'Admin',
    description: `Admins can do everything except adding or removing other
    Admins`,
    value: 'admin',
  },
];

export default function NewAgentMngrModal({
  open,
  handleClose,
  isAgentInitial = false,
  isManagerInitial = false,
}) {
  const dispatch = useDispatch();
  const g = global();

  const [firstName, setFirstName] = useState({ val: '', dirty: false });
  const [lastName, setLastName] = useState({ val: '', dirty: false });
  const [bizName, setBizName] = useState({ val: '', dirty: false });
  const [email, setEmail] = useState({ val: '', dirty: false });
  const [acct, setAcct] = useState('');
  const [routing, setRouting] = useState('');
  const [phone, setPhone] = useState('');
  const [isAgent, setIsAgent] = useState(isAgentInitial);
  const [isManager, setIsManager] = useState(isManagerInitial);
  const [role, setRole] = useState('collaborator');

  useEffect(() => {
    setIsAgent(isAgentInitial);
  }, [isAgentInitial]);

  useEffect(() => {
    setIsManager(isManagerInitial);
  }, [isManagerInitial]);

  const { loading: submitting } = useSelector(inviteMemberSelector);

  const [isBiz, setIsBiz] = useState(false);

  const resetCreatorInfoFields = () => {
    setFirstName({ val: '', dirty: false });
    setLastName({ val: '', dirty: false });
    setBizName({ val: '', dirty: false });
    setEmail({ val: '', dirty: false });
    setAcct('');
    setRouting('');
    setPhone('');
    setIsAgent(isAgentInitial);
    setIsManager(isManagerInitial);
    setRole('collaborator');
  };

  const handleAddCreator = async () => {
    const data = {
      email: email.val,
      account_number: acct,
      routing_number: routing,
      phone_number: phone,
      role,
      is_manager: isManager,
      is_agent: isAgent,
    };
    if (isBiz) {
      data.business_name = bizName.val;
    } else {
      data.first_name = firstName.val;
      data.last_name = lastName.val;
      data.entity_type = 'individual';
    }
    const success = await dispatch(inviteMember(data));
    if (success) {
      handleClose();
      resetCreatorInfoFields();
    }
  };

  const isInputValid =
    ((!isManager && !isAgent) ||
      (isBiz && !isEmpty(bizName.val)) ||
      (!isBiz && !isEmpty(firstName.val) && !isEmpty(lastName.val))) &&
    isEmail(email.val);

  const firstNameInput = React.useRef(null);
  const bizNameInput = React.useRef(null);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Add Agent/ Manager</Typography>
      </ModalTitle>

      <ModalContent>
        <Grid container spacing={1} className={g.mb_md}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Email address"
              value={email.val}
              error={email.dirty && !isEmail(email.val)}
              helperText={
                email.dirty &&
                !isEmail(email.val) &&
                'This field is required and must be an email'
              }
              onChange={(e) => setEmail({ val: e.target.value, dirty: true })}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <RadioDescription
              title="Permissions"
              value={role}
              setValue={setRole}
              options={options}
            />
          </Grid>

          <Typography className={css(g.mt_sm, g.ml_xxs)} variant="h5">
            Roles
          </Typography>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isAgent} color="primary" />}
              onChange={(evt) => setIsAgent(evt.target.checked)}
              label="Agent"
            />
            <FormControlLabel
              control={<Checkbox checked={isManager} color="primary" />}
              onChange={(evt) => setIsManager(evt.target.checked)}
              label="Manager"
            />
          </Grid>

          {(isAgent || isManager) && (
            <>
              <Grid item xs={12} className={g.mb_lg}>
                <ButtonGroup fullWidth variant="outlined">
                  <Button
                    color="primary"
                    variant={!isBiz ? 'contained' : 'outlined'}
                    onClick={() => setIsBiz(false)}
                  >
                    Individual
                  </Button>
                  <Button
                    color="primary"
                    variant={isBiz ? 'contained' : 'outlined'}
                    onClick={() => setIsBiz(true)}
                  >
                    Business
                  </Button>
                </ButtonGroup>
              </Grid>

              {/* NAME */}
              {!isBiz ? (
                <>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      autoFocus
                      margin="dense"
                      variant="outlined"
                      label="First name"
                      value={firstName.val}
                      error={firstName.dirty && isEmpty(firstName.val)}
                      helperText={
                        firstName.dirty &&
                        isEmpty(firstName.val) &&
                        'This field is required'
                      }
                      onChange={(e) =>
                        setFirstName({ val: e.target.value, dirty: true })
                      }
                      inputRef={firstNameInput}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="Last name"
                      value={lastName.val}
                      error={lastName.dirty && isEmpty(lastName.val)}
                      helperText={
                        lastName.dirty &&
                        isEmpty(lastName.val) &&
                        'This field is required'
                      }
                      onChange={(e) =>
                        setLastName({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      margin="dense"
                      variant="outlined"
                      label="Business name"
                      value={bizName.val}
                      error={bizName.dirty && isEmpty(bizName.val)}
                      helperText={
                        bizName.dirty &&
                        isEmpty(bizName.val) &&
                        'This field is required'
                      }
                      onChange={(e) =>
                        setBizName({ val: e.target.value, dirty: true })
                      }
                      inputRef={bizNameInput}
                      required
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Phone number (optional)"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                />
              </Grid>

              {/* BANK INFO */}
              <Typography variant="body2">Bank info (optional)</Typography>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Account number"
                    value={acct}
                    onChange={(e) => setAcct(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Routing number"
                    value={routing}
                    onChange={(e) => setRouting(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Typography variant="subtitle2">
                Don't worry, bank info is not required to pay Agents/ Managers!
                <InfoTooltipIcon
                  height={14}
                  title="You can create a Agent/ Manager without these details and fill them in later. Or if you send a payment to an Agent/ Manager without details, Creator Pay will fetch them on your behalf!"
                />
              </Typography>
            </>
          )}
        </Grid>
      </ModalContent>

      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          loading={submitting}
          onClick={handleAddCreator}
          color="primary"
          variant="contained"
          disabled={!isInputValid}
        >
          Add
        </Button>
      </ModalActions>
    </Modal>
  );
}
