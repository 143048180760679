import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getUserCreators = async (state) => {
  return createRequest(`/v1/creators/user`, state, {
      method: 'GET',
      vgs: false,
    }, true).then((res) => handleError(res));
};

export const updateCreator = async (state, data) => {
  return createRequest(`/v1/creators/user/${data.id}`, state, {
      method: 'PUT',
      body: data,
      vgs: true,
    }).then((res) => handleError(res));
};
