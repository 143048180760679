import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { sendAch, sendAchSelector } from 'slices/treasury';
import { Typography, Grid } from '@material-ui/core';
import Button from 'components/Button';
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';

import global from 'styles/global';
import DollarInput from 'components/DollarInput';
import { stringToNumberCents, formatCurrency } from 'util/renderStrings';
import { getDelimDateFromDateObj } from 'util/time';
import TextField from 'components/TextField';
import Card from 'components/Card';
import KeyValuePair from 'components/KeyValuePair';
import { currentOrgSelector, orgsByIdSelector } from '../../slices/org';

const useStyles = makeStyles((theme) => ({
  personalSection: {
    marginBottom: theme.spacing(2),
  },
}));

const TransferFundsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [memo, setMemo] = useState('');
  const [acct, setAcct] = useState('');
  const [routing, setRouting] = useState('');

  const classes = useStyles();
  const g = global();

  const [isConfirmationStep, setIsConfirmationStep] = useState(false);

  const { loading: submitting } = useSelector(sendAchSelector);

  const amountCents = stringToNumberCents(amount);
  const fee = Math.min(1000, Math.round(amountCents / 10000));

  const { data: currentOrg } = useSelector(currentOrgSelector);

  const handleNext = () => {
    setIsConfirmationStep(true);
  };

  const handleSendAch = async () => {
    await dispatch(
      sendAch({
        amount: amountCents,
        name,
        description: memo,
        account_num: acct,
        routing_num: routing,
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setName('');
    setAmount(0);
    setMemo('');
    setAcct('');
    setRouting('');
    setIsConfirmationStep(false);
    onClose();
  };

  const isInputInvalid =
    name === '' || !amount || memo === '' || acct === '' || routing === '';
  if (currentOrg?.id === '207d5801-66ac-477f-8347-4f5282e0245b') {
    return (
      <Modal open={open} onClose={handleClose} fullWidth>
        <ModalTitle onClose={handleClose}>
          <Typography variant="h3">Transfer Funds</Typography>
        </ModalTitle>
        <ModalContent>
          {isConfirmationStep ? (
            <Card title="Review your transfer:">
              <KeyValuePair label="Name" value={name} />
              <KeyValuePair label="Memo" value={memo} />
              <KeyValuePair
                label="Date"
                value={getDelimDateFromDateObj(new Date())}
              />
              <KeyValuePair label="Account number" value={acct} />
              <KeyValuePair label="Routing number" value={routing} />
              <KeyValuePair
                label="Amount"
                value={formatCurrency(amountCents)}
              />
              <KeyValuePair label="Fee" value={formatCurrency(fee)} />
              <KeyValuePair
                label="Total"
                value={formatCurrency(amountCents + fee)}
              />
            </Card>
          ) : (
            <>
              {/* PERSONAL INFO */}
              <Typography variant="body2">Personal info</Typography>
              <Grid container spacing={1} className={classes.personalSection}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    label="Name"
                    onChange={(evt) => setName(evt.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <DollarInput
                    margin="dense"
                    variant="outlined"
                    label="Amount to transfer"
                    value={amount}
                    onChange={(evt) => setAmount(evt.target.value)}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Memo"
                    onChange={(evt) => setMemo(evt.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>

              {/* BANK INFO */}
              <Typography variant="body2">Bank info</Typography>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Account number"
                    onChange={(evt) => setAcct(evt.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Routing number"
                    onChange={(evt) => setRouting(evt.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          )}
        </ModalContent>
        <ModalActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            loading={submitting}
            onClick={isConfirmationStep ? handleSendAch : handleNext}
            color="primary"
            variant="contained"
            disabled={isInputInvalid}
          >
            {isConfirmationStep ? 'Transfer' : 'Next'}
          </Button>
        </ModalActions>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleClose} fullWidth>
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Transfer Funds</Typography>
      </ModalTitle>
      <ModalContent>
        Please contact support to transfer funds out of Creator Pay
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Ok
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default TransferFundsModal;
