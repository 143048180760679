import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import css from 'classnames';
import { Grid } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import WelcomeSplash from 'components/WelcomeSplash';
import { ReactComponent as LogoFull } from 'assets/cpay_logo_text.svg';
import { createOrgSelector, createOrg as createOrgSlice } from 'slices/org';
import Card from 'components/Card';
import global, { colors } from 'styles/global';
import TextField from 'components/TextFieldDark';
import Button from 'components/Button';
import WelcomeBackground from 'components/WelcomeBackground';
import { logout } from 'slices/user';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '60%',
    height: 'auto',
    padding: 0,
  },
  fullHeight: {
    height: 'calc(100vh - 72px)',
  },
  welcomeCard: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 28,
    paddingRight: 28,
  }
}));

function CreateOrg({ createOrgState, createOrg }) {
  const g = global();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');

  const { loading: submitting } = createOrgState;

  const handleCreateOrg = async (evt) => {
    evt.preventDefault();
    await createOrg(
      {
        name,
        website,
      },
      () => {
        history.push('/');
      }
    );
  };

  const cancelSignUp = () => {
    dispatch(logout());
    history.push('/signup-umw01jf14qx7d6');
  };

  const isInputInvalid = name === '' || website === '';

  return (
    <>
      <WelcomeBackground noEarth />

      <Grid container spacing={2} className={css(g.centered, classes.fullHeight)}>
        <Grid item sm={12} md={8}>
          <WelcomeSplash />
        </Grid>

        <Grid item sm={12} md={4} className={g.z_index_1}>
          <Card bgColor={colors.brand.payBlack} className={classes.welcomeCard}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={css(g.centerChildren, g.mb_xxl)}>
                  <LogoFull className={classes.logo} />
                </div>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  title="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  title="Website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={handleCreateOrg}
                  loading={submitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isInputInvalid}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12} className={g.mb_xl}>
                <Button
                  onClick={cancelSignUp}
                  type="submit"
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  createOrgState: createOrgSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createOrg: (data, callback) => dispatch(createOrgSlice(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrg);
