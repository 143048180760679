import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import css from 'classnames';

import LoadingOverlay from 'components/LoadingOverlay';
import {
  getPayments,
  paymentsSelector,
  paymentTotalsSelector,
} from 'slices/payments';
import { orgsSelector, getOrgs } from 'slices/org';
import { contractsSelector, getContracts } from 'slices/contracts';
import { setHeaderData } from 'slices/misc';
import { isCustomerSelector } from 'slices/user';
import { getInvoices } from 'slices/invoices';
import MetricTile from 'components/MetricTile';
import { abbreviateCurrency } from 'util/renderStrings';
import global from 'styles/global';
import TodoList from './TodoList';

function CreatorHome({
  contractsState,
  orgsState,
  paymentsState,
  totalPayments,
}) {
  const g = global();
  const dispatch = useDispatch();

  const { data: contracts, loading: contractsLoading } = contractsState;
  const { data: payments, loading: paymentsLoading } = paymentsState;
  const { loading: orgsLoading } = orgsState;
  const isCustomer = useSelector(isCustomerSelector);

  const loading = contractsLoading || paymentsLoading || orgsLoading;

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Home',
        breadcrumbs: [{ label: 'Creator Pay' }],
      })
    );

    if (isCustomer) {
      dispatch(getInvoices());
    } else {
      dispatch(getPayments());
    }
    dispatch(getContracts());
    dispatch(getOrgs());
  }, []);

  return (
    <div>
      <LoadingOverlay open={loading} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">Welcome to Creator Pay!</Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={css(g.flexRowSpacing, g.gap_lg)}>
            <MetricTile subtitle="Contracts" value={contracts.length} />
            {!isCustomer && (
              <>
                <MetricTile subtitle="Payments" value={payments.length} />
                <MetricTile
                  subtitle="Completed"
                  value={abbreviateCurrency(totalPayments.completed)}
                />
                <MetricTile
                  subtitle="Pending"
                  value={abbreviateCurrency(totalPayments.pending)}
                />
              </>
            )}
          </div>
        </Grid>

        {!isCustomer && (
          <Grid item xs={12}>
            <TodoList />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  paymentsState: paymentsSelector(state),
  orgsState: orgsSelector(state),
  contractsState: contractsSelector(state),
  totalPayments: paymentTotalsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorHome);
