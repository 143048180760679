import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  getDeliverables as getDeliverablesAPI,
  createDeliverables as createDeliverablesAPI,
  updateDeliverables as updateDeliverablesAPI,
  deleteDeliverables as deleteDeliverablesAPI,
} from 'api/deliverables';
export const initialDeliverablesState = {
  deliverables: getGenericState([]),
};

export const deliverablesSlice = createSlice({
  name: 'deliverables',
  initialState: initialDeliverablesState,
  reducers: {
    getDeliverablesStarted: getGenericStarted('deliverables'),
    getDeliverablesSuccess: getPayloadSuccess('deliverables'),
    getDeliverablesFailure: getGenericFailure('deliverables'),

    createDeliverablesStarted: getGenericStarted('createDeliverables'),
    createDeliverablesSuccess: getGenericSuccess('createDeliverables'),
    createDeliverablesFailure: getGenericFailure('createDeliverables'),

    updateDeliverablesStarted: getGenericStarted('updateDeliverables'),
    updateDeliverablesSuccess: getGenericSuccess('updateDeliverables'),
    updateDeliverablesFailure: getGenericFailure('updateDeliverables'),

    deleteDeliverablesStarted: getGenericStarted('deleteDeliverables'),
    deleteDeliverablesSuccess: getGenericSuccess('deleteDeliverables'),
    deleteDeliverablesFailure: getGenericFailure('deleteDeliverables'),
  },
});

export const {
  getDeliverablesStarted,
  getDeliverablesSuccess,
  getDeliverablesFailure,

  createDeliverablesStarted,
  createDeliverablesSuccess,
  createDeliverablesFailure,

  updateDeliverablesStarted,
  updateDeliverablesSuccess,
  updateDeliverablesFailure,

  deleteDeliverablesStarted,
  deleteDeliverablesSuccess,
  deleteDeliverablesFailure,
} = deliverablesSlice.actions;

export default deliverablesSlice.reducer;

export const getDeliverables = (body) => async (dispatch, getState) => {
  dispatch(getDeliverablesStarted());
  try {
    const res = await getDeliverablesAPI(getState(), body);
    dispatch(getDeliverablesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getDeliverablesFailure,
      'There was an issue retrieving your deliverables'
    );
  }
};

export const createDeliverables = (data, suppressRefetch) => async (dispatch, getState) => {
  if (!Array.isArray(data)) return;
  if (data.length === 0) return;

  dispatch(createDeliverablesStarted());
  try {
    const res = await createDeliverablesAPI(getState(), data);
    dispatch(createDeliverablesSuccess(res));
    !suppressRefetch && dispatch(getDeliverables({ campaign_id: data[0].campaign_id }));
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      createDeliverablesFailure,
      'There was an issue creating your deliverable'
    );
  }
};

export const updateDeliverables = (data, suppressRefetch) => async (dispatch, getState) => {
  dispatch(updateDeliverablesStarted());
  try {
    const res = await updateDeliverablesAPI(getState(), data);
    dispatch(updateDeliverablesSuccess(res));
    !suppressRefetch && dispatch(getDeliverables());
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateDeliverablesFailure,
      'There was an issue updating your deliverables'
    );
  }
};

export const deleteDeliverables = (data, suppressRefetch) => async (dispatch, getState) => {
  dispatch(deleteDeliverablesStarted());
  try {
    const res = await deleteDeliverablesAPI(getState(), data);
    dispatch(deleteDeliverablesSuccess(res));
    !suppressRefetch && dispatch(getDeliverables());
  } catch (err) {
    handleError(
      err,
      dispatch,
      deleteDeliverablesFailure,
      'There was an issue deleting your deliverables'
    );
  }
};

// selectors
const selectDeliverables = (state) => state.deliverables;
const selectId = (_, id) => id;

export const deliverablesSelector = createSelector(
  selectDeliverables,
  (deliverablesState = {}) =>
    deliverablesState.deliverables || getGenericState([])
);

export const deliverablesByCreatorDictSelector = createSelector(
  deliverablesSelector,
  (deliverablesState = {}) => {
    const { data: dels } = deliverablesState;
    return dels.reduce((agg, del) => {
      if (!agg[del.creator_id]) agg[del.creator_id] = [];
      agg[del.creator_id].push(del);
      return agg;
    }, {});
  }
);

export const deliverablesForCampaignSelector = createSelector(
  [deliverablesSelector, selectId],
  (deliverablesState = {}, id) => {
    const { data: deliverables } = deliverablesState;
    return deliverables.filter((del) => del.campaign_id === id);
  }
);

export const createDeliverablesSelector = createSelector(
  selectDeliverables,
  (deliverablesState = {}) =>
    deliverablesState.createDeliverables || getGenericState()
);

export const updateDeliverableSelector = createSelector(
  selectDeliverables,
  (deliverablesState = {}) =>
    deliverablesState.updateDeliverables || getGenericState()
);

export const deleteDeliverableSelector = createSelector(
  selectDeliverables,
  (deliverablesState = {}) =>
    deliverablesState.deleteDeliverables || getGenericState()
);
