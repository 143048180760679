import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { Grid, IconButton, FormHelperText } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';

import global from 'styles/global';
import DollarInput from 'components/DollarInput';
import PercentInput from 'components/PercentInput';
import constants from 'constants/index';
import TextField from 'components/TextField';
import { formatCurrency } from 'util/renderStrings';

const useStyles = makeStyles((theme) => ({
  destructive: {
    color: theme.palette.error.main,
  },
}));

export default function NewCreatorPayment({
  vals,
  updateProp,
  deletePayment,
  rowId,
}) {
  const g = global();
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState('');

  const validateFields = () => {
    const { amount } = vals;
    let error = '';
    if (!amount) {
      error = 'No amount provided';
    } else if ((amount * 100) <= constants.paymentAmtThreshold) {
      error = `Amount to be paid should be more than ${formatCurrency(
        constants.paymentAmtThreshold
      )}`;
    }

    return error;
  };

  const handleUpdateProp = (id, val, key, subKey, subId) => {
    if (!dirty) {
      setDirty(true);
    }

    updateProp(id, val, key, subKey, subId);
  };

  useEffect(() => {
    const error = validateFields();
    setError(error);
  }, [vals.amount, vals.recipient, vals.memo, vals.group]);

  return (
    <div>
      <Grid container spacing={1}>
        {/* earnings input */}
        <Grid item sm={3} className={css(g.flexRow, g.alignCenter)}>
          <DollarInput
            value={vals.earnings}
            margin="dense"
            className={g.m_zero}
            variant="outlined"
            label="Earnings"
            onChange={(evt) =>
              handleUpdateProp(
                rowId,
                evt.target.value,
                'payments',
                'earnings',
                vals.id
              )
            }
            required
            fullWidth
          />
        </Grid>

        {/* split input */}
        <Grid item sm={2} className={css(g.flexRow, g.alignCenter)}>
          <PercentInput
            label="Split"
            required
            className={g.m_zero}
            value={vals.split}
            onChange={(evt) =>
              handleUpdateProp(
                rowId,
                evt.target.value,
                'payments',
                'split',
                vals.id
              )
            }
            margin="dense"
            fullWidth
          />
        </Grid>

        {/* memo input */}
        <Grid item sm={4} className={css(g.flexRow, g.alignCenter)}>
          <TextField
            margin="dense"
            className={g.m_zero}
            variant="outlined"
            label="Memo"
            value={vals.memo}
            onChange={(evt) =>
              handleUpdateProp(
                rowId,
                evt.target.value,
                'payments',
                'memo',
                vals.id
              )
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={3} className={css(g.flexRow, g.alignCenter)}>
          <DollarInput
            value={vals.amount}
            margin="dense"
            disabled
            className={g.m_zero}
            variant="outlined"
            label="Amount"
            required
            fullWidth
          />
          <IconButton
            className={g.ml_zero}
            onClick={() => deletePayment(rowId, vals.id)}
          >
            <CloseIcon className={classes.destructive} />
          </IconButton>
        </Grid>

        <FormHelperText error className={g.ml_lg}>
          {dirty && error}
        </FormHelperText>
      </Grid>
    </div>
  );
}
