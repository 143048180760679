import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  getConnections as getConnectionsAPI,
  deleteConnection as deleteConnectionAPI,
} from 'api/orgOauth';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialOrgOauthState = {
  connections: getGenericState([]),
};

export const orgOauthSlice = createSlice({
  name: 'orgOauth',
  initialState: initialOrgOauthState,
  reducers: {
    getConnectionsStarted: getGenericStarted('connections'),
    getConnectionsSuccess: getPayloadSuccess('connections'),
    getConnectionsFailure: getGenericFailure('connections'),

    deleteConnectionStarted: getGenericStarted('deleteConnection'),
    deleteConnectionSuccess: getGenericSuccess('deleteConnection'),
    deleteConnectionFailure: getGenericFailure('deleteConnection'),
  },
});

export const {
  getConnectionsStarted,
  getConnectionsSuccess,
  getConnectionsFailure,

  deleteConnectionStarted,
  deleteConnectionSuccess,
  deleteConnectionFailure,
} = orgOauthSlice.actions;

export default orgOauthSlice.reducer;

export const getConnections = () => async (dispatch, getState) => {
  dispatch(getConnectionsStarted());
  try {
    const res = await getConnectionsAPI(getState());
    dispatch(getConnectionsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getConnectionsFailure,
      'There was an issue retrieving your integrations.'
    );
  }
};

export const deleteConnection = (id) => async (dispatch, getState) => {
  dispatch(deleteConnectionStarted());
  try {
    const res = await deleteConnectionAPI(getState(), id);
    dispatch(deleteConnectionSuccess(res));
    dispatch(getConnections());
  } catch (err) {
    handleError(
      err,
      dispatch,
      deleteConnectionFailure,
      'There was an issue retrieving your integrations.'
    );
  }
};

// selectors
const selectOrgOauth = (state) => state.orgOauth;

export const orgOauthSelector = createSelector(
  selectOrgOauth,
  (orgOauthState = {}) => orgOauthState.connections || getGenericState([])
);

export const deleteConnectionSelector = createSelector(
  selectOrgOauth,
  (orgOauthState = {}) => orgOauthState.deleteConnection || getGenericState()
);

export const quickbooksSelector = createSelector(
  orgOauthSelector,
  (orgOauthState) => {
    const { data: connections } = orgOauthState;
    return connections.filter(
      (connection) => connection.platform === 'quickbooks'
    )[0];
  }
);
