export const emptyAgentMngrData = {
  id: '',
  account_number: '',
  created_at: '',
  email: '',
  first_name: '',
  last_name: '',
  role: '',
  routing_number: '',
  phone_number: '',
};
