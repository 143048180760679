import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

import { isAccountNumber, isRoutingNumber } from 'util/validation';

import Card from '../Card';
import TextField from '../TextField';
import Button from '../Button';
import Select from '../Select';

import global from 'styles/global';

const IntlBankPayoutForm = ({ userID }) => {
  const g = global();
  const [availableBanks, setAvailableBanks] = useState({
    val: [{ name: 'Bank 1' }, { name: 'Bank 2' }, { name: 'Bank 3' }],
    dirty: false,
  });
  const [chosenBank, setChosenBank] = useState({
    val: '',
    dirty: false,
  });

  const [firstName, setFirstName] = useState({ val: '', dirty: false });
  const [lastName, setLastName] = useState({ val: '', dirty: false });
  const [accountNumber, setAccountNumber] = useState({ val: '', dirty: false });
  const [routingNumber, setRoutingNumber] = useState({ val: '', dirty: false });

  const isInputValid =
    isAccountNumber(accountNumber.val) && isRoutingNumber(routingNumber.val);

  const savePaymentMethod = () => {
    alert('Saving intl bank account');
  };

  return (
    <Card title="Beneficiaries International Bank Account">
      <FormControl size="small" variant="outlined" className={g.full_width}>
        <InputLabel id="select-available-banks-label">Bank</InputLabel>
        <Select
          labelId="select-available-banks-label"
          label="Bank"
          id="select-available-banks"
          value={chosenBank.val}
          onChange={(evt) =>
            setChosenBank({ val: evt.target.value, dirty: true })
          }
        >
          {availableBanks.val.map((bank) => (
            <MenuItem key={bank.name} value={bank.name}>
              {bank.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        variant="outlined"
        label="First Name"
        value={firstName.val}
        error={firstName.dirty && !firstName.val}
        helperText={
          firstName.dirty && !firstName.val && 'This field is required'
        }
        onChange={(e) => setFirstName({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Last Name"
        value={lastName.val}
        error={lastName.dirty && !lastName.val}
        helperText={lastName.dirty && !lastName.val && 'This field is required'}
        onChange={(e) => setLastName({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Account number"
        value={accountNumber.val}
        error={accountNumber.dirty && !accountNumber.val}
        helperText={
          accountNumber.dirty && !accountNumber.val && 'This field is required'
        }
        onChange={(e) => setAccountNumber({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Routing number"
        value={routingNumber.val}
        error={routingNumber.dirty && !routingNumber.val}
        helperText={
          routingNumber.dirty && !routingNumber.val && 'This field is required'
        }
        onChange={(e) => setRoutingNumber({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <Button
        loading={false}
        onClick={savePaymentMethod}
        color="primary"
        variant="contained"
        disabled={!isInputValid}
      >
        Save
      </Button>
    </Card>
  );
};

export default IntlBankPayoutForm;
