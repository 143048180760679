import React, { useState } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

import { Modal, ModalActions } from 'components/Modal';
import global from 'styles/global';
import Button from 'components/Button';
import Card from 'components/Card';
import RadioDescription from 'components/RadioDescription';
import Select from 'components/Select';
import { taxDocsSelector, getTaxDocs } from 'slices/taxes';

const options = [
  {
    title: 'Download',
    value: 'download',
  },
  {
    title: 'Email',
    value: 'email',
  },
];

const CreateTaxDocsModal = ({ open, onClose, users }) => {
  const g = global();
  const dispatch = useDispatch();

  const [taxYear, setTaxYear] = useState('');
  const [method, setMethod] = useState('download');

  const { loading: submitting } = useSelector(taxDocsSelector);

  const handleClose = () => {
    setTaxYear('');
    setMethod('download');
    onClose();
  };

  const generateTaxDocs = async (evt) => {
    evt.preventDefault();
    const resURL = await dispatch(
      getTaxDocs({
        year: taxYear,
        method,
        userIds: users,
      })
    );

    if (method !== 'email') {
      window.open(resURL, '_blank');
      URL.revokeObjectURL(resURL);
    }
    handleClose();
  };

  const isInputValid = taxYear !== '';

  let currentYear = new Date().getFullYear();
  const years = [currentYear];
  while (currentYear > 2022) {
    currentYear -= 1;
    years.push(currentYear);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Card title="Generate Tax Documents">
        <FormControl
          size="small"
          variant="outlined"
          className={css(g.full_width, g.mb_md)}
        >
          <InputLabel id="select-tax-year-label">Tax year</InputLabel>
          <Select
            labelId="select-tax-year-label"
            label="Tax year"
            id="select-tax-year"
            value={taxYear}
            onChange={(evt) => setTaxYear(evt.target.value)}
          >
            {years.map((yr) => (
              <MenuItem value={yr}>{yr}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <RadioDescription
          title="Permissions"
          value={method}
          setValue={setMethod}
          options={options}
        /> */}
        <ModalActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            className={g.ml_sm}
            loading={submitting}
            onClick={generateTaxDocs}
            color="primary"
            variant="contained"
            disabled={!isInputValid}
          >
            Generate
          </Button>
        </ModalActions>
      </Card>
    </Modal>
  );
};

export default CreateTaxDocsModal;
