import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import TabPanel from 'components/TabPanel';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import global from 'styles/global';
import useTabs from 'hooks/useTabs';
import {
  agentsMngrsSelector,
  agentsMngrsGroupedSelector,
  getAgentsMngrs,
} from 'slices/agentsMngrs';
import Tabs from 'components/Tabs';
import NewAgentMngrModal from 'components/NewAgentMngrModal';
import UsersTable from 'components/UsersTable';
import { getPaymentGroups, paymentGroupsSelector } from 'slices/paymentGroups';
import { getPayments } from 'slices/payments';
import { setHeaderData } from 'slices/misc';

const allTabs = [
  { label: 'Agents', name: 'agents' },
  { label: 'Managers', name: 'managers' },
];

function AgentsMngrs() {
  const g = global();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    allTabs.map((tab) => tab.name),
    '/agentsManagers'
  );

  const { loading: agentsMngrsLoading } = useSelector(agentsMngrsSelector);
  const { loading: paymentGroupsLoading } = useSelector(paymentGroupsSelector);

  const loading = agentsMngrsLoading || paymentGroupsLoading;
  const { agents, managers } = useSelector(agentsMngrsGroupedSelector);

  const [isModalOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Agents and Managers',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Agents and Managers' },
        ],
      })
    );

    dispatch(getAgentsMngrs());
    dispatch(getPaymentGroups());
    dispatch(getPayments());
  }, []);

  return (
    <div>
      <LoadingOverlay open={loading} />
      <NewAgentMngrModal
        open={isModalOpen}
        handleClose={() => setModalIsOpen(false)}
        isAgentInitial={currentTab === 0}
        isManagerInitial={currentTab === 1}
      />

      <Tabs
        tabLabels={allTabs.map((tab) => tab.label)}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="agents-managers-tabpanel"
      />

      {/* All */}
      <TabPanel value={currentTab} index={0}>
        <TableHeader title="Agents">
          <div>
            <Button
              size="small"
              variant="contained"
              className={g.ml_xs}
              color="primary"
              onClick={() => setModalIsOpen(true)}
            >
              New Agent
            </Button>
          </div>
        </TableHeader>
        <div className={g.tableWrapper}>
          <UsersTable
            users={agents}
            columnsToDisplay={[
              'emailAgentMngr',
              'name',
              'sales',
              'payments',
              'paymentsAmt',
              'paymentMethod',
            ]}
          />
        </div>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <TableHeader title="Managers">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setModalIsOpen(true)}
          >
            New Manager
          </Button>
        </TableHeader>

        <div className={g.tableWrapper}>
          <UsersTable
            users={managers}
            columnsToDisplay={[
              'emailAgentMngr',
              'name',
              'payments',
              'paymentsAmt',
              'paymentMethod',
            ]}
          />
        </div>
      </TabPanel>
    </div>
  );
}

export default AgentsMngrs;
