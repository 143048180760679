import Papa from 'papaparse';

import { download, stringToBlobUrl } from './download';

export const exportPayments = (payments, campaigns) => {
  const refIdMap = campaigns.reduce((agg, campaign) => {
    agg[campaign.id] = campaign.campaign_ref_id;
    return agg;
  }, {});
  const paymentsWithRefIds = payments.map((pmt) => ({
    ...pmt,
    campaign_reference_id: refIdMap[pmt.payment_group_id],
  }));
  const csv = Papa.unparse(paymentsWithRefIds);
  const blob = stringToBlobUrl(csv);
  download(blob, 'Creator Pay - Exported Payments.csv');
};
