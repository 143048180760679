import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import css from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { isEmail, isInt } from 'validator';
import moment from 'moment';

import { ReactComponent as Illustration } from 'assets/bankDetails.svg';
import { ReactComponent as DarkLogo } from 'assets/darkLogo.svg';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import { ButtonGroup, Paper, Typography, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { USStates } from 'constants/usStates';
import { LegalEntityTypes } from 'constants/legalEntityTypes';

import { formatCurrency, maskAcctNumber } from 'util/renderStrings';
import global from 'styles/global';
import {
  updateBankDetailsSelector,
  updateBankDetails as updateBankDetailsSlice,
} from 'slices/user';
import {
  getExpiringLink as getExpiringLinkSlice,
  expiringLinkSelector,
} from 'slices/expiringLink';
import ExpiringLinkInvalid from 'components/ExpiringLinkInvalid';
import { isAccountNumber, isRoutingNumber } from 'util/validation';
import isMobilePhone from 'validator/lib/isMobilePhone';
import PhoneInput from 'components/PhoneInput';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    backgroundColor: theme.palette.shades.charcoal004,
    borderRadius: 0,
    padding: theme.spacing(1, 2),
  },
  actions: { textAlign: 'right' },
  submitBtn: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: '0 auto',
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.shades.charcoal020}`,
  },
  illustration: {
    width: '100%',
    padding: theme.spacing(3),
  },
  well: {
    backgroundColor: theme.palette.custom.payBlue004,
    borderRadius: 0,
    padding: theme.spacing(2, 2),
    width: '100%',
  },
  amount: {
    padding: theme.spacing(2, 2),
  },
}));

const UpdateBankDetails = ({
  updateBankDetails,
  updateBankDetailsState,
  getExpiringLink,
  expiringLinkState,
}) => {
  const classes = useStyles();
  const g = global();

  const { data: expiringLink = {}, loading, error } = expiringLinkState;
  const {
    amount,
    org_name: orgName,
    rec_first_name,
    rec_last_name,
    rec_business_name,
  } = expiringLink;

  const query = new URLSearchParams(useLocation().search);
  const linkId = query.get('id');

  useEffect(() => {
    getExpiringLink(linkId);
  }, []);

  useEffect(() => {
    setFirst({ val: rec_first_name, dirty: true });
    setLast({ val: rec_last_name, dirty: true });
    setBizName({ val: rec_business_name, dirty: true });
  }, [expiringLink]);

  const paymentMethodMap = {
    'stripe': {
      name: 'Bank account',
    },
    'paypal': {
      name: 'PayPal',
    },
    'venmo': {
      name: 'Venmo',
    },
  };

  const { loading: submitting } = updateBankDetailsState;

  const handleUpdateBankDetails = async () => {
    const updateObj = {
      address: {
        city: city.val,
        line_1: address1.val,
        line_2: address2.val,
        state: stateCode.val,
        zip: zip.val,
      },
      linkId,
      account_number: acct.val,
      routing_number: routing.val,
      selected_payment_method: paymentMethod,
      paypal_email: paypalEmail.val,
      venmo_phone_number: venmoPhone.val,
    };
    if (isBiz) {
      updateObj.business_name = bizName.val;
      updateObj.entity_type = entityType.val;
      updateObj.tax_id = ein.val;
    } else {
      updateObj.entity_type = 'individual';
      updateObj.date_of_birth = moment(dob.val).format('YYYY-MM-DD');
      updateObj.first_name = first.val;
      updateObj.last_name = last.val;
      updateObj.tax_id = ssn.val;
    }
    await updateBankDetails(updateObj, () => {
      setShowSuccess(true);
    });
  };

  const [acct, setAcct] = useState({ val: '', dirty: false });
  const [routing, setRouting] = useState({ val: '', dirty: false });
  const [first, setFirst] = useState({ val: '', dirty: false });
  const [last, setLast] = useState({ val: '', dirty: false });
  const [ssn, setSSN] = useState({ val: '', dirty: false });
  const [dob, setDOB] = useState({ val: '', dirty: false });
  const [bizName, setBizName] = useState({ val: '', dirty: false });
  const [ein, setEIN] = useState({ val: '', dirty: false });
  const [entityType, setEntityType] = useState({ val: '', dirty: false });
  const [address1, setAddress1] = useState({ val: '', dirty: false });
  const [address2, setAddress2] = useState({ val: '', dirty: false });
  const [city, setCity] = useState({ val: '', dirty: false });
  const [stateCode, setStateCode] = useState({ val: '', dirty: false });
  const [zip, setZip] = useState({ val: '', dirty: false });
  const [paypalEmail, setPaypalEmail] = useState({ val: '', dirty: false });
  const [venmoPhone, setVenmoPhone] = useState({ val: '', dirty: false });

  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [isBiz, setIsBiz] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const acctValid = isAccountNumber(acct.val);
  const routingValid = isRoutingNumber(routing.val);
  const paypalValid = !!paypalEmail.val && isEmail(paypalEmail.val);
  const venmoValid = !!venmoPhone.val && isMobilePhone(venmoPhone.val, 'en-US');
  const stateValid = !!stateCode.val && stateCode.val.length === 2;
  const zipValid = !!zip.val && zip.val.length === 5 && isInt(zip.val);
  const einValid = !!ein.val && ein.val.length === 9 && isInt(ein.val);
  const ssnValid = !!ssn.val && ssn.val.length === 9 && isInt(ssn.val);

  const getIsValid = () => {
    const paymentMethodValid =
      (paymentMethod === 'paypal' && paypalValid) ||
      (paymentMethod === 'stripe' && acctValid && routingValid) ||
      (paymentMethod === 'venmo' && venmoValid);
    const isIndividualValid =
      !!first.val && !!last.val && ssnValid && !!dob.val;
    const isBizValid = !!bizName.val && einValid && !!entityType.val;
    const addressValid = !!address1.val && !!city.val && stateValid && zipValid;

    return isBiz
      ? paymentMethodValid && isBizValid && addressValid
      : paymentMethodValid && isIndividualValid && addressValid;
  };

  const isValid = getIsValid();

  const renderSuccessScreen = () => (
    <Grid container justifyContent="center" alignItems="center">
      <LoadingOverlay open={loading} />
      <Grid item sm={12} md={4}>
        <Paper>
          <Grid container className={g.p_lg} spacing={6}>
            <Grid item xs={12} className={g.centerChildren}>
              <DarkLogo className={g.mb_sm} />
              <Typography variant="subtitle1" className={g.mb_xs}>
                {`Thanks ${rec_first_name || rec_business_name}!`}
              </Typography>
              <Typography variant="h4" className={g.mb_sm}>
                Your payment is on the way
              </Typography>
              <div className={css(g.centerChildren, classes.amount)}>
                <Typography variant="h1" className={g.success}>
                  {formatCurrency(amount)}
                </Typography>
              </div>

              <Paper className={classes.paper} elevation={0}>
                <div className={css(g.flexRowSpacing, g.mb_sm)}>
                  <Typography variant="body2">From</Typography>
                  <Typography variant="body2">{orgName}</Typography>
                </div>
                <div className={css(g.flexRowSpacing, g.mb_sm)}>
                  <Typography variant="body2">Payment method</Typography>
                  <Typography variant="body2">
                    {paymentMethodMap[paymentMethod].name}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderUpdateBankDetailsScreen = () => (
    <Grid container justifyContent="center" alignItems="center">
      <LoadingOverlay open={loading} />
      <Grid item sm={12} md={10}>
        <Paper>
          <Grid container className={g.p_lg} spacing={6}>
            <Grid
              item
              sm={6}
              xs={12}
              className={css(g.centerChildren, classes.borderRight)}
            >
              <DarkLogo className={g.mb_sm} />
              <Typography variant="subtitle1" className={g.mb_xs}>
                Payment from
              </Typography>
              <Typography variant="h4" className={g.mb_sm}>
                {orgName}
              </Typography>
              <Illustration className={classes.illustration} />
              <div className={css(g.centerChildren, classes.well)}>
                <Typography variant="h1" className={g.success}>
                  {formatCurrency(amount)}
                </Typography>
              </div>
            </Grid>

            {/* input forms */}
            <Grid item sm={6} xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">Payment details</Typography>
              </Grid>
              <Grid item xs={12}>
                <ButtonGroup fullWidth variant="outlined">
                  <Button
                    color="primary"
                    variant={
                      paymentMethod === 'stripe' ? 'contained' : 'outlined'
                    }
                    onClick={() => setPaymentMethod('stripe')}
                  >
                    Bank
                  </Button>
                  {expiringLink.is_paypal_enabled && (
                    <Button
                      color="primary"
                      variant={
                        paymentMethod === 'paypal' ? 'contained' : 'outlined'
                      }
                      onClick={() => setPaymentMethod('paypal')}
                    >
                      PayPal
                    </Button>
                  )}
                  {expiringLink.is_paypal_enabled && (
                    <Button
                      color="primary"
                      variant={
                        paymentMethod === 'venmo' ? 'contained' : 'outlined'
                      }
                      onClick={() => setPaymentMethod('venmo')}
                    >
                      Venmo
                    </Button>
                  )}
                </ButtonGroup>
              </Grid>

              {/* ACH data collection */}
              {paymentMethod === 'stripe' && (
                <>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="Account number"
                      value={acct.val}
                      error={acct.dirty && !acctValid}
                      helperText={
                        acct.dirty && !acctValid && 'This field is required'
                      }
                      onChange={(e) =>
                        setAcct({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={g.mb_md}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="Routing number"
                      value={routing.val}
                      error={routing.dirty && !routingValid}
                      helperText={
                        routing.dirty &&
                        !routingValid &&
                        'This field is required and must be 9 digits'
                      }
                      onChange={(e) =>
                        setRouting({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {/* PayPal data collection */}
              {paymentMethod === 'paypal' && (
                <Grid item xs={12} className={g.mb_md}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Paypal email"
                    value={paypalEmail.val}
                    error={paypalEmail.dirty && !isEmail(paypalEmail.val)}
                    helperText={
                      paypalEmail.dirty &&
                      !isEmail(paypalEmail.val) &&
                      'This field is required and must be an email address'
                    }
                    onChange={(e) =>
                      setPaypalEmail({ val: e.target.value, dirty: true })
                    }
                    required
                    fullWidth
                  />
                </Grid>
              )}

              {/* Venmo data collection */}
              {paymentMethod === 'venmo' && (
                <Grid item xs={12} className={g.mb_md}>
                  <PhoneInput
                    margin="dense"
                    variant="outlined"
                    label="Venmo phone number"
                    value={venmoPhone.val}
                    error={
                      venmoPhone.dirty &&
                      !isMobilePhone(venmoPhone.val, 'en-US')
                    }
                    helperText={
                      venmoPhone.dirty &&
                      !isMobilePhone(venmoPhone.val, 'en-US') &&
                      'This field is required and must be a valid US phone number'
                    }
                    onChange={(e) =>
                      setVenmoPhone({ val: e.target.value, dirty: true })
                    }
                    required
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="h4">Tax information</Typography>
              </Grid>
              <Grid item xs={12}>
                <ButtonGroup fullWidth variant="outlined">
                  <Button
                    color="primary"
                    variant={!isBiz ? 'contained' : 'outlined'}
                    onClick={() => setIsBiz(false)}
                  >
                    Individual
                  </Button>
                  <Button
                    color="primary"
                    variant={isBiz ? 'contained' : 'outlined'}
                    onClick={() => setIsBiz(true)}
                  >
                    Business
                  </Button>
                </ButtonGroup>
              </Grid>

              {!isBiz ? (
                <>
                  {/* individual tax info */}
                  <Grid item sm={6} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="First name"
                      value={first.val}
                      error={first.dirty && !first.val}
                      helperText={
                        first.dirty && !first.val && 'This field is required'
                      }
                      onChange={(e) =>
                        setFirst({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="Last name"
                      value={last.val}
                      error={last.dirty && !last.val}
                      helperText={
                        last.dirty && !last.val && 'This field is required'
                      }
                      onChange={(e) =>
                        setLast({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="SSN"
                      value={ssn.val}
                      error={ssn.dirty && !ssnValid}
                      helperText={
                        ssn.dirty && !ssnValid && 'This field is required'
                      }
                      onChange={(e) =>
                        setSSN({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="date"
                      margin="dense"
                      variant="outlined"
                      label="Date of birth"
                      value={dob.val}
                      error={dob.dirty && !dob.val}
                      helperText={
                        dob.dirty && !dob.val && 'This field is required'
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setDOB({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {/* business tax info */}
                  <Grid item sm={8} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="Business Name"
                      value={bizName.val}
                      error={bizName.dirty && !bizName.val}
                      helperText={
                        bizName.dirty &&
                        !bizName.val &&
                        'This field is required'
                      }
                      onChange={(e) =>
                        setBizName({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      label="EIN"
                      value={ein.val}
                      error={ein.dirty && !einValid}
                      helperText={
                        ein.dirty && !einValid && 'This field is required'
                      }
                      onChange={(e) =>
                        setEIN({ val: e.target.value, dirty: true })
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={LegalEntityTypes}
                      className={g.pb_zero}
                      getOptionLabel={(option) => `${option.label}`}
                      onChange={(_, value) => {
                        setEntityType({ val: value?.value, dirty: true });
                      }}
                      textInputProps={{
                        margin: 'dense',
                        label: 'Legal entity type',
                        required: true,
                      }}
                    />
                  </Grid>
                </>
              )}

              {/* address */}
              <Grid item sm={8} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Address 1"
                  value={address1.val}
                  error={address1.dirty && !address1.val}
                  helperText={
                    address1.dirty && !address1.val && 'This field is required'
                  }
                  onChange={(e) =>
                    setAddress1({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Address 2"
                  value={address2.val}
                  onChange={(e) =>
                    setAddress2({ val: e.target.value, dirty: true })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="City"
                  value={city.val}
                  error={city.dirty && !city.val}
                  helperText={
                    city.dirty && !city.val && 'This field is required'
                  }
                  onChange={(e) =>
                    setCity({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Autocomplete
                  options={USStates}
                  className={g.pb_zero}
                  getOptionLabel={(option) => `${option.abbreviation}`}
                  onChange={(_, value) => {
                    setStateCode({
                      val: value?.abbreviation || '',
                      dirty: true,
                    });
                  }}
                  textInputProps={{
                    margin: 'dense',
                    label: 'State',
                    required: true,
                  }}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Zip code"
                  value={zip.val}
                  error={zip.dirty && !zipValid}
                  helperText={
                    zip.dirty &&
                    !zipValid &&
                    'This field is required and must be 5 digits'
                  }
                  onChange={(e) => setZip({ val: e.target.value, dirty: true })}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} className={classes.actions}>
                <Button
                  onClick={handleUpdateBankDetails}
                  disabled={!isValid}
                  color="primary"
                  variant="contained"
                  className={classes.submitBtn}
                  loading={submitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  if (error !== '') {
    return <ExpiringLinkInvalid />;
  }

  return showSuccess ? renderSuccessScreen() : renderUpdateBankDetailsScreen();
};

const mapStateToProps = (state) => ({
  updateBankDetailsState: updateBankDetailsSelector(state),
  expiringLinkState: expiringLinkSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateBankDetails: (data, callback) =>
    dispatch(updateBankDetailsSlice(data, callback)),
  getExpiringLink: (id) => dispatch(getExpiringLinkSlice(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBankDetails);
