import { createParams, handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const signin = async (state, data) => {
  return createRequest(`/v1/users/signin`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const loginAs = async (state, data) => {
  return createRequest(`/v1/users/loginas`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const signup = async (state, data) => {
  return createRequest(`/v1/users/signup`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const getUser = async (state) => {
  return createRequest(`/v1/user`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const getCreators = async (state) => {
  return createRequest(`/v1/creators`, state, {
    method: 'GET',
    vgs: false,
  }).then((res) => handleError(res));
};

export const updatePassword = async (state, data) => {
  return createRequest(`/v1/users/password`, state, {
    method: 'PUT',
    body: data,
  }).then((res) => handleError(res));
};

export const updateCreator = async (state, data) => {
  const { id, ...body } = data;
  return createRequest(`/v1/creators/${id}`, state, {
    method: 'PUT',
    body,
    vgs: true,
  }).then((res) => handleError(res));
};

export const updateUser = async (state, data) => {
  const { id, ...body } = data;
  return createRequest(`/v1/users/${id}`, state, {
    method: 'PUT',
    body,
    vgs: true,
  }).then((res) => handleError(res));
};

export const addCreators = async (state, data) => {
  return createRequest(`/v1/creators`, state, {
    method: 'POST',
    body: data,
    vgs: true,
  }).then((res) => handleError(res));
};

export const addCreatorsBulk = async (state, data) => {
  return createRequest(`/v1/creators/bulk`, state, {
    method: 'POST',
    body: data,
    vgs: true,
  }).then((res) => handleError(res));
};

export const updateBankDetailsExpiringLink = async (state, data) => {
  const { linkId, ...body } = data;
  const qStr = createParams({ id: linkId });
  return createRequest(`/v1/expiring_link${qStr}`, state, {
    method: 'POST',
    body,
    vgs: true,
  }).then((res) => handleError(res));
};

export const sendPasswordReset = async (state, data) => {
  return createRequest(`/v1/users/password/reset`, state, {
    method: 'PUT',
    body: data,
  }).then((res) => handleError(res));
};

export const createNewPassword = async (state, data) => {
  const { id, ...body } = data;
  const qStr = createParams({ id });
  return createRequest(`/v1/expiring_link${qStr}`, state, {
    method: 'POST',
    body,
  }).then((res) => handleError(res));
};

export const getMembers = async (state) => {
  return createRequest(`/v1/users`, state, {
    method: 'GET',
    vgs: true,
  }).then((res) => handleError(res));
};

export const inviteMember = async (state, data) => {
  return createRequest(`/v1/users`, state, {
    method: 'POST',
    body: data,
    vgs: true,
  }).then((res) => handleError(res));
};

export const deleteMember = async (state, id) => {
  return createRequest(`/v1/users/${id}`, state, {
    method: 'DELETE',
    vgs: true,
  }).then((res) => handleError(res));
};
