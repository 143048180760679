import React from 'react';

const OrgLink = ({ orgs, id }) => {
  const org = orgs.find((o) => id === o.id) || {};
  let { website = '' } = org;
  if (!website.startsWith('http')) {
    website = 'https://' + website;
  }
  return (
    <a target="_blank" rel="noreferrer noopener" href={website}>
      {org?.name}
    </a>
  );
};

export default OrgLink;
