import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { groupBy, prop } from 'ramda';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  getContracts as getContractsAPI,
  getContractPreview as getContractPreviewAPI,
  invalidateContract as invalidateContractAPI,
  remindContract as remindContractAPI,
  createContract as createContractsAPI,
} from 'api/contracts';
import objectStates from 'constants/objectStates';
import { dataURItoBlob } from 'util/download';
import { emptyContractData } from 'fixtures/contracts';
export const initialContractsState = {
  contracts: getGenericState([]),
  invalidateContract: getGenericState(),
  invalidateContracts: getGenericState(),
  remindContracts: getGenericState(),
  createContracts: getGenericState(),
  contractPreview: getGenericState(''),
};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState: initialContractsState,
  reducers: {
    getContractsStarted: getGenericStarted('contracts'),
    getContractsSuccess: getPayloadSuccess('contracts'),
    getContractsFailure: getGenericFailure('contracts'),

    getContractPreviewStarted: getGenericStarted('contractPreview'),
    getContractPreviewSuccess: getPayloadSuccess('contractPreview'),
    getContractPreviewFailure: getGenericFailure('contractPreview'),
    // One off pending state to account for hello sign delays in creating contracts
    getContractPreviewPending: (state) => {
      state.contractPreview.loading = false;
      state.contractPreview.error = '';
      state.contractPreview.data = 'PENDING';
    },

    invalidateContractStarted: getGenericStarted('invalidateContract'),
    invalidateContractSuccess: getGenericSuccess('invalidateContract'),
    invalidateContractFailure: getGenericFailure('invalidateContract'),

    invalidateContractsStarted: getGenericStarted('invalidateContracts'),
    invalidateContractsSuccess: getGenericSuccess('invalidateContracts'),
    invalidateContractsFailure: getGenericFailure('invalidateContracts'),

    remindContractsStarted: getGenericStarted('remindContracts'),
    remindContractsSuccess: getGenericSuccess('remindContracts'),
    remindContractsFailure: getGenericFailure('remindContracts'),

    createContractsStarted: getGenericStarted('createContracts'),
    createContractsSuccess: getGenericSuccess('createContracts'),
    createContractsFailure: getGenericFailure('createContracts'),
  },
});

export const {
  getContractsStarted,
  getContractsSuccess,
  getContractsFailure,

  getContractPreviewStarted,
  getContractPreviewSuccess,
  getContractPreviewPending,

  getContractPreviewFailure,
  invalidateContractStarted,
  invalidateContractSuccess,

  invalidateContractFailure,
  invalidateContractsStarted,
  invalidateContractsSuccess,
  invalidateContractsFailure,

  remindContractsStarted,
  remindContractsSuccess,
  remindContractsFailure,

  createContractsStarted,
  createContractsSuccess,
  createContractsFailure,
} = contractsSlice.actions;

export default contractsSlice.reducer;

export const getContracts = (id) => async (dispatch, getState) => {
  dispatch(getContractsStarted());
  try {
    const res = await getContractsAPI(getState(), id);
    dispatch(getContractsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getContractsFailure,
      'There was an issue retrieving your contracts'
    );
  }
};

export const getContractPreview = (id) => async (dispatch, getState) => {
  dispatch(getContractPreviewStarted());
  try {
    const res = await getContractPreviewAPI(getState(), { contractId: id });
    const blob = dataURItoBlob(res, 'application/pdf');
    const previewUrl = URL.createObjectURL(blob);
    dispatch(getContractPreviewSuccess(previewUrl));
  } catch (err) {
    if (err?.message.includes('still being processed')) {
      dispatch(getContractPreviewPending(err));
    } else {
      handleError(
        err,
        dispatch,
        getContractPreviewFailure,
        'There was an issue retrieving the contract preview'
      );
    }
  }
};

export const invalidateContract = (id) => async (dispatch, getState) => {
  dispatch(invalidateContractStarted());
  try {
    await invalidateContractAPI(getState(), id);
    dispatch(invalidateContractSuccess());
  } catch (err) {
    handleError(
      err,
      dispatch,
      invalidateContractFailure,
      'Failed to invalidate contract'
    );
  }
};

export const invalidateContracts = (ids) => async (dispatch, getState) => {
  dispatch(invalidateContractsStarted());
  try {
    await Promise.all(ids.map((id) => invalidateContractAPI(getState(), id)));
    dispatch(invalidateContractsSuccess());
  } catch (err) {
    handleError(
      err,
      dispatch,
      invalidateContractsFailure,
      'Failed to invalidate contracts'
    );
  }
};

export const createContract = (data, cb) => async (dispatch, getState) => {
  dispatch(createContractsStarted());
  try {
    const res = await createContractsAPI(getState(), data);
    dispatch(createContractsSuccess(res));
    toast.success(`Contract has been created!`);
    !!cb && cb();
  } catch (err) {
    handleError(
      err,
      dispatch,
      createContractsFailure,
      'There was an issue creating your contract'
    );
  }
};

export const remindContracts = (ids, method) => async (dispatch, getState) => {
  dispatch(remindContractsStarted());
  try {
    await remindContractAPI(getState(), ids, method);
    toast.success(`Reminder(s) have been sent!`);
    dispatch(remindContractsSuccess());
  } catch (err) {
    handleError(
      err,
      dispatch,
      remindContractsFailure,
      'There was an issue sending your Contract reminder(s)'
    );
  }
};

// selectors
const idSelector = (_, id) => id;
const reflectThird = (_, __, third) => third;
const selectContracts = (state) => state.contracts;

export const contractsSelector = createSelector(
  selectContracts,
  (contractsState = {}) => contractsState.contracts || getGenericState([])
);

export const contractSelector = createSelector(
  [contractsSelector, idSelector, reflectThird],
  (contractsState, id) => 
      contractsState.data.find((contract) => contract.id === id) || emptyContractData
);

export const contractWithRecipientSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return data.map(
      (contract) => {
        if (!!contract.creator_id) {
          return {
            ...contract,
            recipient_type: 'creator',
            recipient_id: contract.creator_id,
          }
        } else {
          return {
            ...contract,
            recipient_type: 'customer',
            recipient_id: contract.customer_id,
          }
        }
      }
    );
  }
);

export const sentContractsSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return data.filter(
      (contract) =>
        !contract.signature_date && contract.status !== objectStates.canceled
    );
  }
);

export const invalidatedContractsSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return data.filter((contract) => contract.status === objectStates.canceled);
  }
);

export const signedContractsSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return data.filter((contract) => !!contract.signature_date);
  }
);

export const invalidateContractSelector = createSelector(
  selectContracts,
  (contractsState = {}) =>
    contractsState.invalidateContract || getGenericState()
);

export const invalidateContractsSelector = createSelector(
  selectContracts,
  (contractsState = {}) =>
    contractsState.invalidateContracts || getGenericState()
);

export const remindContractsSelector = createSelector(
  selectContracts,
  (contractsState = {}) => contractsState.remindContracts || getGenericState()
);

export const createContractsSelector = createSelector(
  selectContracts,
  (contractsState = {}) => contractsState.createContracts || getGenericState()
);

export const contractPreviewSelector = createSelector(
  selectContracts,
  (contractsState = {}) => contractsState.contractPreview || getGenericState()
);

export const contractsByCreatorsSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return groupBy(prop('creator_id'), data);
  }
);

export const contractsByPaymentsSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return groupBy(prop('payment_id'), data);
  }
);

export const contractsByPaymentGroupSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return groupBy(prop('payment_group_id'), data);
  }
);

export const contractsDictSelector = createSelector(
  contractsSelector,
  (contractsState) => {
    const { data } = contractsState;
    return data.reduce((agg, contract) => {
      agg[contract.id] = contract;
      return agg;
    }, {});
  }
);
