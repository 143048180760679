import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from 'classnames';
import Button from 'components/Button';
import { AddBankAccountModal } from './AddBankAccountModal';
import global from 'styles/global';
import { AddPayPalModal } from './AddPayPalModal';
// import AddNiumModal from './AddNiumModal';
import IconWithInfo from 'components/IconWithInfo';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentMethodIcon from 'components/PaymentMethodIcon';
import { maskAcctNumber } from 'util/renderStrings';
import { AddVenmoModal } from './AddVenmoModal';
import { getWallets, walletsSelector } from 'slices/wallets';
// import { getPayMethodWidget, payMethodWidgetSelector } from 'slices/widgets';
import { getIsDev } from 'util/env';

function DirectDeposit({
  creator,
  updateCreator,
  updateCreatorState,
  isPayPalEnabled,
}) {
  const g = global();
  const dispatch = useDispatch();
  const isDev = getIsDev();

  const { data = {}, loading } = useSelector(walletsSelector);
  // const { data: payMethodWidget } = useSelector(payMethodWidgetSelector);
  // const { nium: niumWallet } = data;

  const [addBankAccountModalOpen, setAddBankAccountModalOpen] = useState(false);
  const [addPayPalModalOpen, setAddPayPalModalOpen] = useState(false);
  const [addVenmoOpen, setAddVenmoModalOpen] = useState(false);
  // const [addNiumOpen, setAddNiumOpen] = useState(false);

  const {
    account_number,
    paypal_email,
    venmo_phone_number,
    selected_payment_method,
  } = creator;

  const paymentMethodMap = {
    'stripe': {
      name: 'Bank account',
      value: maskAcctNumber(account_number),
    },
    'paypal': {
      name: 'PayPal',
      value: paypal_email,
    },
    'venmo': {
      name: 'Venmo',
      value: venmo_phone_number,
    },
    'nium': {
      name: 'Bank account (international)',
      value: '',
    },
  };

  useEffect(() => {
    dispatch(getWallets());
  }, []);

  // useEffect(() => {
  //   if (isDev) {
  //     dispatch(
  //       getPayMethodWidget({
  //         email: creator.email,
  //         ref_id: creator.id,
  //       })
  //     );
  //   }
  // }, [isDev, creator]);

  const { loading: updating } = updateCreatorState;

  const handleUpdateCreator = async (data) => {
    const updateObj = { id: creator.id, ...data };
    await dispatch(updateCreator(updateObj));
  };

  const handleDeleteMethod = () => {
    handleUpdateCreator({
      id: creator.id,
      selected_payment_method: '',
      paypal_email: '',
      account_number: '',
      routing_number: '',
    });
  };

  return (
    <>
      <AddBankAccountModal
        creator={creator}
        open={addBankAccountModalOpen}
        onClose={() => setAddBankAccountModalOpen(false)}
        handleUpdateCreator={handleUpdateCreator}
        updateCreatorState={updateCreatorState}
      />
      <AddPayPalModal
        creator={creator}
        open={addPayPalModalOpen}
        onClose={() => setAddPayPalModalOpen(false)}
        handleUpdateCreator={handleUpdateCreator}
        updateCreatorState={updateCreatorState}
      />
      <AddVenmoModal
        creator={creator}
        open={addVenmoOpen}
        onClose={() => setAddVenmoModalOpen(false)}
        handleUpdateCreator={handleUpdateCreator}
        updateCreatorState={updateCreatorState}
      />
      {/* <AddNiumModal
        creator={creator}
        open={addNiumOpen}
        onClose={() => setAddNiumOpen(false)}
        handleUpdateCreator={handleUpdateCreator}
        updateCreatorState={updateCreatorState}
        niumWallet={niumWallet}
        payMethodWidget={payMethodWidget.url}
      /> */}
      {!!selected_payment_method ? (
        <IconWithInfo
          buttonAction={handleDeleteMethod}
          submitting={updating}
          text={paymentMethodMap[selected_payment_method].name}
          subtext={paymentMethodMap[selected_payment_method].value}
          icon={<PaymentMethodIcon method={selected_payment_method} />}
          buttonIcon={<DeleteIcon fontSize="small" className={g.error} />}
        />
      ) : (
        <div className={css(g.pl_xxl, g.pr_xxl)}>
          <Button
            loading={false}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setAddBankAccountModalOpen(true)}
          >
            Add bank account
          </Button>
          {/* {!!niumWallet && (
            <div className={g.mt_sm}>
              <Button
                loading={false}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setAddNiumOpen(true)}
              >
                Add international bank account
              </Button>
            </div>
          )} */}
          {isPayPalEnabled && (
            <>
              <Button
                className={g.mt_sm}
                loading={false}
                variant="contained"
                color="default"
                fullWidth
                onClick={() => setAddPayPalModalOpen(true)}
              >
                Add PayPal email
              </Button>
              <Button
                className={g.mt_sm}
                loading={false}
                variant="contained"
                color="default"
                fullWidth
                onClick={() => setAddVenmoModalOpen(true)}
              >
                Add Venmo phone number
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default DirectDeposit;
