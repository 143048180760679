import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { find, propEq } from 'ramda';
import { Grid } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import global from 'styles/global';

import LoadingOverlay from 'components/LoadingOverlay';
import TableHeader from 'components/TableHeader';
import Details from './Details';
import Info from './Info';

import { setHeaderData } from 'slices/misc';
import {
  creatorsSelector,
  getCreators as getCreatorsSlice,
  currentUserSelector,
  adminsSelector,
  getMembers as getMembersSlice,
} from 'slices/user';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
  updatePayments as updatePaymentsSlice,
  initiatePayments as initiatePaymentsSlice,
  initiatePaymentsSelector,
  paymentsDictSelector,
  remindPayments as remindPaymentsSlice,
  remindPaymentsSelector,
  getRecipientPaymentTotals,
} from 'slices/payments';
import {
  getPaymentGroups as getPaymentGroupsSlice,
  paymentGroupsSelector,
} from 'slices/paymentGroups';
import { emptyPaymentData } from 'fixtures/payments';
import { formatCurrency, formatCreatorName } from 'util/renderStrings';
import Button from 'components/Button';
import PaymentConfirmationModal from 'components/PaymentConfirmationModal';
import {
  orgFeesSelector,
  outboundPmtFeesSelector,
  getOrgFees as getOrgFeesSlice,
} from 'slices/orgFees';
import objectStates from 'constants/objectStates';
import { paymentRecipientsDictSelector } from 'slices/multiSliceSelectors';

function Payment({
  creatorsState,
  getCreators,
  getPayments,
  paymentsState,
  updatePayments,
  getPaymentGroups,
  paymentGroupsState,
  initiatePayments,
  initiatePaymentsState,
  paymentsDict,
  paymentRecipientsDict,
  outboundPaymentFees,
  orgFeesState,
  getOrgFees,
  remindPayments,
  remindPaymentsState,
  getMembers,
  adminsState,
}) {
  const location = useLocation();
  const { id } = useParams();

  const dispatch = useDispatch();

  const g = global();

  const [modalVisible, setModalVisible] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});

  const { data: user } = useSelector(currentUserSelector);

  const { loading: orgFeesLoading } = orgFeesState;
  const { loading: creatorsLoading } = creatorsState;
  const { loading: adminsLoading } = adminsState;
  const { loading: paymentsLoading, data: payments } = paymentsState;
  const { loading: remindLoading } = remindPaymentsState;
  const { loading: paymentGroupsLoading, data: paymentGroups } =
    paymentGroupsState;

  const loading =
    creatorsLoading ||
    paymentsLoading ||
    paymentGroupsLoading ||
    orgFeesLoading ||
    adminsLoading;

  const payment = find(propEq('id', id), payments) || emptyPaymentData;
  const recipient = paymentRecipientsDict[payment.recipient];

  const executePayments = () => {
    initiatePayments([id], () => {
      setModalVisible(false);
    });
  };

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: `Payment to ${formatCreatorName(recipient)} - ${formatCurrency(
          payment.amount
        )}`,
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Payments', link: '/payments' },
          {
            label: `Payment to ${formatCreatorName(
              recipient
            )} - ${formatCurrency(payment.amount)}`,
          },
        ],
      })
    );
  }, [recipient, payment]);

  useEffect(() => {
    getCreators();
    getMembers();
    getPayments();
    getPaymentGroups();
    getOrgFees();
    dispatch(getRecipientPaymentTotals());
  }, []);

  useEffect(() => {
    if (location?.state?.data) {
      setPaymentDetails(location.state.data);
    }
  }, [location]);

  const sendReminder = async (method) => {
    await remindPayments([payment.id], method);
  };

  return (
    <div>
      <PaymentConfirmationModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
        executePayments={executePayments}
        payments={[id]}
        paymentsDict={paymentsDict}
        outboundPaymentFees={outboundPaymentFees}
        initiatePaymentsState={initiatePaymentsState}
      />

      <LoadingOverlay open={loading} />
      <TableHeader>
        {payment.status === objectStates.draft && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setModalVisible(true)}
            disabled={user.role === 'collaborator'}
          >
            Execute Payment
          </Button>
        )}

        {payment.status === objectStates.requires_info && (
          <div>
            <Button
              size="small"
              className={g.ml_xs}
              onClick={() => sendReminder('sms')}
              disabled={!recipient.phone_number}
              loading={remindLoading}
              type="submit"
              variant="outlined"
              color="primary"
            >
              Send SMS reminder
            </Button>
            <Button
              size="small"
              className={g.ml_xs}
              onClick={() => sendReminder('email')}
              loading={remindLoading}
              type="submit"
              variant="outlined"
              color="primary"
            >
              Send email reminder
            </Button>
          </div>
        )}
      </TableHeader>

      <Grid container spacing={2}>
        {/* Left column */}
        <Grid item xs={12} sm={8}>
          <Details
            payment={payment}
            updatePayments={updatePayments}
            paymentGroups={paymentGroups}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={8}>
          <Info payment={payment} details={paymentDetails} />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  paymentRecipientsDict: paymentRecipientsDictSelector(state),
  creatorsState: creatorsSelector(state),
  paymentsState: paymentsSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
  initiatePaymentsState: initiatePaymentsSelector(state),
  paymentsDict: paymentsDictSelector(state),
  outboundPaymentFees: outboundPmtFeesSelector(state),
  orgFeesState: orgFeesSelector(state),
  remindPaymentsState: remindPaymentsSelector(state),
  adminsState: adminsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCreators: (data) => dispatch(getCreatorsSlice(data)),
  getPayments: () => dispatch(getPaymentsSlice()),
  updatePayments: (data) => dispatch(updatePaymentsSlice(data)),
  getPaymentGroups: () => dispatch(getPaymentGroupsSlice()),
  initiatePayments: (data, cb) => dispatch(initiatePaymentsSlice(data, cb)),
  getOrgFees: () => dispatch(getOrgFeesSlice()),
  remindPayments: (data, method) => dispatch(remindPaymentsSlice(data, method)),
  getMembers: () => dispatch(getMembersSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
