import { configureStore } from '@reduxjs/toolkit';
import { clone } from 'ramda';
import posthog from 'posthog-js';
import subscribeActionMiddleware, {
  subscribeBefore,
} from 'redux-subscribe-action';
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';

import user, { usersInitialState } from 'slices/user';
import org from 'slices/org';
import treasury from 'slices/treasury';
import payments from 'slices/payments';
import paymentGroups from 'slices/paymentGroups';
import orgFees from 'slices/orgFees';
import contracts from 'slices/contracts';
import misc from 'slices/misc';
import expiringLink from 'slices/expiringLink';
import paymentGroupFiles from 'slices/paymentGroupFiles';
import invoices from 'slices/invoices';
import customers from 'slices/customers';
import plaid from 'slices/plaid';
import charges from 'slices/charges';
import paypal from 'slices/paypal';
import { loadState, saveState } from '../util/localStorage';
import orgApiKeys from 'slices/orgApiKeys';
import wallets from 'slices/wallets';
import notifications from 'slices/notifications';
import orgOauth from 'slices/orgOauth';
import qb from 'slices/qb';
import rapyd from 'slices/rapyd';
import contractTemplates from 'slices/contractTemplates';
import creators from 'euSlices/creators';
import deliverables from 'slices/deliverables';
import agentsMngrs from 'slices/agentsMngrs';
import taxes from 'slices/taxes';
import collectedFees from 'slices/collectedFees';
import tags from 'slices/tags';
import { getIsProd } from 'util/env';

const gtm = GoogleTagManager();

const event = (action, prevState, nextState) => {
  return {
    event: action.type,
  };
};

const eventsMap = {
  '*': event,
};

const gtmMiddleware = createMiddleware(eventsMap, gtm);

let extraMiddleware = [subscribeActionMiddleware];

const isProd = getIsProd();
if (isProd) extraMiddleware = extraMiddleware.concat(gtmMiddleware);

const persistedState = loadState();
const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(extraMiddleware),
  reducer: {
    agentsMngrs,
    charges,
    collectedFees,
    contracts,
    contractTemplates,
    creators,
    customers,
    deliverables,
    expiringLink,
    invoices,
    misc,
    notifications,
    org,
    orgApiKeys,
    orgFees,
    orgOauth,
    paymentGroupFiles,
    paymentGroups,
    payments,
    paypal,
    plaid,
    qb,
    rapyd,
    tags,
    taxes,
    treasury,
    user,
    wallets,
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  const state = store.getState();
  const _user = clone(state.user);
  const _org = clone(state.org);
  const _misc = clone(state.misc);

  saveState({
    user: {
      ...usersInitialState,
      currentUser: _user.currentUser,
    },
    org: _org,
    misc: _misc,
  });
});

posthog.init('phc_Hp01V7m05yHf8OH8tnbYOCQ7T5TD8hTQAiSXOjOzqSP', {
  api_host: 'https://app.posthog.com',
  autocapture: false,
  capture_pageview: false,
});
if (isProd) subscribeBefore((action) => posthog.capture(action.type));

export { store };
