import { createSlice } from '@reduxjs/toolkit';

import { getPayoutMethodTypes as getPayoutMethodTypesAPI } from 'api/rapyd';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialRapydState = {
  payoutMethodTypes: getGenericState(),
};

export const rapydSlice = createSlice({
  name: 'rapyd',
  initialState: initialRapydState,
  reducers: {
    getPayoutMethodTypesStarted: getGenericStarted('payoutMethodTypes'),
    getPayoutMethodTypesSuccess: getPayloadSuccess('payoutMethodTypes'),
    getPayoutMethodTypesFailure: getGenericFailure('payoutMethodTypes'),
  },
});

export const {
  getPayoutMethodTypesStarted,
  getPayoutMethodTypesSuccess,
  getPayoutMethodTypesFailure,
} = rapydSlice.actions;

export default rapydSlice.reducer;

export const getPayoutMethodTypes = () => async (dispatch, getState) => {
  dispatch(getPayoutMethodTypesStarted());
  try {
    const res = await getPayoutMethodTypesAPI(getState());
    dispatch(getPayoutMethodTypesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getPayoutMethodTypesFailure,
      'There was an error getting the payout method types.'
    );
  }
};

// selectors
