import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from 'classnames';
import { Link } from 'react-router-dom';

import global from 'styles/global';
import KeyValuePair from 'components/KeyValuePair';
import PercentInput from 'components/PercentInput';
import EditCard from 'components/EditCard';
import { Grid, Typography } from '@material-ui/core';
import {
  agentsMngrsDictSelector,
  agentsMngrsGroupedSelector,
  getAgentsMngrs,
} from 'slices/agentsMngrs';
import { formatCreatorName } from 'util/renderStrings';
import Autocomplete from 'components/Autocomplete';

const AffiliatedPersons = ({ creator, updateCreator }) => {
  const g = global();
  const dispatch = useDispatch();

  const { manager_id, manager_split } = creator;

  const agentsMngrsDict = useSelector(agentsMngrsDictSelector);
  const { managers } = useSelector(agentsMngrsGroupedSelector);

  const [newManager, setNewManager] = useState();
  const [newSplit, setNewSplit] = useState(10);

  useEffect(() => {
    dispatch(getAgentsMngrs());
  }, []);

  useEffect(() => {
    const manager = agentsMngrsDict[manager_id] || {};
    setNewManager(manager);
  }, [manager_id, agentsMngrsDict]);

  useEffect(() => {
    setNewSplit(manager_split);
  }, [manager_split]);

  const handleUpdateCreator = () => {
    dispatch(
      updateCreator({
        id: creator.id,
        manager_id: newManager ? newManager.id : '',
        manager_split: newSplit,
      })
    );
  };

  const handleCancel = () => {
    setNewManager(agentsMngrsDict[manager_id] || {});
    setNewSplit(manager_split);
  };

  return (
    <EditCard
      title="Manager"
      handleCancel={handleCancel}
      handleSubmit={handleUpdateCreator}
      staticContent={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <KeyValuePair
              label="Name"
              value={
                manager_id ? (
                  <Link to={`/agentsManagers/${manager_id}`}>
                    <Typography>{formatCreatorName(newManager)}</Typography>
                  </Link>
                ) : (
                  <Typography variant="body1">No Manager</Typography>
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <KeyValuePair label="Manager's split" value={newSplit + '%'} />
          </Grid>
        </Grid>
      }
    >
      <Grid item xs={12}>
        <Autocomplete
          options={managers}
          className={css(g.mt_xs, g.full_width)}
          getOptionLabel={(option) => formatCreatorName(option)}
          value={newManager}
          onChange={(_, val) => setNewManager(val)}
          textInputProps={{
            label: 'Manager',
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <PercentInput
          label="Manager's split"
          variant="outlined"
          margin="dense"
          value={newSplit}
          onChange={(evt) => setNewSplit(evt.target.value)}
          fullWidth
        />
      </Grid>
    </EditCard>
  );
};

export default AffiliatedPersons;
