import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DataGrid from 'components/DataGrid';
import global from 'styles/global';
import { formatCurrency, formatCreatorName } from 'util/renderStrings';
import { renderPaymentMethodIcon, renderAgentMngrLink } from 'util/table';
import { recipientsWithPaymentsSelector } from 'slices/multiSliceSelectors';
import { paymentGroupsByAgentSelector } from 'slices/paymentGroups';
import { renderRecipientEmail, renderTagsCell } from 'util/table';

const UsersTable = ({
  users,
  columnsToDisplay,
  checkboxEnabled,
  onSelectionModelChange,
  isRowSelectable,
}) => {
  const g = global();

  const paymentsForRecipients = useSelector(recipientsWithPaymentsSelector);
  const paymentGroupsByAgent = useSelector(paymentGroupsByAgentSelector);

  const possibleColumns = {
    emailCreator: {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/creators/${params.row.id}`}>{params.value}</Link>
      ),
      getApplyFilterFn: (filterItem, column) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        )
          return null;
        return (params) => params.value.includes(filterItem.value);
      },
    },
    emailAgentMngr: {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: renderAgentMngrLink,
      getApplyFilterFn: (filterItem, column) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        )
          return null;
        return (params) => params.value.includes(filterItem.value);
      },
    },
    emailRecipient: {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => renderRecipientEmail(params.row),
      getApplyFilterFn: (filterItem, column) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        )
          return null;
        return (params) => params.value.includes(filterItem.value);
      },
    },
    tags: {
      field: 'tags',
      headerName: 'Tags',
      flex: 1,
      renderCell: (params) => renderTagsCell(params.row),
      getApplyFilterFn: (filterItem) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        )
          return null;

        return (params) => {
          const tagsArray = Array.isArray(params.value) ? params.value : [];
          const tagsString = tagsArray.join('');
          return tagsString.includes(filterItem.value);
        };
      },
    },
    name: {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      valueGetter: (params) => formatCreatorName(params.row),
    },
    sales: {
      field: 'sales',
      headerName: 'Sales',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return (paymentGroupsByAgent[params.row.id] || []).length;
      },
    },
    payments: {
      field: 'numPayments',
      headerName: 'Number of payments',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => {
        const paymentsForUser =
          paymentsForRecipients[params.row.id]?.payments || [];
        return paymentsForUser.length;
      },
    },
    paymentsAmt: {
      field: 'id',
      headerName: 'Amount paid',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => {
        const paymentsForRecipient =
          paymentsForRecipients[params.id]?.payments || [];
        const amtPaid = paymentsForRecipient.reduce(
          (agg, pmt) => agg + pmt.amount,
          0
        );
        return amtPaid;
      },
      renderCell: (params) => {
        const paymentsForRecipient =
          paymentsForRecipients[params.id]?.payments || [];
        const amtPaid = paymentsForRecipient.reduce(
          (agg, pmt) => agg + pmt.amount,
          0
        );
        return formatCurrency(amtPaid);
      },
    },
    paymentMethod: {
      field: 'selected_payment_method',
      headerName: 'Payment method',
      flex: 1,
      renderCell: renderPaymentMethodIcon,
    },
    isW2: {
      field: 'is_w2',
      headerName: 'Is W2?',
      flex: 1,
      valueGetter: (params) => (params.value ? 'Yes' : 'No'),
    },
  };

  const columns = columnsToDisplay.map((col) => possibleColumns[col]);

  return (
    <div className={g.tableWrapper}>
      <DataGrid
        autoHeight
        autoPageSize
        rows={users}
        columns={columns}
        columnDependencies={[paymentsForRecipients, paymentGroupsByAgent]}
        checkboxSelection={checkboxEnabled}
        disableSelectionOnClick={checkboxEnabled}
        onSelectionModelChange={onSelectionModelChange}
        isRowSelectable={isRowSelectable}
      />
    </div>
  );
};

export default connect()(UsersTable);
