import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  createPaypalOauth as createPaypalOauthAPI,
  deletePaypalOauth as deletePaypalOauthAPI,
  getPaypalOauth as getPaypalOauthAPI,
} from 'api/paypal';

export const initialPaypalState = {
  createPaypalOauth: getGenericState(),
  deletePaypalOauth: getGenericState(),
  paypalOauth: getGenericState({}),
};

export const paypalSlice = createSlice({
  name: 'paypal',
  initialState: initialPaypalState,
  reducers: {
    createPaypalOauthStarted: getGenericStarted('createPaypalOauth'),
    createPaypalOauthSuccess: getPayloadSuccess('createPaypalOauth'),
    createPaypalOauthFailure: getGenericFailure('createPaypalOauth'),

    getPaypalOauthStarted: getGenericStarted('paypalOauth'),
    getPaypalOauthSuccess: getPayloadSuccess('paypalOauth'),
    getPaypalOauthFailure: getGenericFailure('paypalOauth'),

    deletePaypalOauthStarted: getGenericStarted('deletePaypalOauth'),
    deletePaypalOauthSuccess: getPayloadSuccess('deletePaypalOauth'),
    deletePaypalOauthFailure: getGenericFailure('deletePaypalOauth'),
  },
});

export const {
  createPaypalOauthStarted,
  createPaypalOauthSuccess,
  createPaypalOauthFailure,
  getPaypalOauthStarted,
  getPaypalOauthSuccess,
  getPaypalOauthFailure,
  deletePaypalOauthStarted,
  deletePaypalOauthSuccess,
  deletePaypalOauthFailure,
} = paypalSlice.actions;

export default paypalSlice.reducer;

export const createPaypalOauth = (data) => async (dispatch, getState) => {
  dispatch(createPaypalOauthStarted());
  try {
    const res = await createPaypalOauthAPI(getState(), data);
    dispatch(createPaypalOauthSuccess(res));
    dispatch(getPaypalOauth());
  } catch (err) {
    handleError(
      err,
      dispatch,
      createPaypalOauthFailure,
      'There was an issue creating paypal oauth'
    );
  }
};

export const deletePaypalOauth = (data) => async (dispatch, getState) => {
  dispatch(deletePaypalOauthStarted());
  try {
    const res = await deletePaypalOauthAPI(getState(), data);
    dispatch(deletePaypalOauthSuccess(res));
    dispatch(getPaypalOauth());
  } catch (err) {
    handleError(
      err,
      dispatch,
      deletePaypalOauthFailure,
      'There was an issue creating paypal oauth'
    );
  }
};

export const getPaypalOauth = (params) => async (dispatch, getState) => {
  dispatch(getPaypalOauthStarted());
  try {
    const res = await getPaypalOauthAPI(getState(), params);
    dispatch(getPaypalOauthSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getPaypalOauthFailure,
      'There was an issue retrieving your payments'
    );
  }
};

// selectors
const selectPaypal = (state) => state.paypal;

export const paypalOauthSelector = createSelector(
  selectPaypal,
  (paypalState = {}) => {
    return paypalState.paypalOauth?.data || {};
  }
);

export const paypalEnabledSelector = createSelector(
  paypalOauthSelector,
  (paypalOauth = {}) => {
    return !!paypalOauth.email;
  }
);

export const createPaypalOauthSelector = createSelector(
  selectPaypal,
  (paypalState = {}) => paypalState.createPaypalOauth || getGenericState()
);

export const deletePaypalOauthSelector = createSelector(
  selectPaypal,
  (paypalState = {}) => paypalState.deletePaypalOauth || getGenericState()
);
