import { createParams, handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const createPayments = async (state, data) => {
  return createRequest(`/v1/payments`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const getPayments = async (state, params) => {
  const qStr = createParams(params);
  return createRequest(`/v1/payments${qStr}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const initiatePayments = async (state, data) => {
  return createRequest(`/v1/payments/initiate`, state, {
    method: 'PUT',
    body: data,
  }).then((res) => handleError(res));
};

export const remindPayment = async (state, ids, reminderType) => {
  return createRequest(`/v1/payments/reminders`, state, {
    method: 'POST',
    body: {
      reminder_type: reminderType,
      ids: ids.join(','),
    },
  }).then((res) => handleError(res));
};

export const deletePayments = async (state, data) => {
  return createRequest(`/v1/payments`, state, {
    method: 'DELETE',
    body: data,
  }).then((res) => handleError(res));
};

export const updatePayments = async (state, data) => {
  return createRequest(`/v1/payments`, state, {
    method: 'PUT',
    body: data,
  }).then((res) => handleError(res));
};

export const getRecipientPaymentTotals = async (state) => {
  return createRequest(`/v1/payments/totals`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};
