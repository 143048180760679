import React from 'react';

import GeneralStep from './GeneralStep';
import PaymentsStep from './PaymentsStep';
import ContractStep from './ContractStep';
import DeliverablesStep from './DeliverablesStep';

export default function StepWrapper({ step, handleStep }) {
  switch (step) {
    case 'General':
      return <GeneralStep handleStep={handleStep} />;
    case 'Payments':
      return <PaymentsStep handleStep={handleStep} />;
    case 'Contract':
      return <ContractStep handleStep={handleStep} />;
    case 'Deliverables':
      return <DeliverablesStep handleStep={handleStep} />;
    default:
      return <GeneralStep handleStep={handleStep} />;
  }
}
