import { Redirect, Route } from 'react-router-dom';
import constants from 'constants/index';
import { useSelector } from 'react-redux';
import { isExternalSelector } from 'slices/user';

export default function ProtectedRoute({ user, org, children, path, ...rest }) {
  const loggedIn = !!user.auth_token;
  const hasOrg = user.org_id !== constants.defaultOrgId;
  const isCollaborator = user.role === 'collaborator';

  const isExternal = useSelector(isExternalSelector);

  const creatorRoutes = ['/creator/payments', '/creator/contracts', '/creator'];

  return (
    <Route
      path={path}
      {...rest}
      render={({ location }) => {
        if (!loggedIn) {
          return location.pathname === '/signup-umw01jf14qx7d6' ||
            location.pathname === '/signin' ? (
            children
          ) : (
            <Redirect to={{ pathname: '/signin', state: { from: location } }} />
          );
        }
        if (!hasOrg) {
          return location.pathname === '/createOrg' ? (
            children
          ) : (
            <Redirect
              to={{ pathname: '/createOrg', state: { from: location } }}
            />
          );
        }

        if (isExternal) {
          if (
            creatorRoutes.findIndex((path) =>
              location.pathname.startsWith(path)
            ) !== -1
          ) {
            return children;
          }
          return (
            <Redirect
              to={{ pathname: '/creator', state: { from: location } }}
            />
          );
        }

        // collaborators can't see the wallets page
        if (isCollaborator && location.pathname.startsWith('/wallets')) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }

        // if you're fully authed, you shouldn't be able to see onboarding pages
        if (
          location.pathname === '/signin' ||
          location.pathname === '/signu-umw01jf14qx7d6' ||
          location.pathname === '/createOrg'
        ) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }
        return children;
      }}
    />
  );
}
