import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';

import { updateAgentMngr } from 'slices/agentsMngrs';
import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import { USStates } from 'constants/usStates';
import global from 'styles/global';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';

function Contact({ agentMngr }) {
  const dispatch = useDispatch();
  const g = global();

  const {
    email,
    phone_number,
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_zip,
  } = agentMngr;

  const [newPhone, setNewPhone] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newAddress2, setNewAddress2] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newZip, setNewZip] = useState('');

  useEffect(() => {
    setNewPhone(phone_number);
    setNewAddress(address_line_1);
    setNewAddress2(address_line_2);
    setNewCity(address_city);
    setNewState({ abbreviation: address_state });
    setNewZip(address_zip);
  }, [
    phone_number,
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_zip,
  ]);

  const cancelEditing = () => {
    setNewPhone(phone_number);
    setNewAddress(address_line_1);
    setNewAddress2(address_line_2);
    setNewCity(address_city);
    setNewState({ abbreviation: address_state });
    setNewZip(address_zip);
  };

  const handleUpdateUser = async () => {
    const updateObj = { id: agentMngr.id };

    updateObj.phone_number = newPhone;
    updateObj.address = {
      line_1: newAddress,
      city: newCity,
      state: newState.abbreviation,
      zip: newZip,
      line_2: newAddress2,
    };

    await dispatch(updateAgentMngr(updateObj));
  };

  return (
    <EditCard
      title="Contact"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdateUser}
      staticContent={
        <>
          <KeyValuePair label="Email" value={email} />
          <KeyValuePair label="Phone number" value={phone_number} />
          <Typography variant="h5" className={css(g.mt_sm, g.mb_sm)}>
            Home address
          </Typography>
          <KeyValuePair label="Address 1" value={address_line_1} />
          <KeyValuePair label="Address 2" value={address_line_2} />
          <KeyValuePair label="City" value={address_city} />
          <KeyValuePair label="State" value={address_state} />
          <KeyValuePair label="Zip code" value={address_zip} />
        </>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            required
            variant="outlined"
            margin="dense"
            value={email}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone number"
            variant="outlined"
            margin="dense"
            value={newPhone}
            onChange={(evt) => setNewPhone(evt.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" className={g.mt_sm}>
            Home address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Address 1"
            value={newAddress}
            onChange={(e) => setNewAddress(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address 2"
            variant="outlined"
            margin="dense"
            value={newAddress2}
            onChange={(evt) => setNewAddress2(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="City"
            required
            variant="outlined"
            margin="dense"
            value={newCity}
            onChange={(evt) => setNewCity(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={USStates}
            value={newState}
            getOptionLabel={(option) => `${option.abbreviation || ''}`}
            onChange={(_, val) => setNewState(val)}
            textInputProps={{
              label: 'State',
              margin: 'dense',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Zip code"
            required
            variant="outlined"
            margin="dense"
            value={newZip}
            onChange={(evt) => setNewZip(evt.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default Contact;
