import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import TypographyWithDots from 'components/TypographyWithDots';
import { USStates } from 'constants/usStates';
import global from 'styles/global';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';

function Address({ customer, updateCustomer }) {
  const dispatch = useDispatch();
  const g = global();

  const {
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_zip,
  } = customer;

  const [newAddress, setNewAddress] = useState('');
  const [newAddress2, setNewAddress2] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newZip, setNewZip] = useState('');

  useEffect(() => {
    setNewAddress(address_line_1);
    setNewAddress2(address_line_2);
    setNewCity(address_city);
    setNewState({ abbreviation: address_state });
    setNewZip(address_zip);
  }, [
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_zip,
  ]);

  const cancelEditing = () => {
    setNewAddress(address_line_1);
    setNewAddress2(address_line_2);
    setNewCity(address_city);
    setNewState({ abbreviation: address_state });
    setNewZip(address_zip);
  };

  const handleUpdateCreator = async () => {
    const updateObj = { id: customer.id };

    updateObj.address = {
      line_1: newAddress,
      city: newCity,
      state: newState.abbreviation,
      zip: newZip,
      line_2: newAddress2,
    };

    await updateCustomer(updateObj);
  };

  return (
    <EditCard
      title="Address"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdateCreator}
      staticContent={
        <>
          <KeyValuePair label="Address 1" value={address_line_1} />
          <KeyValuePair label="Address 2" value={address_line_2} />
          <KeyValuePair label="City" value={address_city} />
          <KeyValuePair label="State" value={address_state} />
          <KeyValuePair label="Zip code" value={address_zip} />
        </>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Address 1"
            value={newAddress}
            onChange={(e) => setNewAddress(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address 2"
            variant="outlined"
            margin="dense"
            value={newAddress2}
            onChange={(evt) => setNewAddress2(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="City"
            variant="outlined"
            margin="dense"
            value={newCity}
            onChange={(evt) => setNewCity(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={USStates}
            value={newState}
            getOptionLabel={(option) => `${option.abbreviation || ''}`}
            onChange={(_, val) => setNewState(val)}
            textInputProps={{
              label: 'State',
              margin: 'dense',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Zip code"
            variant="outlined"
            margin="dense"
            value={newZip}
            onChange={(evt) => setNewZip(evt.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default Address;
