import { Chip } from '@material-ui/core';
import objectStates from 'constants/objectStates';

import { colors } from 'styles/global';

export const calculateFee = (
  amt = 0,
  flatRate = 0,
  pctRate = 0,
  _cap = Infinity
) => {
  const cap = _cap ?? Infinity;
  const pctFee = Math.round(amt * (pctRate / 100));
  return Math.min(flatRate + pctFee, cap);
};

const paymentStatusMap = {
  [objectStates.draft]: <Chip variant="outlined" label="Draft" />,
  [objectStates.requires_info]: (
    <Chip variant="outlined" label="Awaiting input" />
  ),
  [objectStates.processing]: <Chip variant="outlined" label="Pending" />,
  [objectStates.succeeded]: (
    <Chip
      variant="outlined"
      label="Posted"
      style={{ color: colors.success.main, borderColor: colors.success.main }}
    />
  ),
  [objectStates.returned]: (
    <Chip variant="outlined" color="secondary" label="Returned" />
  ),
  [objectStates.failed]: (
    <Chip variant="outlined" color="secondary" label="Failed" />
  ),
  [objectStates.failed]: (
    <Chip variant="outlined" color="secondary" label="Failed" />
  ),
  [objectStates.canceled]: (
    <Chip variant="outlined" color="secondary" label="Canceled" />
  ),
};

export const renderPaymentStatusChip = (value) => paymentStatusMap[value];
