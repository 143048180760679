import React, { useRef, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import css from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, ButtonGroup } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useDropzone } from 'react-dropzone';
import Draggable from 'react-draggable';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { contractInputTypes } from 'constants/contracts';
import {
  createContract as createContractSlice,
  createContractsSelector,
} from 'slices/contracts';
import global from 'styles/global';
import Button from 'components/Button';
import TableHeader from 'components/TableHeader';
import Card from 'components/Card';
import { contractTypes } from 'constants/contracts';
import { paymentGroupSelector } from 'slices/paymentGroups';

const TWENTY_FIVE_MB = 25 * 1000 * 1000;

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    padding: theme.spacing(2),
  },
  docHeader: {
    paddingBottom: '0 !important',
    marginBottom: '-12px',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8),
    borderWidth: '2px',
    borderRadius: 0,
    borderColor: theme.palette.shades.charcoal036,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.shades.charcoal004,
    color: theme.palette.shades.charcoal036,
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
  },
  dropzoneContainer: {
    padding: theme.spacing(3),
  },
  sigBlock: {
    position: 'absolute',
    cursor: 'move',
    color: 'red',
    maxWidth: '215px',
    borderRadius: '5px',
    margin: 'auto',
    userSelect: 'none',
  },
  sigContainer: {
    height: '120px',
  },
  docContainer: {
    border: '1px solid black',
  },
  flowRoot: {
    display: 'flow-root',
  },
  removeSig: {
    position: 'absolute',
    top: -20,
    right: -20,
  },
  pdfCtrBar: {
    padding: theme.spacing(1, 2, 0, 2),
  },
}));

const ContractStep = ({ createContract, createContractsState }) => {
  const classes = useStyles();
  const g = global();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const campaignId = query.get('campaignId');

  const { loading: createContractsLoading } = createContractsState;

  const campaign = useSelector((state) =>
    paymentGroupSelector(state, campaignId)
  );

  const [isHelloSign, setIsHelloSign] = useState(true);
  const [file, setFile] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [dropErr, setDropErr] = useState('');

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({ accept: 'application/pdf', maxSize: TWENTY_FIVE_MB });

  useEffect(() => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    setFile({
      file: newFile[0],
      preview: URL.createObjectURL(newFile),
    });
    setDropErr('');
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      setDropErr('Contract must be a PDF under 25 MB');
    }
  }, [fileRejections]);

  const docRef = useRef();
  const sigRef = useRef();
  const doc = docRef.current;
  const sig = sigRef.current;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const [lastSigId, setLastSigId] = useState(0);
  const [sigBlocks, setSigBlocks] = useState([
    { id: 0, x: 0, y: 0, pristine: true },
  ]);

  const trackPos = (data, id) => {
    const sigBlock = sigBlocks.find((sb) => sb.id === id);
    if (!sigBlock) return;

    const newSigBlocks = sigBlocks.map((sb) => {
      if (sb.id === id) {
        return {
          ...sb,
          page: pageNumber,
          x: data.x,
          y: data.y,
          pristine: false,
        };
      }
      return sb;
    });
    if (sigBlock.pristine) {
      setSigBlocks([
        ...newSigBlocks,
        { id: lastSigId + 1, x: 0, y: 0, pristine: true },
      ]);
      setLastSigId(lastSigId + 1);
    } else {
      setSigBlocks(newSigBlocks);
    }
  };

  const createContractAndNavigate = async () => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      formData.append('file', acceptedFiles[0], acceptedFiles[0].name);
    }
    formData.append('title', campaign.title);
    formData.append('recipient', campaign.customer_id);
    formData.append('recipient_type', 'customer');
    formData.append('payment_group_id', campaignId);

    if (isHelloSign) {
      formData.append('type', contractTypes.HelloSign);

      const sigs = sigBlocks
        .filter((sb) => !sb.pristine)
        .map((sb) => {
          const d1 = sig.offsetLeft - doc.offsetLeft + sb.x;
          const d2 = sig.offsetTop - doc.offsetTop + sb.y;

          // This is assuming that the document is the standard A4 size, PDFs are measured in points, A4 width/ height at 72 PPI = 612 pts x 792 pts
          const xPts = (d1 / doc.offsetWidth) * 612;
          const yPts = (d2 / doc.offsetHeight) * 792;
          return {
            x: Math.round(xPts),
            y: Math.round(yPts),
            type: contractInputTypes.signature,
            width: 80,
            height: 16,
            required: true,
            signer: 0,
            api_id: `signature_field_${sb.id}`,
            page: sb.page,
          };
        });
      formData.append('formFields', JSON.stringify(sigs));
    } else {
      formData.append('type', contractTypes.Uploaded);
    }
    createContract(formData, () => history.push(`/campaigns/${campaignId}`));
  };

  const handleSkip = () => {
    history.push(`/campaigns/${campaignId}`);
  };

  const removeIfOutsideDoc = (data, id) => {
    if (!sig || !doc) return;
    const sigBlock = sigBlocks.find((sb) => sb.id === id);
    const d1 = sig.offsetLeft - doc.offsetLeft + sigBlock.x + 16;
    const d2 = sig.offsetTop - doc.offsetTop + sigBlock.y;
    if (
      d1 < 0 ||
      d1 > doc.offsetWidth - 112 || // 32 for padding of container element, 80 for width of sig block
      d2 < 0 ||
      d2 > doc.offsetHeight - 32 // 16 for height of sig block, 16 for container element padding
    ) {
      handleDelete(id);
    }
  };

  const handleDelete = (id) => {
    const newSigBlocks = sigBlocks.filter((sb) => sb.id !== id);
    setSigBlocks(newSigBlocks);
  };

  const handleNextPage = () => {
    setPageNumber(Math.min(numPages, pageNumber + 1));
  };

  const handleBackPage = () => {
    setPageNumber(Math.max(1, pageNumber - 1));
  };

  const clearFile = () => {
    setFile(null);
    setSigBlocks(sigBlocks.slice(-1));
  };

  const isValid = !!file;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableHeader title="Contract details">
            <div>
              <Button
                size="small"
                onClick={handleSkip}
                variant="outlined"
                color="primary"
              >
                Skip
              </Button>

              <Button
                size="small"
                className={g.ml_xs}
                onClick={createContractAndNavigate}
                variant="contained"
                color="primary"
                loading={createContractsLoading}
                disabled={!isValid}
              >
                Create Contract
              </Button>
            </div>
          </TableHeader>

          <Card noHeader>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <Typography variant="h5" className={g.mb_xs}>
                  Select one
                </Typography>
                <ButtonGroup variant="outlined">
                  <Button
                    color="primary"
                    variant={isHelloSign ? 'contained' : 'outlined'}
                    onClick={() => setIsHelloSign(true)}
                  >
                    Use Hello Sign
                  </Button>
                  <Button
                    color="primary"
                    variant={!isHelloSign ? 'contained' : 'outlined'}
                    onClick={() => setIsHelloSign(false)}
                  >
                    Upload Contract
                  </Button>
                </ButtonGroup>
                <Typography variant="body2" className={g.mt_xs}>
                  {isHelloSign
                    ? `We'll send a Contract to the recipient via HelloSign to be signed. This is a legally binding Contract.`
                    : `You'll upload a copy of a document that's already been signed. No notification will be sent out.`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} className={classes.docHeader}>
          <TableHeader title="Add a document"></TableHeader>
        </Grid>

        <>
          <Grid item sm={isHelloSign ? 9 : 12} xs={12}>
            <Card noHeader>
              {!file && (
                <section className={classes.dropzoneContainer}>
                  <div {...getRootProps({ className: classes.dropzone })}>
                    <input {...getInputProps()} />
                    <AddIcon
                      style={{ fontSize: 60 }}
                      className={g.charcoal036}
                    />
                    <Typography variant="body1">
                      Drag and drop a PDF to get started
                    </Typography>
                  </div>
                  <aside>
                    <Typography
                      variant="body1"
                      className={css(g.error, g.mt_sm)}
                    >
                      {dropErr}
                    </Typography>
                  </aside>
                </section>
              )}
              {!!file && (
                <>
                  <div
                    className={css(
                      classes.pdfCtrBar,
                      g.flexRowSpacing,
                      g.alignCenter
                    )}
                  >
                    <div className={css(g.alignCenter, g.flexRow)}>
                      <Typography variant="body2" className={g.alignStart}>
                        Page {pageNumber} of {numPages}
                      </Typography>
                      <Button
                        disabled={pageNumber === 1}
                        onClick={handleBackPage}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={handleNextPage}
                        disabled={pageNumber === numPages}
                      >
                        Next
                      </Button>
                    </div>
                    <CloseIcon
                      className={css(g.clickable, g.error)}
                      onClick={clearFile}
                    />
                  </div>
                  <div className={classes.flowRoot} ref={docRef}>
                    <Document
                      file={file.preview}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className={css(classes.docContainer, g.m_md, g.mt_zero)}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </div>
                </>
              )}
            </Card>
          </Grid>

          {isHelloSign && (
            <Grid item sm={3} xs={12}>
              <Card noHeader>
                <Grid container>
                  <Grid item xs={12} className={g.mb_md}>
                    <Typography variant="h3">Signature blocks</Typography>
                    <Typography variant="subtitle2">Drag to add</Typography>
                  </Grid>

                  <Grid item xs={6} className={classes.sigContainer}>
                    <div ref={sigRef}>
                      {sigBlocks.map((sigBlock) => (
                        <Draggable
                          key={`sigBlock-${sigBlock.id}`}
                          disabled={!file}
                          onStop={(e, data) =>
                            removeIfOutsideDoc(data, sigBlock.id)
                          }
                          onDrag={(e, data) => trackPos(data, sigBlock.id)}
                        >
                          <div
                            className={css(
                              classes.sigBlock,
                              g.centerChildren,
                              g.bg_sigYellow,
                              g.p_xs,
                              sigBlock.page === pageNumber ||
                                sigBlock.page === undefined
                                ? undefined
                                : g.hide
                            )}
                          >
                            <DownloadIcon />
                            {!sigBlock.pristine && (
                              <CloseIcon
                                onClick={() => handleDelete(sigBlock.id)}
                                className={css(
                                  g.clickable,
                                  g.error,
                                  classes.removeSig
                                )}
                              />
                            )}
                            <Typography variant="subtitle2">
                              Signature
                            </Typography>
                            {/* <div>
                        x: {sigBlock.x.toFixed(0)}, y: {sigBlock.y.toFixed(0)}
                      </div> */}
                          </div>
                        </Draggable>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    {/* TODO: add initials */}
                    {/* <Draggable onDrag={(e, data) => trackPos(data)}>
                  <div
                    className={css(
                      classes.sigBlock,
                      g.centerChildren,
                      g.bg_sigYellow,
                      g.p_xs
                    )}
                  >
                    <DownloadIcon />
                    <Typography variant="subtitle2">Initial</Typography>
                  </div>
                </Draggable> */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
        </>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  createContractsState: createContractsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createContract: (data, cb) => dispatch(createContractSlice(data, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractStep);
