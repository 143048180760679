import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import css from 'classnames';
import AddIcon from '@mui/icons-material/Add';

import EditCard from 'components/EditCard';
import global from 'styles/global';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderWidth: '2px',
    borderRadius: 0,
    borderColor: theme.palette.shades.charcoal036,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.shades.charcoal004,
    color: theme.palette.shades.charcoal036,
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
  },
  dropzoneContainer: {
    padding: theme.spacing(3),
  },
  docContainer: {
    border: '1px solid black',
  },
  flowRoot: {
    display: 'flow-root',
  },
}));

function OrgLogoSection({ orgState, handleUpdateOrgLogo, updateOrgLogoState }) {
  const classes = useStyles();
  const g = global();

  const {
    data: { logo },
  } = orgState;

  const { loading } = updateOrgLogoState;

  const [file, setFile] = useState();
  const [fileURL, setFileURL] = useState('');
  const [dropErr, setDropErr] = useState('');

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone();

  useEffect(() => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    if (!newFile.type.startsWith('image/')) {
      setDropErr('invalid file type');
      return;
    }
    setFile({
      file: newFile,
    });
    setFileURL(URL.createObjectURL(newFile));
    setDropErr('');
  }, [acceptedFiles]);

  const handleSubmit = async () => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      formData.append('file', acceptedFiles[0], acceptedFiles[0].name);
    }
    await handleUpdateOrgLogo(formData);
    setFile();
    setDropErr('');
  };

  const cancelEditing = () => {
    setFile();
    setDropErr('');
  };

  return (
    <EditCard
      variant="outlined"
      title="Logo"
      handleCancel={cancelEditing}
      handleSubmit={handleSubmit}
      submitDisabled={loading}
      staticContent={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {!!logo ? (
              <img alt="logo" style={{ height: '70px' }} src={logo} />
            ) : (
              <Typography variant="body1">No logo</Typography>
            )}
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {!file && (
            <section className={classes.dropzoneContainer}>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <AddIcon style={{ fontSize: 60 }} className={g.charcoal036} />
                <Typography variant="body1">
                  Drag and drop a new logo
                </Typography>
              </div>
              <aside>
                <Typography variant="body1" className={css(g.error, g.mt_sm)}>
                  {dropErr}
                </Typography>
              </aside>
            </section>
          )}
          {!!file && (
            <>
              <img alt="logo" style={{ height: '70px' }} src={fileURL} />
            </>
          )}
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default OrgLogoSection;
