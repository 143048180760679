import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import css from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import global from 'styles/global';
import { colors } from 'styles/global';
import { ReactComponent as DarkLogo } from 'assets/darkLogo.svg';
import { formatCurrency } from 'util/renderStrings';

const useStyles = makeStyles((theme) => ({
  successIcon: {
    height: '50px',
    width: '50px',
  },
  border: {
    border: `1px solid ${theme.palette.shades.charcoal020}`,
  },
}));

const PayInvoiceSuccess = ({ amount }) => {
  const g = global();
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item sm={12} md={8}>
        <DarkLogo />
        <div className={css(classes.border, g.p_lg)}>
          <Typography align="center" variant="h1" className={g.mb_md}>
            <CheckCircleIcon fontSize="36" htmlColor={colors.success.main} />
          </Typography>
          <Typography variant="h1" className={css(g.mb_xl, g.textCenter)}>
            Thank you!
          </Typography>
          <Typography
            variant="h5"
            className={css(g.mb_xxl, g.textCenter, g.ph_xxl)}
          >
            {`Your payment of ${formatCurrency(
              amount
            )} has been sent! A receipt has been emailed to you,
            please check your spam folder if you're having trouble finding it.`}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default PayInvoiceSuccess;
