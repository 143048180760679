import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import {
  getPaymentGroups as getPaymentGroupsSlice,
  paymentGroupsSelector,
} from 'slices/paymentGroups';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
  initiatePaymentsSelector,
} from 'slices/payments';
import {
  getOrgFees as getOrgFeesSlice,
  orgFeesSelector,
  outboundPmtFeesSelector,
} from 'slices/orgFees';
import { getCreators as getCreatorsSlice, creatorsSelector } from 'slices/user';

import LoadingOverlay from 'components/LoadingOverlay';
import PageHeader from 'components/PageHeader';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import OverviewTab from './OverviewTab';
import DeliverablesTab from './DeliverablesTab';

import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import {
  paymentGroupFilesSelector,
  getPaymentGroupFiles as getPaymentGroupFilesSlice,
} from 'slices/paymentGroupFiles';
import {
  getInvoices as getInvoicesSlice,
  invoicesSelector,
} from 'slices/invoices';
import {
  customersSelector,
  getCustomers as getCustomersSlice,
} from 'slices/customers';
import { getContracts } from 'slices/contracts';
import { paymentGroupTypes } from 'constants/paymentGroups';
import useTabs from 'hooks/useTabs';
import PaymentsTab from './PaymentsTab';
import InvoicesTab from './InvoicesTab';
import ContractsTab from './ContractsTab';
import QuickbooksTab from './QuickbooksTab';
import {
  deliverablesSelector,
  getDeliverables as getDeliverablesSlice,
} from 'slices/deliverables';
import { getMembers as getMembersSlice, adminsSelector } from 'slices/user';
import {
  getAgentsMngrs as getAgentsMngrsSlice,
  agentsMngrsSelector,
} from 'slices/agentsMngrs';
import { setHeaderData } from 'slices/misc';

const tabs = [
  { label: 'Overview', name: 'overview' },
  { label: 'Payments', name: 'payments' },
  { label: 'Invoices', name: 'invoices' },
  { label: 'Contracts', name: 'contracts' },
  { label: 'Deliverables', name: 'deliverables' },
  { label: 'Quickbooks', name: 'quickbooks' },
];

const CampaignDetails = ({
  creatorsState,
  getCreators,
  getInvoices,
  getOrgFees,
  getPaymentGroupFiles,
  getPaymentGroups,
  getPayments,
  invoicesState,
  orgFeesState,
  paymentGroupFilesState,
  paymentGroupsState,
  paymentsState,
  customersState,
  getCustomers,
  getDeliverables,
  deliverablesState,
  getMembers,
  adminsState,
  getAgentsMngrs,
  agentsMngrsState,
}) => {
  const dispatch = useDispatch();
  const { loading: orgFeesLoading } = orgFeesState;
  const { loading: creatorsLoading } = creatorsState;
  const { loading: paymentsLoading } = paymentsState;
  const { loading: invoicesLoading } = invoicesState;
  const { loading: paymentGroupsLoading } = paymentGroupsState;
  const { loading: paymentGroupFilesLoading } = paymentGroupFilesState;
  const { loading: customersLoading } = customersState;
  const { loading: deliverablesLoading } = deliverablesState;
  const { loading: adminsLoading } = adminsState;
  const { loading: agentsMngrsLoading } = agentsMngrsState;

  const loading =
    invoicesLoading ||
    paymentsLoading ||
    paymentGroupsLoading ||
    creatorsLoading ||
    orgFeesLoading ||
    paymentGroupFilesLoading ||
    customersLoading ||
    deliverablesLoading ||
    adminsLoading ||
    agentsMngrsLoading;

  const { groupId } = useParams();
  const group = useSelector((state) => {
    return (
      state.paymentGroups.paymentGroups.data.find(
        (grp) => grp.id === groupId
      ) || emptyPaymentGroupData
    );
  });

  const { search } = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    tabs.map((tab) => tab.name),
    `/campaigns/${groupId}`
  );

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: group.title,
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Campaigns', link: '/campaigns' },
          { label: group.title },
        ],
      })
    );
  }, [group]);

  useEffect(() => {
    dispatch(getContracts());
    getOrgFees();
    getCreators();
    getPaymentGroups({ type: paymentGroupTypes.campaign });
    getPaymentGroupFiles();
    getCustomers();
    getPayments({
      payment_group_id: groupId,
    });
    getInvoices({
      payment_group_id: groupId,
    });
    getDeliverables({ campaign_id: groupId });
    getMembers();
    getAgentsMngrs();
  }, []);

  return (
    <div>
      <LoadingOverlay open={loading || !group.id} />

      <Tabs
        tabLabels={tabs.map((tab) => tab.label)}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="contracts-tabpanel"
      />

      <TabPanel value={currentTab} index={0}>
        <OverviewTab />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <PaymentsTab />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <InvoicesTab />
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <ContractsTab />
      </TabPanel>

      <TabPanel value={currentTab} index={4}>
        <DeliverablesTab />
      </TabPanel>

      <TabPanel value={currentTab} index={5}>
        <QuickbooksTab />
      </TabPanel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  creatorsState: creatorsSelector(state),
  customersState: customersSelector(state),
  initiatePaymentsState: initiatePaymentsSelector(state),
  invoicesState: invoicesSelector(state),
  orgFeesState: orgFeesSelector(state),
  outboundPaymentFees: outboundPmtFeesSelector(state),
  paymentGroupFilesState: paymentGroupFilesSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
  paymentsState: paymentsSelector(state),
  deliverablesState: deliverablesSelector(state),
  adminsState: adminsSelector(state),
  agentsMngrsState: agentsMngrsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentGroups: () => dispatch(getPaymentGroupsSlice()),
  getPayments: (params) => dispatch(getPaymentsSlice(params)),
  getCreators: () => dispatch(getCreatorsSlice()),
  getOrgFees: () => dispatch(getOrgFeesSlice()),
  getPaymentGroupFiles: () => dispatch(getPaymentGroupFilesSlice()),
  getInvoices: (params) => dispatch(getInvoicesSlice(params)),
  getCustomers: () => dispatch(getCustomersSlice()),
  getDeliverables: (params) => dispatch(getDeliverablesSlice(params)),
  getMembers: () => dispatch(getMembersSlice()),
  getAgentsMngrs: () => dispatch(getAgentsMngrsSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
