import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import DataGrid from 'components/DataGrid';
import TableHeader from 'components/TableHeader';
import LoadingOverlay from 'components/LoadingOverlay';
import global from 'styles/global';
import {
  getOrgValue,
  renderContractStatusChip,
  renderCreatorContractTitle,
  renderOrgCell,
} from 'util/table';

import {
  contractsSelector,
  getContracts as getContractsSlice,
} from 'slices/contracts';
import {
  getPayments as getPaymentsSlice,
  paymentsDictSelector,
  paymentsSelector,
} from 'slices/payments';
import { getDateAndTimeFromIso } from 'util/time';
import PaymentLinkCreator from 'components/PaymentLinkCreator';
import { orgsSelector } from 'slices/org';
import { setHeaderData } from 'slices/misc';

function CreatorContracts({
  contractsState,
  getContracts,
  getPayments,
  paymentsDict,
  paymentsState,
  orgsState,
}) {
  const g = global();
  const dispatch = useDispatch();

  const { data: contracts, loading: contractsLoading } = contractsState;
  const { loading: paymentsLoading } = paymentsState;
  const { loading: orgsLoading, data: orgs } = orgsState;
  const loading = contractsLoading || paymentsLoading || orgsLoading;

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Contracts',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Contracts' },
        ],
      })
    );

    getPayments();
    getContracts();
  }, []);

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => renderCreatorContractTitle(params),
    },
    {
      field: 'payment_id',
      headerName: 'Payment',
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.value) return '-';
        const pmt = paymentsDict[params.value];
        if (!pmt) return '-';
        return <PaymentLinkCreator payment={pmt} />;
      },
      flex: 1,
    },
    {
      field: 'org_id',
      headerName: 'Company',
      flex: 1,
      renderCell: (params) => renderOrgCell(params, orgs),
      valueGetter: (params) => getOrgValue(params.value, orgs),
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) =>
        renderContractStatusChip(params.row.type, params.row.status),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'signature_date',
      headerName: 'Date signed',
      renderCell: (params) => {
        if (!params.value) return '-';
        return getDateAndTimeFromIso(params.value);
      },
      flex: 1,
      disableColumnMenu: true,
    },
  ];

  return (
    <div>
      <LoadingOverlay open={loading} />

      {/* All */}
      <TableHeader title="All Contracts" />
      <div className={g.tableWrapper}>
        <DataGrid
          suppressMemoization
          autoHeight
          autoPageSize
          rows={contracts}
          columns={columns}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contractsState: contractsSelector(state),
  paymentsDict: paymentsDictSelector(state),
  paymentsState: paymentsSelector(state),
  orgsState: orgsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContracts: () => dispatch(getContractsSlice()),
  getPayments: () => dispatch(getPaymentsSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorContracts);
