import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import css from 'classnames';
import { Typography, Grid } from '@material-ui/core';

import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import Button from 'components/Button';
import global from 'styles/global';
import {
  createDeliverables,
  createDeliverablesSelector,
} from 'slices/deliverables';
import { creatorsSelector } from 'slices/user';
import { formatCreatorName } from 'util/renderStrings';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';

const NewDeliverableModal = ({ open, handleClose, campaignId }) => {
  const g = global();
  const dispatch = useDispatch();

  const { data: creators } = useSelector(creatorsSelector);

  const [creator, setCreator] = useState();
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [postDate, setPostDate] = useState('');
  const { loading: submitting } = useSelector(createDeliverablesSelector);

  const resetFields = () => {
    setCreator();
    setDescription('');
    setQuantity('');
    setPostDate('');
  };

  const handleCreateDeliverable = async () => {
    const deliverableObj = {
      campaign_id: campaignId,
      creator_id: creator.id,
      description,
      quantity: parseInt(quantity),
      post_date: postDate,
    };

    const success = await dispatch(createDeliverables([deliverableObj]));
    if (success) {
      handleClose();
      resetFields();
    }
  };

  const isInputValid = !!creator;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">New Deliverable</Typography>
      </ModalTitle>

      <ModalContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} className={css(g.flexRow, g.alignCenter)}>
            <Autocomplete
              className={css(g.mt_zero, g.mb_zero_i)}
              options={creators}
              getOptionLabel={formatCreatorName}
              value={creator}
              onChange={(_, value) => setCreator(value)}
              textInputProps={{
                required: true,
                label: 'Recipient',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={css(g.flexRow, g.alignCenter)}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={css(g.mt_zero_i, g.mb_zero_i)}
              variant="outlined"
              label="Post date"
              type="date"
              fullWidth
              margin="dense"
              value={postDate}
              onChange={(evt) => setPostDate(evt.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={10} className={css(g.flexRow, g.alignCenter)}>
            <TextField
              margin="dense"
              className={g.m_zero}
              variant="outlined"
              label="Description"
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2} className={css(g.flexRow, g.alignCenter)}>
            <TextField
              type="number"
              margin="dense"
              className={g.m_zero}
              variant="outlined"
              label="Qty"
              value={quantity}
              onChange={(evt) => setQuantity(evt.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          loading={submitting}
          onClick={handleCreateDeliverable}
          color="primary"
          variant="contained"
          disabled={!isInputValid}
        >
          Create
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default NewDeliverableModal;
