import DazzedThin from './Dazzed-Thin.otf';
import DazzedLight from './Dazzed-Light.otf';
import DazzedRegular from './Dazzed-Regular.otf';
import DazzedMedium from './Dazzed-Medium.otf';
import DazzedSemiBold from './Dazzed-SemiBold.otf';
import DazzedBold from './Dazzed-Bold.otf';
import DazzedHeavy from './Dazzed-Heavy.otf';

export const dazzedThin = {
  fontFamily: 'Dazzed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    local('Dazzed'),
    url(${DazzedThin}) format('opentype')
  `,
};

export const dazzedLight = {
  fontFamily: 'Dazzed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
      local('Dazzed'),
      url(${DazzedLight}) format('opentype')
    `,
};

export const dazzedRegular = {
  fontFamily: 'Dazzed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Dazzed'),
      url(${DazzedRegular}) format('opentype')
    `,
};

export const dazzedMedium = {
  fontFamily: 'Dazzed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
      local('Dazzed'),
      url(${DazzedMedium}) format('opentype')
    `,
};

export const dazzedSemiBold = {
  fontFamily: 'Dazzed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
      local('Dazzed'),
      url(${DazzedSemiBold}) format('opentype')
    `,
};

export const dazzedBold = {
  fontFamily: 'Dazzed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
      local('Dazzed'),
      url(${DazzedBold}) format('opentype')
    `,
};

export const dazzedHeavy = {
  fontFamily: 'Dazzed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
      local('Dazzed'),
      url(${DazzedHeavy}) format('opentype')
    `,
};
