export const emptyPaymentGroupData = {
  created_at: '',
  description: '',
  id: '',
  ignored_rows: '',
  org_id: '',
  title: '',
  type: '',
  updated_at: '',
};
