import {  handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const addApiKey = async (state, data) => {
  return createRequest(`/v1/org_api_token`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};
export const getApiKeys = async (state, data) => {
  return createRequest(`/v1/org_api_token`, state, {
    method: 'GET',
    body: data,
  }).then((res) => handleError(res));
};
export const deleteApiKey = async (state, id) => {
  return createRequest(`/v1/org_api_token/${id}`, state, {
    method: 'DELETE',
  }).then((res) => handleError(res));
};
