import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Button';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import css from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';

import global from 'styles/global';
import { uploadAttachment } from 'slices/invoices';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8),
    borderWidth: '2px',
    borderRadius: 0,
    borderColor: theme.palette.shades.charcoal036,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.shades.charcoal004,
    color: theme.palette.shades.charcoal036,
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
  },
}));

const AttachmentModal = ({ open, onClose, setAttachment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [preview, setPreview] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone();

  const g = global();

  useEffect(() => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    setPreview(URL.createObjectURL(newFile));
    setError('');
  }, [acceptedFiles]);

  const handleNextPage = () => {
    setPageNumber(Math.min(numPages, pageNumber + 1));
  };

  const handleBackPage = () => {
    setPageNumber(Math.max(1, pageNumber - 1));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleCancel = () => {
    setAttachment();
    setPreview();
    onClose();
  };

  const upload = async () => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0], acceptedFiles[0].name);
    const attachmentUrl = await dispatch(uploadAttachment(formData));
    setAttachment({ url: attachmentUrl, name: acceptedFiles[0].name });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <ModalTitle onClose={onClose}>
        <Typography variant="h3">Upload PDF attachment</Typography>
      </ModalTitle>

      <ModalContent>
        {!preview && (
          <section className={classes.paperContainer}>
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              <AddIcon style={{ fontSize: 60 }} className={g.charcoal036} />
              <Typography variant="body1">Drag and drop a PDF</Typography>
            </div>
            <aside>
              <Typography variant="body1" className={css(g.error, g.mt_sm)}>
                {error}
              </Typography>
            </aside>
          </section>
        )}

        {!!preview && (
          <>
            <div className={css(g.alignCenter, g.flexRow)}>
              <Typography variant="body2" className={g.alignStart}>
                Page {pageNumber} of {numPages}
              </Typography>
              <Button disabled={pageNumber === 1} onClick={handleBackPage}>
                Back
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={pageNumber === numPages}
              >
                Next
              </Button>
            </div>
            <CloseIcon
              className={css(g.clickable, g.error)}
              onClick={() => {
                setAttachment();
                setPreview();
              }}
            />
            <div className={classes.flowRoot}>
              <Document
                file={preview}
                onLoadSuccess={onDocumentLoadSuccess}
                className={css(classes.docContainer, g.m_md, g.mt_zero)}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </>
        )}
      </ModalContent>

      <div> {error && <p className={classes.errorMessage}>{error}</p>}</div>

      <ModalActions>
        <Button onClick={handleCancel} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={upload} color="primary" variant="contained">
          Continue
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default AttachmentModal;
