import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getAccounts = async (state) => {
  return createRequest(`/api/qb/accounts`, state, {
    method: 'GET',
    isNode: true,
  }).then((res) => handleError(res));
};

export const saveAcctMap = async (state, data) => {
  return createRequest(`/api/qb/acctMap`, state, {
    method: 'POST',
    body: data,
    isNode: true,
  }).then((res) => handleError(res));
};

export const getAcctMap = async (state) => {
  return createRequest(`/api/qb/acctMap`, state, {
    method: 'GET',
    isNode: true,
  }).then((res) => handleError(res));
};

export const updateAcctMap = async (state, data) => {
  const { id, ...body } = data;
  return createRequest(`/api/qb/${id}`, state, {
    method: 'PUT',
    body,
    isNode: true,
  }).then((res) => handleError(res));
};

export const getJournalEntries = async (state) => {
  return createRequest(`/api/qb/journalEntries`, state, {
    method: 'GET',
    isNode: true,
  }).then((res) => handleError(res));
};

export const newJe = async (state, data) => {
  return createRequest(`/api/qb/initiatePayments`, state, {
    method: 'POST',
    body: data,
    isNode: true,
  }).then((res) => handleError(res));
};

export const getVendors = async (state) => {
  return createRequest(`/api/qb/vendors`, state, {
    method: 'GET',
    isNode: true,
  }).then((res) => handleError(res));
};

export const getCustomers = async (state) => {
  return createRequest(`/api/qb/customers`, state, {
    method: 'GET',
    isNode: true,
  }).then((res) => handleError(res));
};