import React, { useState, useEffect } from 'react';
import css from 'classnames';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  walletsSelector,
  stripeWalletSelector,
  stripeWalletBalanceSelector,
  txsSelector,
  stripeTxsSelector,
  getTxs,
} from 'slices/wallets';
import TransferFundsModal from './TransferFundsModal';
import DepositFundsModal from './AddFundsModal';
import LoadingOverlay from 'components/LoadingOverlay';
import Card from 'components/Card';
import DataGrid from 'components/DataGrid';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import { flowTypeLabels } from 'constants/labelMaps';
import { getDateAndTimeFromEpochSec } from 'util/time';
import { renderPaymentStatusChip } from 'util/payments';
import { formatCurrency } from 'util/renderStrings';
import global from 'styles/global';
import { paymentStatusValueMap } from 'util/table';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: (params) => getDateAndTimeFromEpochSec(params.value),
  },
  {
    field: 'memo',
    headerName: 'Description',
    flex: 2,
    renderCell: (params) => {
      const paymentId = params.row?.metadata?.payment_id;
      return paymentId ? (
        <Link to={`/payments/${paymentId}`}>{params.value}</Link>
      ) : (
        params.value
      );
    },
  },
  {
    field: 'flow_type',
    headerName: 'Flow type',
    flex: 1,
    valueGetter: (params) => flowTypeLabels[params.value] || '-',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    valueGetter: (params) => paymentStatusValueMap[params.value],
    renderCell: (params) => renderPaymentStatusChip(params.row.status),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    renderCell: (params) => formatCurrency(params.value),
    flex: 1,
  },
];

export default function UsTab({ walletData = {} }) {
  const g = global();
  const dispatch = useDispatch();

  const refreshData = () => {
    dispatch(getTxs('stripe'));
  };

  useEffect(() => {
    if (walletData.account_details) {
      refreshData();
    }
  }, [walletData]);

  const { loading: walletsLoading } = useSelector(walletsSelector);
  const { loading: txsLoading } = useSelector(txsSelector);
  const stripeWallet = useSelector(stripeWalletSelector);
  const { payment_method, setup_intent } = stripeWallet || {};
  const txs = useSelector(stripeTxsSelector);
  const usdBalance = useSelector(stripeWalletBalanceSelector);
  const loading = walletsLoading || txsLoading;

  const [showDetails, setShowDetails] = useState(false);
  const [transferFundsModalVisible, setTransferFundsModalVisible] =
    useState(false);
  const [depositFundsModalVisible, setDepositFundsModalVisible] =
    useState(false);

  let buttonText = 'Connect bank';
  if (!!payment_method) {
    buttonText = 'Add funds';
  } else if (!!setup_intent) {
    buttonText = 'Verify account';
  }

  return (
    <>
      <LoadingOverlay open={loading} />

      <TransferFundsModal
        open={transferFundsModalVisible}
        onClose={() => setTransferFundsModalVisible(false)}
        currentBalance={usdBalance}
      />

      <DepositFundsModal
        open={depositFundsModalVisible}
        onClose={() => setDepositFundsModalVisible(false)}
      />

      {/* Title bar */}
      {!stripeWallet && (
        <div className={css(g.centerChildren, g.p_xxl)}>
          <Typography variant="h2" style={{ marginBottom: '16px' }}>
            Your US-only Wallet is not setup
          </Typography>
          <Link to="/createFundAcct">Set it up now</Link>
        </div>
      )}

      {!!stripeWallet && (
        <div>
          <div className={g.mb_lg}>
            <Card noHeader variant="outlined">
              <Grid container spacing={2}>
                <Grid item md={2} sm={12}>
                  <Typography variant="h6">ACCOUNT BALANCE</Typography>
                  <Typography variant="h2" className={g.cashGreen1}>
                    {formatCurrency(usdBalance?.amount)}
                  </Typography>
                </Grid>

                {showDetails ? (
                  <>
                    <Grid item md={3} sm={12}>
                      <Typography variant="subtitle2">Account:</Typography>
                      <Typography variant="body2">
                        {walletData.account_details.account_number}
                      </Typography>
                    </Grid>

                    <Grid item md={3} sm={12}>
                      <Typography variant="subtitle2">Routing:</Typography>
                      <Typography variant="body2">
                        {walletData.account_details.routing_number}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid item md={6} sm={12}>
                    <Button type="submit" onClick={() => setShowDetails(true)}>
                      View account details
                    </Button>
                  </Grid>
                )}

                <Grid item md={4} sm={12} className={g.textRight}>
                  <Button
                    className={g.mb_xs}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    onClick={() => setTransferFundsModalVisible(true)}
                  >
                    Transfer funds
                  </Button>
                  <Button
                    className={css(g.mb_xs, g.ml_xs)}
                    onClick={() => setDepositFundsModalVisible(true)}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </div>
          {/* Transactions table */}
          <TableHeader title="Transactions" />
          <div className={g.tableWrapper}>
            <DataGrid
              variant="outlined"
              autoHeight
              autoPageSize
              rows={txs}
              columns={columns}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </div>
        </div>
      )}
    </>
  );
}
