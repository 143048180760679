import React, { useState } from 'react';

import { isAccountNumber, isRoutingNumber } from 'util/validation';

import Card from '../Card';
import TextField from '../TextField';
import Button from '../Button';

const USBankPayoutForm = ({ userID }) => {
  const [firstName, setFirstName] = useState({ val: '', dirty: false });
  const [lastName, setLastName] = useState({ val: '', dirty: false });
  const [accountNumber, setAccountNumber] = useState({ val: '', dirty: false });
  const [routingNumber, setRoutingNumber] = useState({ val: '', dirty: false });

  const isInputValid =
    isAccountNumber(accountNumber.val) && isRoutingNumber(routingNumber.val);

  const savePaymentMethod = () => {
    alert('Saving bank account');
  };

  return (
    <Card title="Beneficiaries US bank account">
      <TextField
        margin="dense"
        variant="outlined"
        label="First Name"
        value={firstName.val}
        error={firstName.dirty && !firstName.val}
        helperText={
          firstName.dirty && !firstName.val && 'This field is required'
        }
        onChange={(e) => setFirstName({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Last Name"
        value={lastName.val}
        error={lastName.dirty && !lastName.val}
        helperText={lastName.dirty && !lastName.val && 'This field is required'}
        onChange={(e) => setLastName({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Account number"
        value={accountNumber.val}
        error={accountNumber.dirty && !accountNumber.val}
        helperText={
          accountNumber.dirty && !accountNumber.val && 'This field is required'
        }
        onChange={(e) => setAccountNumber({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Routing number"
        value={routingNumber.val}
        error={routingNumber.dirty && !routingNumber.val}
        helperText={
          routingNumber.dirty && !routingNumber.val && 'This field is required'
        }
        onChange={(e) => setRoutingNumber({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <Button
        loading={false}
        onClick={savePaymentMethod}
        color="primary"
        variant="contained"
        disabled={!isInputValid}
      >
        Save
      </Button>
    </Card>
  );
};

export default USBankPayoutForm;
