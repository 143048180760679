import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  currentOrgSelector,
  updateOrg as updateOrgSlice,
  updateOrgSelector,
  updateOrgLogo,
  updateOrgLogoSelector,
} from 'slices/org';
import { setHeaderData } from 'slices/misc';
import { Grid } from '@material-ui/core';
import LoadingOverlay from 'components/LoadingOverlay';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import AccountSettingsSection from 'components/AccountSettingsSection';
import OrgSettingsSection from './OrgSettingsSection';
import QuickbooksSection from './QuickbooksSection';
import APIKeySection from './APIKeySection';
import {
  adminsSelector,
  getMembers as getMembersSlice,
  updatePassword as updatePasswordSlice,
  updatePasswordSelector,
  deleteMemberSelector,
  deleteMember as deleteMemberSlice,
  inviteMemberSelector,
  inviteMember as inviteMemberSlice,
} from 'slices/user';
import {
  getApiKeys as getApiKeysSlice,
  getApiKeysSelector,
} from 'slices/orgApiKeys';
import { notificationsSelector, getNotifications } from 'slices/notifications';
import { getConnections, orgOauthSelector } from 'slices/orgOauth';
import useTabs from 'hooks/useTabs';
import OrgMembersSection from './OrgMembersSection';
import OrgLogoSection from './OrgLogoSection';
import SplitsSection from './SplitsSection';
import NotificationsSection from 'components/NotificationsSection';
import { isOwnerOrAdmin } from 'util/user';
import { currentUserSelector } from 'slices/user';
import BillingSection from './BillingSection';
import { getCollectedFees } from 'slices/collectedFees';
import { getOrgFees } from 'slices/orgFees';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  titleBar: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  value: {
    fontWeight: 600,
    color: theme.palette.primary.cashGreen089,
  },
  label: { fontSize: '10px', textTransform: 'uppercase' },
}));

const allTabs = [
  { label: 'Organization', name: 'org' },
  { label: 'Billing', name: 'billing' },
  { label: 'Profile', name: 'profile' },
  { label: 'API', name: 'api' },
  { label: 'Notifications', name: 'notifications' },
  { label: 'Quickbooks', name: 'quickbooks' },
];

function Settings({
  orgState,
  updatePasswordState,
  updateOrg,
  updatePassword,
  updateOrgState,
  getApiKeys,
  getApiKeysState,
  adminsState,
  getMembers,
  inviteMember,
  inviteMemberState,
  deleteMember,
  deleteMemberState,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    allTabs.map((tab) => tab.name),
    '/settings'
  );

  const { data: currentUser } = useSelector(currentUserSelector);

  const [tabs, setTabs] = useState(allTabs);
  useEffect(() => {
    if (!isOwnerOrAdmin(currentUser)) {
      // removing api tab
      setTabs(allTabs.slice(0, 2).concat(allTabs.slice(3)));
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Settings',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Settings' },
        ],
      })
    );

    getMembers();
    getApiKeys();
    dispatch(getNotifications());
    dispatch(getConnections());
    dispatch(getCollectedFees());
    dispatch(getOrgFees());
  }, []);

  const { loading: orgOauthLoading } = useSelector(orgOauthSelector);
  const { data: notifications } = useSelector(notificationsSelector);
  const { loading: adminsLoading } = adminsState;
  const { loading: apiKeysLoading } = getApiKeysState;
  const { loading: orgLoading } = orgState;
  const updateOrgLogoState = useSelector(updateOrgLogoSelector);

  const loading =
    orgLoading || adminsLoading || apiKeysLoading || orgOauthLoading;

  return (
    <div className={classes.root}>
      <LoadingOverlay open={loading} />

      <Tabs
        tabLabels={tabs.map((tab) => tab.label)}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="campaigns-tabpanel"
      />

      {/* Org Tab */}
      <TabPanel value={currentTab} index={0}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OrgSettingsSection
                  orgState={orgState}
                  handleUpdateOrg={updateOrg}
                  updateOrgState={updateOrgState}
                />
              </Grid>

              <Grid item xs={12}>
                <OrgLogoSection
                  orgState={orgState}
                  handleUpdateOrgLogo={(file) => dispatch(updateOrgLogo(file))}
                  updateOrgLogoState={updateOrgLogoState}
                />
              </Grid>

              <Grid item xs={12}>
                <SplitsSection
                  orgState={orgState}
                  handleUpdateOrg={updateOrg}
                  updateOrgState={updateOrgState}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12}>
            <OrgMembersSection
              adminsState={adminsState}
              inviteMember={inviteMember}
              inviteMemberState={inviteMemberState}
              deleteMember={deleteMember}
              deleteMemberState={deleteMemberState}
            />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Billing Tab */}
      <TabPanel value={currentTab} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BillingSection
              handleUpdatePassword={async (data) => await updatePassword(data)}
              updatePasswordState={updatePasswordState}
            />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Profile Tab */}
      <TabPanel value={currentTab} index={2}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <AccountSettingsSection
              handleUpdatePassword={async (data) => await updatePassword(data)}
              updatePasswordState={updatePasswordState}
            />
          </Grid>
        </Grid>
      </TabPanel>

      {/* API Tab */}
      <TabPanel value={currentTab} index={3}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={12}>
            <APIKeySection />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Notifications Tab */}
      <TabPanel value={currentTab} index={4}>
        <NotificationsSection notifications={notifications} />
      </TabPanel>

      {/* Quickbooks Tab */}
      <TabPanel value={currentTab} index={5}>
        <QuickbooksSection notifications={notifications} />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  orgState: currentOrgSelector(state),
  updateOrgState: updateOrgSelector(state),
  updatePasswordState: updatePasswordSelector(state),
  apiKeys: getApiKeysSelector(state),
  adminsState: adminsSelector(state),
  inviteMemberState: inviteMemberSelector(state),
  deleteMemberState: deleteMemberSelector(state),
  getApiKeysState: getApiKeysSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateOrg: (data, callback) => {
    dispatch(updateOrgSlice(data, callback));
  },
  updatePassword: (data) => dispatch(updatePasswordSlice(data)),
  getMembers: () => dispatch(getMembersSlice()),
  inviteMember: (data) => dispatch(inviteMemberSlice(data)),
  deleteMember: (data) => dispatch(deleteMemberSlice(data)),
  getApiKeys: () => dispatch(getApiKeysSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
