import { useEffect, useState } from 'react';
import { Chip, Grid, TextField, Typography } from '@material-ui/core';
import css from 'classnames';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

import EditCard from 'components/EditCard';
import global from 'styles/global';

const filter = createFilterOptions();

export default function TagEditor({
  currentTags,
  tagOptions = [],
  addTag,
  createTag,
  disabled,
  removeTag,
}) {
  const [newTags, setNewTags] = useState(null);
  const [currentTagIds, setCurrentTagIds] = useState([]);
  const [existingTagLabels, setExistingTagLabels] = useState([]);
  const g = global();

  useEffect(() => {
    setCurrentTagIds(currentTags.map((t) => t.tag_id));
  }, [currentTags]);

  useEffect(() => {
    setExistingTagLabels(tagOptions.map((t) => t.label));
  }, [tagOptions]);

  const tagsComponent = (isEditing) => {
    return currentTags.length > 0 ? (
      currentTags.map((t) => (
        <Chip
          variant="outlined"
          onDelete={isEditing ? () => removeTag(t.id) : null}
          label={(tagOptions.find((opt) => opt.id === t.tag_id) || {}).label}
          className={css(g.mr_xs, g.mb_xs)}
        />
      ))
    ) : (
      <Typography variant="h5">No tags</Typography>
    );
  };

  const uploadTag = async (val, id, exists) => {
    if (exists) {
      setNewTags(val);
      await addTag(id);
      setNewTags(null);
    } else {
      setNewTags(val);
      const res = await createTag(val);
      await res.forEach((tag) => addTag(tag.id));
      setNewTags(null);
    }
  };

  const changeFunction = async (event, newValue) => {
    if (newValue && newValue.inputValue) {
      uploadTag(newValue.inputValue, -1, false);
    } else if (event.keyCode === 13 && !existingTagLabels.includes(newValue)) {
      uploadTag(newValue, -1, false);
    } else if (event.keyCode === 13 && existingTagLabels.includes(newValue)) {
      let tagID = tagOptions.find((tag) => tag.label === newValue).id;
      uploadTag(newValue, tagID, true);
    } else {
      uploadTag(newValue, newValue.id, true);
    }
  };

  return (
    <EditCard
      title="Tags"
      hideCancel
      handleSubmit={() => {}}
      staticContent={tagsComponent(false)}
      submitDisabled={disabled}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            value={newTags}
            onChange={changeFunction}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (
                params.inputValue !== '' &&
                !existingTagLabels.includes(params.inputValue)
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  label: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={tagOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.label;
            }}
            getOptionDisabled={(tag) => currentTagIds.includes(tag.id)}
            renderOption={(option) => option.label}
            style={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Find a tag"
                variant="outlined"
              />
            )}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          {tagsComponent(true)}
        </Grid>
      </Grid>
    </EditCard>
  );
}
