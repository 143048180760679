import React from 'react';
import css from 'classnames';
import { Typography } from '@material-ui/core';
import Card from 'components/Card';
import global from 'styles/global';

function MetricTile({ subtitle, value }) {
  const g = global();

  return (
    <Card noHeader customClassName={g.centerChildren}>
      <Typography variant="h1" className={css(g.textCenter, g.mb_xs)}>
        {value}
      </Typography>
      <Typography className={g.textCenter} variant="subtitle1">
        {subtitle}
      </Typography>
    </Card>
  );
}

export default MetricTile;
