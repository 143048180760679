import { createSlice, createSelector } from '@reduxjs/toolkit';
import { groupBy, prop } from 'ramda';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  getInvoices as getInvoicesAPI,
  getInvoicePreview as getInvoicePreviewAPI,
  invalidateInvoice as invalidateInvoiceAPI,
  remindInvoice as remindInvoiceAPI,
  createInvoice as createInvoicesAPI,
  payInvoice as payInvoiceAPI,
  updateInvoice as updateInvoiceAPI,
  uploadAttachment as uploadAttachmentAPI,
} from 'api/invoices';
import { toast } from 'react-toastify';

import objectStates from 'constants/objectStates';
import { dataURItoBlob } from 'util/download';

export const initialInvoicesState = {
  invoices: getGenericState([]),
  invalidateInvoice: getGenericState(),
  invalidateInvoices: getGenericState(),
  remindInvoices: getGenericState(),
  createInvoices: getGenericState(),
  invoicePreview: getGenericState(''),
  updateInvoice: getGenericState(),
  uploadAttachment: getGenericState(),
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: initialInvoicesState,
  reducers: {
    getInvoicesStarted: getGenericStarted('invoices'),
    getInvoicesSuccess: getPayloadSuccess('invoices'),
    getInvoicesFailure: getGenericFailure('invoices'),

    getInvoicePreviewStarted: getGenericStarted('invoicePreview'),
    getInvoicePreviewSuccess: getPayloadSuccess('invoicePreview'),
    getInvoicePreviewFailure: getGenericFailure('invoicePreview'),
    // One off pending state to account for hello sign delays in creating invoices
    getInvoicePreviewPending: (state) => {
      state.invoicePreview.loading = false;
      state.invoicePreview.error = '';
      state.invoicePreview.data = 'PENDING';
    },

    invalidateInvoiceStarted: getGenericStarted('invalidateInvoice'),
    invalidateInvoiceSuccess: getGenericSuccess('invalidateInvoice'),
    invalidateInvoiceFailure: getGenericFailure('invalidateInvoice'),

    invalidateInvoicesStarted: getGenericStarted('invalidateInvoices'),
    invalidateInvoicesSuccess: getGenericSuccess('invalidateInvoices'),
    invalidateInvoicesFailure: getGenericFailure('invalidateInvoices'),

    remindInvoicesStarted: getGenericStarted('remindInvoices'),
    remindInvoicesSuccess: getGenericSuccess('remindInvoices'),
    remindInvoicesFailure: getGenericFailure('remindInvoices'),

    createInvoicesStarted: getGenericStarted('createInvoices'),
    createInvoicesSuccess: getGenericSuccess('createInvoices'),
    createInvoicesFailure: getGenericFailure('createInvoices'),

    payInvoiceStarted: getGenericStarted('payInvoice'),
    payInvoiceSuccess: getGenericSuccess('payInvoice'),
    payInvoiceFailure: getGenericFailure('payInvoice'),

    updateInvoiceStarted: getGenericStarted('updateInvoiceGroup'),
    updateInvoiceSuccess: getGenericSuccess('updateInvoiceGroup'),
    updateInvoiceFailure: getGenericFailure('updateInvoiceGroup'),

    uploadAttachmentStarted: getGenericStarted('uploadAttachmentGroup'),
    uploadAttachmentSuccess: getGenericSuccess('uploadAttachmentGroup'),
    uploadAttachmentFailure: getGenericFailure('uploadAttachmentGroup'),
  },
});

export const {
  getInvoicesStarted,
  getInvoicesSuccess,
  getInvoicesFailure,

  getInvoicePreviewStarted,
  getInvoicePreviewSuccess,
  getInvoicePreviewPending,
  getInvoicePreviewFailure,

  invalidateInvoiceStarted,
  invalidateInvoiceSuccess,
  invalidateInvoiceFailure,

  invalidateInvoicesStarted,
  invalidateInvoicesSuccess,
  invalidateInvoicesFailure,

  remindInvoicesStarted,
  remindInvoicesSuccess,
  remindInvoicesFailure,

  createInvoicesStarted,
  createInvoicesSuccess,
  createInvoicesFailure,

  payInvoiceStarted,
  payInvoiceSuccess,
  payInvoiceFailure,

  updateInvoiceStarted,
  updateInvoiceSuccess,
  updateInvoiceFailure,

  uploadAttachmentStarted,
  uploadAttachmentSuccess,
  uploadAttachmentFailure,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;

export const getInvoices = (group_id) => async (dispatch, getState) => {
  dispatch(getInvoicesStarted());
  try {
    const res = await getInvoicesAPI(getState(), group_id);
    dispatch(getInvoicesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getInvoicesFailure,
      'There was an issue retrieving your invoices'
    );
  }
};

export const getInvoicePreview = (id) => async (dispatch, getState) => {
  dispatch(getInvoicePreviewStarted());
  try {
    const res = await getInvoicePreviewAPI(getState(), { file: id });
    const blob = dataURItoBlob(res, 'application/pdf');
    const previewUrl = URL.createObjectURL(blob);
    dispatch(getInvoicePreviewSuccess(previewUrl));
  } catch (err) {
    if (err?.message.includes('still being processed')) {
      dispatch(getInvoicePreviewPending(err));
    } else {
      handleError(
        err,
        dispatch,
        getInvoicePreviewFailure,
        'There was an issue retrieving the invoice preview'
      );
    }
  }
};

export const invalidateInvoice = (id) => async (dispatch, getState) => {
  dispatch(invalidateInvoiceStarted());
  try {
    await invalidateInvoiceAPI(getState(), id);
    dispatch(invalidateInvoiceSuccess());
    dispatch(getInvoices());
  } catch (err) {
    handleError(
      err,
      dispatch,
      invalidateInvoiceFailure,
      'Failed to invalidate invoice'
    );
  }
};

export const invalidateInvoices = (ids) => async (dispatch, getState) => {
  dispatch(invalidateInvoicesStarted());
  try {
    await Promise.all(ids.map((id) => invalidateInvoiceAPI(getState(), id)));
    dispatch(invalidateInvoicesSuccess());
    dispatch(getInvoices());
  } catch (err) {
    handleError(
      err,
      dispatch,
      invalidateInvoicesFailure,
      'Failed to invalidate invoices'
    );
  }
};

export const createInvoice = (data, cb) => async (dispatch, getState) => {
  dispatch(createInvoicesStarted());
  try {
    const res = await createInvoicesAPI(getState(), data);
    dispatch(createInvoicesSuccess(res));
    !!cb && cb();
  } catch (err) {
    handleError(
      err,
      dispatch,
      createInvoicesFailure,
      'There was an issue creating your invoice'
    );
  }
};

export const remindInvoices = (ids, method) => async (dispatch, getState) => {
  dispatch(remindInvoicesStarted());
  try {
    await remindInvoiceAPI(getState(), ids, method);
    toast.success(`Reminder(s) have been sent!`);
    dispatch(remindInvoicesSuccess());
  } catch (err) {
    handleError(
      err,
      dispatch,
      remindInvoicesFailure,
      'There was an issue sending your Invoice reminder(s)'
    );
  }
};

export const payInvoice = (data, cb) => async (dispatch, getState) => {
  dispatch(payInvoiceStarted());
  try {
    const res = await payInvoiceAPI(getState(), data);
    dispatch(payInvoiceSuccess(res));
    !!cb && cb();
  } catch (err) {
    handleError(
      err,
      dispatch,
      payInvoiceFailure,
      'There was an issue paying your invoice'
    );
  }
};

export const updateInvoice = (id, data) => async (dispatch, getState) => {
  dispatch(updateInvoiceStarted());
  try {
    await updateInvoiceAPI(getState(), id, data);
    dispatch(updateInvoiceSuccess());
    dispatch(getInvoices());
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateInvoiceFailure,
      'Failed to update Payment Group for Invoice'
    );
  }
};

export const linkInvoicesToPaymentGroup =
  (invoiceIds, data) => async (dispatch, getState) => {
    dispatch(updateInvoiceStarted());
    try {
      await Promise.all(
        invoiceIds.map((id) => updateInvoiceAPI(getState(), id, data))
      );
      dispatch(updateInvoiceSuccess());
      dispatch(getInvoices());
    } catch (err) {
      handleError(
        err,
        dispatch,
        updateInvoiceFailure,
        'Failed to update Payment Group for Invoice'
      );
    }
  };

export const uploadAttachment = (data) => async (dispatch, getState) => {
  dispatch(uploadAttachmentStarted());
  try {
    const res = await uploadAttachmentAPI(getState(), data);
    dispatch(uploadAttachmentSuccess());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      uploadAttachmentFailure,
      'There was an issue creating your contract'
    );
  }
};

// selectors
const selectInvoices = (state) => state.invoices;

export const invoicesSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.invoices || getGenericState([])
);

export const invoiceTotalsSelector = createSelector(
  invoicesSelector,
  (invoicesState) =>
    invoicesState.data.reduce(
      (agg, invoice) => {
        if (
          invoice.status === objectStates.requires_info ||
          invoice.status === objectStates.processing
        ) {
          agg.pending += invoice.amount;
        } else if (invoice.status === objectStates.succeeded) {
          agg.completed += invoice.amount;
        }
        return agg;
      },
      { pending: 0, completed: 0 }
    )
);

export const openInvoicesSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return data.filter(
      (invoice) => invoice.status === objectStates.requires_info
    );
  }
);
export const canceledInvoicesSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return data.filter((invoice) => invoice.status === objectStates.canceled);
  }
);
export const paidInvoicesSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return data.filter((invoice) => invoice.status === objectStates.succeeded);
  }
);

export const invalidateInvoiceSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.invalidateInvoice || getGenericState()
);

export const invalidateInvoicesSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.invalidateInvoices || getGenericState()
);

export const remindInvoicesSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.remindInvoices || getGenericState()
);

export const createInvoicesSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.createInvoices || getGenericState()
);

export const invoicePreviewSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.invoicePreview || getGenericState()
);

export const payInvoiceSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.payInvoice || getGenericState()
);

export const updateInvoiceSelector = createSelector(
  selectInvoices,
  (invoicesState = {}) => invoicesState.updateInvoice || getGenericState()
);

export const invoicesByCreatorsSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return groupBy(prop('user_id'), data);
  }
);

export const invoicesByPaymentsSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return groupBy(prop('payment_id'), data);
  }
);

export const invoicesByPaymentGroupSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return groupBy(prop('payment_group_id'), data);
  }
);

export const invoicesDictSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return data.reduce((agg, invoice) => {
      agg[invoice.id] = invoice;
      return agg;
    }, {});
  }
);

export const invoicesByCustomerSelector = createSelector(
  invoicesSelector,
  (invoicesState) => {
    const { data } = invoicesState;
    return groupBy(prop('customer_id'), data);
  }
);
