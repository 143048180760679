import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmail, isInt } from 'validator';
import moment from 'moment';

import Button from 'components/Button';
import { ButtonGroup, Typography, Grid } from '@material-ui/core';

import { USStates } from 'constants/usStates';
import { LegalEntityTypes } from 'constants/legalEntityTypes';

import global from 'styles/global';
import { isAccountNumber, isRoutingNumber } from 'util/validation';
import isMobilePhone from 'validator/lib/isMobilePhone';
import PhoneInput from 'components/PhoneInput';
import Card from 'components/Card';
import {
  updateCreatorSelector,
  updateCreator as updateCreatorSlice,
} from 'euSlices/creators';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';
import { maskAcctNumber } from '../../util/renderStrings';

const PaymentDetailsCard = ({
  updateCreator,
  updateCreatorState,
  org,
  creator,
}) => {
  const g = global();

  const { loading: submitting } = updateCreatorState;

  const handleUpdateBankDetails = async () => {
    const submitAccount = acctChanged ? acct.val : creator.account_number;
    const submitSSN = ssnChanged ? ssn.val : creator.tax_id;
    const submitEIN = einChanged ? ein.val : creator.tax_id;
    const updateObj = {
      id: creator.id,
      address: {
        city: city.val,
        line_1: address1.val,
        line_2: address2.val,
        state: stateCode.val,
        zip: zip.val,
      },
      account_number: submitAccount,
      routing_number: routing.val,
      selected_payment_method: paymentMethod,
      paypal_email: paypalEmail.val,
      venmo_phone_number: venmoPhone.val,
    };
    if (isBiz) {
      updateObj.business_name = bizName.val;
      updateObj.entity_type = entityType.val;
      updateObj.tax_id = submitEIN;
    } else {
      updateObj.entity_type = 'individual';
      updateObj.date_of_birth = moment(dob.val).format('YYYY-MM-DD');
      updateObj.first_name = first.val;
      updateObj.last_name = last.val;
      updateObj.tax_id = submitSSN;
    }
    await updateCreator(updateObj);

    setAcctChanged(false);
    setSSNChanged(false);
    setEINChanged(false);
  };

  const [acct, setAcct] = useState({ val: null, dirty: false });
  const [acctChanged, setAcctChanged] = useState(false);
  const [routing, setRouting] = useState({
    val: creator.routing_number,
    dirty: false,
  });
  const [first, setFirst] = useState({ val: creator.first_name, dirty: false });
  const [last, setLast] = useState({ val: creator.last_name, dirty: false });
  const [ssn, setSSN] = useState({ val: null, dirty: false });
  const [ssnChanged, setSSNChanged] = useState(false);
  const [dob, setDOB] = useState({
    val: moment(creator.date_of_birth).format('YYYY-MM-DD'),
    dirty: false,
  });
  const [bizName, setBizName] = useState({
    val: creator.business_name || null,
    dirty: false,
  });

  const [ein, setEIN] = useState({ val: null, dirty: false });
  const [einChanged, setEINChanged] = useState(false);
  const [entityType, setEntityType] = useState({
    val: creator.entity_type,
    dirty: false,
  });
  const [address1, setAddress1] = useState({
    val: creator.address_line_1,
    dirty: false,
  });
  const [address2, setAddress2] = useState({
    val: creator.address_line_2,
    dirty: false,
  });
  const [city, setCity] = useState({ val: creator.address_city, dirty: false });
  const [stateCode, setStateCode] = useState({
    val: creator.address_state,
    dirty: false,
  });

  const [zip, setZip] = useState({ val: creator.address_zip, dirty: false });
  const [paypalEmail, setPaypalEmail] = useState({
    val: creator.paypal_email,
    dirty: false,
  });
  const [venmoPhone, setVenmoPhone] = useState({
    val: creator.venmo_phone_number,
    dirty: false,
  });

  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [isBiz, setIsBiz] = useState(creator.entity_type !== 'individual');

  useEffect(() => {
    const accN = creator.account_number
      ? maskAcctNumber(creator.account_number)
      : null;
    setAcct({
      val: accN,
      dity: false,
    });
  }, [creator.account_number]);

  useEffect(() => {
    const displaySSN = creator.tax_id ? maskAcctNumber(creator.tax_id) : null;
    setSSN({
      val: displaySSN,
      dirty: false,
    });
  }, [creator.tax_id]);

  useEffect(() => {
    const displayEIN = creator.tax_id ? maskAcctNumber(creator.tax_id) : null;
    setEIN({
      val: displayEIN,
      dirty: false,
    });
  }, [creator.tax_id]);

  const acctValid = acctChanged ? isAccountNumber(acct.val) : true;
  const routingValid = isRoutingNumber(routing.val);
  const paypalValid = !!paypalEmail.val && isEmail(paypalEmail.val);
  const venmoValid = !!venmoPhone.val && isMobilePhone(venmoPhone.val, 'en-US');
  const stateValid = !!stateCode.val && stateCode.val.length === 2;
  const zipValid = !!zip.val && zip.val.length === 5 && isInt(zip.val);
  const einValid = () => {
    // is valid, or is the same as the creator's tax id (meaning no change)
    if (!einChanged) return true;
    if (!ein.val) return false;
    if (ein.val.length !== 9) return false;
    return isInt(ein.val);
  };

  const ssnValid = () => {
    // is valid, or is the same as the creator's tax id (meaning no change)
    if (!ssnChanged) return true;
    if (!ssn.val) return false;
    if (ssn.val.length !== 9) return false;
    return isInt(ssn.val);
  };

  const getIsValid = () => {
    const paymentMethodValid =
      (paymentMethod === 'paypal' && paypalValid) ||
      (paymentMethod === 'stripe' && acctValid && routingValid) ||
      (paymentMethod === 'venmo' && venmoValid);

    const isIndividualValid =
      !!first.val && !!last.val && ssnValid() && !!dob.val;

    const isBizValid = !!bizName.val && einValid() && !!entityType.val;
    const addressValid = !!address1.val && !!city.val && stateValid && zipValid;

    return isBiz
      ? paymentMethodValid && isBizValid && addressValid
      : paymentMethodValid && isIndividualValid && addressValid;
  };

  const isValid = getIsValid();

  const handleAccountChange = (e) => {
    setAcctChanged(true);
    setAcct({ val: e.target.value, dirty: true });
  };

  const handleSSNChange = (e) => {
    setSSNChanged(true);
    setSSN({ val: e.target.value, dirty: true });
  };

  const handleEINChange = (e) => {
    setEINChanged(true);
    setEIN({ val: e.target.value, dirty: true });
  };

  return (
    <Card title={org?.name} variant="outlined">
      <Grid container spacing={1}>
        {/* input forms */}
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4">Payment details</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup fullWidth variant="outlined">
              <Button
                color="primary"
                variant={paymentMethod === 'stripe' ? 'contained' : 'outlined'}
                onClick={() => setPaymentMethod('stripe')}
              >
                Bank
              </Button>
              {org.paypal_enabled && (
                <Button
                  color="primary"
                  variant={
                    paymentMethod === 'paypal' ? 'contained' : 'outlined'
                  }
                  onClick={() => setPaymentMethod('paypal')}
                >
                  PayPal
                </Button>
              )}
              {org.paypal_enabled && (
                <Button
                  color="primary"
                  variant={paymentMethod === 'venmo' ? 'contained' : 'outlined'}
                  onClick={() => setPaymentMethod('venmo')}
                >
                  Venmo
                </Button>
              )}
            </ButtonGroup>
          </Grid>

          {/* ACH data collection */}
          {paymentMethod === 'stripe' && (
            <>
              <Grid item sm={6} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Account number"
                  value={acct.val}
                  error={acct.dirty && !acctValid}
                  helperText={
                    acct.dirty && !acctValid && 'This field is required'
                  }
                  onChange={(e) => handleAccountChange(e)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12} className={g.mb_md}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Routing number"
                  value={routing.val}
                  error={routing.dirty && !routingValid}
                  helperText={
                    routing.dirty &&
                    !routingValid &&
                    'This field is required and must be 9 digits'
                  }
                  onChange={(e) =>
                    setRouting({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>
            </>
          )}

          {/* PayPal data collection */}
          {paymentMethod === 'paypal' && (
            <Grid item xs={12} className={g.mb_md}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Paypal email"
                value={paypalEmail.val}
                error={paypalEmail.dirty && !isEmail(paypalEmail.val)}
                helperText={
                  paypalEmail.dirty &&
                  !isEmail(paypalEmail.val) &&
                  'This field is required and must be an email address'
                }
                onChange={(e) =>
                  setPaypalEmail({ val: e.target.value, dirty: true })
                }
                required
                fullWidth
              />
            </Grid>
          )}

          {/* Venmo data collection */}
          {paymentMethod === 'venmo' && (
            <Grid item xs={12} className={g.mb_md}>
              <PhoneInput
                margin="dense"
                variant="outlined"
                label="Venmo phone number"
                value={venmoPhone.val}
                error={
                  venmoPhone.dirty && !isMobilePhone(venmoPhone.val, 'en-US')
                }
                helperText={
                  venmoPhone.dirty &&
                  !isMobilePhone(venmoPhone.val, 'en-US') &&
                  'This field is required and must be a valid US phone number'
                }
                onChange={(e) =>
                  setVenmoPhone({ val: e.target.value, dirty: true })
                }
                required
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h4">Tax information</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup fullWidth variant="outlined">
              <Button
                color="primary"
                variant={!isBiz ? 'contained' : 'outlined'}
                onClick={() => setIsBiz(false)}
              >
                Individual
              </Button>
              <Button
                color="primary"
                variant={isBiz ? 'contained' : 'outlined'}
                onClick={() => setIsBiz(true)}
              >
                Business
              </Button>
            </ButtonGroup>
          </Grid>

          {!isBiz ? (
            <>
              {/* individual tax info */}
              <Grid item sm={6} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="First name"
                  value={first.val}
                  error={first.dirty && !first.val}
                  helperText={
                    first.dirty && !first.val && 'This field is required'
                  }
                  onChange={(e) =>
                    setFirst({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Last name"
                  value={last.val}
                  error={last.dirty && !last.val}
                  helperText={
                    last.dirty && !last.val && 'This field is required'
                  }
                  onChange={(e) =>
                    setLast({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="SSN"
                  value={ssn.val}
                  error={ssn.dirty && !ssnValid}
                  helperText={
                    ssn.dirty && !ssnValid && 'This field is required'
                  }
                  onChange={(e) => handleSSNChange(e)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  type="date"
                  margin="dense"
                  variant="outlined"
                  label="Date of birth"
                  value={dob.val}
                  error={dob.dirty && !dob.val}
                  helperText={dob.dirty && !dob.val && 'This field is required'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDOB({ val: e.target.value, dirty: true })}
                  required
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <>
              {/* business tax info */}
              <Grid item sm={8} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Business Name"
                  value={bizName.val}
                  error={bizName.dirty && !bizName.val}
                  helperText={
                    bizName.dirty && !bizName.val && 'This field is required'
                  }
                  onChange={(e) =>
                    setBizName({ val: e.target.value, dirty: true })
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="EIN"
                  value={ein.val}
                  error={ein.dirty && !einValid()}
                  helperText={
                    ein.dirty && !einValid() && 'This field is required'
                  }
                  onChange={(e) => handleEINChange(e)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={LegalEntityTypes}
                  className={g.pb_zero}
                  getOptionLabel={(option) => `${option.label}`}
                  value={LegalEntityTypes.find(
                    (et) => et.value === entityType.val
                  )}
                  onChange={(_, value) => {
                    setEntityType({ val: value?.value, dirty: true });
                  }}
                  textInputProps={{
                    margin: 'dense',
                    label: 'Legal entity type',
                    required: true,
                  }}
                />
              </Grid>
            </>
          )}

          {/* address */}
          <Grid item sm={8} xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Address 1"
              value={address1.val}
              error={address1.dirty && !address1.val}
              helperText={
                address1.dirty && !address1.val && 'This field is required'
              }
              onChange={(e) =>
                setAddress1({ val: e.target.value, dirty: true })
              }
              required
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Address 2"
              value={address2.val}
              onChange={(e) =>
                setAddress2({ val: e.target.value, dirty: true })
              }
              fullWidth
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="City"
              value={city.val}
              error={city.dirty && !city.val}
              helperText={city.dirty && !city.val && 'This field is required'}
              onChange={(e) => setCity({ val: e.target.value, dirty: true })}
              required
              fullWidth
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Autocomplete
              options={USStates}
              className={g.pb_zero}
              value={USStates.find((s) => s.abbreviation === stateCode.val)}
              getOptionLabel={(option) => `${option.abbreviation}`}
              onChange={(_, value) => {
                setStateCode({
                  val: value?.abbreviation || '',
                  dirty: true,
                });
              }}
              textInputProps={{
                margin: 'dense',
                label: 'State',
                required: true,
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Zip code"
              value={zip.val}
              error={zip.dirty && !zipValid}
              helperText={
                zip.dirty &&
                !zipValid &&
                'This field is required and must be 5 digits'
              }
              onChange={(e) => setZip({ val: e.target.value, dirty: true })}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={handleUpdateBankDetails}
              disabled={!isValid}
              color="primary"
              variant="contained"
              loading={submitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  updateCreatorState: updateCreatorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateCreator: (data) => dispatch(updateCreatorSlice(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsCard);
