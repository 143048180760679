import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import { USStates } from 'constants/usStates';
import global from 'styles/global';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';

function Contact({ creator, updateCreator }) {
  const dispatch = useDispatch();
  const g = global();

  const {
    email,
    phone_number,
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_zip,
    address_shipping_line_1,
    address_shipping_line_2,
    address_shipping_city,
    address_shipping_state,
    address_shipping_zip,
    is_shipping_same_as_home,
  } = creator;

  const [newPhone, setNewPhone] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newAddress2, setNewAddress2] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newZip, setNewZip] = useState('');
  const [newAddressShip, setNewAddressShip] = useState('');
  const [newAddress2Ship, setNewAddress2Ship] = useState('');
  const [newCityShip, setNewCityShip] = useState('');
  const [newStateShip, setNewStateShip] = useState('');
  const [newZipShip, setNewZipShip] = useState('');
  const [newIsShipSameAsHome, setNewIsShipSameAsHome] = useState(true);

  useEffect(() => {
    setNewPhone(phone_number);
    setNewAddress(address_line_1);
    setNewAddress2(address_line_2);
    setNewCity(address_city);
    setNewState({ abbreviation: address_state });
    setNewZip(address_zip);
    setNewAddressShip(address_shipping_line_1);
    setNewAddress2Ship(address_shipping_line_2);
    setNewCityShip(address_shipping_city);
    setNewStateShip({ abbreviation: address_shipping_state });
    setNewZipShip(address_shipping_zip);
  }, [
    phone_number,
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_zip,
    address_shipping_line_1,
    address_shipping_line_2,
    address_shipping_city,
    address_shipping_state,
    address_shipping_zip,
  ]);

  const cancelEditing = () => {
    setNewPhone(phone_number);
    setNewAddress(address_line_1);
    setNewAddress2(address_line_2);
    setNewCity(address_city);
    setNewState({ abbreviation: address_state });
    setNewZip(address_zip);
    setNewAddressShip(address_shipping_line_1);
    setNewAddress2Ship(address_shipping_line_2);
    setNewCityShip(address_shipping_city);
    setNewStateShip({ abbreviation: address_shipping_state });
    setNewZipShip(address_shipping_zip);
  };

  const handleUpdateCreator = async () => {
    const updateObj = { id: creator.id };

    updateObj.phone_number = newPhone;
    updateObj.address = {
      line_1: newAddress,
      city: newCity,
      state: newState.abbreviation,
      zip: newZip,
      line_2: newAddress2,
    };

    updateObj.is_shipping_same_as_home = newIsShipSameAsHome;
    if (!newIsShipSameAsHome) {
      updateObj.shipping_address = {
        line_1: newAddressShip,
        line_2: newAddress2Ship,
        city: newCityShip,
        state: newStateShip.abbreviation,
        zip: newZipShip,
      };
    } else {
      updateObj.shipping_address = {
        line_1: '',
        line_2: '',
        city: '',
        state: '',
        zip: '',
      };
    }
    await dispatch(updateCreator(updateObj));
  };

  const handleToggleIsShipSameAsHome = (evt) => {
    // if it's checked, clear and disable the form fields
    // if it's unchecked, set form fields to redux values
    setNewIsShipSameAsHome(evt.target.checked);
  };

  return (
    <EditCard
      title="Contact"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdateCreator}
      staticContent={
        <>
          <KeyValuePair label="Email" value={email} />
          <KeyValuePair label="Phone number" value={phone_number} />
          <Typography variant="h5" className={css(g.mt_sm, g.mb_sm)}>
            Home address
          </Typography>
          <KeyValuePair label="Address 1" value={address_line_1} />
          <KeyValuePair label="Address 2" value={address_line_2} />
          <KeyValuePair label="City" value={address_city} />
          <KeyValuePair label="State" value={address_state} />
          <KeyValuePair label="Zip code" value={address_zip} />
          {is_shipping_same_as_home ? (
            <>
              <Typography variant="h5" className={css(g.mt_sm, g.mb_sm)}>
                Shipping address
              </Typography>
              <Typography variant="body1">Same as home address</Typography>
            </>
          ) : (
            <>
              <Typography variant="h5" className={css(g.mt_md, g.mb_sm)}>
                Shipping address
              </Typography>
              <KeyValuePair label="Address 1" value={address_shipping_line_1} />
              <KeyValuePair label="Address 2" value={address_shipping_line_2} />
              <KeyValuePair label="City" value={address_shipping_city} />
              <KeyValuePair label="State" value={address_shipping_state} />
              <KeyValuePair label="Zip code" value={address_shipping_zip} />
            </>
          )}
        </>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            required
            variant="outlined"
            margin="dense"
            value={email}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone number"
            variant="outlined"
            margin="dense"
            value={newPhone}
            onChange={(evt) => setNewPhone(evt.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" className={g.mt_sm}>
            Home address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Address 1"
            value={newAddress}
            onChange={(e) => setNewAddress(e.target.value)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address 2"
            variant="outlined"
            margin="dense"
            value={newAddress2}
            onChange={(evt) => setNewAddress2(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="City"
            required
            variant="outlined"
            margin="dense"
            value={newCity}
            onChange={(evt) => setNewCity(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={USStates}
            value={newState}
            getOptionLabel={(option) => `${option.abbreviation || ''}`}
            onChange={(_, val) => setNewState(val)}
            textInputProps={{
              label: 'State',
              margin: 'dense',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Zip code"
            required
            variant="outlined"
            margin="dense"
            value={newZip}
            onChange={(evt) => setNewZip(evt.target.value)}
            fullWidth
          />
        </Grid>

        {/* shipping address */}
        <Grid item xs={12}>
          <Typography variant="h5" className={g.mt_sm}>
            Shipping address
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={newIsShipSameAsHome}
                onChange={handleToggleIsShipSameAsHome}
                color="primary"
              />
            }
            label="Shipping address is same as home"
          />
        </Grid>

        {!newIsShipSameAsHome && (
          <>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Address 1"
                value={newAddressShip}
                onChange={(e) => setNewAddressShip(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address 2"
                variant="outlined"
                margin="dense"
                value={newAddress2Ship}
                onChange={(evt) => setNewAddress2Ship(evt.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="City"
                variant="outlined"
                margin="dense"
                value={newCityShip}
                onChange={(evt) => setNewCityShip(evt.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={USStates}
                value={newStateShip}
                getOptionLabel={(option) => `${option.abbreviation || ''}`}
                onChange={(_, val) => setNewStateShip(val)}
                textInputProps={{
                  label: 'State',
                  margin: 'dense',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Zip code"
                variant="outlined"
                margin="dense"
                value={newZipShip}
                onChange={(evt) => setNewZipShip(evt.target.value)}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </EditCard>
  );
}

export default Contact;
