import React, { useEffect } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';

import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';

import { getIsDev } from 'util/env';
import { _addFundsDev } from 'slices/treasury';
import { setHeaderData } from 'slices/misc';
import { hasTreasurySelector } from 'slices/org';
import global from 'styles/global';
import Tabs from 'components/Tabs';
import Card from 'components/Card';
import TabPanel from 'components/TabPanel';
import useTabs from 'hooks/useTabs';
import { formatCurrency } from 'util/renderStrings';

import StripeTab from './StripeTab';
import PayPalTab from './PayPalTab';
import NiumTab from './NiumTab';
import {
  getWallets as getWalletsSlice,
  stripeWalletSelector,
  walletsSelector,
  getWalletDetails as getWalletDetailsSlice,
  walletDetailsSelector,
  totalBalanceSelector,
} from 'slices/wallets';

const tabs = [
  { label: 'US Only', name: 'usOnly' },
  { label: 'PayPal /  Venmo', name: 'paypal' },
];

const isDev = getIsDev();

if (isDev) {
  tabs.push({ label: 'International', name: 'nium' });
}

function WalletsHome({
  getWallets,
  walletsState,
  stripeWallet,
  getFreeCashNow,
  hasTreasury,
  getWalletDetails,
  walletDetailsState,
}) {
  const g = global();
  const dispatch = useDispatch();

  const { search } = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useTabs(
    search,
    history,
    tabs.map((tab) => tab.name),
    '/wallets'
  );

  const { loading: walletsLoading, data: walletData } = walletsState;
  const { loading: walletDetailsLoading } = walletDetailsState;
  const loading = walletsLoading || walletDetailsLoading;

  const wallets = Object.values(walletData);
  const totalBalance = useSelector(totalBalanceSelector);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Wallets',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Wallets' },
        ],
      })
    );

    // only fetch for orgs with valid connect/treasury IDs
    if (hasTreasury) {
      getWallets();
      getWalletDetails('stripe');
    }
  }, []);

  return (
    <div>
      {/* FREE CASH NOW (dev only) https://www.youtube.com/watch?v=gYTjiIm4h_Q*/}
      {isDev && (
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          style={{ position: 'absolute', right: 20, bottom: 20 }}
          onClick={getFreeCashNow}
        >
          FREE CASH NOW!
        </Button>
      )}

      <LoadingOverlay open={loading} />

      {/* Total balance */}
      {wallets.length > 1 && (
        <div className={g.mb_lg}>
          <Card noHeader variant="outlined">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">TOTAL ACCOUNT BALANCE</Typography>
                <Typography variant="h2" className={g.cashGreen1}>
                  {formatCurrency(totalBalance)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>
      )}

      <Tabs
        tabLabels={tabs.map((tab) => tab.label)}
        value={currentTab}
        onChange={setCurrentTab}
        aria-label="contracts-tabpanel"
      />

      <TabPanel value={currentTab} index={0}>
        <StripeTab walletData={stripeWallet} hasTreasury={hasTreasury} />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <PayPalTab />
      </TabPanel>

      {isDev && (
        <TabPanel value={currentTab} index={2}>
          <NiumTab />
        </TabPanel>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  walletsState: walletsSelector(state),
  stripeWallet: stripeWalletSelector(state),
  hasTreasury: hasTreasurySelector(state),
  walletDetailsState: walletDetailsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getWallets: () => dispatch(getWalletsSlice()),
  getFreeCashNow: () => dispatch(_addFundsDev()),
  getWalletDetails: (type) => dispatch(getWalletDetailsSlice(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletsHome);
