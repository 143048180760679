import { createSlice, createSelector } from '@reduxjs/toolkit';
import { groupBy, prop } from 'ramda';

import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getGenericState,
  handleError,
  getPayloadSuccess,
} from './sliceUtils';
import {
  createPaymentGroupFile as createPaymentGroupFileAPI,
  getPaymentGroupFiles as getPaymentGroupFilesAPI,
  downloadFile,
} from 'api/paymentGroupFiles';
import { dataURItoBlob } from 'util/download';

export const initialPaymentGroupFilesState = {
  createPaymentGroupFile: getGenericState(),
  getPaymentGroupFiles: getGenericState([]),
};

export const paymentGroupFilesSlice = createSlice({
  name: 'paymentGroupFiles',
  initialState: initialPaymentGroupFilesState,
  reducers: {
    createPaymentGroupFileStarted: getGenericStarted('createPaymentGroupFile'),
    createPaymentGroupFileSuccess: getGenericSuccess('createPaymentGroupFile'),
    createPaymentGroupFileFailure: getGenericFailure('createPaymentGroupFile'),

    getFileDownloadStarted: getGenericStarted('filePreview'),
    getFileDownloadSuccess: getPayloadSuccess('filePreview'),
    getFileDownloadFailure: getGenericFailure('filePreview'),

    getPaymentGroupFilesStarted: getGenericStarted('getPaymentGroupFiles'),
    getPaymentGroupFilesSuccess: getPayloadSuccess('getPaymentGroupFiles'),
    getPaymentGroupFilesFailure: getGenericFailure('getPaymentGroupFiles'),
  },
});

export const {
  createPaymentGroupFileStarted,
  createPaymentGroupFileSuccess,
  createPaymentGroupFileFailure,

  getFileDownloadStarted,
  getFileDownloadSuccess,
  getFileDownloadFailure,

  getPaymentGroupFilesStarted,
  getPaymentGroupFilesSuccess,
  getPaymentGroupFilesFailure,
} = paymentGroupFilesSlice.actions;

export default paymentGroupFilesSlice.reducer;

export const createPaymentGroupFile = (data) => async (dispatch, getState) => {
  dispatch(createPaymentGroupFileStarted());
  try {
    const res = await createPaymentGroupFileAPI(getState(), data);
    dispatch(createPaymentGroupFileSuccess(res));
    return res.id;
  } catch (err) {
    handleError(
      err,
      dispatch,
      createPaymentGroupFileFailure,
      'There was an issue uploading your file'
    );
  }
};

export const getFileDownload = (id, fileType) => async (dispatch, getState) => {
  dispatch(getFileDownloadStarted());
  try {
    const res = await downloadFile(getState(), id, fileType);
    const blob = dataURItoBlob(res, 'application/octet-stream');
    const previewUrl = URL.createObjectURL(blob);
    dispatch(getFileDownloadSuccess(previewUrl));
    return previewUrl;
  } catch (err) {
    handleError(
      err,
      dispatch,
      getFileDownloadFailure,
      'There was an issue retrieving your file'
    );
  }
};

export const getPaymentGroupFiles = () => async (dispatch, getState) => {
  dispatch(getPaymentGroupFilesStarted());
  try {
    const res = await getPaymentGroupFilesAPI(getState());
    dispatch(getPaymentGroupFilesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getPaymentGroupFilesFailure,
      'There was an issue retrieving your payment group files'
    );
  }
};

// selectors
const selectPaymentGroupFiles = (state) => state.paymentGroupFiles;

export const createPaymentGroupFileSelector = createSelector(
  selectPaymentGroupFiles,
  (paymentGroupFilesState = {}) =>
    paymentGroupFilesState.createPaymentGroupFile || getGenericState()
);

export const getFileDownloadSelector = createSelector(
  selectPaymentGroupFiles,
  (paymentGroupFilesState = {}) =>
    paymentGroupFilesState.filePreview || getGenericState()
);

export const paymentGroupFilesSelector = createSelector(
  selectPaymentGroupFiles,
  (paymentGroupFilesState = {}) =>
    paymentGroupFilesState.getPaymentGroupFiles || getGenericState([])
);

export const filesByPaymentGroupSelector = createSelector(
  paymentGroupFilesSelector,
  (paymentGroupFilesState) => {
    const { data } = paymentGroupFilesState;
    return groupBy(prop('payment_group_id'), data);
  }
);
