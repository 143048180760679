import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import global from 'styles/global';
import { paymentGroupDetailsSelector } from 'slices/paymentGroups';
import {
  getAcctMap,
  getAccounts,
  getJournalEntries,
  journalEntriesSelector,
  journalEntriesForCampaignSelector,
} from 'slices/qb';
import { quickbooksSelector, getConnections } from 'slices/orgOauth';
import TableHeader from 'components/TableHeader';
import DataGrid from 'components/DataGrid';
import { renderCreatedAtDate, renderQuickbooksLines, renderQbStatusChip } from 'util/table';
import LoadingOverlay from 'components/LoadingOverlay';

function QuickbooksTab() {
  const g = global();
  const dispatch = useDispatch();

  const { groupId } = useParams();
  const campaign = useSelector((state) =>
    paymentGroupDetailsSelector(state, groupId)
  );

  const journalEntries = useSelector((state) =>
    journalEntriesForCampaignSelector(state, groupId)
  );

  const { loading: journalEntriesLoading } = useSelector(
    journalEntriesSelector
  );

  const loading = journalEntriesLoading;

  const qboConnection = useSelector(quickbooksSelector);

  useEffect(() => {
    dispatch(getConnections());
    dispatch(getAcctMap());
  }, []);

  useEffect(() => {
    if (qboConnection?.id) {
      dispatch(getAccounts());
      dispatch(getJournalEntries());
    }
  }, [qboConnection]);

  const columns = [
    {
      field: 'Metadata.Create_Time',
      headerName: 'Created',
      flex: 1,
      renderCell: renderCreatedAtDate,
      valueFormatter: renderCreatedAtDate,
    },
    {
      field: 'Details',
      headerName: 'Details',
      flex: 3,
      renderCell: (params) => renderQuickbooksLines(params.row),
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => renderQbStatusChip(params.value),
      valueFormatter:  (params) => renderQbStatusChip(params.value),
    },
  ];

  return (
    <>
      <LoadingOverlay open={loading} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            <TableHeader title={`Journal entries for ${campaign.title}`} />
            <div className={g.tableWrapper}>
              <DataGrid
                autoHeight
                autoPageSize
                rows={journalEntries}
                columns={columns}
                variant="outlined"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default QuickbooksTab;
