import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import css from 'classnames';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'query-string';
import { Grid, IconButton, FormHelperText } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';

import { setHeaderData } from 'slices/misc';
import {
  getOrgFees as getOrgFeesSlice,
  orgFeesSelector,
  outboundPmtFeesSelector,
} from 'slices/orgFees';
import {
  getPaymentGroups as getPaymentGroupsSlice,
  paymentGroupsSelector,
} from 'slices/paymentGroups';
import {
  getCreators as getCreatorsSlice,
  creatorsSelector,
  currentUserSelector,
  getMembers,
  adminsSelector,
} from 'slices/user';
import {
  createPayments as createPaymentsSlice,
  createPaymentsSelector,
  initiatePayments as initiatePaymentsSlice,
  initiatePaymentsSelector,
  paymentsDictSelector,
  paymentsSelector,
  getRecipientPaymentTotals,
  paymentRecipientTotalsSelector,
} from 'slices/payments';
import global from 'styles/global';
import PaymentConfirmationModal from 'components/PaymentConfirmationModal';
import Button from 'components/Button';
import NewPaymentRow from 'components/NewPaymentRow';
import LoadingOverlay from 'components/LoadingOverlay';
import { stringToNumberCents } from 'util/renderStrings';
import { paymentGroupTypes } from 'constants/paymentGroups';
import { newPaymentRecipientsDictSelector } from 'slices/multiSliceSelectors';
import { getTotalWithholdingsForPayment } from 'pages/Campaigns/NewCampaign/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    padding: theme.spacing(2),
  },
  addButton: {
    marginLeft: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.custom.payBlue036,
    },
  },
  addIcon: {
    color: theme.palette.shades.white,
  },
  errorText: { textAlign: 'center' },
}));

const NewPayment = ({
  createPayments,
  creatorsState,
  getCreators,
  getPaymentGroups,
  initiatePayments,
  paymentGroupsState,
  paymentsDict,
  outboundPaymentFees,
  initiatePaymentsState,
  newPaymentRecipientsDict,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const g = global();
  const history = useHistory();

  const { data: user } = useSelector(currentUserSelector);

  const { payment_group_id, recipient_id } = qs.parse(history.location.search);
  const defaultGroup = !!payment_group_id
    ? { id: payment_group_id, title: '' }
    : undefined;
  const defaultRecipient = !!recipient_id ? { id: recipient_id } : undefined;

  const { loading: creatorsLoading } = creatorsState;
  const { loading: adminsLoading } = useSelector(adminsSelector);

  const { data: paymentGroups, loading: paymentGroupsLoading } =
    paymentGroupsState;
  const { loading: initiatePaymentsLoading } = initiatePaymentsState;

  const {
    data: paymentRecipientTotals,
    loading: paymentRecipientTotalsLoading,
  } = useSelector(paymentRecipientTotalsSelector);

  const loading =
    creatorsLoading ||
    paymentGroupsLoading ||
    adminsLoading ||
    paymentRecipientTotalsLoading;

  const [lastId, setLastId] = useState(0);
  const [payments, setPayments] = useState([
    { id: 0, group: defaultGroup, recipient: defaultRecipient, error: '' },
  ]);

  const addPayment = () => {
    setPayments([...payments, { id: lastId + 1 }]);
    setLastId(lastId + 1);
  };

  const updateProp = (id, val, key) => {
    const newPayments = payments.map((pmt) => {
      if (pmt.id === id) {
        // can't set group to null or some destructuring fails in the NewPaymnetGroup component
        if (key === 'group' && !val) {
          return {
            ...pmt,
            [key]: {},
          };
        }
        const newPayment = {
          ...pmt,
          [key]: val,
        };

        if (key === 'split' || key === 'earnings') {
          newPayment.amount = (newPayment.earnings * newPayment.split) / 100;
        }
        if (key === 'recipient') {
          newPayment.split = val?.split || 100;
        }

        const { stateWithholdings, federalWithholdings } =
          getTotalWithholdingsForPayment(
            newPayment,
            newPayment.recipient || {},
            paymentRecipientTotals[newPayment.recipient?.id] || 0
          );

        newPayment.state_withholdings = stateWithholdings;
        newPayment.federal_withholdings = federalWithholdings;

        newPayment.amount =
          newPayment.amount -
          newPayment.state_withholdings -
          newPayment.federal_withholdings;

        return newPayment;
      }
      return pmt;
    });
    setPayments(newPayments);
  };

  const handleDelete = (id) => {
    const newPayments = payments.filter((pmt) => pmt.id !== id);
    setPayments(newPayments);
  };

  const [paramIsUsed, setParamIsUsed] = useState(false);

  // When the page loads, we might have a payment_group_id specified in the query parameters, so we make
  // an object like so: { id: payment_group_id }, but when the campaign details are fetched we need to
  // update the selection so the autocomplete has the real selection, which renders title and everything
  useEffect(() => {
    if (payments.length === 0 || paramIsUsed) return;
    const { group = {}, recipient = {} } = payments[0];
    if (!group?.title && !!payment_group_id) {
      const newGroup = paymentGroups.find((grp) => grp.id === payment_group_id);
      !!newGroup && updateProp(0, newGroup, 'group');
      setParamIsUsed(true);
    } else if (!recipient?.first_name && !!recipient_id) {
      const newRecipient = newPaymentRecipientsDict[recipient_id];
      !!newRecipient && updateProp(0, newRecipient, 'recipient');
      setParamIsUsed(true);
    }
  }, [payment_group_id, paymentGroups, payments, newPaymentRecipientsDict]);

  const [modalVisible, setModalVisible] = useState(false);
  const [pmtsToExecute, setPmtsToExecute] = useState([]);
  const [error, setError] = useState('');

  const getConvertedAmtPayments = () => {
    if (payments.length === 0) {
      setError('There are no Payments');
      return;
    }
    return payments.map((pmt) => {
      const amtCents = stringToNumberCents((pmt.earnings * pmt.split) / 100);
      const earningsCents = stringToNumberCents(pmt.earnings);
      const stateWithholdings = Math.floor(pmt.state_withholdings * 100);
      const fedWithholdings = Math.floor(pmt.federal_withholdings * 100);
      const newPmt = {
        earnings: earningsCents,
        split: +pmt.split,
        amount: amtCents - stateWithholdings - fedWithholdings,
        memo: pmt.memo,
        recipient: pmt.recipient.id,
        state_withholdings: stateWithholdings,
        federal_withholdings: fedWithholdings,
      };
      if (pmt.group?.id) {
        newPmt.payment_group_id = pmt.group.id;
      }
      return newPmt;
    });
  };

  const getErrorInPayments = () => payments.find((pmt) => pmt.error)?.error;

  const createPmtsAndNavigate = async () => {
    const error = getErrorInPayments();

    if (error) {
      setError('There were issues with the payments above');
      return;
    }

    const convertedAmtPayments = getConvertedAmtPayments();
    createPayments(convertedAmtPayments, () => history.push('/payments'));
  };

  const createPmtsForExecution = async () => {
    const error = getErrorInPayments();

    if (error) {
      setError('There were issues with the payments above');
      return;
    }

    const convertedAmtPayments = getConvertedAmtPayments();
    const pmts = await createPayments(convertedAmtPayments);
    setPmtsToExecute(pmts.map((pmt) => pmt.id));
    setModalVisible(true);
  };

  const executePayments = () => {
    initiatePayments(pmtsToExecute, () => {
      setModalVisible(false);
      history.push('/payments?tab=payments');
    });
  };

  // if they cancel out of the execute payments modal, their payments have already been created at that point
  // so they should be redirected to the draft payments page so they can see that they've been created
  const handleCloseModal = () => {
    setModalVisible(false);
    history.push('/payments?tab=draft_payments');
  };

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'New Payment',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Payments', link: '/payments' },
          { label: 'New Payment' },
        ],
      })
    );
    getPaymentGroups({ type: paymentGroupTypes.campaign });
    getCreators();
    dispatch(getMembers());
    dispatch(getRecipientPaymentTotals());
  }, []);

  useEffect(() => {
    if (payments.length === 0) {
      addPayment();
    }
  }, [payments]);

  return (
    <div>
      <LoadingOverlay open={loading} />

      <PaymentConfirmationModal
        open={modalVisible}
        handleClose={handleCloseModal}
        executePayments={executePayments}
        payments={pmtsToExecute}
        paymentsDict={paymentsDict}
        outboundPaymentFees={outboundPaymentFees}
        initiatePaymentsState={initiatePaymentsState}
      />

      <Grid container>
        <Grid item xs={12}>
          <div className={g.p_md}>
            {/* Payments */}
            {payments.map((pmt, idx) => (
              <NewPaymentRow
                idx={idx}
                creators={Object.values(newPaymentRecipientsDict)}
                key={`new_payment-${pmt.id}`}
                vals={pmt}
                updateProp={updateProp}
                handleDelete={handleDelete}
                paymentGroups={paymentGroups}
              />
            ))}

            <IconButton
              variant="outlined"
              size="large"
              color="primary"
              onClick={addPayment}
              className={classes.addButton}
            >
              <AddIcon size="large" className={classes.addIcon} />
            </IconButton>

            <FormHelperText error margin="dense" className={classes.errorText}>
              {error}
            </FormHelperText>
          </div>

          <div
            className={css(
              g.flexRowSpacing,
              g.justifyEnd,
              classes.paddingBottom,
              g.mt_md
            )}
          >
            <div>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={createPmtsForExecution}
                disabled={user.role === 'collaborator'}
              >
                Create and execute
              </Button>
              <Button
                size="small"
                className={g.ml_xs}
                onClick={createPmtsAndNavigate}
                variant="contained"
                color="primary"
                loading={initiatePaymentsLoading}
              >
                Create Payment
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orgFeesState: orgFeesSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
  creatorsState: creatorsSelector(state),
  outboundPaymentFees: outboundPmtFeesSelector(state),
  paymentsDict: paymentsDictSelector(state),
  paymentsState: paymentsSelector(state),
  initiatePaymentsState: initiatePaymentsSelector(state),
  createPaymentsState: createPaymentsSelector(state),
  newPaymentRecipientsDict: newPaymentRecipientsDictSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  //we have paymentsDict above, but no paymentsDict here because we fetch payments after the new payment(s) is created
  getCreators: () => dispatch(getCreatorsSlice()),
  getOrgFees: () => dispatch(getOrgFeesSlice()),
  createPayments: (data, cb) => dispatch(createPaymentsSlice(data, cb)),
  initiatePayments: (data, cb) => dispatch(initiatePaymentsSlice(data, cb)),
  getPaymentGroups: (params) => dispatch(getPaymentGroupsSlice(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPayment);
