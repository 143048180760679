import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import css from 'classnames';
import { Typography, Grid, Paper } from '@material-ui/core';
import FilePresent from '@mui/icons-material/FilePresent';

import Earnings from './Earnings';
import CampaignInfo from './CampaignInfo';
import {
  getFileDownload,
  filesByPaymentGroupSelector,
} from 'slices/paymentGroupFiles';
import { paymentGroupDetailsSelector } from 'slices/paymentGroups';
import { invoiceTotalsSelector } from 'slices/invoices';
import { paymentTotalsSelector } from 'slices/payments';
import Card from 'components/Card';
import global from 'styles/global';
import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import { download } from 'util/download';
import { formatCurrency } from 'util/renderStrings';

export default function OverviewTab() {
  const g = global();
  const dispatch = useDispatch();
  const { groupId } = useParams();

  const group = useSelector((state) =>
    paymentGroupDetailsSelector(state, groupId)
  );

  const paymentTotals = useSelector(paymentTotalsSelector);
  const invoiceTotals = useSelector(invoiceTotalsSelector);
  const filesByPaymentGroup = useSelector(filesByPaymentGroupSelector);
  const groupFiles = filesByPaymentGroup[group.id] || [];

  const currentIncome = invoiceTotals.completed - paymentTotals.completed;
  const projectedIncome =
    invoiceTotals.completed +
    invoiceTotals.pending -
    paymentTotals.completed -
    paymentTotals.pending;

  const {
    title,
    description,
    campaign_ref_id,
    campaign_start_date,
    campaign_end_date,
    campaign_status,
    deal_size,
    terms,
    customer_id,
    agent_id,
  } = group;

  const handleDownloadFile = async (file) => {
    const res = await dispatch(getFileDownload(file.id, 'source'));
    download(res, file.file_name);
  };

  return (
    <div className={g.mb_lg}>
      <Grid container spacing={2}>
        {/* Title and description */}
        <Grid item xs={12} sm={7}>
          <CampaignInfo
            id={groupId}
            title={title}
            description={description}
            startDate={campaign_start_date}
            endDate={campaign_end_date}
            refId={campaign_ref_id}
            status={campaign_status}
            dealSize={deal_size}
            terms={terms}
            customerId={customer_id}
            agentId={agent_id}
          />
        </Grid>

        {/* Earnings section */}
        <Grid item xs={12} sm={5} className={g.display_flex}>
          <Card title="Earnings" variant="outlined">
            <Earnings
              invoiceTotals={invoiceTotals}
              paymentTotals={paymentTotals}
            />
            <div className={css(g.p_lg, g.flexRowSpacing)}>
              <Typography variant="h5">
                Income (current): {formatCurrency(currentIncome)}
              </Typography>
              <Typography variant="h5">
                Income (projected): {formatCurrency(projectedIncome)}
              </Typography>
            </div>
          </Card>
        </Grid>

        {/* Uploaded CSVs */}
        {groupFiles.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">Uploaded files</Typography>
            </Grid>
            {groupFiles.map((file) => (
              <Grid item md={3} sm={6} xs={12}>
                <Paper
                  onClick={() => handleDownloadFile(file)}
                  className={css(g.paper, g.mb_lg, g.clickable)}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} className={g.centerChildren}>
                      <FilePresent style={{ fontSize: 48 }} />
                      <Typography
                        variant="subtitle1"
                        className={g.wrapAnywhere}
                      >
                        {file.file_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </div>
  );
}
