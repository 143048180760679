import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from 'classnames';
import { InputLabel, Grid, FormControl, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { updatePaymentGroup } from 'slices/paymentGroups';
import global from 'styles/global';
import KeyValuePair from 'components/KeyValuePair';
import EditCard from 'components/EditCard';
import { renderCampaignStatusChip } from 'util/campaigns';
import { formatDateForTimeInput, getDelimDateFromDateObj } from 'util/time';
import DollarInput from 'components/DollarInput';
import { customersDictSelector, customersSelector } from 'slices/customers';
import {
  agentsMngrsDictSelector,
  agentsMngrsGroupedSelector,
} from 'slices/agentsMngrs';
import Autocomplete from 'components/Autocomplete';
import { emptyCustomerData } from 'fixtures/customer';
import { emptyAgentMngrData } from 'fixtures/agentsMngrs';
import {
  formatCurrency,
  formatCustomerName,
  renderTerms,
  stringToNumberCents,
  formatCreatorName,
} from 'util/renderStrings';
import TextField from 'components/TextField';
import Select from 'components/Select';

function CampaignInfo({
  id,
  title,
  refId,
  description,
  startDate,
  endDate,
  status,
  dealSize,
  terms,
  customerId,
  agentId,
}) {
  const g = global();
  const dispatch = useDispatch();
  const statuses = [
    { label: 'Pre-flight', value: 'preflight' },
    { label: 'Completed', value: 'completed' },
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];

  const { data: customers } = useSelector(customersSelector);
  const { agents } = useSelector(agentsMngrsGroupedSelector);
  const customerDict = useSelector(customersDictSelector);
  const agentsDict = useSelector(agentsMngrsDictSelector);

  const [newTitle, setNewTitle] = useState(title);
  const [newRefId, setNewRefId] = useState(refId);
  const [newDescription, setNewDescription] = useState(description);
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [newStatus, setNewStatus] = useState();
  const [newDealSize, setNewDealSize] = useState();
  const [newTerms, setNewTerms] = useState();
  const [newCustomer, setNewCustomer] = useState();
  const [newAgent, setNewAgent] = useState();

  useEffect(() => {
    setNewStartDate(formatDateForTimeInput(startDate));
  }, [startDate]);
  useEffect(() => {
    setNewEndDate(formatDateForTimeInput(endDate));
  }, [endDate]);
  useEffect(() => {
    setNewStatus(status);
  }, [status]);

  useEffect(() => {
    setNewTitle(title);
    setNewDescription(description);
    setNewRefId(refId);
    setNewTerms(terms);
    setNewDealSize(dealSize / 100);
    setNewCustomer(customerDict[customerId] || emptyCustomerData);
    setNewAgent(agentsDict[agentId] || emptyAgentMngrData);
  }, [
    title,
    description,
    refId,
    terms,
    dealSize,
    customerId,
    customerDict,
    agentId,
    agentsDict,
  ]);

  const cancelEditing = () => {
    setNewTitle(title);
    setNewDescription(description);
  };

  const saveFields = () => {
    const updateFields = {
      id,
      title: newTitle,
      campaign_ref_id: newRefId,
      description: newDescription,
      campaign_status: newStatus,
      customer_id: newCustomer.id,
      terms: newTerms,
      agent_id: newAgent ? newAgent.id : '',
    };
    if (!!newDealSize)
      updateFields.deal_size = stringToNumberCents(newDealSize);
    if (!!newStartDate) updateFields.campaign_start_date = newStartDate;
    if (!!newEndDate) updateFields.campaign_end_date = newEndDate;
    dispatch(updatePaymentGroup(updateFields));
  };

  return (
    <EditCard
      title="Details"
      variant="outlined"
      handleCancel={cancelEditing}
      handleSubmit={saveFields}
      staticContent={
        <>
          <KeyValuePair label="Title" value={title} />
          <KeyValuePair label="Description" value={description} />
          <KeyValuePair
            label="Start date"
            value={!!startDate ? getDelimDateFromDateObj(startDate) : '-'}
          />
          <KeyValuePair
            label="End date"
            value={!!endDate ? getDelimDateFromDateObj(endDate) : '-'}
          />
          <KeyValuePair
            label="Status"
            value={renderCampaignStatusChip(status)}
            valueClasses={g.p_xs}
          />
          <KeyValuePair
            label="Customer"
            value={
              !!customerId ? (
                <Link to={`/customers/${customerId}`}>
                  {formatCustomerName(
                    customerDict[customerId] || emptyCustomerData
                  )}
                </Link>
              ) : (
                '-'
              )
            }
          />
          <KeyValuePair
            label="Agent"
            value={
              !!agentId ? (
                <Link to={`/agentsManagers/${agentId}`}>
                  {formatCreatorName(agentsDict[agentId] || emptyCustomerData)}
                </Link>
              ) : (
                '-'
              )
            }
          />
          <KeyValuePair
            label="Campaign budget"
            value={formatCurrency(dealSize)}
          />
          <KeyValuePair label="Terms" value={renderTerms(terms)} />
          <KeyValuePair label="Reference ID" value={refId} />
        </>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Title"
            value={newTitle}
            onChange={(evt) => setNewTitle(evt.target.value)}
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Description"
            value={newDescription}
            onChange={(evt) => setNewDescription(evt.target.value)}
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            label="Start date"
            type="date"
            fullWidth
            margin="dense"
            value={newStartDate}
            onChange={(evt) => setNewStartDate(evt.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            label="End date"
            type="date"
            fullWidth
            margin="dense"
            value={newEndDate}
            onChange={(evt) => setNewEndDate(evt.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl
            size="small"
            variant="outlined"
            className={css(g.mt_xs, g.full_width)}
          >
            <InputLabel id="select-status-label">Status</InputLabel>
            <Select
              labelId="select-status-label"
              label="Status"
              id="select-status"
              value={newStatus}
              onChange={(evt) => setNewStatus(evt.target.value)}
            >
              {statuses.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={customers}
            className={css(g.mt_xs, g.full_width)}
            getOptionLabel={(option) => `${option.name || ''}`}
            value={newCustomer}
            onChange={(_, val) => setNewCustomer(val)}
            textInputProps={{
              label: 'Customer',
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={agents}
            className={css(g.mt_xs, g.full_width)}
            getOptionLabel={(option) => formatCreatorName(option)}
            value={newAgent}
            onChange={(_, val) => setNewAgent(val)}
            textInputProps={{
              label: 'Agent',
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DollarInput
            margin="dense"
            variant="outlined"
            label="Campaign budget"
            value={newDealSize}
            onChange={(evt) => {
              setNewDealSize(evt.target.value);
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl size="small" variant="outlined" className={g.full_width}>
            <InputLabel id="select-terms-label">Terms</InputLabel>
            <Select
              labelId="select-terms-label"
              label="Terms"
              id="select-terms"
              value={newTerms}
              onChange={(evt) => setNewTerms(evt.target.value)}
            >
              <MenuItem value="due">Due upon invoice</MenuItem>
              <MenuItem value="30">Net 30</MenuItem>
              <MenuItem value="60">Net 60</MenuItem>
              <MenuItem value="90">Net 90</MenuItem>
              <MenuItem value="120">Net 120</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Reference ID"
            value={newRefId}
            onChange={(evt) => setNewRefId(evt.target.value)}
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default CampaignInfo;
