import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { find, propEq } from 'ramda';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import LoadingOverlay from 'components/LoadingOverlay';
import PageHeader from 'components/PageHeader';

import { emptyCustomerData } from 'fixtures/customer';
import {
  customersSelector,
  getCustomers as getCustomersSlice,
  updateCustomer as updateCustomerSlice,
} from 'slices/customers';
import { setHeaderData } from 'slices/misc';
import {
  invoicesSelector,
  invalidateInvoicesSelector,
  remindInvoicesSelector,
  getInvoices as getInvoicesSlice,
  invalidateInvoices as invalidateInvoicesSlice,
  remindInvoices as remindInvoicesSlice,
  invoicesByCustomerSelector,
} from 'slices/invoices';
import Details from './Details';
import Invoices from './Invoices';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import {
  paymentGroupsSelector,
  getPaymentGroups as getPaymentGroupsSlice,
} from 'slices/paymentGroups';
import { paymentGroupTypes } from 'constants/paymentGroups';
import Address from './Address';
import {
  customersSelector as qbCustomersSelector,
  getCustomers as getQbCustomersSlice,
} from 'slices/qb';
import {
  quickbooksSelector,
  getConnections as getConnectionsSlice,
} from 'slices/orgOauth';
import Quickbooks from './Quickbooks';
import Contracts from './Contracts';
import {
  contractsSelector,
  getContracts as getContractsSlice,
} from 'slices/contracts';

function Customer({
  getCustomers,
  customersState,
  getInvoices,
  invoicesState,
  invoicesByCustomer,
  updateCustomer,
  getPaymentGroups,
  paymentGroupsState,
  invalidateInvoicesState,
  remindInvoicesState,
  remindInvoices,
  invalidateInvoices,
  getConnections,
  getQbCustomers,
  qbCustomersState,
  getContracts,
  contractsState,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading: customersLoading, data: customers } = customersState;
  const { loading: paymentGroupsLoading, data: paymentGroups } =
    paymentGroupsState;
  const { loading: invoicesLoading } = invoicesState;
  const { loading: qbCustomersLoading } = qbCustomersState;
  const { loading: contractsLoading } = contractsState;
  const loading =
    customersLoading ||
    invoicesLoading ||
    paymentGroupsLoading ||
    qbCustomersLoading;

  const { loading: invalidateLoading } = invalidateInvoicesState;
  const { loading: remindLoading } = remindInvoicesState;

  const customer = find(propEq('id', id), customers) || emptyCustomerData;
  const { name } = customer;

  const customerInvoices = invoicesByCustomer[id] || [];

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: name,
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Customers', link: '/customers' },
          { label: name },
        ],
      })
    );
  }, [name]);

  useEffect(() => {
    getCustomers();
    getInvoices();
    getPaymentGroups({ type: paymentGroupTypes.campaign });
    getConnections();
    getContracts();
  }, []);

  const qboConnection = useSelector(quickbooksSelector);

  useEffect(() => {
    if (qboConnection?.id) {
      getQbCustomers();
    }
  }, [qboConnection]);

  return (
    <div>
      <LoadingOverlay open={loading} />

      <Tabs
        tabLabels={['Info', 'Invoices', 'Contracts']}
        value={currentTab}
        onChange={handleTabChange}
        aria-label="customer-tabpanel"
      />
      <TabPanel value={currentTab} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Details customer={customer} updateCustomer={updateCustomer} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Address customer={customer} updateCustomer={updateCustomer} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Quickbooks customer={customer} updateCustomer={updateCustomer} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Invoices
          invoices={customerInvoices}
          paymentGroups={paymentGroups}
          sendEmailReminders={remindInvoices}
          invalidateLoading={invalidateLoading}
          remindLoading={remindLoading}
          invalidateInvoices={invalidateInvoices}
          customer={customer}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <Contracts customer={customer} />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  customersState: customersSelector(state),
  invoicesState: invoicesSelector(state),
  invoicesByCustomer: invoicesByCustomerSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
  invalidateInvoicesState: invalidateInvoicesSelector(state),
  remindInvoicesState: remindInvoicesSelector(state),
  qbCustomersState: qbCustomersSelector(state),
  contractsState: contractsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(getCustomersSlice()),
  getInvoices: () => dispatch(getInvoicesSlice()),
  updateCustomer: (data) => dispatch(updateCustomerSlice(data)),
  getPaymentGroups: (params) => dispatch(getPaymentGroupsSlice(params)),
  invalidateInvoices: (data) => dispatch(invalidateInvoicesSlice(data)),
  remindInvoices: (ids, method) => dispatch(remindInvoicesSlice(ids, method)),
  getConnections: () => dispatch(getConnectionsSlice()),
  getQbCustomers: () => dispatch(getQbCustomersSlice()),
  getContracts: () => dispatch(getContractsSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
