export const emptyOrgFee = {
  id: '',
  created_at: '',
  updated_at: '',
  org_id: '',
  payment_type: 'outbound_payment',
  percentage: '0', // '45.2' for 45.2%
  flat_rate: 0,
  cap: null,
};
