import { createSlice, createSelector } from '@reduxjs/toolkit';

import { getOrgFees as getOrgFeesAPI } from 'api/orgFees';
import { emptyOrgFee } from 'fixtures/orgFees';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import { feeTypes } from "constants/fees";

export const initialOrgState = {
  orgFees: getGenericState([]),
};

export const orgFeesSlice = createSlice({
  name: 'orgFees',
  initialState: initialOrgState,
  reducers: {
    getOrgFeesStarted: getGenericStarted('orgFees'),
    getOrgFeesSuccess: getPayloadSuccess('orgFees'),
    getOrgFeesFailure: getGenericFailure('orgFees'),
  },
});

export const { getOrgFeesStarted, getOrgFeesSuccess, getOrgFeesFailure } =
  orgFeesSlice.actions;

export default orgFeesSlice.reducer;

export const getOrgFees = () => async (dispatch, getState) => {
  dispatch(getOrgFeesStarted());
  try {
    const res = await getOrgFeesAPI(getState());
    dispatch(getOrgFeesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getOrgFeesFailure,
      'There was an error getting the org fees.'
    );
  }
};

// selectors
const selectOrgFees = (state) => state.orgFees;

export const orgFeesSelector = createSelector(
  selectOrgFees,
  (orgFeesState = {}) => orgFeesState.orgFees || getGenericState([])
);

export const outboundPmtFeesSelector = createSelector(
  orgFeesSelector,
  (orgFeesState) => {
    const { data: orgFees } = orgFeesState;
    return (
      orgFees.find((orgFee) => orgFee.type === feeTypes.OutboundPayment) ||
      emptyOrgFee
    );
  }
);

export const cardInvoiceFeeSelector = createSelector(
  orgFeesSelector,
  (orgFeesState) => {
    const { data: orgFees } = orgFeesState;
    return (
      orgFees.find((orgFee) => orgFee.type === feeTypes.InvoiceCard) ||
      emptyOrgFee
    );
  }
);

export const bankInvoiceFeeSelector = createSelector(
  orgFeesSelector,
  (orgFeesState) => {
    const { data: orgFees } = orgFeesState;
    return (
      orgFees.find((orgFee) => orgFee.type === feeTypes.InvoiceACH) ||
      emptyOrgFee
    );
  }
);

export const contractFeeSelector = createSelector(
  orgFeesSelector,
  (orgFeesState) => {
    const { data: orgFees } = orgFeesState;
    return (
      orgFees.find((orgFee) => orgFee.type === feeTypes.Contract) ||
      emptyOrgFee
    );
  }
);
