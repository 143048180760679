export const emptyCustomerData = {
  id: '',
  created_at: '',
  updated_at: '',
  email: '',
  name: '',
  stripe_customer_id: '',
  stripe_payment_method: '',
  phone_number: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  },
};
