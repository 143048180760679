import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import PageHeader from 'components/PageHeader';
import LoadingOverlay from 'components/LoadingOverlay';
import {
  getPaymentGroups as getPaymentGroupsSlice,
  createPaymentGroup as createPaymentGroupSlice,
  paymentGroupsSelector,
  paymentGroupsSortedSelector,
} from 'slices/paymentGroups';
import { paymentGroupsStatsSelector } from 'slices/multiSliceSelectors';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
} from 'slices/payments';
import { renderGroupTitle, renderCustomer } from 'util/table';
import { formatCurrency } from 'util/renderStrings';
import global from 'styles/global';
import DataGrid from 'components/DataGrid';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import {
  invoicesByPaymentGroupSelector,
  getInvoices as getInvoicesSlice,
  invoicesSelector,
} from 'slices/invoices';
import objectStates from 'constants/objectStates';
import { paymentGroupTypes } from 'constants/paymentGroups';
import {
  customersSelector,
  getCustomers as getCustomersSlice,
  customersDictSelector,
} from 'slices/customers';
import { setHeaderData } from 'slices/misc';

function Campaigns({
  getPaymentGroups,
  getPayments,
  paymentGroupsState,
  paymentGroupsStats,
  paymentsState,
  getInvoices,
  invoicesState,
  invoicesByPaymentGroup,
  paymentGroups,
  paymentGroupsArchived,
  getCustomers,
  customersState,
  customersDict,
}) {
  const dispatch = useDispatch();
  const { loading: paymentGroupsLoading } = paymentGroupsState;
  const { loading: paymentsLoading } = paymentsState;
  const { loading: customersLoading } = customersState;
  const { loading: invoicesLoading } = invoicesState;
  const loading =
    paymentGroupsLoading ||
    paymentsLoading ||
    customersLoading ||
    invoicesLoading;

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Campaigns',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Campaigns' },
        ],
      })
    );
    refreshData();
  }, []);

  const refreshData = () => {
    getPaymentGroups({ type: paymentGroupTypes.campaign });
    getPayments();
    getInvoices();
    getCustomers();
  };

  const g = global();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);

  const changeTab = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => renderGroupTitle(params, params.row.id),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'customer_id',
      headerName: 'Customer',
      valueGetter: (params) => {
        const customer = customersDict[params.value] || {};
        return customer.name;
      },
      renderCell: (params) => renderCustomer(customersDict[params.row.customer_id]),
      flex: 1,
    },
    {
      field: 'deal_size',
      headerName: 'Deal Size',
      renderCell: (params) => formatCurrency(params.value),
      flex: 1,
    },
    {
      field: 'numPayments',
      headerName: 'Payments completed',
      valueGetter: (params) => {
        const { numPosted } =
          paymentGroupsStats[params.row.id] || {};
        return numPosted;
      },
      renderCell: (params) => {
        const { numPayments, numPosted } =
          paymentGroupsStats[params.row.id] || {};
        return `${numPosted} / ${numPayments}`;
      },
      flex: 1,
    },
    {
      field: 'invoices',
      headerName: 'Invoices',
      flex: 1,
      valueGetter: (params) => {
        const invoices = invoicesByPaymentGroup[params.row.id];
        if (!invoices) {
          return -1;
        }
        const paid = invoices.filter(
          // checking against new status succeeded and old status paid. can remove paid eventually
          (invoice) => invoice.status === objectStates.succeeded
        );
        return paid.length;
      },
      renderCell: (params) => {
        const invoices = invoicesByPaymentGroup[params.row.id];
        if (!invoices) {
          return '-';
        }
        const paid = invoices.filter(
          // checking against new status succeeded and old status paid. can remove paid eventually
          (invoice) => invoice.status === objectStates.succeeded
        );
        return `${paid.length}/${invoices.length} Paid`;
      },
    },
  ];

  const handleNewPaymentGroup = () => {
    history.push('/campaigns/new');
  };

  return (
    <div>
      <LoadingOverlay open={loading} />
      <div>
        <Tabs
          tabLabels={['Campaigns', 'Archived Campaigns']}
          value={currentTab}
          onChange={changeTab}
          aria-label="campaigns-tabpanel"
        />

        <TabPanel value={currentTab} index={0}>
          <TableHeader title="Campaigns">
            <Button
              size="small"
              onClick={handleNewPaymentGroup}
              type="submit"
              variant="contained"
              color="primary"
            >
              New Campaign
            </Button>
          </TableHeader>

          <div className={g.tableWrapper}>
            <DataGrid
              autoHeight
              autoPageSize
              rows={paymentGroups}
              columns={columns}
              columnDependencies={[
                paymentGroupsStats,
                invoicesByPaymentGroup,
                customersDict,
              ]}
            />
          </div>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <TableHeader title="Archived Campaigns" />

          <div className={g.tableWrapper}>
            <DataGrid
              autoHeight
              autoPageSize
              rows={paymentGroupsArchived}
              columns={columns}
              columnDependencies={[
                paymentGroupsStats,
                invoicesByPaymentGroup,
                customersDict,
              ]}
            />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  paymentGroupsState: paymentGroupsSelector(state),
  paymentGroupsStats: paymentGroupsStatsSelector(state),
  paymentsState: paymentsSelector(state),
  invoicesState: invoicesSelector(state),
  invoicesByPaymentGroup: invoicesByPaymentGroupSelector(state),
  paymentGroups: paymentGroupsSortedSelector(state, false),
  paymentGroupsArchived: paymentGroupsSortedSelector(state, true),
  customersState: customersSelector(state),
  customersDict: customersDictSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createPaymentGroup: (data) => dispatch(createPaymentGroupSlice(data)),
  getPaymentGroups: (params) => dispatch(getPaymentGroupsSlice(params)),
  getPayments: () => dispatch(getPaymentsSlice()),
  getInvoices: () => dispatch(getInvoicesSlice()),
  getCustomers: () => dispatch(getCustomersSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
