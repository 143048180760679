import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import { getTaxDocs as getTaxDocsAPI } from 'api/taxes';

export const initialTaxesState = {
  taxDocs: getGenericState([]),
};

export const taxesSlice = createSlice({
  name: 'taxes',
  initialState: initialTaxesState,
  reducers: {
    getTaxDocsStarted: getGenericStarted('taxDocs'),
    getTaxDocsSuccess: getPayloadSuccess('taxDocs'),
    getTaxDocsFailure: getGenericFailure('taxDocs'),
  },
});

export const { getTaxDocsStarted, getTaxDocsSuccess, getTaxDocsFailure } =
  taxesSlice.actions;

export default taxesSlice.reducer;

export const getTaxDocs = (data) => async (dispatch, getState) => {
  dispatch(getTaxDocsStarted());
  try {
    const res = await getTaxDocsAPI(getState(), data);
    dispatch(getTaxDocsSuccess());
    const blob = await res.blob();
    const fileURL = URL.createObjectURL(blob);
    return fileURL;
  } catch (err) {
    handleError(
      err,
      dispatch,
      getTaxDocsFailure,
      'There was an issue retrieving your tax docs'
    );
  }
};

// selectors
const selectTaxes = (state) => state.taxes;

export const taxDocsSelector = createSelector(
  selectTaxes,
  (taxesState = {}) => taxesState.taxDocs || getGenericState([])
);
