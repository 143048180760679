import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import RefreshIcon from '@mui/icons-material/Refresh';

import { nodeDomain } from 'api/utils';
import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import ActionRows from 'components/ActionRows';
import connectToQb from 'assets/C2QB_white_btn_lg_default.png';
import connectToQbHover from 'assets/C2QB_white_btn_lg_hover.png';
import { getDelimitedDateFromIso } from 'util/time';
import global from 'styles/global';
import { authTokenSelector } from 'slices/user';
import {
  getAcctMap,
  getAccounts,
  accountsSelector,
  acctMapParsedSelector,
  saveAcctMap,
  getJournalEntries,
  journalEntriesSelector,
  newJe,
} from 'slices/qb';
import {
  quickbooksSelector,
  deleteConnection,
  deleteConnectionSelector,
  getConnections,
} from 'slices/orgOauth';
import TableHeader from 'components/TableHeader';
import DataGrid from 'components/DataGrid';
import Card from 'components/Card';
import {
  renderCreatedAtDate,
  renderQuickbooksLines,
  renderQbStatusChip,
} from 'util/table';
import LoadingOverlay from 'components/LoadingOverlay';
import Select from 'components/Select';

function IntegrationsSection() {
  const [img, setImg] = useState(connectToQb);
  const [newAcctPayable, setNewAcctPayable] = useState('');
  const [newIncomeAcct, setNewIncomeAcct] = useState('');
  const [newSalesAcct, setNewSalesAcct] = useState('');
  const [newAcctReceivable, setNewAcctReceivable] = useState('');
  const [newClearingAcct, setNewClearingAcct] = useState('');
  const [newLaborAcct, setNewLaborAcct] = useState('');
  const [creatorUniqueIncome, setCreatorUniqueIncome] = useState(false);
  const g = global();
  const dispatch = useDispatch();

  const handleCreatorUniq = (checked) => {
    setCreatorUniqueIncome(checked);
    if (checked) setNewIncomeAcct('');
  };

  const { data: accounts = [], loading: accountsLoading } =
    useSelector(accountsSelector);
  const { data: journalEntries, loading: journalEntriesLoading } = useSelector(
    journalEntriesSelector
  );

  const loading = accountsLoading || journalEntriesLoading;
  const jwt = useSelector(authTokenSelector);
  const qboConnection = useSelector(quickbooksSelector);
  const { loading: submitting } = useSelector(deleteConnectionSelector);
  const acctMap = useSelector(acctMapParsedSelector);
  const {
    payable: acctPayable,
    income: incomeAcct,
    sales: salesAcct,
    receivable: acctReceivable,
    clearing: clearingAcct,
    labor: laborAcct,
  } = acctMap;

  const handleDeleteQbo = () => {
    dispatch(deleteConnection(qboConnection.id));
  };

  const refreshData = () => {
    dispatch(getConnections());
    dispatch(getAcctMap());
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (qboConnection?.id) {
      dispatch(getAccounts());
      dispatch(getJournalEntries());
    }
  }, [qboConnection]);

  useEffect(() => {
    setNewAcctPayable(acctMap?.payable?.Id ?? '');
    setNewIncomeAcct(acctMap?.income?.Id ?? '');
    setNewSalesAcct(acctMap?.sales?.Id ?? '');
    setNewAcctReceivable(acctMap?.receivable?.Id ?? '');
    setNewClearingAcct(acctMap?.clearing?.Id ?? '');
    setNewLaborAcct(acctMap?.labor?.Id ?? '');
    setCreatorUniqueIncome(acctMap?.unique_income_creator);
  }, [acctMap]);

  const launchPopup = () => {
    var parameters = 'location=1,width=800,height=650';
    parameters +=
      ',left=' +
      (window.screen.width - 800) / 2 +
      ',top=' +
      (window.screen.height - 650) / 2;

    const path = `${nodeDomain()}/api/qb/connect?jwt=${jwt}`;
    window.open(path, 'connectPopup', parameters);
  };

  const handleSaveAcctMap = () => {
    const updateObj = accounts.reduce(
      (agg, acct) => {
        if (acct.Id === newIncomeAcct) agg.income = JSON.stringify(acct);
        if (acct.Id === newAcctPayable) agg.payable = JSON.stringify(acct);
        if (acct.Id === newSalesAcct) agg.sales = JSON.stringify(acct);
        if (acct.Id === newAcctReceivable)
          agg.receivable = JSON.stringify(acct);
        if (acct.Id === newClearingAcct) agg.clearing = JSON.stringify(acct);
        if (acct.Id === newLaborAcct) agg.labor = JSON.stringify(acct);
        return agg;
      },
      {
        income: '',
        payable: '',
        sales: '',
        receivable: '',
        clearing: '',
        labor: '',
      }
    );
    updateObj.unique_income_creator = creatorUniqueIncome;
    dispatch(saveAcctMap(updateObj));
  };

  const cancelEditing = () => {
    setNewAcctPayable(acctMap?.payable?.Id ?? '');
    setNewIncomeAcct(acctMap?.income?.Id ?? '');
    setNewSalesAcct(acctMap?.sales?.Id ?? '');
    setNewAcctReceivable(acctMap?.receivable?.Id ?? '');
    setNewClearingAcct(acctMap?.clearing?.Id ?? '');
    setNewLaborAcct(acctMap?.labor?.Id ?? '');
    setCreatorUniqueIncome(acctMap?.unique_income_creator);
  };

  const columns = [
    {
      field: 'Metadata.Create_Time',
      headerName: 'Created',
      flex: 1,
      renderCell: renderCreatedAtDate,
      valueFormatter: renderCreatedAtDate,
    },
    {
      field: 'Details',
      headerName: 'Details',
      flex: 3,
      renderCell: (params) => renderQuickbooksLines(params.row),
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => renderQbStatusChip(params.row),
      valueFormatter: (params) => renderQbStatusChip(params.row),
    },
  ];

  const handleNewJe = () => {
    dispatch(newJe());
  };

  return (
    <>
      <LoadingOverlay open={loading} />
      <Grid container spacing={2}>
        {/* connection card */}
        <Grid item xs={12} sm={6}>
          <Card
            title="Connection"
            variant="outlined"
            action={
              <IconButton
                size="small"
                aria-label="editing"
                onClick={refreshData}
              >
                <RefreshIcon />
              </IconButton>
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {!qboConnection ? (
                  <img
                    className={g.clickable}
                    alt="connect to quickbooks"
                    style={{ height: '40px' }}
                    src={img}
                    onMouseOver={() => setImg(connectToQbHover)}
                    onMouseOut={() => setImg(connectToQb)}
                    onClick={launchPopup}
                  />
                ) : (
                  <ActionRows
                    handleDelete={handleDeleteQbo}
                    submitting={submitting}
                    rows={[
                      {
                        title: 'Quickbooks',
                        subtitle: `Added on ${getDelimitedDateFromIso(
                          qboConnection.created_at
                        )}`,
                      },
                    ]}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* account mapping card */}
        <Grid item xs={12} sm={6}>
          {!!qboConnection && (
            <EditCard
              variant="outlined"
              title="Account mapping"
              handleCancel={cancelEditing}
              handleSubmit={handleSaveAcctMap}
              staticContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <KeyValuePair
                      label="Income account"
                      value={incomeAcct?.Name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KeyValuePair
                      label="Account payable"
                      value={acctPayable?.Name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KeyValuePair
                      label="Sales account"
                      value={salesAcct?.Name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KeyValuePair
                      label="Account receivable"
                      value={acctReceivable?.Name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KeyValuePair
                      label="Clearing account"
                      value={clearingAcct?.Name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KeyValuePair
                      label="Labor expense account"
                      value={laborAcct?.Name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">{`Creators ${
                      !creatorUniqueIncome ? 'do not ' : ''
                    } have unique income accounts.`}</Typography>
                  </Grid>
                </Grid>
              }
            >
              <Grid container spacing={1}>
                {/* Income */}
                <Grid item xs={12}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={css(g.mt_xs, g.full_width)}
                  >
                    <InputLabel id="select-income-label">
                      Creator income account
                    </InputLabel>
                    <Select
                      labelId="select-income-label"
                      label="Creator income account"
                      id="select-income"
                      value={newIncomeAcct}
                      onChange={(evt) => setNewIncomeAcct(evt.target.value)}
                      disabled={!!creatorUniqueIncome}
                    >
                      {accounts
                        .filter((acct) => acct.Classification === 'Revenue')
                        .map((acct) => (
                          <MenuItem key={acct.Id} value={acct.Id}>
                            {acct.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* accounts payable */}
                <Grid item xs={12}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={css(g.mt_xs, g.full_width)}
                  >
                    <InputLabel id="select-payable-label">
                      Accounts payable
                    </InputLabel>
                    <Select
                      labelId="select-payable-label"
                      label="Accounts payable"
                      id="select-payable"
                      value={newAcctPayable}
                      onChange={(evt) => setNewAcctPayable(evt.target.value)}
                    >
                      {accounts
                        .filter((acct) => acct.Classification === 'Liability')
                        .map((acct) => (
                          <MenuItem key={acct.Id} value={acct.Id}>
                            {acct.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* sales account */}
                <Grid item xs={12}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={css(g.mt_xs, g.full_width)}
                  >
                    <InputLabel id="select-sales-label">
                      Sales account
                    </InputLabel>
                    <Select
                      labelId="select-sales-label"
                      label="Sales account"
                      id="select-sales"
                      value={newSalesAcct}
                      onChange={(evt) => setNewSalesAcct(evt.target.value)}
                    >
                      {accounts
                        .filter((acct) => acct.Classification === 'Revenue')
                        .map((acct) => (
                          <MenuItem key={acct.Id} value={acct.Id}>
                            {acct.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* account receivable */}
                <Grid item xs={12}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={css(g.mt_xs, g.full_width)}
                  >
                    <InputLabel id="select-receivable-label">
                      Account receivable
                    </InputLabel>
                    <Select
                      labelId="select-receivable-label"
                      label="Account receivable"
                      id="select-receivable"
                      value={newAcctReceivable}
                      onChange={(evt) => setNewAcctReceivable(evt.target.value)}
                    >
                      {accounts
                        .filter((acct) => acct.Classification === 'Asset')
                        .map((acct) => (
                          <MenuItem key={acct.Id} value={acct.Id}>
                            {acct.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* clearing account */}
                <Grid item xs={12}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={css(g.mt_xs, g.full_width)}
                  >
                    <InputLabel id="select-clearing-label">
                      Clearing account
                    </InputLabel>
                    <Select
                      labelId="select-clearing-label"
                      label="Clearing account"
                      id="select-clearing"
                      value={newClearingAcct}
                      onChange={(evt) => setNewClearingAcct(evt.target.value)}
                    >
                      {accounts
                        .filter(
                          (acct) =>
                            acct.Classification === 'Asset' ||
                            acct.Classification === 'Equity'
                        )
                        .map((acct) => (
                          <MenuItem key={acct.Id} value={acct.Id}>
                            {acct.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* labor expense account */}
                <Grid item xs={12}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={css(g.mt_xs, g.full_width)}
                  >
                    <InputLabel id="select-labor-label">
                      Labor expense account
                    </InputLabel>
                    <Select
                      labelId="select-labor-label"
                      label="Labor expense account"
                      id="select-labor"
                      value={newLaborAcct}
                      onChange={(evt) => setNewLaborAcct(evt.target.value)}
                    >
                      {accounts
                        .filter((acct) => acct.Classification === 'Expense')
                        .map((acct) => (
                          <MenuItem key={acct.Id} value={acct.Id}>
                            {acct.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={creatorUniqueIncome} />
                    }
                    onChange={(evt) => handleCreatorUniq(evt.target.checked)}
                    label="Creators have unique income accounts."
                  />
                </Grid>
              </Grid>
            </EditCard>
          )}
        </Grid>
        {/* <Button onClick={handleNewJe}>new je</Button> */}
        {/* journal entries */}
        <Grid item xs={12}>
          <div>
            <TableHeader title="Journal entries" />
            <div className={g.tableWrapper}>
              <DataGrid
                autoHeight
                autoPageSize
                rows={journalEntries}
                columns={columns}
                variant="outlined"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default IntegrationsSection;
