import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import {
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';

import DataGrid from 'components/DataGrid';
import Button from 'components/Button';
import global from 'styles/global';
import { formatCurrency, shortenUUID } from 'util/renderStrings';
import {
  getGroupValue,
  getOrgValue,
  renderCreatedAtDate,
  renderGroupTitle,
  renderOrgCell,
  renderPostDate,
  paymentStatusValueMap,
  renderPaymentMethodIcon,
  renderPaymentLinkIcon,
  renderRecipientCell,
  renderRecipientRole,
} from 'util/table';
import { paymentGroupsSelector } from 'slices/paymentGroups';
import { renderPaymentStatusChip } from 'util/payments';
import { paymentRecipientsDictSelector } from 'slices/multiSliceSelectors';
import { formatCreatorName } from 'util/renderStrings';

const PaymentsTable = ({
  checkboxEnabled,
  columnsToDisplay,
  handleExportPayments,
  isRowSelectable,
  onSelectionModelChange,
  orgs,
  paymentGroupsState,
  paymentRecipientsDict,
  payments,
}) => {
  const g = global();

  const possibleColumns = {
    id: {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      valueGetter: (params) => shortenUUID(params.value),
      renderCell: (params) => (
        <Link to={`/payments/${params.row.id}`}>
          {shortenUUID(params.value)}
        </Link>
      ),
    },
    id_no_link: {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      valueGetter: (params) => shortenUUID(params.value),
    },
    payment_group_id: {
      field: 'payment_group_id',
      headerName: 'Campaign',
      flex: 1,
      valueGetter: (params) => getGroupValue(params.value, paymentGroups),
      renderCell: (params) =>
        renderGroupTitle(params, params.row.payment_group_id),
    },
    status: {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueGetter: (params) => paymentStatusValueMap[params.value],
      renderCell: (params) => renderPaymentStatusChip(params.row.status),
    },
    memo: {
      field: 'memo',
      headerName: 'Memo',
      renderCell: (params) => params.value || '-',
      flex: 1,
    },
    amount: {
      field: 'amount',
      headerName: 'Amount',
      disableColumnMenu: true,
      valueFormatter: (params) => formatCurrency(params.value),
      flex: 1,
    },
    recipient: {
      field: 'recipient',
      headerName: 'Recipient',
      flex: 1,
      valueGetter: (params) => {
        const recipient = paymentRecipientsDict[params.row.recipient] || {};
        return formatCreatorName(recipient);
      },
      renderCell: (params) => {
        const recipient = paymentRecipientsDict[params.row.recipient] || {};
        return renderRecipientCell(recipient);
      },
    },
    recipient_role: {
      field: 'recipient_role',
      headerName: "Recipient's role",
      flex: 1,
      renderCell: (params) => renderRecipientRole(params),
    },
    created_at: {
      field: 'created_at',
      type: 'date',
      headerName: 'Date created',
      renderCell: renderCreatedAtDate,
      valueFormatter: renderCreatedAtDate,
      flex: 1,
    },
    date_posted: {
      field: 'date_posted',
      type: 'date',
      headerName: 'Date posted',
      renderCell: renderPostDate,
      valueFormatter: renderPostDate,
      flex: 1,
    },
    org_id: {
      field: 'org_id',
      headerName: 'Company',
      flex: 2,
      renderCell: (params) => renderOrgCell(params, orgs),
      valueGetter: (params) => getOrgValue(params.value, orgs),
    },
    method: {
      field: 'method',
      headerName: 'Method',
      flex: 1,
      renderCell: renderPaymentMethodIcon,
    },
    link: {
      field: 'link',
      headerName: 'Link',
      flex: 1,
      renderCell: renderPaymentLinkIcon,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {handleExportPayments ? (
          <Button
            onClick={handleExportPayments}
            size="small"
            startIcon={<DownloadIcon />}
          >
            Export
          </Button>
        ) : (
          <GridToolbarExport csvOptions={{ fileName: 'payments_report' }} />
        )}
      </GridToolbarContainer>
    );
  };

  const { data: paymentGroups } = paymentGroupsState;

  const columns = columnsToDisplay.map((col) => possibleColumns[col]);

  return (
    <div className={g.tableWrapper}>
      <DataGrid
        autoHeight
        autoPageSize
        rows={payments}
        columns={columns}
        columnDependencies={[paymentRecipientsDict, paymentGroups]}
        checkboxSelection={checkboxEnabled}
        disableSelectionOnClick={checkboxEnabled}
        onSelectionModelChange={onSelectionModelChange}
        isRowSelectable={isRowSelectable}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  paymentRecipientsDict: paymentRecipientsDictSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
});

export default connect(mapStateToProps)(PaymentsTable);
