import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import css from 'classnames';
import { find, propEq } from 'ramda';
import { useParams, Link } from 'react-router-dom';
import { isInt } from 'validator';
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import PaymentIcon from '@mui/icons-material/Send';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import Select from 'components/Select';
import global from 'styles/global';

import { setHeaderData } from 'slices/misc';
import {
  invoicesSelector,
  getInvoices as getInvoicesSlice,
  invalidateInvoiceSelector,
  remindInvoicesSelector,
  invalidateInvoice as invalidateInvoiceSlice,
  remindInvoices as remindInvoicesSlice,
  updateInvoice as updateInvoiceSlice,
  updateInvoiceSelector,
} from 'slices/invoices';
import {
  getCustomers as getCustomersSlice,
  customersSelector,
  customersDictSelector,
} from 'slices/customers';
import {
  getCharges as getChargesSlice,
  chargesSelector,
  chargesByInvoiceSelector,
} from 'slices/charges';
import { emptyInvoiceData } from 'fixtures/invoices';
import { renderInvoiceStatusChip, renderChargeStatusChip } from 'util/table';
import { emptyCustomerData } from 'fixtures/customer';
import { addDaysToTime, getDelimDateFromDateObj } from 'util/time';
import { formatCurrency, shortenUUID } from 'util/renderStrings';
import {
  paymentGroupsSelector,
  getPaymentGroups as getPaymentGroupsSlice,
  paymentGroupDictSelector,
} from 'slices/paymentGroups';
import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import LinkPaymentGroupModal from 'components/LinkPaymentGroupModal';
import { paymentGroupTypes } from 'constants/paymentGroups';
import Card from 'components/Card';
import objectStates from 'constants/objectStates';
import KeyValuePair from 'components/KeyValuePair';
import {
  renderTerms,
  formatCreatorName,
  renderPaymentStatus,
} from 'util/renderStrings';
import { creatorsByPaymentIdSelector } from 'slices/multiSliceSelectors';
import { getCreators as getCreatorsSlice, creatorsSelector } from 'slices/user';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
} from 'slices/payments';
import EditCard from 'components/EditCard';
import TextField from 'components/TextField';
import DollarInput from 'components/DollarInput';
import { stringToNumberCents } from 'util/renderStrings';
import constants from 'constants/index';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.shades.white,
    padding: '8px',
    borderRadius: '50%',
  },
  metric: {
    width: '100%',
    marginLeft: theme.spacing(3),
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  value: {
    fontSize: '10px',
    color: theme.palette.shades.charcoal052,
  },
  disabled: { color: theme.palette.shades.charcoal036 },
}));

function Invoice({
  invoicesState,
  getInvoices,
  invalidateInvoice,
  invalidateInvoiceState,
  remindInvoicesState,
  sendReminders,
  customersDict,
  getCustomers,
  customersState,
  getCharges,
  chargesState,
  chargesByInvoice,
  paymentGroupsState,
  paymentGroupsDict,
  getPaymentGroups,
  updateInvoice,
  updateInvoiceState,
  getCreators,
  creatorsState,
  getPayments,
  paymentsState,
}) {
  const g = global();
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [paymentGroup, setPaymentGroup] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const { data: invoices, loading } = invoicesState;
  const { loading: customersLoading } = customersState;
  const { loading: chargesLoading } = chargesState;
  const { data: paymentGroups, paymentGroupsLoading } = paymentGroupsState;
  const { loading: creatorsLoading } = creatorsState;
  const { loading: paymentsLoading } = paymentsState;
  const pageLoading =
    loading ||
    customersLoading ||
    chargesLoading ||
    paymentGroupsLoading ||
    creatorsLoading ||
    paymentsLoading;

  const { loading: remindLoading } = remindInvoicesState;

  const { loading: invalidateLoading } = invalidateInvoiceState;
  const { loading: updateInvoiceLoading } = updateInvoiceState;

  const invoice = find(propEq('id', invoiceId), invoices) || emptyInvoiceData;

  const { title, description, amount, net } = invoice;
  const [newTitle, setNewTitle] = useState();
  const [newDescription, setNewDescription] = useState();
  const [newAmount, setNewAmount] = useState();
  const [newNet, setNewNet] = useState();

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: invoice.title,
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Invoices', link: '/invoices' },
          { label: invoice.title },
        ],
      })
    );
    setNewTitle(invoice.title);
    setNewDescription(invoice.description);
    setNewAmount(invoice.amount / 100);
    setNewNet(invoice.net);
  }, [invoice]);

  const cancelEditing = () => {
    setNewTitle(title);
    setNewDescription(description);
    setNewAmount(amount / 100);
    setNewNet(net);
  };

  useEffect(() => {
    getInvoices();
    getCustomers();
    getCharges();
    getPaymentGroups({ type: paymentGroupTypes.campaign });
    getCreators();
    getPayments();
  }, []);

  useEffect(() => {
    setPaymentGroup(
      paymentGroupsDict[invoice.payment_group_id] || emptyPaymentGroupData
    );
  }, [paymentGroupsDict, invoice.payment_group_id]);

  const creatorsByPayment = useSelector(creatorsByPaymentIdSelector);

  const handleRemindEmail = async () => {
    sendReminders([invoiceId], 'email');
  };

  const handleRemindSms = async () => {
    sendReminders([invoiceId], 'sms');
  };

  const handleUpdatePaymentGroup = async (paymentGroup) => {
    updateInvoice(invoice.id, {
      payment_group_id: paymentGroup.id,
    });
    setModalVisible(false);
  };

  const customer = customersDict[invoice.customer_id] || emptyCustomerData;
  const invoiceCharges = chargesByInvoice[invoice.id] || [];

  const handleUpdateInvoice = () => {
    setModalVisible(true);
  };

  const getIsPhoneNumberAvailable = () => {
    let customer_id = invoices.find((c) => c.id === invoiceId).customer_id;
    let customer = customersDict[customer_id] || emptyCustomerData;
    return !!customer.phone_number;
  };
  const invoiceIdentifier = shortenUUID(invoice.id);

  const invoiceIsPaid = invoice.status === objectStates.succeeded;

  const handleMarkPaid = async () => {
    const newStatus =
      invoice.status === objectStates.succeeded
        ? objectStates.requires_info
        : objectStates.succeeded;
    updateInvoice(invoiceId, {
      status: newStatus,
    });
  };

  const saveFields = () => {
    const updateFields = {
      title: newTitle,
      description: newDescription,
      amount: stringToNumberCents(newAmount),
      net: newNet,
    };
    updateInvoice(invoice.id, updateFields);
  };

  const amountCents = stringToNumberCents(newAmount);
  const amountValid = isInt(`${amountCents}`, {
    min: constants.invoiceAmtThreshold,
  });

  return (
    <div>
      <LinkPaymentGroupModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
        updateInvoices={handleUpdatePaymentGroup}
        paymentGroups={paymentGroups}
      />
      <LoadingOverlay open={pageLoading} />
      <div className={css(g.flexRow, g.justifyEnd, g.mb_sm)}>
        <Button
          type="submit"
          variant="outlined"
          color="secondary"
          disabled={invoice.status !== objectStates.requires_info}
          onClick={() => invalidateInvoice(invoiceId)}
          loading={invalidateLoading}
        >
          Cancel
        </Button>
        <Button
          className={g.ml_xs}
          onClick={handleRemindSms}
          type="submit"
          variant="outlined"
          color="primary"
          disabled={
            invoice.status !== objectStates.requires_info ||
            !getIsPhoneNumberAvailable()
          }
          loading={remindLoading}
        >
          Send SMS reminder
        </Button>
        <Button
          className={g.ml_xs}
          onClick={handleRemindEmail}
          type="submit"
          variant="outlined"
          color="primary"
          disabled={invoice.status !== objectStates.requires_info}
          loading={remindLoading}
        >
          Send email reminder
        </Button>
      </div>

      <Grid container spacing={1}>
        <Grid item sm={6}>
          {/* INVOICE INFO */}
          <EditCard
            title="Invoice info"
            handleCancel={cancelEditing}
            handleSubmit={saveFields}
            submitDisabled={!amountValid}
            disabled={invoice.status === objectStates.succeeded}
            staticContent={
              <>
                <KeyValuePair label="Invoice ID" value={invoiceIdentifier} />
                <KeyValuePair label="Title" value={invoice.title} />
                <KeyValuePair label="Description" value={invoice.description} />
                <KeyValuePair
                  label="Amount"
                  value={formatCurrency(invoice.amount)}
                />
                <KeyValuePair label="Terms" value={renderTerms(invoice.net)} />
                <KeyValuePair
                  label="Due Date"
                  value={
                    !!invoice.net
                      ? getDelimDateFromDateObj(
                          addDaysToTime(invoice.created_at, invoice.net)
                        )
                      : '-'
                  }
                />
                <KeyValuePair
                  label="Status"
                  value={renderInvoiceStatusChip(invoice.status)}
                  valueClasses={g.p_xs}
                />
                <KeyValuePair label="Processor" value={invoice.processor} />
              </>
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Title"
                  value={newTitle}
                  onChange={(evt) => setNewTitle(evt.target.value)}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Description"
                  value={newDescription}
                  onChange={(evt) => setNewDescription(evt.target.value)}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DollarInput
                  margin="dense"
                  className={g.mt_zero}
                  variant="outlined"
                  label="Amount"
                  value={newAmount}
                  error={amount.dirty && !amountValid}
                  helperText={amountValid ? '' : 'Invalid amount'}
                  onChange={(evt) => setNewAmount(evt.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={g.full_width}
                >
                  <InputLabel id="select-terms-label">Net</InputLabel>
                  <Select
                    labelId="select-terms-label"
                    label="Terms"
                    id="select-terms"
                    value={newNet}
                    onChange={(evt) => setNewNet(evt.target.value)}
                  >
                    <MenuItem value="due">Due upon invoice</MenuItem>
                    <MenuItem value="30">Net 30</MenuItem>
                    <MenuItem value="60">Net 60</MenuItem>
                    <MenuItem value="90">Net 90</MenuItem>
                    <MenuItem value="120">Net 120</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </EditCard>

          {/* RECIPIENT INFO */}
          <Card title="Recipient info">
            <KeyValuePair
              label="Name"
              value={
                <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
              }
            />
            <KeyValuePair label="Email" value={customer.email} />
            <KeyValuePair label="Attn" value={customer.attn} />
          </Card>

          {/* LINK CAMPAIGN  */}
          <Card title="Linked Campaign">
            {!!paymentGroup.id ? (
              <div className={classes.heading}>
                <Link to={`/campaigns/${paymentGroup.id}`}>
                  <Typography component="p" variant="body1">
                    {paymentGroup.title}
                  </Typography>
                </Link>
                <CloseIcon
                  onClick={() => handleUpdatePaymentGroup({id:''})}
                  className={css(g.clickable, g.error)}
                />
              </div>
            ) : (
              <div className={css(g.centerChildren)}>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(g.ml_sm, g.m_md)}
                  onClick={handleUpdateInvoice}
                  loading={updateInvoiceLoading}
                  fullWidth
                >
                  Link a Campaign
                </Button>
              </div>
            )}
          </Card>
        </Grid>

        {/* PAYMENT INFO */}
        <Grid item md={6}>
          <Card title="Payment info">
            {/* Empty State */}
            {invoice.processor !== 'offline' && invoiceCharges.length === 0 && (
              <div className={g.centerChildren}>
                <Typography variant="body2">No Payment</Typography>
              </div>
            )}
            {invoiceCharges.map((charge) => (
              <div className={g.mb_md}>
                <KeyValuePair
                  label="Date"
                  value={getDelimDateFromDateObj(charge.created_at)}
                />
                <KeyValuePair
                  label="Payment method"
                  value={
                    charge.charge_type.charAt(0).toUpperCase() +
                    charge.charge_type.slice(1)
                  }
                />
                <KeyValuePair
                  label="Total collected"
                  value={formatCurrency(charge.amount)}
                />
                <KeyValuePair label="Fee" value={formatCurrency(charge.fee)} />
                <KeyValuePair
                  label="Status"
                  value={renderChargeStatusChip(charge.status)}
                />
              </div>
            ))}
            {invoice.processor === 'offline' && (
              <div className={g.centerChildren}>
                <Button
                  onClick={handleMarkPaid}
                  color="primary"
                  variant={invoiceIsPaid ? 'outlined' : 'contained'}
                  loading={updateInvoiceLoading}
                >
                  {!invoiceIsPaid ? 'Mark as paid' : 'Mark as unpaid'}
                </Button>
              </div>
            )}
          </Card>

          {invoice.funded_payments && invoice.funded_payments.length > 0 && (
            <Card title="Funded payments">
              {invoice.funded_payments.map((payment) => (
                <div className={css(g.alignCenter, g.flexRowSpacing, g.pb_sm)}>
                  <div className={g.flexRow}>
                    <div className={css(g.centerChildren, g.ph_sm, g.mg_xxs)}>
                      <PaymentIcon />
                    </div>
                    <div>
                      <Link to={`/payments/${payment.id}`}>
                        <Typography variant="body1">
                          {`Payment for ${formatCreatorName(
                            creatorsByPayment[payment.id]
                          )}`}
                        </Typography>
                      </Link>
                      <Typography variant="subtitle1">
                        {`${renderPaymentStatus(payment.status)} -
                        ${formatCurrency(payment.amount)}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  invoicesState: invoicesSelector(state),
  invalidateInvoiceState: invalidateInvoiceSelector(state),
  remindInvoicesState: remindInvoicesSelector(state),
  customersState: customersSelector(state),
  customersDict: customersDictSelector(state),
  chargesState: chargesSelector(state),
  chargesByInvoice: chargesByInvoiceSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
  paymentGroupsDict: paymentGroupDictSelector(state),
  updateInvoiceState: updateInvoiceSelector(state),
  creatorsState: creatorsSelector(state),
  paymentsState: paymentsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getInvoices: (data) => dispatch(getInvoicesSlice(data)),
  invalidateInvoice: (id) => dispatch(invalidateInvoiceSlice(id)),
  sendReminders: (ids, method) => dispatch(remindInvoicesSlice(ids, method)),

  getCustomers: () => dispatch(getCustomersSlice()),
  getCharges: () => dispatch(getChargesSlice()),
  getPaymentGroups: (params) => dispatch(getPaymentGroupsSlice(params)),
  updateInvoice: (id, data) => dispatch(updateInvoiceSlice(id, data)),
  getCreators: () => dispatch(getCreatorsSlice()),
  getPayments: () => dispatch(getPaymentsSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
