import React from 'react';
import css from 'classnames';
import {
  Grid,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';

import global from 'styles/global';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  destructive: {
    color: theme.palette.error.main,
  },
}));

export default function NewCreatorPayment({
  vals,
  updateProp,
  deleteDeliverable,
  rowId,
}) {
  const g = global();
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        {/* qty input */}
        <Grid item sm={2} className={css(g.flexRow, g.alignCenter)}>
          <TextField
            type="number"
            margin="dense"
            className={g.m_zero}
            variant="outlined"
            label="Qty"
            value={vals.quantity}
            onChange={(evt) =>
              updateProp(
                rowId,
                evt.target.value,
                'deliverables',
                'quantity',
                vals.id
              )
            }
            fullWidth
          />
        </Grid>

        {/* description input */}
        <Grid item sm={6} className={css(g.flexRow, g.alignCenter)}>
          <TextField
            margin="dense"
            className={g.m_zero}
            variant="outlined"
            label="Description"
            placeholder="E.g. YouTube video, 1 min integration midroll"
            value={vals.description}
            onChange={(evt) =>
              updateProp(
                rowId,
                evt.target.value,
                'deliverables',
                'description',
                vals.id
              )
            }
            fullWidth
          />
        </Grid>

        <Grid item sm={4} className={css(g.flexRow, g.alignCenter)}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            label="Post date"
            type="date"
            fullWidth
            margin="dense"
            value={vals.postDate}
            onChange={(evt) =>
              updateProp(
                rowId,
                evt.target.value,
                'deliverables',
                'postDate',
                vals.id
              )
            }
          />
          <IconButton
            className={g.ml_zero}
            onClick={() => deleteDeliverable(rowId, vals.id)}
          >
            <CloseIcon className={classes.destructive} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}
