import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import global from 'styles/global';

const ExpiringLinkInvalid = () => {
  const g = global();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item sm={12} md={10}>
        <Grid container className={g.p_lg} spacing={6}>
          <Grid item xs={12} className={g.centerChildren}>
            <Typography variant="h5">
              Sorry! This link is no longer valid.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExpiringLinkInvalid;
