import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingOverlay from 'components/LoadingOverlay';
import InvoicesTable from 'components/InvoicesTable';
import TableHeader from 'components/TableHeader';
import { getInvoices, invoicesSelector } from 'slices/invoices';
import { setHeaderData } from 'slices/misc';
import { orgsSelector, getOrgs } from 'slices/org';

export default function EndUserInvoices() {
  const dispatch = useDispatch();
  const { loading: orgsLoading, data: orgs } = useSelector(orgsSelector);
  const { loading: invoicesLoading, data: invoices } =
    useSelector(invoicesSelector);
  const loading = invoicesLoading || orgsLoading;

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Invoices',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Invoices' },
        ],
      })
    );

    dispatch(getOrgs());
    dispatch(getInvoices());
  }, []);

  return (
    <div>
      <LoadingOverlay open={loading} />

      <TableHeader title="All Invoices" />

      <div>
        <InvoicesTable
          autoHeight
          autoPageSize
          orgs={orgs}
          invoices={invoices}
          columnsToDisplay={[
            'title',
            'amount',
            'net',
            'due_date',
            'status',
            'payment_group_id',
          ]}
        />
      </div>
    </div>
  );
}
