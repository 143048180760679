import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const createAgentMngr = async (state, data) => {
  return createRequest(`/v1/users`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const getAgentsMngrs = async (state) => {
  return createRequest(`/v1/users?isAgentManager=true`, state, {
    method: 'GET',
    vgs: true,
  }).then((res) => handleError(res));
};

export const updateAgentMngr = async (state, data) => {
  const { id, ...body } = data;
  return createRequest(`/v1/users/${id}`, state, {
    method: 'PUT',
    body,
    vgs: true,
  }).then((res) => handleError(res));
};

export const deleteAgentMngr = async (state, id) => {
  return createRequest(`/v1/users/${id}`, state, {
    method: 'DELETE',
  }).then((res) => handleError(res));
};
