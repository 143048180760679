import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLegend,
  VictoryStack,
  VictoryLabel,
} from 'victory';
import global, { colors } from 'styles/global';
import { formatCurrency } from 'util/renderStrings';

const useStyles = makeStyles((theme) => ({
  controls: { textAlign: 'right' },
}));

function Earnings({ invoiceTotals, paymentTotals }) {
  const g = global();
  const classes = useStyles();

  // x-axis:
  // 1: invoices
  // 2: payments
  // 3: net current
  // 4: net projected

  const pending = [
    { x: 1, y: invoiceTotals.pending / 100 },
    { x: 2, y: paymentTotals.pending / 100 },
  ];

  const completed = [
    { x: 1, y: invoiceTotals.completed / 100 },
    { x: 2, y: paymentTotals.completed / 100 },
  ];

  const isCurrentNegative = paymentTotals.completed > invoiceTotals.completed;

  const totalPayments = paymentTotals.completed + paymentTotals.pending;
  const totalInvoices = invoiceTotals.completed + invoiceTotals.pending;
  const isProjectedNegative = totalPayments > totalInvoices;

  const netCurrent = [
    {
      x: 3,
      y0: paymentTotals.completed / 100,
      y: invoiceTotals.completed / 100,
    },
  ];
  const netProjected = [
    { x: 4, y0: totalPayments / 100, y: totalInvoices / 100 },
  ];

  const getYAxisDollars = (y) => {
    const absY = Math.abs(y);
    if (absY < 10000) {
      return '$' + y;
    }
    return `$${y / 1000}k`;
  };

  return (
    <VictoryChart domainPadding={90}>
      <VictoryLegend
        x={75}
        centerTitle
        orientation="horizontal"
        gutter={20}
        data={[
          { name: 'Completed', symbol: { fill: colors.shades.charcoal } },
          { name: 'Incomplete', symbol: { fill: colors.custom.pending } },
          // { name: 'Profit', symbol: { fill: colors.success.main } },
          // { name: 'Loss', symbol: { fill: colors.error.main } },
        ]}
      />
      <VictoryAxis
        // tickValues={[1, 2, 3, 4]}
        tickValues={[1, 2]}
        tickFormat={[
          'Invoices',
          'Payments',
          // 'Net\n(current)',
          // 'Net\n(projected)',
        ]}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={getYAxisDollars}
        style={{
          grid: {
            stroke: colors.shades.charcoal020,
            strokeWidth: 0.5,
            strokeDasharray: '8, 6',
          },
        }}
      />
      <VictoryStack
        labels={({ datum }) => `${formatCurrency(datum.y * 100)}`} // <-- important
        labelComponent={<VictoryLabel dy={-5} />}
      >
        <VictoryBar
          alignment="middle"
          data={completed}
          barRatio={1}
          // barWidth={20}
          style={{ data: { fill: colors.shades.charcoal } }}
        />
        <VictoryBar
          alignment="middle"
          data={pending}
          barRatio={1}
          // barWidth={20}
          style={{ data: { fill: colors.custom.pending } }}
        />
      </VictoryStack>
      {/* <VictoryBar
        data={netCurrent}
        barWidth={20}
        style={{
          data: {
            fill: isCurrentNegative ? colors.error.main : colors.success.main,
          },
        }}
      />
      <VictoryBar
        data={netProjected}
        barWidth={20}
        style={{
          data: {
            fill: isProjectedNegative ? colors.error.main : colors.success.main,
          },
        }}
      /> */}
    </VictoryChart>
  );
}

export default Earnings;
