import React, { useState, useEffect } from 'react';
import css from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { isEmpty } from 'ramda';
import { isInt, isEmail } from 'validator';
import qs from 'query-string';
import CloseIcon from '@mui/icons-material/Close';

import { setHeaderData } from 'slices/misc';
import {
  getCustomers as getCustomersSlice,
  customersSelector,
} from 'slices/customers';
import {
  createInvoice as createInvoiceSlice,
  createInvoicesSelector,
} from 'slices/invoices';
import { getCreators } from 'slices/user';
import { getPayments } from 'slices/payments';
import {
  getOrgFees as getOrgFeesSlice,
  orgFeesSelector,
  cardInvoiceFeeSelector,
  bankInvoiceFeeSelector,
} from 'slices/orgFees';
import { quickbooksSelector, getConnections } from 'slices/orgOauth';
import { paymentsForCampaignWithCreatorsSelector } from 'slices/multiSliceSelectors';
import { getPaymentGroups, paymentGroupsSelector } from 'slices/paymentGroups';
import global from 'styles/global';
import DollarInput from 'components/DollarInput';
import Button from 'components/Button';
import TransferList from 'components/TransferList';
import TableHeader from 'components/TableHeader';
import LoadingOverlay from 'components/LoadingOverlay';
import { formatCurrency, stringToNumberCents } from 'util/renderStrings';
import constants from 'constants/index';
import { calculateFee } from 'util/payments';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';
import Select from 'components/Select';
import Card from 'components/Card';
import AttachmentModal from 'components/AttachmentModal';
import { getIsDev } from 'util/env';

const NewInvoice = ({
  customersState,
  getCustomers,
  createInvoice,
  createInvoicesState,
  getOrgFees,
  orgFeesState,
  cardInvoiceFee,
  bankInvoiceFee,
}) => {
  const dispatch = useDispatch();
  const g = global();
  const history = useHistory();

  const { customerId, campaignId } = qs.parse(history.location.search);

  const { loading: orgFeesLoading } = orgFeesState;
  const { data: customers, loading: customersLoading } = customersState;
  const qboConnection = useSelector(quickbooksSelector);
  const { data: campaigns, loading: campaignsLoading } = useSelector(
    paymentGroupsSelector
  );

  const isDev = getIsDev();
  const loading = customersLoading || orgFeesLoading || campaignsLoading;

  const { loading: createInvoicesLoading } = createInvoicesState;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [attn, setAttn] = useState('');
  const [amount, setAmount] = useState({ val: '', dirty: false });
  const [customer, setCustomer] = useState('');
  const [customerInput, setCustomerInput] = useState('');
  const [campaign, setCampaign] = useState('');
  const [net, setNet] = useState('30');
  const [allowBank, setAllowBank] = useState(true);
  const [allowCard, setAllowCard] = useState(false);
  const [ccEmails, setCCEmails] = useState('');
  const [collectViaStripe, setCollectViaStripe] = useState(true);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false);
  const [attachment, setAttachment] = useState();
  const [showCustomNet, setShowCustomNet] = useState(false);
  const [customNet, setCustomNet] = useState('');

  const payments = useSelector((state) =>
    paymentsForCampaignWithCreatorsSelector(state, campaign?.id)
  );

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'New Invoice',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Invoices', link: '/invoices' },
          { label: 'New Invoice' },
        ],
      })
    );

    getCustomers();
    getOrgFees();
    dispatch(getPaymentGroups());
    dispatch(getPayments());
    dispatch(getCreators());
    dispatch(getConnections());
  }, []);

  useEffect(() => {
    if (!!customerId) {
      const defaultCustomer = customers.find((c) => customerId === c.id) || {};
      setCustomer(defaultCustomer);
    }
  }, [customers, customerId]);

  useEffect(() => {
    if (!!campaignId) {
      const defaultCampaign =
        campaigns.find((campaign) => campaign.id === campaignId) || {};
      const defaultCustomer =
        customers.find(
          (customer) => defaultCampaign.customer_id === customer.id
        ) || {};
      setCustomer(defaultCustomer);
      setCampaign(defaultCampaign);
      setNet(defaultCampaign.terms);
      setTitle(defaultCampaign.title);
      if (!!defaultCampaign.deal_size)
        setAmount({ val: defaultCampaign.deal_size / 100, dirty: true });
    }
  }, [campaigns, customers, campaignId]);

  const createInvoiceAndNavigate = async () => {
    const invoicePayload = {
      title,
      description,
      amount: amountCents,
      net: net != 'custom' ? net : customNet,
    };

    // create email attachment if user has selected one
    if (!!attachment) {
      invoicePayload.attachment = attachment.url;
    }

    if (!!campaign) {
      invoicePayload.payment_group_id = campaign.id;
    }
    if (typeof customer === 'string') {
      invoicePayload.email = customerInput;
      invoicePayload.name = name;
      invoicePayload.attn = attn;
    } else {
      invoicePayload.customer_id = customer.id;
    }
    if (collectViaStripe) {
      invoicePayload.processor = 'stripe';
      const disallowedMethods = [];
      if (!allowBank) disallowedMethods.push('bank');
      if (!allowCard) disallowedMethods.push('card');
      invoicePayload.disallowed_methods = disallowedMethods;
      invoicePayload.cc_emails = parseEmails(ccEmails);
    } else {
      invoicePayload.processor = 'offline';
    }

    if (!!qboConnection) {
      const fundedPayments = right.map((num) => payments[num].id);
      invoicePayload.funded_payments = fundedPayments;
    }
    createInvoice(invoicePayload, () => history.push('/invoices'));
  };

  const handleCustomerChange = (_, value) => {
    setCustomer(value || '');
    if (!!value) setCustomerInput(value);
  };

  const handleCustomerInputChange = (_, val) => {
    setCustomerInput(val);
  };

  const handleNetChange = (evt) => {
    if (evt.target.value == 'custom') {
      setShowCustomNet(true);
    } else if (evt.target.value != 'custom') {
      setShowCustomNet(false);
      setCustomNet('');
    }
    setNet(evt.target.value);
  };

  const amountCents = stringToNumberCents(amount.val);

  const amountValid = isInt(`${amountCents}`, {
    min: constants.invoiceAmtThreshold,
  });

  const bankFee = calculateFee(
    amountCents,
    bankInvoiceFee.flat_rate,
    bankInvoiceFee.percentage,
    bankInvoiceFee.cap || Infinity
  );
  const cardFee = calculateFee(
    amountCents,
    cardInvoiceFee.flat_rate,
    cardInvoiceFee.percentage,
    cardInvoiceFee.cap || Infinity
  );

  const validCustomNet =
    net == 'custom' &&
    !isEmpty(customNet) &&
    customNet > 0 &&
    customNet <= 365 &&
    !isNaN(customNet);

  const parseEmails = (emails) => {
    return emails.replace(/\s/g, '').split(',');
  };

  const isValid =
    amountValid &&
    !isEmpty(title) && // title required
    !isEmpty(description) && // description required
    (allowBank || allowCard) && // one payment method at least must be allowed
    (!isEmpty(customerInput) || isEmail(customerInput)) && // customer required
    (!isEmpty(name) || !isEmpty(customer.name)) && // customer name require
    (ccEmails === '' ||
      parseEmails(ccEmails).every((ccEmail) => isEmail(ccEmail))) && // cc emails must be empty or all valid emails
    (validCustomNet || net != 'custom');

  return (
    <div>
      <LoadingOverlay open={loading} />

      <AttachmentModal
        setAttachment={setAttachment}
        attachment={attachment}
        open={attachmentModalVisible}
        onClose={() => setAttachmentModalVisible(false)}
      />

      <TableHeader>
        <Button
          size="small"
          onClick={createInvoiceAndNavigate}
          variant="contained"
          color="primary"
          loading={createInvoicesLoading}
          disabled={!isValid}
        >
          Send Invoice
        </Button>
      </TableHeader>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card title="Recipient info">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  isForwardRef
                  className={g.mb_zero}
                  options={customers}
                  freeSolo
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option.email || '';
                  }}
                  value={customer}
                  inputValue={customerInput}
                  onChange={handleCustomerChange}
                  onInputChange={handleCustomerInputChange}
                  textInputProps={{
                    label: 'Recipient email',
                    required: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Recipient name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={typeof customer === 'string' ? name : customer.name}
                  disabled={typeof customer !== 'string'}
                  onChange={(evt) => setName(evt.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  options={campaigns}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.title || ''}
                  onChange={(_, value) => setCampaign(value)}
                  value={campaign}
                  textInputProps={{
                    label: 'Campaign',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Recipient attn"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={typeof customer === 'string' ? attn : customer.attn}
                  disabled={typeof customer !== 'string'}
                  onChange={(evt) => setAttn(evt.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="CC emails"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={
                    typeof customer === 'string'
                      ? ccEmails
                      : (customer.cc_emails || []).join(',')
                  }
                  disabled={typeof customer !== 'string'}
                  helperText="Comma-separated list of emails"
                  onChange={(evt) => setCCEmails(evt.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                {!attachment ? (
                  <Button
                    size="small"
                    onClick={() => setAttachmentModalVisible(true)}
                    variant="outlined"
                    color="primary"
                    loading={createInvoicesLoading}
                  >
                    Add email attachment
                  </Button>
                ) : (
                  <div className={g.flexRow}>
                    <Typography variant="h5">{attachment.name}</Typography>

                    <CloseIcon
                      className={css(g.clickable, g.error)}
                      onClick={() => setAttachment()}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card className={g.p_md} title="Invoice Info">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Invoice title"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={title}
                  onChange={(evt) => setTitle(evt.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Invoice description"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={description}
                  onChange={(evt) => setDescription(evt.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <DollarInput
                  margin="dense"
                  className={g.mt_zero}
                  variant="outlined"
                  label="Amount"
                  value={amount.val}
                  error={amount.dirty && !amountValid}
                  helperText={
                    !amount.dirty || amountValid ? '' : 'Invalid amount'
                  }
                  onChange={(evt) => {
                    setAmount({ val: evt.target.value, dirty: true });
                  }}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={g.full_width}
                >
                  <InputLabel id="select-terms-label">Net</InputLabel>
                  <Select
                    labelId="select-terms-label"
                    label="Terms"
                    id="select-terms"
                    value={net}
                    onChange={handleNetChange}
                  >
                    <MenuItem value="due">Due upon invoice</MenuItem>
                    <MenuItem value="30">Net 30</MenuItem>
                    <MenuItem value="60">Net 60</MenuItem>
                    <MenuItem value="90">Net 90</MenuItem>
                    <MenuItem value="120">Net 120</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: showCustomNet ? 'block' : 'none' }}
              >
                <TextField
                  label="Custom Net Value"
                  helperText={
                    customNet && !validCustomNet
                      ? 'Invalid value - Positive number less than or equal to 365'
                      : 'Positive number less than or equal to 365'
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={customNet}
                  error={customNet && !validCustomNet}
                  onChange={(evt) => setCustomNet(evt.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={collectViaStripe}
                      onChange={(evt) =>
                        setCollectViaStripe(evt.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Collect payment with Stripe"
                />
              </Grid>
              {collectViaStripe && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Allowable Payment Methods
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allowBank}
                          onChange={(evt) => {
                            setAllowBank(evt.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <div>
                          <Typography>Bank</Typography>
                          <Typography variant="subtitle1">{`Fee: ${formatCurrency(
                            bankFee
                          )}`}</Typography>
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allowCard}
                          onChange={(evt) => setAllowCard(evt.target.checked)}
                          color="primary"
                        />
                      }
                      label={
                        <div>
                          <Typography>Card</Typography>
                          <Typography variant="subtitle1">{`Fee: ${formatCurrency(
                            cardFee
                          )}`}</Typography>
                        </div>
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {!!qboConnection && (
        <>
          <div className={g.mt_xxl}></div>

          <TableHeader
            title="What Payments does this Invoice fund?"
            tooltipText="If you're integrated with Quickbooks, we'll need to know which Payments to a Creator you'll be funding with the proceeds from this Invoice. If the earnings from the Payments don't match up with the Invoice amount then the QuickBooks journal entries will fail to be created"
          />

          <div className={g.mt_md}></div>

          <Grid container spacing={3}>
            <TransferList
              payments={payments}
              left={left}
              right={right}
              setLeft={setLeft}
              setRight={setRight}
            />
          </Grid>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  customersState: customersSelector(state),
  createInvoicesState: createInvoicesSelector(state),
  orgFeesState: orgFeesSelector(state),
  cardInvoiceFee: cardInvoiceFeeSelector(state),
  bankInvoiceFee: bankInvoiceFeeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(getCustomersSlice()),
  createInvoice: (data, cb) => dispatch(createInvoiceSlice(data, cb)),
  getOrgFees: () => dispatch(getOrgFeesSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);
