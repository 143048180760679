import React, { useState } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ContractIcon from '@mui/icons-material/Assignment';
import PaymentIcon from '@mui/icons-material/Send';
import InvoiceIcon from '@mui/icons-material/Receipt';
import ButtonMenu from 'components/ButtonMenu';
import Card from 'components/Card';
import Button from 'components/Button';
import {
  todoListSelector,
  creatorsByPaymentIdSelector,
} from 'slices/multiSliceSelectors';
import { remindContracts } from 'slices/contracts';
import { remindInvoices } from 'slices/invoices';
import { remindPayments } from 'slices/payments';
import global from 'styles/global';
import { formatCurrency, formatCreatorName } from 'util/renderStrings';

const todoIconMap = {
  contract: <ContractIcon />,
  payment: <PaymentIcon />,
  invoice: <InvoiceIcon />,
};

const todoTitleMap = {
  contract: 'Unsigned Contract',
  payment: 'Payment needs payout method',
  invoice: 'Unpaid Invoice',
};

const getSubtitle = (todoItem, creatorsByPayment) => {
  switch (todoItem.todoType) {
    case 'contract':
      return <Link to={`/contracts/${todoItem.id}`}>{todoItem.title}</Link>;
    case 'payment':
      return (
        <Link to={`/payments/${todoItem.id}`}>
          {formatCreatorName(creatorsByPayment[todoItem.id])} -{' '}
          {formatCurrency(todoItem.amount)}
        </Link>
      );
    case 'invoice':
      return (
        <Link to={`/invoices/${todoItem.id}`}>
          {todoItem.title} - {formatCurrency(todoItem.amount)}
        </Link>
      );
    default:
      return 'default';
  }
};

function TodoList() {
  const dispatch = useDispatch();
  const g = global();
  const todoList = useSelector(todoListSelector);
  const creatorsByPayment = useSelector(creatorsByPaymentIdSelector);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleReminder = async (todoItem, method) => {
    switch (todoItem.todoType) {
      case 'invoice':
        return dispatch(remindInvoices([todoItem.id], method));
      case 'contract':
        return dispatch(remindContracts([todoItem.id], method));
      case 'payment':
        return dispatch(remindPayments([todoItem.id], method));
      default:
        return;
    }
  };

  let filteredList = todoList;
  if (!isExpanded) {
    filteredList = todoList.slice(0, 5);
  }

  return (
    <Card title="To-do list">
      {todoList.length === 0 && (
        <Typography className={css(g.flexRow, g.centered)} variant="body1">
          No outstanding tasks
        </Typography>
      )}
      {filteredList.map((todoItem) => (
        <div className={css(g.alignCenter, g.flexRowSpacing, g.pb_sm)}>
          <div className={g.flexRow}>
            <div className={css(g.centerChildren, g.ph_sm, g.mg_xxs)}>
              {todoIconMap[todoItem.todoType]}
            </div>
            <div>
              <Typography variant="body1">
                {todoTitleMap[todoItem.todoType]}
              </Typography>
              <Typography variant="subtitle1">
                {getSubtitle(todoItem, creatorsByPayment)}
              </Typography>
            </div>
          </div>

          <ButtonMenu
            className={g.mt_zero}
            variant="outlined"
            size="small"
            options={[
              {
                label: 'Remind via SMS',
                handler: () => {
                  handleReminder(todoItem, 'sms');
                },
                disabled: !todoItem.hasPhone,
              },
              {
                label: 'Remind via email',
                handler: () => {
                  handleReminder(todoItem, 'email');
                },
              },
            ]}
          >
            Send reminder
          </ButtonMenu>
        </div>
      ))}
      {todoList.length > 5 && (
        <div className={css(g.flexRow, g.centered)}>
          <Button
            onClick={() => setIsExpanded(!isExpanded)}
            color="primary"
            variant="text"
          >
            {isExpanded ? 'See less' : 'See all'}
          </Button>
        </div>
      )}
    </Card>
  );
}

export default TodoList;
