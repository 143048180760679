import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import {
  updateCreatorSelector,
  updateCreator,
  creatorSelector,
} from 'slices/user';
import { currentOrgSelector } from 'slices/org';
import EditCard from 'components/EditCard';
import PercentInput from 'components/PercentInput';
import KeyValuePair from 'components/KeyValuePair';

export default function SplitsTab() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { split } = useSelector((state) => creatorSelector(state, id));
  const updateCreatorState = useSelector(updateCreatorSelector);
  const { data: currentOrg } = useSelector(currentOrgSelector);

  const [newSplit, setNewSplit] = useState(100);

  useEffect(() => {
    setNewSplit(split);
  }, [split]);

  const updateSplit = () => {
    dispatch(
      updateCreator({
        id,
        split: +newSplit,
      })
    );
  };

  const cancelEditing = () => {
    setNewSplit(split);
  };

  return (
    <Grid container spacing={2}>
      {/* Left column */}
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EditCard
              title="Campaign Splits"
              handleSubmit={updateSplit}
              handleCancel={cancelEditing}
              staticContent={
                <>
                  <KeyValuePair
                    label="Creator's Split"
                    value={`${newSplit} %`}
                  />
                  <KeyValuePair
                    label={`${currentOrg.name}'s Split`}
                    value={`${100 - newSplit} %`}
                  />
                </>
              }
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <PercentInput
                    label="Creator's split"
                    value={newSplit}
                    onChange={(evt) => setNewSplit(evt.target.value)}
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </EditCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
