import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Button';

import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

const ChangePasswordModal = ({ open, onClose, handleUpdatePassword, updatePasswordState }) => {
  const classes = useStyles();

  const { loading } = updatePasswordState;

  const [curPassword, setCurPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    //TODO: more validation

    if (newPassword !== confirmNewPassword) {
      setError('Passwords must match');
    } else {
      setError('');
    }
  }, [newPassword, confirmNewPassword]);

  const handleClose = () => {
    setCurPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    onClose();
  };

  const handleResetPassword = async () => {
    const isComplete = await handleUpdatePassword({
      current_password: curPassword,
      new_password: newPassword,
    });
    if (isComplete) {
      onClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Change password</Typography>
      </ModalTitle>
      <ModalContent>
        <TextField
          margin="dense"
          variant="outlined"
          label="Current password"
          type="password"
          onChange={(evt) => setCurPassword(evt.target.value)}
          autoFocus
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="New password"
          type="password"
          onChange={(evt) => setNewPassword(evt.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="Confirm new password"
          type="password"
          onChange={(evt) => setConfirmNewPassword(evt.target.value)}
          fullWidth
        />
      </ModalContent>
      <div> {error && <p className={classes.errorMessage}>{error}</p>}</div>
      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!!error}
          loading={loading}
          onClick={handleResetPassword}
        >
          Submit
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ChangePasswordModal;
