import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
} from '@material-ui/core';
import css from 'classnames';

import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import global from 'styles/global';
import { customersSelector, customersDictSelector } from 'slices/qb';
import { useSelector } from 'react-redux';
import Select from 'components/Select';

function Details({ customer, updateCustomer }) {
  const g = global();

  const { id, qb_customer } = customer;

  const { data: customers = [] } = useSelector(customersSelector);

  const customersDict = useSelector(customersDictSelector);

  const [newQbCustomer, setNewQbCustomer] = useState('');

  useEffect(() => {
    if (!!qb_customer) {
      setNewQbCustomer(JSON.parse(qb_customer).Id);
    }
  }, [qb_customer]);

  const cancelEditing = () => {
    setNewQbCustomer(qb_customer);
  };

  const handleUpdateCustomer = async () => {
    const updateObj = {
      id,
      qb_customer: JSON.stringify(customersDict[newQbCustomer]),
    };
    await updateCustomer(updateObj);
  };

  return (
    <EditCard
      title="Quickbooks"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdateCustomer}
      staticContent={
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <KeyValuePair
              label="Customer"
              value={JSON.parse(qb_customer || '{}').DisplayName || 'No customer'}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl
            size="small"
            variant="outlined"
            className={css(g.mt_xs, g.full_width)}
          >
            <InputLabel id="customer-label">Customer</InputLabel>
            <Select
              labelId="customer-label"
              label="Customer"
              id="customer"
              value={newQbCustomer}
              onChange={(evt) => setNewQbCustomer(evt.target.value)}
            >
              {customers.map((customer) => (
                <MenuItem key={customer.Id} value={customer.Id}>
                  {customer.DisplayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default Details;
