import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'ramda';
import isEmail from 'validator/lib/isEmail';

import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import global from 'styles/global';
import PhoneInput from 'components/PhoneInput';
import TextField from 'components/TextField';

function Details({ customer, updateCustomer }) {
  const g = global();

  const { name, email, attn, id, cc_emails, phone_number } = customer;

  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newAttn, setNewAttn] = useState('');
  const [newCCEmails, setNewCCEmails] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');

  useEffect(() => {
    setNewName(name);
    setNewEmail(email);
    setNewAttn(attn);
    setNewCCEmails(cc_emails?.join(',') || '');
    setNewPhoneNumber(phone_number);
  }, [name, email, attn, cc_emails, phone_number]);

  const cancelEditing = () => {
    setNewName(name);
    setNewEmail(email);
    setNewAttn(attn);
    setNewCCEmails(cc_emails?.join(',')  || '');
    setNewPhoneNumber(phone_number);
  };

  const handleUpdateCustomer = async () => {
    const updateObj = {
      id,
      name: newName,
      email: newEmail,
      attn: newAttn,
      cc_emails: newCCEmails.split(','),
      phone_number: newPhoneNumber,
    };
    await updateCustomer(updateObj);
  };

  const isInputValid =
    !isEmpty(newName) && // name required
    (!isEmpty(newEmail) || isEmail(newEmail)) && // email required
    (newCCEmails === '' ||
      newCCEmails?.split(',').every((ccEmail) => isEmail(ccEmail))); // cc emails must be empty or all valid emails

  return (
    <EditCard
      title="Details"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdateCustomer}
      submitDisabled={!isInputValid}
      staticContent={
        <>
            <KeyValuePair label="Name" value={name} />
            <KeyValuePair label="Attn" value={attn} />
            <KeyValuePair label="Email" value={email} />
            <KeyValuePair label="Phone number" value={phone_number} />
            <KeyValuePair label="CC emails" value={cc_emails?.join(',')} />
        </>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            variant="outlined"
            margin="dense"
            value={newName}
            onChange={(evt) => setNewName(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Attn"
            variant="outlined"
            margin="dense"
            value={newAttn}
            onChange={(evt) => setNewAttn(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="outlined"
            margin="dense"
            value={newEmail}
            onChange={(evt) => setNewEmail(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            margin="dense"
            variant="outlined"
            label="Phone number"
            value={newPhoneNumber}
            onChange={(evt) => setNewPhoneNumber(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={g.mt_xs}
            label="CC emails"
            variant="outlined"
            fullWidth
            size="small"
            value={newCCEmails}
            helperText="Comma-separated list of emails"
            onChange={(evt) => setNewCCEmails(evt.target.value)}
          />
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default Details;
