import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import { invoicesSelector } from 'slices/invoices';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import global from 'styles/global';
import InvoicesTable from 'components/InvoicesTable';

export default function InvoicesTab() {
  const g = global();
  const { groupId } = useParams();
  const group = useSelector((state) => {
    return (
      state.paymentGroups.paymentGroups.data.find(
        (grp) => grp.id === groupId
      ) || emptyPaymentGroupData
    );
  });
  const history = useHistory();

  const { data: invoices } = useSelector(invoicesSelector);

  return (
    <>
      <TableHeader title={`Invoices in ${group.title}`}>
        <Button
          size="small"
          className={g.ml_xs}
          variant="contained"
          color="primary"
          onClick={() =>
            history.push(`/invoices/newInvoice?campaignId=${groupId}`)
          }
        >
          Create Invoice
        </Button>
      </TableHeader>
      <div className={g.tableWrapper}>
        <InvoicesTable
          variant="outlined"
          autoHeight
          autoPageSize
          invoices={invoices}
          columnsToDisplay={['title', 'customer', 'amount', 'status']}
        />
      </div>
    </>
  );
}
