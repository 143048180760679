import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DataGrid from 'components/DataGrid';
import global from 'styles/global';
import { formatCreatorName } from 'util/renderStrings';
import {
  renderContractTitle,
  contractStatusValueMap,
  renderContractStatusChip,
} from 'util/table';
import { getDateAndTimeFromIso } from 'util/time';
import { creatorsDictSelector } from 'slices/user';
import { customersDictSelector } from 'slices/customers';
import { emptyCreatorData } from 'fixtures/creatorData';
import { emptyCustomerData } from 'fixtures/customer';

const ContractsTable = ({
  contracts,
  columnsToDisplay,
  checkboxEnabled,
  onSelectionModelChange,
  isRowSelectable,
}) => {
  const g = global();

  const creatorsDict = useSelector(creatorsDictSelector);
  const customersDict = useSelector(customersDictSelector);

  const possibleColumns = {
    title: {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => renderContractTitle(params),
    },
    recipient_type: {
      field: 'recipient_type',
      headerName: 'Recipient Type',
      renderCell: (params) =>
        params.value === 'creator' ? 'Creator' : 'Customer',
      flex: 1,
    },
    recipient_id: {
      field: 'recipient_id',
      headerName: 'Recipient',
      valueGetter: (params) => {
        let recipient = null;
        if (params.row.recipient_type === 'creator') {
          recipient = creatorsDict[params.row.recipient_id] || emptyCreatorData;
          return formatCreatorName(recipient);
        } else {
          recipient = customersDict[params.row.recipient_id] || emptyCustomerData;
          return formatCreatorName(recipient);
        }
      },
      renderCell: (params) => {
        let recipient = null;
        if (params.row.recipient_type === 'creator') {
          recipient = creatorsDict[params.row.recipient_id] || emptyCreatorData;
          return (
            <Link to={`/creators/${recipient.id}`}>
              {formatCreatorName(recipient)}
            </Link>
          );
        } else {
          recipient = customersDict[params.row.recipient_id] || emptyCustomerData;
          return (
            <Link to={`/customers/${recipient.id}`}>{recipient.name}</Link>
          );
        }
      },
      flex: 1,
    },
    status: {
      field: 'status',
      headerName: 'Status',
      valueGetter: (params) =>
        contractStatusValueMap[params.value] || 'Uploaded',
      renderCell: (params) =>
        renderContractStatusChip(params.row.type, params.row.status),
      flex: 1,
      disableColumnMenu: true,
    },
    signature_date: {
      field: 'signature_date',
      headerName: 'Date signed',
      renderCell: (params) => {
        if (!params.value) return '-';
        return getDateAndTimeFromIso(params.value);
      },
      flex: 1,
      disableColumnMenu: true,
    },
  };
  const columns = columnsToDisplay.map((col) => possibleColumns[col]);

  return (
    <div className={g.tableWrapper}>
      <DataGrid
        autoHeight
        autoPageSize
        rows={contracts}
        columns={columns}
        columnDependencies={[creatorsDict, customersDict, contracts]}
        checkboxSelection={checkboxEnabled}
        disableSelectionOnClick={checkboxEnabled}
        onSelectionModelChange={onSelectionModelChange}
        isRowSelectable={isRowSelectable}
      />
    </div>
  );
};

export default connect()(ContractsTable);
