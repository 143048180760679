import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import {
  updateCreatorSelector,
  updateCreator,
  creatorSelector,
} from 'slices/user';
import { paypalEnabledSelector } from 'slices/paypal';
import {
  creatorTagsSelector,
  tagOptionsSelector,
  addTagToCreator as addTagToCreatorSlice,
  removeTagFromCreator as removeTagFromCreatorSlice,
  createTag as createTagSlice,
  createTagSelector,
  removeTagFromCreatorSelector,
  addTagToCreatorSelector,
} from 'slices/tags';
import Card from 'components/Card';
import UserDetails from 'components/UserDetails';
import TagEditor from 'components/TagEditor';

import DirectDeposit from './DirectDeposit';
import Notes from './Notes';
import Contact from './Contact';
import Platforms from './Platforms';
import Status from './Status';
import Manager from './Manager';
import QuickbooksAccount from './QuickbooksAccount';
import { quickbooksSelector } from 'slices/orgOauth';

function GeneralTab() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const creator = useSelector((state) => creatorSelector(state, id));
  const creatorTags = useSelector((state) => creatorTagsSelector(state, id));
  const { data: tagOptions } = useSelector((state) =>
    tagOptionsSelector(state)
  );
  const updateCreatorState = useSelector(updateCreatorSelector);
  const isPayPalEnabled = useSelector((state) => paypalEnabledSelector(state));
  const qboConnection = useSelector(quickbooksSelector);
  const addTagToCreator = (val) =>
    dispatch(addTagToCreatorSlice([{ tag_id: val, creator_id: creator.id }]));
  const { loading: addTagLoading } = useSelector(addTagToCreatorSelector);
  const createTag = (val) => dispatch(createTagSlice([{ label: val }]));
  const { loading: createTagLoading } = useSelector(createTagSelector);
  const removeTag = (val) =>
    dispatch(removeTagFromCreatorSlice([val], creator.id));
  const { loading: removeTagLoading } = useSelector(
    removeTagFromCreatorSelector
  );

  return (
    <Grid container spacing={2}>
      {/* Left column */}
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserDetails
              creator={creator}
              updateCreator={updateCreator}
              isAgentMangerDetail={false}
            />
          </Grid>

          <Grid item xs={12}>
            <Manager creator={creator} updateCreator={updateCreator} />
          </Grid>

          <Grid item xs={12}>
            <TagEditor
              currentTags={creatorTags}
              tagOptions={tagOptions}
              addTag={(val) => addTagToCreator(val)}
              createTag={(val) => createTag(val)}
              removeTag={(val) => removeTag(val)}
              disabled={createTagLoading || addTagLoading || removeTagLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <Platforms creator={creator} updateCreator={updateCreator} />
          </Grid>

          <Grid item xs={12}>
            <Notes creator={creator} updateCreator={updateCreator} />
          </Grid>
        </Grid>
      </Grid>

      {/* Right Column */}
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Status creator={creator} updateCreator={updateCreator} />
          </Grid>

          <Grid item xs={12}>
            <Card title="Payout methods">
              <DirectDeposit
                creator={creator}
                updateCreator={updateCreator}
                updateCreatorState={updateCreatorState}
                isPayPalEnabled={isPayPalEnabled}
              />
            </Card>
          </Grid>

          {!!qboConnection && (
            <Grid item xs={12}>
              <QuickbooksAccount
                creator={creator}
                updateCreator={updateCreator}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Contact creator={creator} updateCreator={updateCreator} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GeneralTab;
