import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { find, propEq } from 'ramda';

import { getOrg, getOrgs } from 'slices/org';
import {
  signin as signinAPI,
  signup as signupAPI,
  getUser as getUserAPI,
  addCreators as addCreatorsAPI,
  getCreators as getCreatorsAPI,
  updateCreator as updateCreatorAPI,
  updatePassword as updatePasswordAPI,
  updateBankDetailsExpiringLink as updateBankDetailsExpiringLinkAPI,
  sendPasswordReset as sendPasswordResetAPI,
  createNewPassword as createNewPasswordAPI,
  addCreatorsBulk as addCreatorsBulkAPI,
  getMembers as getMembersAPI,
  inviteMember as inviteMemberAPI,
  deleteMember as deleteMemberAPI,
  updateUser as updateUserAPI,
  loginAs as loginAsAPI,
} from 'api/user';
import { emptyUserData, demoUserData } from 'fixtures/user';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import { getAgentsMngrs } from 'slices/agentsMngrs';

export const usersInitialState = {
  signup: getGenericState(),
  signin: getGenericState(),
  loginAs: getGenericState(),
  currentUser: getGenericState(emptyUserData),
  getCreators: getGenericState(),
  updateUser: getGenericState(),
  creators: getGenericState([]),
  getUser: getGenericState(),
  addCreators: getGenericState(),
  addCreatorsBulk: getGenericState(),
  updateCreator: getGenericState(),
  updatePassword: getGenericState(),
  updateBankDetails: getGenericState(),
  sendPasswordReset: getGenericState(),
  createNewPassword: getGenericState(),
  getMembers: getGenericState([]),
  inviteMember: getGenericState(),
  deleteMember: getGenericState(),
  admins: getGenericState([]),
};

export const userSlice = createSlice({
  name: 'user',
  initialState: usersInitialState,
  reducers: {
    signupStarted: getGenericStarted('signup'),
    signupSuccess: getPayloadSuccess('signup', 'currentUser'),
    signupFailure: getGenericFailure('signup'),

    logout: (state) => {
      state.currentUser = getGenericState(emptyUserData);
    },

    // this is only used for enabling/disabling demo-mode
    setUser: (state, action) => {
      state.currentUser = getGenericState(demoUserData);
    },

    getUserStarted: getGenericStarted('getUser'),
    getUserSuccess: (state, action) => {
      state.getUser.loading = false;
      state.getUser.error = '';
      state.currentUser = {
        ...state.currentUser,
        data: {
          ...action.payload,
          auth_token: state.currentUser.data.auth_token,
        },
      };
    },
    getUserFailure: getGenericFailure('getUser'),

    updateUserStarted: getGenericStarted('updateUser'),
    updateUserSuccess: getPayloadSuccess('updateUser'),
    updateUserFailure: getGenericFailure('updateUser'),

    addCreatorsStarted: getGenericStarted('addCreators'),
    addCreatorsSuccess: getGenericSuccess('addCreators'),
    addCreatorsFailure: getGenericFailure('addCreators'),

    addCreatorsBulkStarted: getGenericStarted('addCreatorsBulk'),
    addCreatorsBulkSuccess: getGenericSuccess('addCreatorsBulk'),
    addCreatorsBulkFailure: getGenericFailure('addCreatorsBulk'),

    getCreatorsStarted: getGenericStarted('creators'),
    getCreatorsSuccess: getPayloadSuccess('creators'),
    getCreatorsFailure: getGenericFailure('creators'),

    signinStarted: getGenericStarted('signin'),
    signinSuccess: getPayloadSuccess('signin', 'currentUser'),
    signinFailure: getGenericFailure('signin'),

    loginAsStarted: getGenericStarted('loginAs'),
    loginAsSuccess: getPayloadSuccess('loginAs'),
    loginAsFailure: getGenericFailure('loginAs'),

    updateCreatorStarted: getGenericStarted('updateCreator'),
    updateCreatorSuccess: getPayloadSuccess('updateCreator'),
    updateCreatorFailure: getGenericFailure('updateCreator'),

    updatePasswordStarted: getGenericStarted('updatePassword'),
    updatePasswordSuccess: getPayloadSuccess('updatePassword'),
    updatePasswordFailure: getGenericFailure('updatePassword'),

    updateBankDetailsStarted: getGenericStarted('updateBankDetails'),
    updateBankDetailsSuccess: getPayloadSuccess('updateBankDetails'),
    updateBankDetailsFailure: getGenericFailure('updateBankDetails'),

    sendPasswordResetStarted: getGenericStarted('sendPasswordReset'),
    sendPasswordResetSuccess: getGenericSuccess('sendPasswordReset'),
    sendPasswordResetFailure: getGenericFailure('sendPasswordReset'),

    createNewPasswordStarted: getGenericStarted('createNewPassword'),
    createNewPasswordSuccess: getGenericSuccess('createNewPassword'),
    createNewPasswordFailure: getGenericFailure('createNewPassword'),

    getMembersStarted: getGenericStarted('admins'),
    getMembersSuccess: getPayloadSuccess('admins'),
    getMembersFailure: getGenericFailure('admins'),

    inviteMemberStarted: getGenericStarted('inviteMember'),
    inviteMemberSuccess: getPayloadSuccess('inviteMember'),
    inviteMemberFailure: getGenericFailure('inviteMember'),

    deleteMemberStarted: getGenericStarted('deleteMember'),
    deleteMemberSuccess: getPayloadSuccess('deleteMember'),
    deleteMemberFailure: getGenericFailure('deleteMember'),
  },
});

export const {
  signupStarted,
  signupSuccess,
  signupFailure,

  logout,

  getUserStarted,
  getUserSuccess,
  getUserFailure,

  updateUserStarted,
  updateUserSuccess,
  updateUserFailure,

  updatePasswordStarted,
  updatePasswordSuccess,
  updatePasswordFailure,

  signinStarted,
  signinSuccess,
  signinFailure,

  loginAsStarted,
  loginAsSuccess,
  loginAsFailure,

  addCreatorsStarted,
  addCreatorsSuccess,
  addCreatorsFailure,

  addCreatorsBulkStarted,
  addCreatorsBulkSuccess,
  addCreatorsBulkFailure,

  getCreatorsStarted,
  getCreatorsSuccess,
  getCreatorsFailure,

  updateCreatorStarted,
  updateCreatorSuccess,
  updateCreatorFailure,

  updateBankDetailsStarted,
  updateBankDetailsSuccess,
  updateBankDetailsFailure,

  sendPasswordResetStarted,
  sendPasswordResetSuccess,
  sendPasswordResetFailure,

  createNewPasswordStarted,
  createNewPasswordSuccess,
  createNewPasswordFailure,

  getMembersStarted,
  getMembersSuccess,
  getMembersFailure,

  inviteMemberStarted,
  inviteMemberSuccess,
  inviteMemberFailure,

  deleteMemberStarted,
  deleteMemberSuccess,
  deleteMemberFailure,
} = userSlice.actions;

export default userSlice.reducer;

export const signup = (data, callback) => async (dispatch, getState) => {
  dispatch(signupStarted());
  try {
    const res = await signupAPI(getState(), data);
    dispatch(signupSuccess(res));
    !!callback && callback();
  } catch (err) {
    handleError(err, dispatch, signupFailure, 'There was an issue signing up');
  }
};

export const getUser = () => async (dispatch, getState) => {
  const jwt = authTokenSelector(getState());
  if (!jwt) return;

  dispatch(getUserStarted());
  try {
    const res = await getUserAPI(getState());
    dispatch(getUserSuccess(res));
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      getUserFailure,
      'There was an issue refreshing your user data'
    );
  }
};

export const signin = (data, callback) => async (dispatch, getState) => {
  dispatch(signinStarted());
  try {
    const res = await signinAPI(getState(), data);
    dispatch(signinSuccess(res));
    if (res.is_external) {
      dispatch(getOrgs());
    } else {
      dispatch(getOrg());
    }

    !!callback && callback();
  } catch (err) {
    handleError(err, dispatch, signinFailure, 'There was an issue signing in');
  }
};

export const loginAs = (data, callback) => async (dispatch, getState) => {
  dispatch(loginAsStarted());
  try {
    const res = await loginAsAPI(getState(), data);

    dispatch(loginAsSuccess(res));

    dispatch(signinStarted());
    dispatch(signinSuccess(res));

    callback();
  } catch (err) {
    handleError(
      err,
      dispatch,
      loginAsFailure,
      'There was an issue logging in as that user'
    );
  }
};

export const updatePassword = (data) => async (dispatch, getState) => {
  dispatch(updatePasswordStarted());
  try {
    const res = await updatePasswordAPI(getState(), data);
    dispatch(updatePasswordSuccess(res));
    toast.success(`Password has been updated.`);
    return true;
  } catch (err) {
    handleError(
      err,
      dispatch,
      updatePasswordFailure,
      'There was an issue updating your password'
    );
    return false;
  }
};

export const getCreators = (data) => async (dispatch, getState) => {
  dispatch(getCreatorsStarted());
  try {
    const res = await getCreatorsAPI(getState(), { ...data });
    dispatch(getCreatorsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getCreatorsFailure,
      'There was an issue getting your creators'
    );
  }
};

export const updateCreator = (data, callback) => async (dispatch, getState) => {
  dispatch(updateCreatorStarted());
  try {
    const res = await updateCreatorAPI(getState(), data);
    dispatch(updateCreatorSuccess(res));
    dispatch(getCreators());
    !!callback && callback();
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateCreatorFailure,
      'There was an issue updating this user'
    );
  }
};

export const addCreators = (data) => async (dispatch, getState) => {
  dispatch(addCreatorsStarted());
  try {
    const res = await addCreatorsAPI(getState(), data);
    dispatch(addCreatorsSuccess(res));
    dispatch(getCreators());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      addCreatorsFailure,
      'There was an issue creating this user'
    );
  }
};

export const addCreatorsBulk = (data) => async (dispatch, getState) => {
  dispatch(addCreatorsBulkStarted());
  try {
    const res = await addCreatorsBulkAPI(getState(), { ...data });
    dispatch(addCreatorsBulkSuccess(res));
    dispatch(getCreators());
    toast.success(`Creators have been added!`);
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      addCreatorsBulkFailure,
      'There was an issue bulk creating these creators'
    );
  }
};

export const updateBankDetails = (data, cb) => async (dispatch, getState) => {
  dispatch(updateBankDetailsStarted());
  try {
    await updateBankDetailsExpiringLinkAPI(getState(), data);
    dispatch(updateBankDetailsSuccess());
    toast.success('Payment is on the way!');
    !!cb && cb();
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateBankDetailsFailure,
      'There was an issue updating your account'
    );
  }
};

export const sendPasswordReset = (data) => async (dispatch, getState) => {
  dispatch(sendPasswordResetStarted());
  try {
    const res = await sendPasswordResetAPI(getState(), data);
    dispatch(sendPasswordResetSuccess(res));
    toast.success('Request has been sent, check your email!');
  } catch (err) {
    handleError(
      err,
      dispatch,
      sendPasswordResetFailure,
      'There was an issue sending a password reset email'
    );
  }
};

export const createNewPassword = (data) => async (dispatch, getState) => {
  dispatch(createNewPasswordStarted());
  try {
    const res = await createNewPasswordAPI(getState(), data);
    dispatch(createNewPasswordSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      sendPasswordResetFailure,
      'There was an issue creating a new passsword for your account'
    );
  }
};

export const getMembers = () => async (dispatch, getState) => {
  dispatch(getMembersStarted());
  try {
    const res = await getMembersAPI(getState());
    dispatch(getMembersSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getMembersFailure,
      'There was an issue getting your admins'
    );
  }
};

export const inviteMember = (data) => async (dispatch, getState) => {
  dispatch(inviteMemberStarted());
  try {
    const res = await inviteMemberAPI(getState(), data);
    dispatch(inviteMemberSuccess(res));
    dispatch(getMembers());
    dispatch(getAgentsMngrs());
    return true;
  } catch (err) {
    handleError(
      err,
      dispatch,
      inviteMemberFailure,
      'There was an issue inviting this user'
    );
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  dispatch(updateUserStarted());
  try {
    const res = await updateUserAPI(getState(), data);
    dispatch(updateUserSuccess(res));
    dispatch(getMembers());
    return true;
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateUserFailure,
      'There was an issue updating this user'
    );
  }
};

export const deleteMember = (data) => async (dispatch, getState) => {
  dispatch(deleteMemberStarted());
  try {
    const res = await deleteMemberAPI(getState(), data);
    dispatch(deleteMemberSuccess(res));
    dispatch(getMembers());
  } catch (err) {
    handleError(
      err,
      dispatch,
      deleteMemberFailure,
      'There was an issue deleting this user'
    );
  }
};

// selectors
const selectUser = (state) => state.user;
const selectId = (_, id) => id;

export const currentUserSelector = createSelector(
  selectUser,
  (userState = {}) => userState.currentUser || getGenericState(emptyUserData)
);

export const isExternalSelector = createSelector(
  currentUserSelector,
  (currentUserState) => {
    const { data: currentUser } = currentUserState;
    return currentUser.is_external;
  }
);

export const signupStateSelector = createSelector(
  selectUser,
  (userState) => userState.signup
);

export const signinStateSelector = createSelector(
  selectUser,
  (userState) => userState.signin
);

export const authTokenSelector = createSelector(
  selectUser,
  (userState) => userState.currentUser.data?.auth_token
);

export const creatorsSelector = createSelector(selectUser, (userState) => {
  const creatorsState = userState.creators || getGenericState([]);
  const parsedCreatorsState = {
    ...creatorsState,
    data: creatorsState.data.map((creator) => ({
      ...creator,
      tags: JSON.parse(creator.tags),
    })),
  };
  return parsedCreatorsState;
});

export const bareCreatorsSelector = createSelector(
  creatorsSelector,
  (creatorsState) => {
    const { data } = creatorsState;
    return data.map((creator) => ({
      id: creator.id,
      email: creator.email,
      first_name: creator.first_name,
      last_name: creator.last_name,
      business_name: creator.business_name,
    }));
  }
);

export const sortedCreatorsSelector = createSelector(
  creatorsSelector,
  (creatorsState) => {
    const { data } = creatorsState;
    const creators = [...data];
    return creators.sort((a, b) => a.email.localeCompare(b.email));
  }
);

export const creatorSelector = createSelector(
  [creatorsSelector, selectId],
  (creatorsState, id) =>
    find(propEq('id', id), creatorsState.data) || emptyUserData
);

export const updateBankDetailsSelector = createSelector(
  selectUser,
  (userState) => userState.updateBankDetails || getGenericState()
);

export const updatePasswordSelector = createSelector(
  selectUser,
  (userState) => userState.updatePassword || getGenericState()
);

export const creatorsDictSelector = createSelector(
  creatorsSelector,
  (creatorsState) => {
    const { data: creators } = creatorsState;
    return creators.reduce((agg, creator) => {
      agg[creator.id] = { ...creator, is_creator: true };
      return agg;
    }, {});
  }
);

export const activeCreatorsSelector = createSelector(
  creatorsSelector,
  (creatorsState) => {
    const { data: creators } = creatorsState;
    return creators.filter((c) => c.is_active);
  }
);

export const updateCreatorSelector = createSelector(
  selectUser,
  (userState) => userState.updateCreator || getGenericState()
);
export const addCreatorsSelector = createSelector(
  selectUser,
  (userState = {}) => userState.addCreators || getGenericState()
);

export const addCreatorsBulkSelector = createSelector(
  selectUser,
  (userState = {}) => userState.addCreatorsBulk || getGenericState()
);

export const sendPasswordResetStateSelector = createSelector(
  selectUser,
  (userState) => userState.sendPasswordReset || getGenericState()
);

export const createNewPasswordStateSelector = createSelector(
  selectUser,
  (userState) => userState.createNewPassword || getGenericState()
);

export const adminsSelector = createSelector(
  selectUser,
  (userState) => userState.admins || getGenericState([])
);

export const adminsDictSelector = createSelector(
  adminsSelector,
  (adminsState) => {
    const { data: admins } = adminsState;
    return admins.reduce((agg, admin) => {
      agg[admin.id] = { ...admin, is_creator: false };
      return agg;
    }, {});
  }
);

export const inviteMemberSelector = createSelector(
  selectUser,
  (userState) => userState.inviteMember || getGenericState()
);

export const deleteMemberSelector = createSelector(
  selectUser,
  (userState) => userState.deleteMember || getGenericState()
);

export const isCustomerSelector = createSelector(
  currentUserSelector,
  (currentUserState) => {
    const { data: currentUser } = currentUserState || emptyUserData;
    return currentUser.role === 'customer';
  }
);
