import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  getNotifications as getNotificationsAPI,
  dismissNotification as dismissNotificationAPI,
} from 'api/notifications';
export const initialNotificationsState = {
  notifications: getGenericState([]),
  dismissNotification: getGenericState(),
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialNotificationsState,
  reducers: {
    getNotificationsStarted: getGenericStarted('notifications'),
    getNotificationsSuccess: getPayloadSuccess('notifications'),
    getNotificationsFailure: getGenericFailure('notifications'),

    dismissNotificationStarted: getGenericStarted('dismissNotification'),
    dismissNotificationSuccess: getGenericSuccess('dismissNotification'),
    dismissNotificationFailure: getGenericFailure('dismissNotification'),
  },
});

export const {
  getNotificationsStarted,
  getNotificationsSuccess,
  getNotificationsFailure,

  dismissNotificationStarted,
  dismissNotificationSuccess,
  dismissNotificationFailure,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const getNotifications = () => async (dispatch, getState) => {
  dispatch(getNotificationsStarted());
  try {
    const res = await getNotificationsAPI(getState());
    dispatch(getNotificationsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getNotificationsFailure,
      'There was an issue retrieving your notifications'
    );
  }
};

export const dismissNotification = (id) => async (dispatch, getState) => {
  dispatch(dismissNotificationStarted());
  try {
    await dismissNotificationAPI(getState(), id);
    dispatch(dismissNotificationSuccess());
    dispatch(getNotifications());
  } catch (err) {
    handleError(
      err,
      dispatch,
      dismissNotificationFailure,
      'Failed to dismiss notification'
    );
  }
};

// selectors
const selectNotifications = (state) => state.notifications;

export const notificationsSelector = createSelector(
  selectNotifications,
  (notificationsState = {}) =>
    notificationsState.notifications || getGenericState([])
);

export const activeNotificationsSelector = createSelector(
  notificationsSelector,
  (notificationsState = {}) => {
    const { data: notifications } = notificationsState;
    return notifications.filter((notification) => !notification.dismissed);
  }
);

export const dismissNotificationSelector = createSelector(
  selectNotifications,
  (notificationsState = {}) =>
    notificationsState.dismissNotification || getGenericState()
);
