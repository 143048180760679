import { createSlice, createSelector } from '@reduxjs/toolkit';

import { getCollectedFees as getCollectedFeesAPI } from 'api/collectedFees';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialCollectedFeesState = {
  collectedFees: getGenericState([]),
};

export const collectedFeesSlice = createSlice({
  name: 'collectedFees',
  initialState: initialCollectedFeesState,
  reducers: {
    getCollectedFeesStarted: getGenericStarted('collectedFees'),
    getCollectedFeesSuccess: getPayloadSuccess('collectedFees'),
    getCollectedFeesFailure: getGenericFailure('collectedFees'),
  },
});

export const {
  getCollectedFeesStarted,
  getCollectedFeesSuccess,
  getCollectedFeesFailure,
} = collectedFeesSlice.actions;

export default collectedFeesSlice.reducer;

export const getCollectedFees = () => async (dispatch, getState) => {
  dispatch(getCollectedFeesStarted());
  try {
    const res = await getCollectedFeesAPI(getState());
    dispatch(getCollectedFeesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getCollectedFeesFailure,
      'There was an error getting the collected fees.'
    );
  }
};

// selectors
const selectCollectedFees = (state) => state.collectedFees;

export const collectedFeesSelector = createSelector(
  selectCollectedFees,
  (collectedFeesState = {}) =>
    collectedFeesState.collectedFees || getGenericState([])
);
