import { createSelector } from '@reduxjs/toolkit';
import { domain, vgsDomain, nodeDomain, pdfDomain } from 'api/utils';

const selectUser = (state) => state.user;

export const authTokenSelector = createSelector(
  selectUser,
  (userState) => userState.currentUser.data?.auth_token
);

export const createRequest = async (
  endpoint,
  state,
  { method, body, headers, contentType, isNode, vgs, isPdfRenderer }
) => {
  let reqDomain = domain();
  if (isNode) {
    reqDomain = nodeDomain();
  } else if (vgs) {
    reqDomain = vgsDomain();
  } else if (isPdfRenderer) {
    reqDomain = pdfDomain();
  }
  let url = `${reqDomain}${endpoint}`;
  const jwt = authTokenSelector(state);
  const opts = {
    method,
    headers: {
      'auth-token': jwt,
      ...headers,
    },
  };
  if (contentType !== 'multipart/form-data') {
    opts.headers['Content-Type'] = 'application/json';
  }

  if (!!body && opts.headers['Content-Type'] === 'application/json') {
    opts.body = JSON.stringify(body);
  } else if (!!body) {
    opts.body = body;
  }

  return fetch(url, opts);
};
