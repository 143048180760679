import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import global from 'styles/global';
import Button from 'components/Button';
import TableHeader from 'components/TableHeader';

import objectStates from 'constants/objectStates';
import { connect } from 'react-redux';
import {
  contractsDictSelector,
  invalidateContracts as invalidateContractsSlice,
  remindContracts as remindContractsSlice,
  contractsSelector,
} from 'slices/contracts';
import { paymentGroupDetailsSelector } from 'slices/paymentGroups';
import { contractTypes } from 'constants/contracts';
import ContractsTable from 'components/ContractsTable';

const ContractsTab = ({
  contractsDict,
  invalidateContracts,
  sendReminders,
}) => {
  const g = global();
  const history = useHistory();

  const { groupId } = useParams();
  const campaign = useSelector((state) =>
    paymentGroupDetailsSelector(state, groupId)
  );
  const { data: contracts } = useSelector(contractsSelector);

  const contractsForCampaign = contracts.filter(
    (contract) => contract.payment_group_id === campaign.id
  );

  const [selectedContracts, setSelectedContracts] = useState([]);

  const handleSendReminders = () => {
    sendReminders(selectedContracts, 'email');
  };

  const handleInvalidateContracts = () => {
    invalidateContracts(selectedContracts);
  };

  // if there are any contracts selected and none are invalidated or signed, then actions are valid
  const areActionsDisabled =
    selectedContracts.length === 0 ||
    selectedContracts.reduce((isDisabled, contractId) => {
      if (isDisabled) return isDisabled;
      const contract = contractsDict[contractId];
      if (!contract) return true;
      if (
        contract.type === contractTypes.Uploaded ||
        contract.status === objectStates.canceled ||
        !!contract.signature_date
      )
        return true;
      return false;
    }, false);

  return (
    <div>
      <TableHeader title={`Contracts for ${campaign.title}`}>
        <div>
          <Button
            size="small"
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleInvalidateContracts}
            disabled={areActionsDisabled}
          >
            Invalidate
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={g.ml_xs}
            disabled={areActionsDisabled}
            onClick={handleSendReminders}
          >
            Send email reminder
          </Button>
          <Button
            size="small"
            variant="contained"
            className={g.ml_xs}
            color="primary"
            onClick={() => history.push(`/contracts/newContract?campaignId=${groupId}`)}
          >
            New Contract
          </Button>
        </div>
      </TableHeader>
      <div className={g.tableWrapper}>
        <ContractsTable
          variant="outlined"
          autoHeight
          autoPageSize
          contracts={contractsForCampaign}
          columnsToDisplay={['title', 'status']}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={setSelectedContracts}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contractsDict: contractsDictSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  invalidateContracts: (data) => dispatch(invalidateContractsSlice(data)),
  sendReminders: (ids, method) => dispatch(remindContractsSlice(ids, method)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsTab);
