import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getCustomers = async (state) => {
  return createRequest(`/v1/customers`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const updateCustomer = async (state, data) => {
  const { id, ...body } = data;
  return createRequest(`/v1/customers/${id}`, state, {
    method: 'PUT',
    body,
    vgs: true,
  }).then((res) => handleError(res));
};

export const addCustomer = async (state, data) => {
  const { id, ...body } = data;
  return createRequest(`/v1/customers`, state, {
    method: 'POST',
    body,
    vgs: true,
  }).then((res) => handleError(res));
};
