import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  getApiKeys as getApiKeysAPI,
  addApiKey as addApiKeyAPI,
  deleteApiKey as deleteApiKeyAPI,
} from 'api/orgApiKeys';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialapiKeysState = {
  addApiKey: getGenericState(),
  getApiKeys: getGenericState([]),
  deleteApiKey: getGenericState(),
};

export const apiKeysSlice = createSlice({
  name: 'orgApiKeys',
  initialState: initialapiKeysState,
  reducers: {
    addApiKeyStarted: getGenericStarted('addApiKey'),
    addApiKeySuccess: getGenericSuccess('addApiKey'),
    addApiKeyFailure: getGenericFailure('addApiKey'),

    getApiKeysStarted: getGenericStarted('apiKeys'),
    getApiKeysSuccess: getPayloadSuccess('apiKeys'),
    getApiKeysFailure: getGenericFailure('apiKeys'),

    deleteApiKeyStarted: getGenericStarted('deleteApiKey'),
    deleteApiKeySuccess: getGenericSuccess('deleteApiKey'),
    deleteApiKeyFailure: getGenericFailure('deleteApiKey'),
  },
});

export const {
  addApiKeyStarted,
  addApiKeySuccess,
  addApiKeyFailure,

  getApiKeysStarted,
  getApiKeysSuccess,
  getApiKeysFailure,

  deleteApiKeyStarted,
  deleteApiKeySuccess,
  deleteApiKeyFailure,
} = apiKeysSlice.actions;

export default apiKeysSlice.reducer;

export const getApiKeys = () => async (dispatch, getState) => {
  dispatch(getApiKeysStarted());
  try {
    const res = await getApiKeysAPI(getState());
    dispatch(getApiKeysSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getApiKeysFailure,
      'There was an issue retrieving your api keys'
    );
  }
};

export const addApiKey = (data) => async (dispatch, getState) => {
  dispatch(addApiKeyStarted());
  try {
    const res = await addApiKeyAPI(getState(), data);
    dispatch(addApiKeySuccess(res));
    dispatch(getApiKeys());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      addApiKeyFailure,
      'There was an issue creating your api key'
    );
  }
};

export const deleteApiKey = (data) => async (dispatch, getState) => {
  dispatch(deleteApiKeyStarted());
  try {
    const res = await deleteApiKeyAPI(getState(), data);
    dispatch(deleteApiKeySuccess(res));
    dispatch(getApiKeys());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      deleteApiKeyFailure,
      'There was an issue deleting your api keys'
    );
  }
};

// selectors
const selectApiKeys = (state) => state.orgApiKeys;

export const getApiKeysSelector = createSelector(
  selectApiKeys,
  (apiKeysState = {}) => apiKeysState.apiKeys || getGenericState([])
);

export const addApiKeySelector = createSelector(
  selectApiKeys,
  (apiKeysState = {}) => apiKeysState.addApiKey || getGenericState()
);

export const deleteApiKeySelector = createSelector(
  selectApiKeys,
  (apiKeysState = {}) => apiKeysState.deleteApiKey || getGenericState()
);
