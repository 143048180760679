export const contractInputTypes = {
  signature: 'signature',
  text: 'text',
  date: 'date',
  initials: 'initials',
  'text-merge': 'text-merge',
  'checkbox-merge': 'checkbox-merge',
};

export const contractTypes = {
  HelloSign: 'hello_sign',
  Uploaded: 'uploaded',
}