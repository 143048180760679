import { createSlice, createSelector } from '@reduxjs/toolkit';

import { getExpiringLink as getExpiringLinkAPI } from 'api/expiringLink';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleErrorNoToast,
} from './sliceUtils';

export const initialExpiringLinkState = {
  expiringLink: getGenericState({}),
};

export const expiringLinkSlice = createSlice({
  name: 'expiringLink',
  initialState: initialExpiringLinkState,
  reducers: {
    getExpiringLinkStarted: getGenericStarted('expiringLink'),
    getExpiringLinkSuccess: getPayloadSuccess('expiringLink'),
    getExpiringLinkFailure: getGenericFailure('expiringLink'),
  },
});

export const {
  getExpiringLinkStarted,
  getExpiringLinkSuccess,
  getExpiringLinkFailure,
} = expiringLinkSlice.actions;

export default expiringLinkSlice.reducer;

export const getExpiringLink = (id) => async (dispatch, getState) => {
  dispatch(getExpiringLinkStarted());
  try {
    const res = await getExpiringLinkAPI(getState(), id);
    dispatch(getExpiringLinkSuccess(res));
  } catch (err) {
    handleErrorNoToast(
      err,
      dispatch,
      getExpiringLinkFailure,
      'There was an issue retrieving info for this action'
    );
  }
};

// selectors
const selectExpiringLink = (state) => state.expiringLink;

export const expiringLinkSelector = createSelector(
  selectExpiringLink,
  (expLinkState = {}) => expLinkState.expiringLink || getGenericState({})
);
