import React, { useState } from 'react';

import Button from 'components/Button';
import { Modal, ModalActions } from 'components/Modal';
import isEmail from 'validator/lib/isEmail';
import global from 'styles/global';
import Card from 'components/Card';
import TextField from 'components/TextField';

const AddPayPalModal = ({
  open,
  onClose,
  creator,
  handleUpdateCreator,
  updateCreatorState,
}) => {
  const g = global();

  const [email, setEmail] = useState({ val: '', dirty: false });
  const { loading: submitting } = updateCreatorState;

  const handleClose = () => {
    setEmail({ val: '', dirty: false });
    onClose();
  };

  const addPayPalEmail = async (evt) => {
    evt.preventDefault();
    await handleUpdateCreator({
      id: creator.id,
      selected_payment_method: 'paypal',
      paypal_email: email.val,
      account_number: '',
      routing_number: '',
      venmo_phone_number: '',
    });
    handleClose();
  };

  const isInputValid = isEmail(email.val);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <Card title="Add PayPal">
        <TextField
          margin="dense"
          variant="outlined"
          label="PayPal email"
          value={email.val}
          error={email.dirty && !isEmail(email.val)}
          helperText={
            email.dirty &&
            !isEmail(email.val) &&
            'This field is required and must be an email'
          }
          onChange={(e) => setEmail({ val: e.target.value, dirty: true })}
          required
          fullWidth
        />
        <ModalActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            className={g.ml_sm}
            loading={submitting}
            onClick={addPayPalEmail}
            color="primary"
            variant="contained"
            disabled={!isInputValid}
          >
            Add
          </Button>
        </ModalActions>
      </Card>
    </Modal>
  );
};

export { AddPayPalModal };
