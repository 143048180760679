import React from 'react';
import css from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, Switch } from '@material-ui/core';

import Card from 'components/Card';
import global from 'styles/global';

function Status({ creator, updateCreator }) {
  const g = global();
  const dispatch = useDispatch();
  const { is_active } = creator;

  const toggleIsActive = async () => {
    const updateObj = {
      id: creator.id,
      is_active: !is_active,
    };
    await dispatch(updateCreator(updateObj));
  };

  return (
    <Card title="Status">
      <div className={css(g.alignCenter, g.flexRowSpacing)}>
        {is_active ? (
          <Typography className={g.cashGreen1} variant="h4">
            ACTIVE
          </Typography>
        ) : (
          <Typography className={g.error} variant="h4">
            DISABLED
          </Typography>
        )}

        <Switch
          checked={is_active}
          onChange={toggleIsActive}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </Card>
  );
}

export default Status;
