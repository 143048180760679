import { differenceWith, innerJoin } from 'ramda';

import stateWithholdings from 'constants/stateWithholdings';

const compareFirst = (x, y) => x.id === y;
const compareSecond = (x, y) => x === y.id;

// finds payments/ deliverables that should be created, i.e. they didn't exist when the page was rehydrated, but they do now
// old items = array of IDs, currentItems = array of objects
// returns array of objects

export const getItemsToAdd = (oldItems, currentItems) => {
  if (!Array.isArray(oldItems) || !Array.isArray(currentItems)) return [];

  return differenceWith(compareFirst, currentItems, oldItems);
};

// finds payments/ deliverables to be updated, i.e. they existed when page was rehydrated and also exist now
// old items = array of IDs, currentItems = array of objects
// returns array of objects
export const getItemsToUpdate = (oldItems, currentItems) => {
  if (!Array.isArray(oldItems) || !Array.isArray(currentItems)) return [];
  return innerJoin(compareFirst, currentItems, oldItems);
};

// finds payments/ deliverables to be deleted, i.e. they existed when page was rehydrated, but don't exist now
// old items = array of IDs, currentItems = array of objects
// returns array of IDs
export const getItemsToDelete = (oldItems, currentItems) => {
  if (!Array.isArray(oldItems) || !Array.isArray(currentItems)) return [];
  return differenceWith(compareSecond, oldItems, currentItems);
};

export const getStateWithholdingsForPayment = (payment, recipient) => {
  let withholdings;

  // if recipient has custom state withholding, use that
  if (isFinite(recipient.custom_withholding_rate)) {
    withholdings = (recipient.custom_withholding_rate / 100) * payment.amount;
  } else {
    // if not, use their state of residence's default
    withholdings =
      (stateWithholdings[recipient.address_state]?.rate / 100) *
        payment.amount || 0;
  }

  return withholdings || 0;
};

const ONE_MIL = 1e8;
export const getFederalWithholdingsForpayment = (
  payment,
  recipientsPaymentTotal
) => {
  // get federal withholdings (22% on earnings up to $1M, 37% after that)

  // total payments (including this one) has not reached $1M, just use 22%
  if (recipientsPaymentTotal + payment.amount <= ONE_MIL) {
    return payment.amount * 0.22;
  }

  // total payments already exceeded $1M, use 37%
  if (recipientsPaymentTotal > ONE_MIL) {
    return payment.amount * 0.37;
  }

  // part of the payment is taxed at the lower rate, part is taxed at higher rate
  const lowerAmt = ONE_MIL - recipientsPaymentTotal;
  const upperAmt = payment.amount - lowerAmt;
  return lowerAmt * 0.22 + upperAmt * 0.37;
};

export const getTotalWithholdingsForPayment = (
  payment,
  recipient,
  recipientsPaymentTotal
) => {
  // if recipient is not w2, withholdings is 0
  if (!recipient.is_w2) return { stateWithholdings: 0, federalWithholdings: 0 };

  // get state withholdings
  const stateWithholdings = getStateWithholdingsForPayment(payment, recipient);
  const federalWithholdings = getFederalWithholdingsForpayment(
    payment,
    recipientsPaymentTotal
  );

  return {
    stateWithholdings,
    federalWithholdings,
  };
};
