import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import {
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
} from 'components/Modal';

import global from 'styles/global';
import TextField from 'components/TextField';
import Button from 'components/Button';
import { isEmpty } from 'ramda';
import { isAccountNumber, isRoutingNumber } from 'util/validation';
import DollarInput from 'components/DollarInput';
import { stringToNumberCents } from 'util/renderStrings';
import IconWithInfo from 'components/IconWithInfo';
import PaymentMethodIcon from 'components/PaymentMethodIcon';
import {
  sendAchDebit,
  attachBank,
  attachBankSelector,
  sendAchDebitSelector,
} from 'slices/treasury';
import { getWallets, walletDetailsSelector } from 'slices/wallets';

const DepositFundsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const g = global();

  let handleClose = () => {
    onClose();
  };

  const { loading: submittingAttach } = useSelector(attachBankSelector);
  const { loading: submittingACH } = useSelector(sendAchDebitSelector);
  const { data: walletDetails } = useSelector(walletDetailsSelector);

  const [acctNumber, setAcctNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');

  const [amount, setAmount] = useState(0);

  const isInputValid =
    isAccountNumber(acctNumber) && isRoutingNumber(routingNumber);

  const isAmountInputValid = !isEmpty(amount);

  const { payment_method, setup_intent } = walletDetails || {};

  const handleAttachBank = async (evt) => {
    evt.preventDefault();
    await dispatch(
      attachBank({
        account_number: acctNumber,
        routing_number: routingNumber,
      })
    );
    setAcctNumber('');
    setRoutingNumber('');
    handleClose();
  };

  const handleAddFunds = async (evt) => {
    const amountCents = stringToNumberCents(amount);
    evt.preventDefault();
    await dispatch(
      sendAchDebit({
        amount: amountCents,
        payment_method: payment_method.id,
      })
    );
    setAmount(0);
    handleClose();
  };

  let modalContent = React.Component;
  if (!!payment_method) {
    modalContent = (
      <ModalContent>
        <Typography component="p" variant="body2" className={g.mb_sm}>
          Funds will be debited from:
        </Typography>
        <div className={g.mb_md}>
          <IconWithInfo
            text={payment_method?.us_bank_account.bank_name}
            subtext={`•••••${payment_method?.us_bank_account.last4}`}
            icon={<PaymentMethodIcon method="ach" />}
          />
        </div>
        <DollarInput
          margin="dense"
          variant="outlined"
          label="Amount to debit"
          value={amount}
          onChange={(evt) => setAmount(evt.target.value)}
          required
          fullWidth
        />
        <ModalActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            loading={submittingACH}
            onClick={handleAddFunds}
            color="primary"
            variant="contained"
            disabled={!isAmountInputValid}
          >
            Debit
          </Button>
        </ModalActions>
      </ModalContent>
    );
  } else if (!!setup_intent) {
    handleClose = () => {
      dispatch(getWallets());
      onClose();
    };
    modalContent = (
      <ModalContent>
        <Typography component="p" variant="body2" className={g.mb_sm}>
          Your bank account is pending verification. Once you see a transaction
          with a description starting with the letters 'SM' please click the
          button below.
        </Typography>
        <ModalActions>
          <Button
            target="_blank"
            href={
              setup_intent?.next_action?.verify_with_microdeposits
                .hosted_verification_url
            }
            color="primary"
            variant="contained"
          >
            Verify
          </Button>
        </ModalActions>
      </ModalContent>
    );
  } else {
    modalContent = (
      <ModalContent>
        <Typography component="p" variant="body2" className={g.mb_sm}>
          To add funds to your account you will first need to setup an external
          bank account.
        </Typography>
        <TextField
          margin="dense"
          variant="outlined"
          label="Account number"
          value={acctNumber}
          onChange={(e) => setAcctNumber(e.target.value)}
          required
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="Routing number"
          value={routingNumber}
          onChange={(e) => setRoutingNumber(e.target.value)}
          required
          fullWidth
        />
        <ModalActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            loading={submittingAttach}
            onClick={handleAttachBank}
            color="primary"
            variant="contained"
            disabled={!isInputValid}
          >
            Add
          </Button>
        </ModalActions>
      </ModalContent>
    );
  }

  return (
    <Modal open={open} onClose={handleClose} fullWidth>
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Add Funds</Typography>
      </ModalTitle>
      {modalContent}
    </Modal>
  );
};

export default DepositFundsModal;
