import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import Button from 'components/Button';
import { Modal, ModalActions } from 'components/Modal';
import global from 'styles/global';
import Card from 'components/Card';

export default function BillingAuthModal({ isDelinquent, open, onClose }) {
  const g = global();
  const history = useHistory();

  const handleNav = () => {
    if (isDelinquent) {
      history.push('/wallets');
    } else {
      history.push('/settings?tab=billing');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <Card title="Notice">
        <Typography variant="body1">
          {isDelinquent
            ? "Your Organization's billing is delinquent, please add funds"
            : 'Upgrade your plan to use this feature'}
        </Typography>
        <ModalActions>
          <Button onClick={onClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            className={g.ml_sm}
            onClick={handleNav}
            color="primary"
            variant="contained"
          >
            {isDelinquent ? 'Add funds' : 'Upgrade now'}
          </Button>
        </ModalActions>
      </Card>
    </Modal>
  );
}
