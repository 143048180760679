import React, { useState, useEffect } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Grid,
} from '@material-ui/core';

import { stringToNumberCents, formatCreatorName } from 'util/renderStrings';
import {
  createPaymentGroup,
  updatePaymentGroup,
  createPaymentGroupSelector,
  updatePaymentGroupSelector,
  paymentGroupDetailsSelector,
  getPaymentGroups,
} from 'slices/paymentGroups';
import { agentsMngrsGroupedSelector, getAgentsMngrs } from 'slices/agentsMngrs';
import { getCustomers, customersSelector } from 'slices/customers';
import Button from 'components/Button';
import Card from 'components/Card';
import DollarInput from 'components/DollarInput';
import AddCustomerModal from 'components/AddCustomerModal';
import global from 'styles/global';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';
import Select from 'components/Select';

export default function GeneralStep({ handleStep }) {
  const g = global();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(getPaymentGroups());
    dispatch(getAgentsMngrs());
  }, []);

  const query = new URLSearchParams(useLocation().search);
  const campaignId = query.get('campaignId');

  const [newName, setNewName] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newCustomer, setNewCustomer] = useState('');
  const [customerInput, setCustomerInput] = useState();
  const [newAgent, setNewAgent] = useState('');
  const [agentInput, setAgentInput] = useState();
  const [newDealSize, setNewDealSize] = useState('');
  const [newTerms, setNewTerms] = useState('');
  const [newStart, setNewStart] = useState('');
  const [newEnd, setNewEnd] = useState('');
  const [newRefId, setNewRefId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const campaign = useSelector((state) =>
    paymentGroupDetailsSelector(state, campaignId)
  );
  const { loading: createCampaignLoading } = useSelector(
    createPaymentGroupSelector
  );
  const { loading: updateCampaignLoading } = useSelector(
    updatePaymentGroupSelector
  );

  const { data: customers } = useSelector(customersSelector);
  const { agents } = useSelector(agentsMngrsGroupedSelector);
  const submitting = createCampaignLoading || updateCampaignLoading;

  useEffect(() => {
    const {
      title,
      customer_id,
      agent_id,
      description,
      campaign_ref_id,
      terms,
      deal_size,
      campaign_start_date,
      campaign_end_date,
    } = campaign || {};

    const customer = customers.find((cust) => cust.id === customer_id);
    const agent = agents.find((agnt) => agnt.id === agent_id) || {};
    setNewName(title);
    setNewDescription(description);
    setNewCustomer(customer);
    setCustomerInput(customer?.name);
    setNewAgent(agent);
    setAgentInput(formatCreatorName(agent));
    setNewDealSize(deal_size / 100);
    setNewTerms(terms);
    setNewStart(campaign_start_date);
    setNewEnd(campaign_end_date);
    setNewRefId(campaign_ref_id);
  }, [campaign, customers, agents]);

  const handleSave = async () => {
    const newCampaign = {
      title: newName,
      customer_id: newCustomer.id,
      description: newDescription,
      campaign_ref_id: newRefId,
      terms: newTerms,
    };
    if (!!newDealSize) newCampaign.deal_size = stringToNumberCents(newDealSize);
    if (!!newStart) newCampaign.campaign_start_date = newStart;
    if (!!newEnd) newCampaign.campaign_end_date = newEnd;
    if (!!newAgent?.id) newCampaign.agent_id = newAgent.id;

    let campaign;
    if (!campaignId) {
      campaign = await dispatch(createPaymentGroup(newCampaign));
      history.push(`/campaigns/new?campaignId=${campaign.id}`);
      handleStep(1);
    } else {
      await dispatch(
        updatePaymentGroup({
          ...newCampaign,
          id: campaignId,
        })
      );
      history.push(`/campaigns/new?campaignId=${campaignId}`);
      handleStep(1);
    }
  };

  const handleCustomerChange = (_, value) => {
    setNewCustomer(value);
    if (!!value) setCustomerInput(value);
  };

  const handleCustomerInputChange = (_, val) => {
    setCustomerInput(val);
  };

  const handleAgentChange = (_, value) => {
    setNewAgent(value);
    if (!!value) setAgentInput(value);
  };

  const handleAgentInputChange = (_, val) => {
    setAgentInput(val);
  };

  let helperText = (
    <>
      Don't see your Customer?&nbsp;
      <a href="#" onClick={() => setModalVisible(true)}>
        Add one now
      </a>
    </>
  );

  return (
    <>
      <AddCustomerModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      />

      <Card title="General">
        <Grid container spacing={1}>
          {/* name + description */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="Name"
              required
              variant="outlined"
              margin="dense"
              value={newName}
              onChange={(evt) => setNewName(evt.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Description"
              variant="outlined"
              margin="dense"
              value={newDescription}
              onChange={(evt) => setNewDescription(evt.target.value)}
              fullWidth
            />
          </Grid>

          {/* customer + deal size + terms */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) => `${option.name || ''}`}
              value={newCustomer}
              onChange={handleCustomerChange}
              inputValue={customerInput}
              onInputChange={handleCustomerInputChange}
              textInputProps={{
                label: 'Customer',
                helperText: helperText,
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <DollarInput
              margin="dense"
              className={g.mt_zero_i}
              variant="outlined"
              label="Campaign budget"
              value={newDealSize}
              onChange={(evt) => {
                setNewDealSize(evt.target.value);
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl
              size="small"
              variant="outlined"
              className={g.full_width}
            >
              <InputLabel id="select-terms-label">Terms</InputLabel>
              <Select
                labelId="select-terms-label"
                label="Invoice terms"
                id="select-terms"
                value={newTerms}
                onChange={(evt) => setNewTerms(evt.target.value)}
              >
                <MenuItem value="due">Due upon invoice</MenuItem>
                <MenuItem value="30">Net 30</MenuItem>
                <MenuItem value="60">Net 60</MenuItem>
                <MenuItem value="90">Net 90</MenuItem>
                <MenuItem value="120">Net 120</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* terms + posting date + deal size */}
          <Grid item xs={12} sm={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              label="Start date"
              type="date"
              fullWidth
              margin="dense"
              value={newStart}
              onChange={(evt) => setNewStart(evt.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              label="End date"
              type="date"
              fullWidth
              margin="dense"
              value={newEnd}
              onChange={(evt) => setNewEnd(evt.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="Campaign reference ID"
              variant="outlined"
              helperText="This reference ID will be on your Payments export for all Payments in this Campaign"
              margin="dense"
              value={newRefId}
              onChange={(evt) => setNewRefId(evt.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={agents}
              getOptionLabel={(option) => formatCreatorName(option)}
              value={newAgent}
              onChange={handleAgentChange}
              inputValue={agentInput}
              onInputChange={handleAgentInputChange}
              textInputProps={{
                label: 'Agent',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={css(g.mt_lg, g.flexRowEnd)}>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!newName || !newCustomer}
                loading={submitting}
              >
                Save and continue
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
