import { useState, useEffect } from 'react';
import {
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Grid,
} from '@material-ui/core';

import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import global from 'styles/global';
import { splitSourceLabels } from 'constants/labelMaps';
import SplitsChart from './SplitsChart';
import Select from 'components/Select';

function OrgSettingsSection({ orgState, handleUpdateOrg }) {
  const g = global();
  const {
    data: {
      agent_split_source: agentSplitSource,
      manager_split_source: managerSplitSource,
    },
  } = orgState;

  const [newAgentSplitSource, setNewAgentSplitSource] = useState();
  const [newManagerSplitSource, setNewManagerSplitSource] = useState();

  useEffect(() => {
    setNewAgentSplitSource(agentSplitSource);
    setNewManagerSplitSource(managerSplitSource);
  }, [agentSplitSource, managerSplitSource]);

  const cancelEditing = () => {
    setNewAgentSplitSource(agentSplitSource);
    setNewManagerSplitSource(managerSplitSource);
  };

  const saveFields = async () => {
    handleUpdateOrg(
      {
        agent_split_source: newAgentSplitSource,
        manager_split_source: newManagerSplitSource,
      },
      () => {}
    );
  };

  return (
    <EditCard
      variant="outlined"
      title="Splits"
      handleCancel={cancelEditing}
      handleSubmit={saveFields}
      staticContent={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <KeyValuePair
              label="Agent split source"
              value={splitSourceLabels[newAgentSplitSource]}
            />
          </Grid>

          <Grid item xs={12}>
            <KeyValuePair
              label="Manager split source"
              value={splitSourceLabels[newManagerSplitSource]}
            />
          </Grid>

          <Grid item xs={12}>
            <SplitsChart
              newAgentSplitSource={newAgentSplitSource}
              newManagerSplitSource={newManagerSplitSource}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2">
              This is a sample chart to show the flow of money assuming the
              Creator's split is 80%, and the Manager's and Agent's splits are
              both 10%.
            </Typography>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl size="small" variant="outlined" className={g.full_width}>
            <InputLabel id="select-agent-split-source-label">
              Agent split source
            </InputLabel>
            <Select
              labelId="select-agent-split-source-label"
              label="Agent split source"
              id="select-agent-split-source"
              value={newAgentSplitSource}
              onChange={(evt) => setNewAgentSplitSource(evt.target.value)}
            >
              <MenuItem value="creator">Creator's split</MenuItem>
              <MenuItem value="org">Org's split</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl size="small" variant="outlined" className={g.full_width}>
            <InputLabel id="select-manager-split-source-label">
              Manager split source
            </InputLabel>
            <Select
              labelId="select-manager-split-source-label"
              label="Manager split source"
              id="select-manager-split-source"
              value={newManagerSplitSource}
              onChange={(evt) => setNewManagerSplitSource(evt.target.value)}
            >
              <MenuItem value="creator">Creator's split</MenuItem>
              <MenuItem value="org">Org's split</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <SplitsChart
            newAgentSplitSource={newAgentSplitSource}
            newManagerSplitSource={newManagerSplitSource}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            This is a sample chart to show the flow of money assuming the
            Creator's split is 80%, and the Manager's and Agent's splits are
            both 10%.
          </Typography>
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default OrgSettingsSection;
