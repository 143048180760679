export const countries = [
  { label: 'United States', code: 'US' },
  { label: 'Canada', code: 'CA' },
  { label: 'Andorra', code: 'AD' },
  { label: 'Albania', code: 'AL' },
  { label: 'Austria', code: 'AT' },
  { label: 'Åland Islands', code: 'AX' },
  { label: 'Bosnia and Herzegovina', code: 'BA' },
  { label: 'Belgium', code: 'BE' },
  { label: 'Bulgaria', code: 'BG' },
  { label: 'Belarus', code: 'BY' },
  { label: 'Switzerland', code: 'CH' },
  { label: 'Cyprus', code: 'CY' },
  { label: 'Czech Republic', code: 'CZ' },
  { label: 'Germany', code: 'DE' },
  { label: 'Denmark', code: 'DK' },
  { label: 'Estonia', code: 'EE' },
  { label: 'Spain', code: 'ES' },
  { label: 'Finland', code: 'FI' },
  { label: 'Faroe Islands', code: 'FO' },
  { label: 'France', code: 'FR' },
  { label: 'United Kingdom', code: 'GB' },
  { label: 'Guernsey', code: 'GG' },
  { label: 'Greece', code: 'GR' },
  { label: 'Croatia', code: 'HR' },
  { label: 'Hungary', code: 'HU' },
  { label: 'Ireland', code: 'IE' },
  { label: 'Isle of Man', code: 'IM' },
  { label: 'Iceland', code: 'IC' },
  { label: 'Italy', code: 'IT' },
  { label: 'Jersey', code: 'JE' },
  { label: 'Liechtenstein', code: 'LI' },
  { label: 'Lithuania', code: 'LT' },
  { label: 'Luxembourg', code: 'LU' },
  { label: 'Latvia', code: 'LV' },
  { label: 'Monaco', code: 'MC' },
  { label: 'Moldova, Republic of', code: 'MD' },
  { label: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { label: 'Malta', code: 'MT' },
  { label: 'Netherlands', code: 'NL' },
  { label: 'Norway', code: 'NO' },
  { label: 'Poland', code: 'PL' },
  { label: 'Portugal', code: 'PT' },
  { label: 'Romania', code: 'RO' },
  { label: 'Russian Federation', code: 'RU' },
  { label: 'Sweden', code: 'SE' },
  { label: 'Slovenia', code: 'SI' },
  { label: 'Svalbard and Jan Mayen', code: 'SJ' },
  { label: 'Slovakia', code: 'SK' },
  { label: 'San Marino', code: 'SM' },
  { label: 'Ukraine', code: 'UA' },
  { label: 'Holy See (Vatican City State)', code: 'VA' },
];
