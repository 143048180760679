import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import TextField from 'components/TextField';

function OrgSettingsSection({ orgState, handleUpdateOrg }) {
  const {
    data: { name, website },
  } = orgState;

  const [newName, setNewName] = useState(name);
  const [newWebsite, setNewWebsite] = useState(website);

  const cancelEditing = () => {
    setNewName(name);
    setNewWebsite(website);
  };

  const saveFields = async () => {
    handleUpdateOrg({ name: newName, website: newWebsite }, () => {});
  };

  return (
    <EditCard
      variant="outlined"
      title="My Organization"
      handleCancel={cancelEditing}
      handleSubmit={saveFields}
      staticContent={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <KeyValuePair label="Organization name" value={newName} />
          </Grid>

          <Grid item xs={12}>
            <KeyValuePair label="Website" value={newWebsite} />
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Organization name"
            variant="outlined"
            margin="dense"
            value={newName}
            onChange={(evt) => setNewName(evt.target.value)}
            autoFocus
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Website"
            margin="dense"
            variant="outlined"
            value={newWebsite}
            onChange={(evt) => setNewWebsite(evt.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </EditCard>
  );
}

export default OrgSettingsSection;
