import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import css from 'classnames';
import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import global from 'styles/global';
import { createParams } from 'api/utils';
import { getIsDev, getIsDemo } from 'util/env';

import {
  paypalEnabledSelector,
  createPaypalOauth,
  getPaypalOauth,
} from 'slices/paypal';
import Card from 'components/Card';

const isDev = getIsDev();
const isDemo = getIsDemo();

function PayPalCta({ refreshData }) {
  const g = global();
  const dispatch = useDispatch();

  const openPaypal = () => {
    const queryParams = {
      response_type: 'code',
      scope: 'openid email https://uri.paypal.com/payments/payouts',
      flowEntry: 'static',
    };

    let paypalBaseURL;

    paypalBaseURL = 'https://www.sandbox.paypal.com/connect';
    queryParams.client_id =
      'AW2YR48av0EpR9u2LLmsaN9lQpOxQsDwZ_XCIAfy9IE6knpikirdJW07ExwVmeVRa3uz9Yl6u2493Zzi';
    queryParams.redirect_uri = 'http://local.creatorpay.co:3000/wallets?tab=paypal';
    if (isDemo) {
      queryParams.redirect_uri = 'http://trial.creatorpay.co/wallets?tab=paypal';
    }
    queryParams.authend = 'sandbox';

    if (!isDev && !isDemo) {
      paypalBaseURL = 'https://www.paypal.com/connect';
      queryParams.client_id =
        'AbFnUflvIZZrg-gV56B93K3_O-rreNOcpSnh96ToEd5Rx1vAha2o2BNWKN6ugtrjP8jsqTQnHUfGJtEc';
      queryParams.redirect_uri = 'http://app.creatorpay.co/wallets?tab=paypal';
    }

    const paypalURL = `${paypalBaseURL}?${createParams(queryParams)}`;

    const child = window.open(paypalURL, '_self');
    const interval = setInterval(() => {
      try {
        child.postMessage({ message: 'requestResult' }, '*');
      } catch (e) {
        if (child.closed) {
          clearInterval(interval);
        }
      }
    }, 500);
  };

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const code = params.get('code');

  useEffect(() => {
    if (code !== null && code !== '') {
      dispatch(createPaypalOauth({ code: code }));
      refreshData();
    }
  }, []);

  useEffect(() => {
    dispatch(getPaypalOauth());
  }, []);

  const isPayPalEnabled = useSelector((state) => paypalEnabledSelector(state));

  return (
    <>
      <Card
        title="PAYPAL & VENMO"
        subheader={
          !isPayPalEnabled &&
          'Log in with your business account to enable PayPal and Venmo payouts for Creators.'
        }
      >
        <Grid
          item
          xs={12}
          onClick={openPaypal}
          className={css(g.centerChildren, g.p_xl)}
        >
          <img
            className={g.clickable}
            src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/lipp/loginwithpaypalbutton.png"
            alt="Login with PayPal"
          />
        </Grid>
      </Card>
    </>
  );
}

export default PayPalCta;
