import Plot from 'react-plotly.js';

import global from 'styles/global';

export default function SplitsChart({
  newAgentSplitSource,
  newManagerSplitSource,
}) {
  const g = global();

  const linksAgentOrgManagerCreator = {
    source: [
      0, // budget to creator split
      0, // budget to org split

      1, // creator split to creator check
      1, // creator split to manager

      2, // org split to agent
      2, // org split to org check
    ],

    target: [
      1, // budget to creator split
      2, // budget to org split

      5, // creator split to creator check
      4, // creator split to manager

      3, // org split to agent
      6, // org split to org check
    ],

    value: [
      800, // budget to creator split
      200, // budget to org split

      720, // creator's split to creator check
      80, // creator split to manager

      20, // creator split to agent
      180, // org split to org check
    ],

    labels: [
      'Budget ($1000)', //0
      "Creator's split ($800)", //1
      "Org's split ($200)", //2
      "Agent's split ($20)", //3
      "Manager's split ($80)", //4
      "Creator's check ($720)", //5
      "Org's check ($180)", //6
    ]
  };

  const linksAgentCreatorManagerCreator = {
    source: [
      0, // budget to creator split
      0, // budget to org split

      1, // creator split to creator check
      1, // creator split to manager
      1, // creator split to agent

      2, // org split to org check
    ],

    target: [
      1, // budget to creator split
      2, // budget to org split

      5, // creator split to creator check
      4, // creator split to manager
      3, // creator split to agent

      6, // org split to org check
    ],

    value: [
      800, // budget to creator split
      200, // budget to org split

      640, // creator's split to creator check
      80, // creator split to manager
      80, // creator split to agent

      200, // org split to org check
    ],

    labels: [
      'Budget ($1000)', //0
      "Creator's split ($800)", //1
      "Org's split ($200)", //2
      "Agent's split ($80)", //3
      "Manager's split ($80)", //4
      "Creator's check ($640)", //5
      "Org's check ($200)", //6
    ]
  };

  const linksAgentOrgManagerOrg = {
    source: [
      0, // budget to creator split
      0, // budget to org split

      2, // org split to manager
      2, // org split to agent

      1, // creator check
      2, // creator check
    ],

    target: [
      1, // budget to creator split
      2, // budget to org split

      3, // org split to manager
      4, // org split to agent

      5, // creator check
      6, // org check
    ],

    value: [
      800, // budget to creator split
      200, // budget to org split

      20, // org split to manager
      20, // org split to agent

      800, // creator check
      160, // org check
    ],

    labels: [
      'Budget ($1000)', //0
      "Creator's split ($800)", //1
      "Org's split ($200)", //2
      "Agent's split ($20)", //3
      "Manager's split ($20)", //4
      "Creator's check ($800)", //5
      "Org's check ($160)", //6
    ]
  };

  const linksAgentCreatorManagerOrg = {
    source: [
      0, // budget to creator split
      0, // budget to org split

      2, // org split to manager
      1, // creator split to agent

      1, // creator check
      2, // budget to org split
    ],

    target: [
      1, // budget to creator split
      2, // budget to org split

      4, // org split to manager
      3, // creator split to agent

      5, // creator check
      6, // budget to org split
    ],

    value: [
      800, // budget to creator split
      200, // budget to org split

      20, // org split to manager
      80, // creator split to agent

      720, // creator check
      180, // budget to org split
    ],

    labels: [
      'Budget ($1000)', //0
      "Creator's split ($800)", //1
      "Org's split ($200)", //2
      "Agent's split ($80)", //3
      "Manager's split ($20)", //4
      "Creator's check ($720)", //5
      "Org's check ($180)", //6
    ]
  };

  let links;
  if (newManagerSplitSource === 'creator' && newAgentSplitSource === 'org') {
    links = linksAgentOrgManagerCreator;
  }
  if (
    newManagerSplitSource === 'creator' &&
    newAgentSplitSource === 'creator'
  ) {
    links = linksAgentCreatorManagerCreator;
  }
  if (newManagerSplitSource === 'org' && newAgentSplitSource === 'org') {
    links = linksAgentOrgManagerOrg;
  }
  if (newManagerSplitSource === 'org' && newAgentSplitSource === 'creator') {
    links = linksAgentCreatorManagerOrg;
  }

  return (
    <div className={g.border1}>
      <Plot
        className={g.full_width}
        config={{ responsive: true }}
        data={[
          {
            type: 'sankey',
            orientation: 'h',
            node: {
              pad: 15,
              thickness: 10,
              line: {
                color: 'black',
                width: 0.5,
              },
              label: links ? links.labels : [],
              color: ['black', 'blue', 'blue', 'blue', 'blue', 'blue', 'blue'],
            },

            link: links,
          },
        ]}
        layout={{
          margin: {
            b: 10,
            l: 10,
            t: 10,
            r: 10,
          },
          font: {
            size: 10,
          },
          height: 200,
          autosize: true,
        }}
      />
    </div>
  );
}
