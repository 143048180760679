import React from 'react';
import { ReactComponent as PayPalIcon } from 'assets/paypal.svg';
import { ReactComponent as VenmoIcon } from 'assets/venmo.svg';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  achIcon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.shades.white,
    padding: theme.spacing(1),
    borderRadius: '50%',
  },
  niumIcon: {
    backgroundColor: theme.palette.custom.cashGreen089,
    color: theme.palette.shades.white,
    padding: theme.spacing(1),
    borderRadius: '50%',
  },
}));

const PaymentMethodIcon = ({ method }) => {
  const classes = useStyles();

  switch (method) {
    case 'paypal':
      return <PayPalIcon height={24} width={24} />;
    case 'stripe':
      return (
        <AccountBalanceIcon fontSize="large" className={classes.achIcon} />
      );
    case 'venmo':
      return <VenmoIcon height={24} width={24} />;
    case 'nium':
      return (
        <AccountBalanceIcon fontSize="large" className={classes.niumIcon} />
      );
    default:
      return null;
  }
};

export default PaymentMethodIcon;
