import { handleError } from 'api/utils';
import { createRequest } from 'api/request';
import { createParams } from './utils';

export const getDeliverables = async (state, params) => {
  const qStr = createParams(params);
  return createRequest(`/v1/deliverables${qStr}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const createDeliverables = async (state, body) => {
  return createRequest(`/v1/deliverables`, state, {
    method: 'POST',
    body,
  }).then((res) => handleError(res));
};

export const updateDeliverables = async (state, body) => {
  return createRequest(`/v1/deliverables`, state, {
    method: 'PUT',
    body,
  }).then((res) => handleError(res));
};

export const deleteDeliverables = async (state, body) => {
  return createRequest(`/v1/deliverables`, state, {
    method: 'DELETE',
    body,
  }).then((res) => handleError(res));
};
