import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import css from 'classnames';

import global from 'styles/global';
import { collectedFeesSelector } from 'slices/collectedFees';
import TableHeader from 'components/TableHeader';
import DataGrid from 'components/DataGrid';
import { renderCreatedAtDate, renderFeeLink, renderFeeType } from 'util/table';
import LoadingOverlay from 'components/LoadingOverlay';
import { formatCurrency } from 'util/renderStrings';
import {
  orgFeesSelector,
  cardInvoiceFeeSelector,
  bankInvoiceFeeSelector,
  contractFeeSelector,
  outboundPmtFeesSelector,
} from 'slices/orgFees';
import {
  changeOrgPlan,
  changeOrgPlanSelector,
  currentOrgSelector,
} from 'slices/org';
import { renderFeeStructure } from 'util/renderStrings';
import { colors } from 'styles/global';
import Button from 'components/Button';
import { getSpelledOutDateFromIso } from 'util/time';
import ModalConfirm from 'components/ModalConfirm';

const useStyles = makeStyles((theme) => ({
  currentPlanCard: {
    border: '1px solid black',
  },
  planCard: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  freePlanDetail: {
    border: '1px solid',
    borderColor: theme.palette.shades.charcoal020,
    padding: theme.spacing(3),
  },
  premiumPlanBg: {
    backgroundColor: theme.palette.shades.lightBlueNew,
    overflow: 'hidden',
  },
  premiumPlanContent: {
    padding: theme.spacing(3),
    position: 'relative',
  },
  ribbon: {
    width: 150,
    height: 14,
    background: theme.palette.success.main,
    transform: 'rotate3d(0, 0, 1, 45deg)',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: '-30px',
    marginTop: '30px',
    float: 'right',
  },
  combineTypographys: {
    alignItems: 'baseline',
  },
}));

function BillingSection() {
  const g = global();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const { loading: collectedFeesLoading, data: collectedFees } = useSelector(
    collectedFeesSelector
  );
  const { loading: orgFeesLoading } = useSelector(orgFeesSelector);
  const loading = orgFeesLoading || collectedFeesLoading;

  const cardInvoiceFee = useSelector(cardInvoiceFeeSelector);
  const bankInvoiceFee = useSelector(bankInvoiceFeeSelector);
  const contractFee = useSelector(contractFeeSelector);
  const outboundPmtFee = useSelector(outboundPmtFeesSelector);

  const { data: currentOrg } = useSelector(currentOrgSelector);
  const { billing_plan, billing_amount, billing_date } = currentOrg;
  const isFree = billing_plan === 'free';

  const { loading: submitting } = useSelector(changeOrgPlanSelector);

  const handleUpgradePlan = (notification) => {
    dispatch(changeOrgPlan({ plan: 'standard' }));
  };

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      valueFormatter: renderFeeType,
    },
    {
      field: 'created_at',
      headerName: 'Transaction time',
      flex: 1,
      renderCell: renderCreatedAtDate,
      valueFormatter: renderCreatedAtDate,
    },
    {
      field: 'amount',
      headerName: 'amount',
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'link',
      headerName: 'link',
      flex: 1,
      renderCell: renderFeeLink,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
  ];

  return (
    <>
      <ModalConfirm
        open={upgradeModalVisible}
        handleClose={() => setUpgradeModalVisible(false)}
        callback={handleUpgradePlan}
        title="Confirm Upgrade"
        bodyText={`Are you sure you want to upgrade? You will be charged ${formatCurrency(
          24900
        )} staring next month.`}
        buttonText="Upgrade"
      />

      <LoadingOverlay open={loading} />
      <Grid container spacing={2}>
        {/* <Grid item sm={6} xs={12} className={g.mb_xl}>
          <div
            className={css(classes.planCard, isFree && classes.currentPlanCard)}
          >
            <div className={classes.freePlanDetail}>
              <Typography variant="h4" className={g.mb_md}>
                Free
              </Typography>
              <Typography variant="subtitle1" className={g.mb_lg}>
                Limited functionality to get you started
              </Typography>
              <Typography variant="h2" className={g.mb_md}>
                $0
              </Typography>
              <div className={css(g.flexRow, g.mb_xxs)}>
                <CheckIcon fontSize="small" htmlColor={colors.success.main} />
                <Typography
                  variant="h6"
                  className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                >
                  Limited to 1 User
                </Typography>
              </div>
              <div className={css(g.flexRow, g.mb_xxs)}>
                <CheckIcon fontSize="small" htmlColor={colors.success.main} />
                <Typography
                  variant="h6"
                  className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                >
                  Limited to 1 Creator
                </Typography>
              </div>
              <div className={css(g.flexRow, g.mb_xxs)}>
                <CheckIcon fontSize="small" htmlColor={colors.success.main} />
                <Typography
                  variant="h6"
                  className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                >
                  {`Contracts ${renderFeeStructure(contractFee)}`}
                </Typography>
              </div>
              <div className={css(g.flexRow, g.mb_xxs)}>
                <CheckIcon fontSize="small" htmlColor={colors.success.main} />
                <Typography
                  variant="h6"
                  className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                >
                  {`ACH Payments ${renderFeeStructure(outboundPmtFee)}`}
                </Typography>
              </div>
              <div className={css(g.flexRow, g.mb_xxs)}>
                <CheckIcon fontSize="small" htmlColor={colors.success.main} />
                <Typography
                  variant="h6"
                  className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                >
                  {`Invoices paid via ACH ${renderFeeStructure(
                    bankInvoiceFee
                  )}`}
                </Typography>
              </div>
              <div className={css(g.flexRow, g.mb_xxs)}>
                <CheckIcon fontSize="small" htmlColor={colors.success.main} />
                <Typography
                  variant="h6"
                  className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                >
                  {`Invoices paid via Card ${renderFeeStructure(
                    cardInvoiceFee
                  )}`}
                </Typography>
              </div>
              <div className={css(g.mt_xl, g.flexRowEven)}>
                {isFree ? (
                  <Button disabled color="secondary" variant="contained">
                    Current Plan
                  </Button>
                ) : (
                  <Button disabled color="secondary" variant="contained">
                    Contact support to downgrade
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Grid> */}
        <Grid item sm={12} xs={12}>
          <div
            className={css(
              classes.planCard
              //   !isFree && classes.currentPlanCard
            )}
          >
            <div className={classes.premiumPlanBg}>
              <div className={css(classes.ribbon)} />
              <div className={classes.premiumPlanContent}>
                <Typography variant="h4" className={g.mb_md}>
                  Premium
                </Typography>
                <Typography variant="subtitle1" className={g.mb_lg}>
                  Full suite of tools to pay Creators
                </Typography>
                <div className={css(g.flexRow, classes.combineTypographys)}>
                  <Typography variant="h2" className={g.mb_md}>
                    {!isFree
                      ? formatCurrency(billing_amount)
                      : formatCurrency(24900)}
                  </Typography>
                  <Typography variant="h4" className={css(g.mb_md, g.ml_sm)}>
                    /month
                  </Typography>
                </div>
                <div className={css(g.flexRow, g.mb_xxs)}>
                  <CheckCircleIcon
                    fontSize="small"
                    htmlColor={colors.success.main}
                  />
                  <Typography
                    variant="h6"
                    className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                  >
                    Unlimited Users
                  </Typography>
                </div>
                <div className={css(g.flexRow, g.mb_xxs)}>
                  <CheckCircleIcon
                    fontSize="small"
                    htmlColor={colors.success.main}
                  />
                  <Typography
                    variant="h6"
                    className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                  >
                    Unlimited Creators
                  </Typography>
                </div>
                <div className={css(g.flexRow, g.mb_xxs)}>
                  <CheckCircleIcon
                    fontSize="small"
                    htmlColor={colors.success.main}
                  />
                  <Typography
                    variant="h6"
                    className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                  >
                    {`Contracts ${renderFeeStructure(contractFee)}`}
                  </Typography>
                </div>
                <div className={css(g.flexRow, g.mb_xxs)}>
                  <CheckCircleIcon
                    fontSize="small"
                    htmlColor={colors.success.main}
                  />
                  <Typography
                    variant="h6"
                    className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                  >
                    {`ACH Payments ${renderFeeStructure(outboundPmtFee)}`}
                  </Typography>
                </div>
                <div className={css(g.flexRow, g.mb_xxs)}>
                  <CheckCircleIcon
                    fontSize="small"
                    htmlColor={colors.success.main}
                  />
                  <Typography
                    variant="h6"
                    className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                  >
                    {`Invoices paid via ACH ${renderFeeStructure(
                      bankInvoiceFee
                    )}`}
                  </Typography>
                </div>
                <div className={css(g.flexRow, g.mb_xxs)}>
                  <CheckCircleIcon
                    fontSize="small"
                    htmlColor={colors.success.main}
                  />
                  <Typography
                    variant="h6"
                    className={css(g.mt_auto, g.mb_auto, g.ml_md)}
                  >
                    {`Invoices paid via Card ${renderFeeStructure(
                      cardInvoiceFee
                    )}`}
                  </Typography>
                </div>
                <div className={css(g.mt_xl, g.flexRowEven)}>
                  {!isFree ? (
                    <div className={g.centerChildren}>
                      <Button
                        disabled
                        color="secondary"
                        variant="contained"
                        className={g.mb_xs}
                      >
                        Current Plan
                      </Button>
                      <Typography variant="subtitle2">
                        {`Next charge of ${formatCurrency(
                          billing_amount
                        )} will occur on ${getSpelledOutDateFromIso(
                          billing_date
                        )}`}
                      </Typography>
                    </div>
                  ) : (
                    <Button
                      onClick={() => setUpgradeModalVisible(true)}
                      color="primary"
                      variant="contained"
                      loading={submitting}
                    >
                      Upgrade
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TableHeader title="Collected Fees" topPadding={true} />
          <div className={g.tableWrapper}>
            <DataGrid
              autoHeight
              autoPageSize
              rows={collectedFees}
              columns={columns}
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default BillingSection;
