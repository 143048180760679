import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import css from 'classnames';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography } from '@material-ui/core';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import SendIcon from '@mui/icons-material/Send';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';

import global from 'styles/global';
import { logout } from 'slices/user';
import { isCustomerSelector } from 'slices/user';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  navItem: {
    color: theme.palette.shades.white,
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: `${theme.palette.shades.charcoal036}`,
    },
  },
  icon: {
    color: theme.palette.shades.white,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    height: '40px',
  },
  customIcon: {
    width: 26,
  },
  activeLink: {
    backgroundColor: `${theme.palette.brand.payBlue} !important`,

    '& $h5': {
      fontWeight: 'bold',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  divider: {
    backgroundColor: theme.palette.shades.white,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    height: '40px',
  },
}));

const CreatorNavItems = ({ setIsExpanded, isExpanded }) => {
  const g = global();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const isCustomer = useSelector(isCustomerSelector);

  const signOut = () => {
    dispatch(logout());
    history.push('/signin');
  };

  return (
    <div className={classes.container}>
      <div>
        <ListItem
          onClick={() => setIsExpanded(!isExpanded)}
          className={css(g.clickable, classes.navItem)}
        >
          <div className={classes.iconWrapper}>
            {isExpanded ? (
              <ChevronLeft className={classes.icon} />
            ) : (
              <ChevronRight className={classes.icon} />
            )}
          </div>
        </ListItem>

        <ListItem
          button
          selected={location.pathname === '/creator'}
          onClick={() => history.push('/creator')}
          className={classes.navItem}
          classes={{
            selected: classes.activeLink,
          }}
        >
          {!isExpanded ? (
            <div className={classes.iconWrapper}>
              <HomeIcon className={classes.icon} />
            </div>
          ) : (
            <Typography
              disableTypography
              variant="h5"
              className={classes.label}
            >
              Home
            </Typography>
          )}
        </ListItem>

        <ListItem
          button
          selected={location.pathname.includes('/creator/contracts')}
          onClick={() => history.push('/creator/contracts')}
          className={classes.navItem}
          classes={{
            selected: classes.activeLink,
          }}
        >
          {!isExpanded ? (
            <div className={classes.iconWrapper}>
              <AssignmentIcon className={classes.icon} />
            </div>
          ) : (
            <Typography
              disableTypography
              variant="h5"
              className={classes.label}
            >
              Contracts
            </Typography>
          )}
        </ListItem>

        {isCustomer ? (
          <ListItem
            button
            selected={location.pathname.includes('/creator/invoices')}
            onClick={() => history.push('/creator/invoices')}
            className={classes.navItem}
            classes={{
              selected: classes.activeLink,
            }}
          >
            {!isExpanded ? (
              <div className={classes.iconWrapper}>
                <ReceiptIcon className={classes.icon} />
              </div>
            ) : (
              <Typography
                disableTypography
                variant="h5"
                className={classes.label}
              >
                Invoices
              </Typography>
            )}
          </ListItem>
        ) : (
          <ListItem
            button
            selected={location.pathname.includes('/creator/payments')}
            onClick={() => history.push('/creator/payments')}
            className={classes.navItem}
            classes={{
              selected: classes.activeLink,
            }}
          >
            {!isExpanded ? (
              <div className={classes.iconWrapper}>
                <SendIcon className={classes.icon} />
              </div>
            ) : (
              <Typography
                disableTypography
                variant="h5"
                className={classes.label}
              >
                Payments
              </Typography>
            )}
          </ListItem>
        )}

        <ListItem
          button
          selected={location.pathname.includes('/creator/settings')}
          onClick={() => history.push('/creator/settings')}
          className={classes.navItem}
          classes={{
            selected: classes.activeLink,
          }}
        >
          {!isExpanded ? (
            <div className={classes.iconWrapper}>
              <SettingsIcon className={classes.icon} />
            </div>
          ) : (
            <Typography
              disableTypography
              variant="h5"
              className={classes.label}
            >
              Settings
            </Typography>
          )}
        </ListItem>
      </div>

      <ListItem button onClick={signOut} className={classes.navItem}>
        {!isExpanded ? (
          <div className={classes.iconWrapper}>
            <ExitToAppIcon className={classes.icon} />
          </div>
        ) : (
          <Typography disableTypography variant="h5" className={classes.label}>
            Sign out
          </Typography>
        )}
      </ListItem>
    </div>
  );
};

export default CreatorNavItems;
