import React, { useEffect, useState } from 'react';
import css from 'classnames';
import { isEmpty } from 'ramda';
import {
  Grid,
  IconButton,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import NewCreatorPayment from './NewCreatorPayment';
import NewCreatorDeliverable from './NewCreatorDeliverable';
import ManagerPayment from './ManagerPayment';
import global from 'styles/global';
import { formatCreatorName } from 'util/renderStrings';
import Button from 'components/Button';
import Autocomplete from 'components/Autocomplete';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  destructive: {
    color: theme.palette.error.main,
  },
}));

export default function NewCreatorRow({
  vals,
  updateProp,
  creators,
  deleteRow,
  deletePayment,
  deleteDeliverable,
  addPayment,
  addDeliverable,
  deleteManagerPayment,
  updateManagerPayment,
  addManagerPayment,
  idx,
  managerSplitSource,
  isPaymentStep,
}) {
  const g = global();
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState('');

  // const validateFields = () => {
  //   const { recipient, amount } = vals;
  //   let error = '';
  //   if (!recipient) {
  //     error = 'No Recipent specified';
  //   } else if (!amount) {
  //     error = 'No amount provided';
  //   } else if (amount <= constants.minAmountThresholdInDollars) {
  //     error = `Amount to be paid should be more than $${constants.minAmountThresholdInDollars}`;
  //   }

  //   return error;
  // };

  // const handleUpdateProp = (id, val, key) => {
  //   if (!dirty) {
  //     setDirty(true);
  //   }

  //   updateProp(id, val, key);
  // };

  // useEffect(() => {
  //   const error = validateFields();
  //   updateProp(vals.id, error, 'error');
  //   setError(error);
  // }, [vals.amount, vals.recipient, vals.memo, vals.group]);

  return (
    <Grid container spacing={1}>
      <Grid item sm={4} className={css(g.flexRow, g.alignCenter, g.mb_zero)}>
        {/* creator selection */}
        <Typography variant="h5" className={g.mr_sm}>
          {idx + 1}
        </Typography>
        {creators.length === 0 ? (
          <Link to="/creators">
            <Typography variant="h5">Add a Creator</Typography>
          </Link>
        ) : (
          <Autocomplete
            className={g.mb_zero}
            options={creators}
            // Disabling inactive creators if there is a campaign selected
            getOptionDisabled={(creator) =>
              !isEmpty(vals.group) && !creator.is_active
            }
            getOptionLabel={formatCreatorName}
            value={vals.creator}
            onChange={(_, value) => updateProp(vals.id, value, 'creator')}
            textInputProps={{
              label: 'Creator',
              required: true,
            }}
          />
        )}
      </Grid>

      <Grid item sm={4} className={css(g.flexRow, g.alignCenter)}>
        <IconButton className={g.ml_zero} onClick={() => deleteRow(vals.id)}>
          <CloseIcon className={classes.destructive} />
        </IconButton>
      </Grid>
      <FormHelperText error className={g.ml_lg}>
        {dirty && error}
      </FormHelperText>

      <Grid item xs={12}>
        <div className={g.ml_xxl}>
          {/* payments for row */}
          {isPaymentStep && (
            <>
              <Typography variant="h5" className={g.mr_sm}>
                Payments
              </Typography>
              {vals.payments.map((pmt) => (
                <NewCreatorPayment
                  vals={pmt}
                  key={pmt.id}
                  updateProp={updateProp}
                  deletePayment={deletePayment}
                  rowId={vals.id}
                />
              ))}
              <div className={g.flexRowEnd}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => addPayment(vals.id)}
                >
                  Add Payment
                </Button>
              </div>

              {vals.creator?.manager_id && (
                <ManagerPayment
                  rowId={vals.id}
                  managerPayment={vals.managerPayment}
                  updateManagerPayment={updateManagerPayment}
                  deleteManagerPayment={deleteManagerPayment}
                  addManagerPayment={addManagerPayment}
                  managerId={vals.creator?.manager_id}
                  managerSplitSource={managerSplitSource}
                />
              )}
            </>
          )}

          {!isPaymentStep && (
            <>
              <div className={css(g.alignEnd, g.flexRowSpacing)}>
                <Typography variant="h5" className={g.mr_sm}>
                  Deliverables
                </Typography>
              </div>
              {/* deliverables for row */}
              {vals.deliverables.map((deliv) => (
                <NewCreatorDeliverable
                  vals={deliv}
                  key={deliv.id}
                  updateProp={updateProp}
                  deleteDeliverable={deleteDeliverable}
                  rowId={vals.id}
                />
              ))}
              <div className={css(g.flexRowEnd, g.mt_xxs)}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => addDeliverable(vals.id)}
                >
                  Add Deliverable
                </Button>
              </div>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
