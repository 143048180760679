import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  getWallets,
  walletsSelector,
  paypalWalletSelector,
} from 'slices/wallets';
import {
  deletePaypalOauthSelector,
  deletePaypalOauth,
  paypalOauthSelector,
} from 'slices/paypal';
import ModalConfirm from 'components/ModalConfirm';
import LoadingOverlay from 'components/LoadingOverlay';
import PaymentMethodIcon from 'components/PaymentMethodIcon';
import IconWithInfo from 'components/IconWithInfo';
import PayPalCta from './PayPalCta';

export default function PayPalTab() {
  const dispatch = useDispatch();

  const refreshData = () => {
    dispatch(getWallets());
  };

  useEffect(() => {
    refreshData();
  }, []);

  const paypalWallet = useSelector(paypalWalletSelector);
  const { loading } = useSelector(walletsSelector);
  const { loading: deleteLoading } = useSelector(deletePaypalOauthSelector);
  const paypalOauth = useSelector(paypalOauthSelector);

  const [removeModalVisible, setRemoveModalVisible] = useState(false);

  const handleDelete = async () => {
    await dispatch(deletePaypalOauth());
    refreshData();
    setRemoveModalVisible(false);
  };

  return (
    <div>
      <LoadingOverlay open={loading} />
      <ModalConfirm
        open={removeModalVisible}
        handleClose={() => setRemoveModalVisible(false)}
        callback={handleDelete}
        title="DEACTIVATE PAYPAL"
        bodyText="Are you sure you want to deactivate PayPal? 
        Creators will no longer be able to receive PayPal
        payments."
        buttonText="Deactivate"
        loading={deleteLoading}
        isWarning
      />

      {!paypalWallet && <PayPalCta refreshData={refreshData}></PayPalCta>}

      {!!paypalWallet && (
        <div>
          <IconWithInfo
            buttonAction={() => setRemoveModalVisible(true)}
            submitting={deleteLoading}
            text="Linked account"
            subtext={paypalOauth.email}
            icon={<PaymentMethodIcon method="paypal" />}
            buttonIcon={<DeleteIcon fontSize="small" />}
          />
        </div>
      )}
    </div>
  );
}
