import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getTagOptions = async (state) => {
  return createRequest(`/v1/tags`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const getCreatorTags = async (state, data) => {
  const { creator_id } = data;
  return createRequest(`/v1/tags/enrollments?creator_id=${creator_id}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const addTagToCreator = async (state, data) => {
  return createRequest(`/v1/tags/enrollment`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};

export const removeTagFromCreator = async (state, data) => {
  return createRequest(`/v1/tags/enrollment`, state, {
    method: 'DELETE',
    body: data,
  }).then((res) => handleError(res));
};

export const createTag = async (state, data) => {
  return createRequest(`/v1/tags`, state, {
    method: 'POST',
    body: data,
  }).then((res) => handleError(res));
};
