import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getNotifications = async (state) => {
  return createRequest(`/v1/notifications`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const dismissNotification = async (state, id) => {
  return createRequest(`/v1/notifications/${id}`, state, {
    method: 'PUT',
  }).then((res) => handleError(res));
};
