import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';

import LoadingOverlay from 'components/LoadingOverlay';
import PaymentsTable from 'components/PaymentsTable';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
} from 'slices/payments';
import { setHeaderData } from 'slices/misc';
import { orgsSelector, getOrgs as getOrgsSlice } from 'slices/org';

function CreatorPayments({ getPayments, paymentsState, orgsState }) {
  const dispatch = useDispatch();
  const { loading: orgsLoading, data: orgs } = orgsState;
  const { loading: paymentsLoading, data: payments } = paymentsState;
  const loading = paymentsLoading || orgsLoading;

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Payments',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Payments' },
        ],
      })
    );

    getPayments();
  }, []);

  return (
    <div>
      <LoadingOverlay open={loading} />
      <div>
        <PaymentsTable
          columnsToDisplay={[
            'id_no_link',
            'org_id',
            'status',
            'memo',
            'amount',
            'date_posted',
          ]}
          payments={payments}
          orgs={orgs}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  paymentsState: paymentsSelector(state),
  orgsState: orgsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPayments: () => dispatch(getPaymentsSlice()),
  getOrgs: () => dispatch(getOrgsSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorPayments);
