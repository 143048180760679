import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';
import Button from 'components/Button';
import global from 'styles/global';
import {
  addCreatorsBulk as addCreatorsBulkSlice,
  addCreatorsBulkSelector,
} from 'slices/user';
import AddIcon from '@mui/icons-material/Add';
import { useDropzone } from 'react-dropzone';
import css from 'classnames';
import { parseBulkCreators } from 'util/csvParsing/bulkCreators';
import { formatCreatorName } from 'util/renderStrings';
import { colors } from 'styles/global';
import DataGrid from 'components/DataGrid';

const useStyles = makeStyles((theme) => ({
  modal: {
    minHeight: '200px',
  },
}));

const AddCreatorsBulkModal = ({
  open,
  onClose,
  addCreatorsBulk,
  addCreatorsBulkState,
  setNewCreatorId,
}) => {
  const classes = useStyles();
  const g = global();

  const { loading: submitting } = addCreatorsBulkState;

  const [file, setFile] = useState();
  const [dropErr, setDropErr] = useState('');
  const [parseResult, setParseResult] = useState([]);

  const [invalidCreators, setInvalidCreators] = useState([]);
  const [validCreators, setValidCreators] = useState([]);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone();

  const handleClose = () => {
    setFile();
    setDropErr('');
    setParseResult([]);
    setInvalidCreators([]);
    setValidCreators([]);
    onClose();
  };

  useEffect(() => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    if (newFile.type != 'text/csv') {
      setDropErr(`Invalid file type ${newFile.type}`);
      return;
    }
    setFile({
      file: newFile,
    });
    setDropErr('');
    Papa.parse(newFile, {
      complete: (res) => setParseResult(res.data),
      header: true,
      transformHeader: (h) => h.toLowerCase(),
    });
  }, [acceptedFiles]);

  useEffect(() => {
    if (parseResult.length > 0) {
      const parsedCopy = [...parseResult];

      const { valid, invalid } = parseBulkCreators(parsedCopy);

      setValidCreators(valid);
      setInvalidCreators(invalid);
    }
  }, [parseResult]);

  const validColumns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => formatCreatorName(params.row),
    },
    {
      field: 'phone_number',
      headerName: 'Phone',
      flex: 1,
    },
    {
      field: 'entity_type',
      headerName: 'Type',
      flex: 1,
    },
    {
      // TODO update to payment method name
      field: 'has_bank',
      headerName: 'Has bank info',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        const acctNum = params.getValue(params.id, 'account_number');
        const routingNum = params.getValue(params.id, 'routing_number');
        const hasBank = acctNum && routingNum;
        return hasBank ? (
          <CheckCircleIcon htmlColor={colors.success.main} />
        ) : (
          <CancelIcon className={classes.destructive} />
        );
      },
    },
  ];

  const invalidColumns = [
    {
      field: 'id',
      headerName: 'Index',
      flex: 1,
    },
    {
      field: 'content',
      headerName: 'Content',
      flex: 4,
    },
  ];

  const handleAddCreatorsBulk = async () => {
    const success = await addCreatorsBulk({ data: validCreators });
    if (success) {
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      className={classes.modal}
    >
      <ModalTitle onClose={handleClose}>
        <div>
          <Typography variant="h3" className={g.mb_sm}>
            Bulk Add Creators
          </Typography>
          <Typography variant="subtitle1">
            Expected column headers are email*, first_name, last_name,
            business_name, entity_type, phone_number, account_number and
            routing_number. You can download a template{' '}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://storage.googleapis.com/creator-pay-assets/bulk_creator_template.csv"
            >
              here
            </a>
            .
          </Typography>
        </div>
      </ModalTitle>

      <ModalContent>
        {!file ? (
          <section>
            <div {...getRootProps({ className: g.dropzone })}>
              <input {...getInputProps()} />
              <AddIcon style={{ fontSize: 60 }} className={g.charcoal036} />
              <Typography variant="body1">
                Drag and drop a Bulk Creator CSV to get started
              </Typography>
            </div>
            <aside>
              <Typography variant="body1" className={css(g.error, g.mt_sm)}>
                {dropErr}
              </Typography>
            </aside>
          </section>
        ) : (
          <>
            <Accordion className={g.mb_md}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>{`${validCreators.length} Creators will be added`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  autoHeight
                  autoPageSize
                  rows={validCreators.map((creator, idx) => ({
                    ...creator,
                    id: idx,
                  }))}
                  columns={validColumns}
                  disableSelectionOnClick
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>{`${invalidCreators.length} rows had issues`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  autoHeight
                  autoPageSize
                  rows={invalidCreators}
                  columns={invalidColumns}
                  disableSelectionOnClick
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </ModalContent>

      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          loading={submitting}
          onClick={handleAddCreatorsBulk}
          color="primary"
          variant="contained"
          disabled={validCreators.length === 0}
        >
          Add
        </Button>
      </ModalActions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  addCreatorsBulkState: addCreatorsBulkSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  addCreatorsBulk: (data) => dispatch(addCreatorsBulkSlice(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCreatorsBulkModal);
