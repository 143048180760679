export const emptyOrgData = {
  id: '',
  name: '',
  created_at: '',
  updated_at: '',
  website: '',
  treasury_id: '',
  connect_id: '',
  has_representative: false,
};
