import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Grid } from '@material-ui/core';
import Button from 'components/Button';
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalTitle,
} from 'components/Modal';

import global from 'styles/global';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  personalSection: {
    marginBottom: theme.spacing(2),
  },
}));

const AddNiumWalletModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');

  const classes = useStyles();
  const g = global();

  const handleCreateNiumWallet = async () => {
    // await dispatch(
    //   sendAch({
    //     amount: amountCents,
    //     name,
    //     description: memo,
    //     account_num: acct,
    //     routing_num: routing,
    //   })
    // );
    handleClose();
  };

  const handleClose = () => {
    setFirstName('');
    setMiddleName('');
    setLastName('');
    onClose();
  };

  // const isInputInvalid =
  //   name === '' || !amount || memo === '' || acct === '' || routing === '';

  return (
    <Modal open={open} onClose={handleClose} fullWidth>
      <ModalTitle onClose={handleClose}>
        <Typography variant="h3">Create Nium Wallet</Typography>
      </ModalTitle>
      <ModalContent>
        <Typography variant="body2">Personal info</Typography>
        <Grid container spacing={1} className={classes.personalSection}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="First Name"
              onChange={(evt) => setFirstName(evt.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Middle Name"
              onChange={(evt) => setMiddleName(evt.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Last Name"
              onChange={(evt) => setLastName(evt.target.value)}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          loading={false}
          onClick={handleCreateNiumWallet}
          color="primary"
          variant="contained"
          disabled={false}
        >
          {'Create'}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default AddNiumWalletModal;
