import React, { useState } from 'react';

import Card from '../Card';
import TextField from '../TextField';
import Button from '../Button';

import global from 'styles/global';

const CardPayoutForm = ({ userID }) => {
  const g = global();

  const [cardNumber, setCardNumber] = useState({ val: '', dirty: false });
  const [nameOnCard, setNameOnCard] = useState({ val: '', dirty: false });
  const [expirationDate, setExpirationDate] = useState({
    val: '',
    dirty: false,
  });
  const [cvv, setCVV] = useState({ val: '', dirty: false });

  const isInputValid =
    cardNumber.val && nameOnCard.val && expirationDate.val && cvv.val;

  const savePaymentMethod = () => {
    alert('Saving card');
  };

  return (
    <Card title="Beneficiaries Card">
      <TextField
        margin="dense"
        variant="outlined"
        label="Card Number"
        value={cardNumber.val}
        error={cardNumber.dirty && !cardNumber.val}
        helperText={
          cardNumber.dirty && !cardNumber.val && 'This field is required'
        }
        onChange={(e) => setCardNumber({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Name on Card"
        value={nameOnCard.val}
        error={nameOnCard.dirty && !nameOnCard.val}
        helperText={
          nameOnCard.dirty && !nameOnCard.val && 'This field is required'
        }
        onChange={(e) => setNameOnCard({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="Expiration Date"
        value={expirationDate.val}
        error={expirationDate.dirty && !expirationDate.val}
        helperText={
          expirationDate.dirty &&
          !expirationDate.val &&
          'This field is required'
        }
        onChange={(e) =>
          setExpirationDate({ val: e.target.value, dirty: true })
        }
        required
        fullWidth
      />
      <TextField
        margin="dense"
        variant="outlined"
        label="CVV"
        value={cvv.val}
        error={cvv.dirty && !cvv.val}
        helperText={cvv.dirty && !cvv.val && 'This field is required'}
        onChange={(e) => setCVV({ val: e.target.value, dirty: true })}
        required
        fullWidth
      />
      <Button
        loading={false}
        onClick={savePaymentMethod}
        color="primary"
        variant="contained"
        disabled={!isInputValid}
      >
        Save
      </Button>
    </Card>
  );
};

export default CardPayoutForm;
