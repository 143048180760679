import { createSlice, createSelector } from '@reduxjs/toolkit';
import { groupBy, prop } from 'ramda';

import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import { getCharges as getChargesAPI } from 'api/charges';


export const initialChargesState = {
  getCharges: getGenericState([]),
};

export const chargesSlice = createSlice({
  name: 'charges',
  initialState: initialChargesState,
  reducers: {
    getChargesStarted: getGenericStarted('getCharges'),
    getChargesSuccess: getPayloadSuccess('getCharges'),
    getChargesFailure: getGenericFailure('getCharges'),
  },
});

export const { getChargesStarted, getChargesSuccess, getChargesFailure } =
  chargesSlice.actions;

export default chargesSlice.reducer;

export const getCharges = (data) => async (dispatch, getState) => {
  dispatch(getChargesStarted());
  try {
    const res = await getChargesAPI(getState(), data);
    dispatch(getChargesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getChargesFailure,
      'There was an issue retrieving your charges'
    );
  }
};

// selectors
const selectCharges = (state) => state.charges;

export const chargesSelector = createSelector(
  selectCharges,
  (chargesState = {}) => chargesState.getCharges || getGenericState([])
);

export const chargesByInvoiceSelector = createSelector(
    chargesSelector,
    (chargesState = {}) => {
        const { data } = chargesState;
        return groupBy(prop('invoice_id'), data);
    }
  );