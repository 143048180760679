import css from 'classnames';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import global from 'styles/global';
import constants from 'constants/index.js';

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: constants.bannerOffset,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.shades.white,
    zIndex: 1000,
  },
}));

export default function DelinquentBanner() {
  const classes = useStyles();
  const g = global();

  return (
    <div className={css(g.centerChildren, classes.banner)}>
      <Typography>
        Your Organization's billing is delinquent, please{' '}
        <Link className={g.clickable} to="/wallets">
          add funds
        </Link>
      </Typography>
    </div>
  );
}
