import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import css from 'classnames';

import global from 'styles/global';
import EditCard from 'components/EditCard';
import { Grid, IconButton, Typography } from '@material-ui/core';
import Button from 'components/Button';
import { getIconForLink } from 'assets/platformIcons';
import TextField from 'components/TextField';

const Platforms = ({ creator, updateCreator }) => {
  const g = global();
  const dispatch = useDispatch();

  const { platforms } = creator;

  const [jsonPlatforms, setJSONPlatforms] = useState([]);
  const [newPlatforms, setNewPlatforms] = useState([]);

  useEffect(() => {
    setJSONPlatforms(platforms ? JSON.parse(platforms) : []);
  }, [platforms]);

  useEffect(() => {
    setNewPlatforms(jsonPlatforms);
  }, [jsonPlatforms]);

  const handleUpdateCreator = async () => {
    const updateObj = {
      id: creator.id,
      platforms: JSON.stringify(newPlatforms),
    };
    await dispatch(updateCreator(updateObj));
  };

  const addPlatform = () => {
    const platformsAdded = [...newPlatforms];
    platformsAdded.push('');
    setNewPlatforms(platformsAdded);
  };

  const editPlatformRow = (idx, val) => {
    const platformsEdited = [...newPlatforms];
    platformsEdited[idx] = val;
    setNewPlatforms(platformsEdited);
  };

  const deletePlatformRow = (idx) => {
    const platformDelete = [...newPlatforms];
    platformDelete.splice(idx, 1);
    setNewPlatforms(platformDelete);
  };

  return (
    <EditCard
      title="Platforms"
      handleCancel={() => setNewPlatforms(jsonPlatforms)}
      handleSubmit={handleUpdateCreator}
      staticContent={
        jsonPlatforms.length > 0 ? (
          jsonPlatforms.map((platform) => (
            <Grid item xs={12} className={css(g.flexRow, g.pv_xs)}>
              <div className={css(g.centerChildren, g.mr_sm)}>
                {getIconForLink(platform)}
              </div>
              <Typography variant="h5" className={g.centerChildren}>
                <a href={platform} target="_blank" rel="noreferrer">
                  {platform}
                </a>
              </Typography>
            </Grid>
          ))
        ) : (
          <Typography variant="h5">No platforms</Typography>
        )
      }
    >
      <Grid container spacing={1}>
        {newPlatforms.map((platformVal, i) => (
          <>
            <Grid item xs={11}>
              <TextField
                label="Link to platform"
                variant="outlined"
                required
                fullWidth
                margin="dense"
                value={platformVal}
                onChange={(evt) => editPlatformRow(i, evt.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => deletePlatformRow(i)}>
                <CloseIcon className={g.error} />
              </IconButton>
            </Grid>
          </>
        ))}
        <Grid item xs={12}>
          <Button onClick={addPlatform} size="small">
            Add Platform
          </Button>
        </Grid>
      </Grid>
    </EditCard>
  );
};

export default Platforms;
