import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  sendAch as sendAchAPI,
  _addFundsDev as _addFundsDevAPI,
  attachBank as attachBankAPI,
  sendAchDebit as sendAchDebitAPI,
} from 'api/treasury';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import { getWallets } from 'slices/wallets';

export const initialOrgState = {
  sendAch: getGenericState(),
  _addFundsDev: getGenericState(),
  attachBank: getGenericState(),
  sendAchDebit: getGenericState(),
};

export const treasurySlice = createSlice({
  name: 'treasury',
  initialState: initialOrgState,
  reducers: {
    sendAchStarted: getGenericStarted('sendAch'),
    sendAchSuccess: getGenericSuccess('sendAch'),
    sendAchFailure: getGenericFailure('sendAch'),

    _addFundsDevStarted: getGenericStarted('_addFundsDev'),
    _addFundsDevSuccess: getGenericSuccess('_addFundsDev'),
    _addFundsDevFailure: getGenericFailure('_addFundsDev'),

    attachBankStarted: getGenericStarted('attachBank'),
    attachBankSuccess: getPayloadSuccess('attachBank'),
    attachBankFailure: getGenericFailure('attachBank'),

    sendAchDebitStarted: getGenericStarted('sendAchDebit'),
    sendAchDebitSuccess: getGenericSuccess('sendAchDebit'),
    sendAchDebitFailure: getGenericFailure('sendAchDebit'),
  },
});

export const {
  sendAchStarted,
  sendAchSuccess,
  sendAchFailure,

  _addFundsDevStarted,
  _addFundsDevSuccess,
  _addFundsDevFailure,

  attachBankStarted,
  attachBankSuccess,
  attachBankFailure,

  sendAchDebitStarted,
  sendAchDebitSuccess,
  sendAchDebitFailure,
} = treasurySlice.actions;

export default treasurySlice.reducer;

export const sendAch = (data, callback) => async (dispatch, getState) => {
  dispatch(sendAchStarted());
  try {
    await sendAchAPI(getState(), data);
    dispatch(sendAchSuccess());
    dispatch(getWallets());
    callback && callback();
  } catch (err) {
    handleError(
      err,
      dispatch,
      sendAchFailure,
      'There was an issue transferring funds'
    );
  }
};

export const _addFundsDev = (data) => async (dispatch, getState) => {
  dispatch(_addFundsDevStarted());
  try {
    await _addFundsDevAPI(getState(), data);
    dispatch(_addFundsDevSuccess());
    dispatch(getWallets());
  } catch (err) {
    handleError(
      err,
      dispatch,
      _addFundsDevFailure,
      'There was an issue adding funds'
    );
  }
};

export const attachBank = (data) => async (dispatch, getState) => {
  dispatch(attachBankStarted());
  try {
    await attachBankAPI(getState(), data);
    dispatch(attachBankSuccess());
    dispatch(getWallets());
    toast.success(
      'Bank account added, please check for trial deposits in 1-2 business days.'
    );
  } catch (err) {
    handleError(
      err,
      dispatch,
      attachBankFailure,
      'There was an issue adding your bank'
    );
  }
};

export const sendAchDebit = (data) => async (dispatch, getState) => {
  dispatch(sendAchDebitStarted());
  try {
    await sendAchDebitAPI(getState(), data);
    dispatch(sendAchDebitSuccess());
    dispatch(getWallets());
    toast.success('Funds are on the way!');
  } catch (err) {
    handleError(
      err,
      dispatch,
      sendAchDebitFailure,
      'There was an issue transferring funds'
    );
  }
};

// selectors
const selectTreasury = (state) => state.treasury;

export const sendAchSelector = createSelector(
  selectTreasury,
  (treasuryState = {}) => treasuryState.sendAch || getGenericState()
);

export const _addFundsDevSelector = createSelector(
  selectTreasury,
  (treasuryState = {}) => treasuryState._addFundsDev || getGenericState()
);

export const attachBankSelector = createSelector(
  selectTreasury,
  (treasuryState = {}) => treasuryState.attachBank || getGenericState()
);

export const sendAchDebitSelector = createSelector(
  selectTreasury,
  (treasuryState = {}) => treasuryState.sendAchDebit || getGenericState()
);
