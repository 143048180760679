import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import DataGrid from 'components/DataGrid';
import TabPanel from 'components/TabPanel';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import global from 'styles/global';

import {
  contractsSelector,
  invalidateContractsSelector,
  remindContractsSelector,
  getContracts as getContractsSlice,
  invalidateContracts as invalidateContractsSlice,
  remindContracts as remindContractsSlice,
  contractsDictSelector,
  contractWithRecipientSelector,
} from 'slices/contracts';
import { getCreators as getCreatorsSlice, creatorsSelector } from 'slices/user';
import Tabs from 'components/Tabs';
import BillingAuthModal from 'components/BillingAuthModal';
import {
  contractTemplatesSelector,
  getContractTemplates as getContractTemplatesSlice,
} from 'slices/contractTemplates';
import objectStates from 'constants/objectStates';
import { getCustomers as getCustomersSlice } from 'slices/customers';
import { setHeaderData } from 'slices/misc';
import { billingAuthSelector } from 'slices/org';
import ContractsTable from 'components/ContractsTable';
import { getDateAndTimeFromIso } from 'util/time';

const TAB_QUERY_PARAMS = ['contracts', 'templates'];

function Contracts({
  invalidateContractsState,
  remindContractState,
  contractsState,
  getContracts,
  getCreators,
  creatorsState,
  invalidateContracts,
  sendReminders,
  getContractTemplates,
  contractTemplatesState,
  contractsDict,
  getCustomers,
}) {
  const dispatch = useDispatch();
  const g = global();
  const history = useHistory();

  const { loading: invalidateLoading } = invalidateContractsState;
  const { loading: remindLoading } = remindContractState;

  const { loading: contractsLoading } = contractsState;
  const { loading: creatorsLoading } = creatorsState;
  const { loading: templatesLoading, data: templates } = contractTemplatesState;
  const loading = contractsLoading || creatorsLoading || templatesLoading;

  const contracts = useSelector(contractWithRecipientSelector);
  const { isDelinquent } = useSelector(billingAuthSelector);

  const navToNewContract = () => {
    if (isDelinquent) {
      setBillingModalVisible(true);
      return;
    }
    history.push('/contracts/newContract');
  };

  const [selectedContracts, setSelectedContracts] = useState([]);
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  // const [sendSmsButtonEnabled, setSendSmsButtonEnabled] = useState(false);
  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Contracts',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Contracts' },
        ],
      })
    );

    getContracts();
    getCreators();
    getCustomers();
    getContractTemplates();
  }, []);

  // useEffect(() => {
  //   let isEnabled = true;
  //   if (selectedContracts.length === 0) {
  //     return;
  //   }
  //   selectedContracts.forEach((contractId) => {
  //     let creator_id = contracts.find((c) => c.id === contractId)?.creator_id;

  //     const recipient = creatorsDict[creator_id] || emptyCreatorData;
  //     if (!recipient.phone_number) {
  //       isEnabled = false;
  //     }
  //   });
  //   setSendSmsButtonEnabled(isEnabled);
  // }, [selectedContracts, creatorsDict, contracts]);

  const templateColumns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      renderCell: (params) => {
        if (!params.value) return '-';
        return getDateAndTimeFromIso(params.value);
      },
      flex: 1,
    },
  ];

  const query = new URLSearchParams(useLocation().search);
  const tab = query.get('tab');

  let selectedTab = TAB_QUERY_PARAMS.indexOf(tab);

  if (!tab) {
    selectedTab = 0;
  } else if (tab && selectedTab < 0) {
    selectedTab = 0;
    history.push('/contracts');
  }
  const [currentTab, setCurrentTab] = useState(selectedTab);

  const changeTab = (_, newValue) => {
    setCurrentTab(newValue);
    history.push(`/contracts?tab=${TAB_QUERY_PARAMS[newValue]}`);
  };

  const handleSendEmailReminders = () => {
    sendReminders(selectedContracts, 'email');
  };
  // const handleSendSmsReminders = () => {
  //   sendReminders(selectedContracts, 'sms');
  // };

  const handleInvalidateContracts = () => {
    invalidateContracts(selectedContracts);
  };

  const allSelectedAreSent = selectedContracts.every(
    (c) => contractsDict[c].status === objectStates.requires_info
  );

  return (
    <div>
      <LoadingOverlay open={loading} />

      <BillingAuthModal
        open={billingModalVisible}
        onClose={() => setBillingModalVisible(false)}
        isDelinquent
      />

      <Tabs
        tabLabels={['Contracts', 'Templates']}
        value={currentTab}
        onChange={changeTab}
        aria-label="contracts-tabpanel"
      />

      {/* All */}
      <TabPanel value={currentTab} index={0}>
        <TableHeader title="Contracts">
          <div>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              disabled={selectedContracts.length === 0 || !allSelectedAreSent}
              onClick={handleInvalidateContracts}
              loading={invalidateLoading}
            >
              Invalidate
            </Button>
            {/* <Button
              size="small"
              variant="outlined"
              color="primary"
              className={g.ml_sm}
              disabled={!sendSmsButtonEnabled}
              onClick={handleSendSmsReminders}
              loading={remindLoading}
            >
              Send SMS reminder
            </Button> */}
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={g.ml_xs}
              disabled={selectedContracts.length === 0 || !allSelectedAreSent}
              onClick={handleSendEmailReminders}
              loading={remindLoading}
            >
              Send email reminder
            </Button>
            <Button
              size="small"
              variant="contained"
              className={g.ml_xs}
              color="primary"
              onClick={navToNewContract}
            >
              New Contract
            </Button>
          </div>
        </TableHeader>
        <div className={g.tableWrapper}>
          <ContractsTable
            suppressMemoization
            autoHeight
            autoPageSize
            contracts={contracts}
            columnsToDisplay={[
              'title',
              'recipient_type',
              'recipient_id',
              'status',
              'signature_date',
            ]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={setSelectedContracts}
          />
        </div>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <TableHeader title="Templates">
          <Tooltip
            title={
              templates.length > 0
                ? 'Contact support to create more Contract Templates'
                : '' // doesn't show up if there's no text
            }
          >
            <span>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => history.push('/contracts/newContractTemplate')}
                disabled={templates.length > 0}
              >
                New Template
              </Button>
            </span>
          </Tooltip>
        </TableHeader>
        <div className={g.tableWrapper}>
          <DataGrid
            suppressMemoization
            autoHeight
            autoPageSize
            rows={templates}
            columns={templateColumns}
          />
        </div>
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contractsState: contractsSelector(state),
  contractsDict: contractsDictSelector(state),
  creatorsState: creatorsSelector(state),
  invalidateContractsState: invalidateContractsSelector(state),
  remindContractState: remindContractsSelector(state),
  contractTemplatesState: contractTemplatesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCreators: () => dispatch(getCreatorsSlice()),
  getContracts: () => dispatch(getContractsSlice()),
  invalidateContracts: (data) => dispatch(invalidateContractsSlice(data)),
  sendReminders: (ids, method) => dispatch(remindContractsSlice(ids, method)),
  getContractTemplates: () => dispatch(getContractTemplatesSlice()),
  getCustomers: () => dispatch(getCustomersSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
