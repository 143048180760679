import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const createPaymentGroupFile = async (state, body) => {
  return createRequest(`/v1/campaign_files`, state, {
    method: 'POST',
    body,
    contentType: 'multipart/form-data',
  }).then((res) => handleError(res));
};

// fileType = 'source' | 'audit'
export const downloadFile = async (state, id, fileType = 'source') => {
  return createRequest(`/v1/campaign_files/${id}/${fileType}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const getPaymentGroupFiles = async (state) => {
  return createRequest(`/v1/campaign_files`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};
