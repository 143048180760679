import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import css from 'classnames';
import { useHistory } from 'react-router-dom';

import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import global from 'styles/global';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Button';
import {
  createFundAcctSelector,
  createFundAcct as createFundAcctSlice,
} from 'slices/org';
import { USStates } from 'constants/usStates';
import Card from 'components/Card';
import PhoneInput from 'components/PhoneInput';
import { setBusinessAddress as setBusinessAddressSlice } from 'slices/misc';
import Autocomplete from 'components/Autocomplete';
import TextField from 'components/TextField';
import { getIsDemo, getIsDev } from 'util/env';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.custom.payBlue}`,
    width: '75%',
  },
}));

function CreateFundsAcct({
  createFundAcctState,
  createFundAcct,
  setBusinessAddress,
}) {
  const g = global();
  const classes = useStyles();
  const history = useHistory();

  const isDemo = getIsDemo();
  const isDev = getIsDev();

  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [ssn, setSSN] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDOB] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const { loading: submitting } = createFundAcctState;

  useEffect(() => {
    if (isDemo || isDev) {
      setFirst('Demo');
      setLast('User');
      setSSN('000000000');
      setJobTitle('CEO');
      setPhone('4155555555');
      setDOB('1980-01-02');
      setAddress('1150 Market St');
      setCity('San Francisco');
      setState({name: 'California', abbreviation: 'CA'});
      setZip('94102');
      setAgreeToTerms(true);
    }
  }, [isDemo, isDev])

  const isInputInvalid =
    first === '' ||
    last === '' ||
    ssn === '' ||
    dob === '' ||
    phone === '' ||
    jobTitle === '' ||
    address === '' ||
    city === '' ||
    state === '' ||
    zip === '';

  const handleCreateAccount = async (evt) => {
    evt.preventDefault();
    createFundAcct(
      {
        first_name: first,
        last_name: last,
        ssn,
        dob,
        phone,
        job_title: jobTitle,
        address,
        address2,
        city,
        state: state.abbreviation,
        zip_code: zip,
      },
      () => {
        setBusinessAddress({ address, address2, city, state, zip });
        history.push('/createFundBusiness');
      }
    );
  };

  return (
    <Card noHeader>
      <Grid container spacing={2}>
        {/* Top text section */}
        <Grid item xs={12}>
          <Typography variant="h6" className={g.mb_xxs}>
            Step 1 of 2
          </Typography>
          <Typography variant="h4" className={g.mb_sm}>
            Activate your account
          </Typography>
          <Typography variant="h2" className={g.mb_xs}>
            Business Representative
          </Typography>
          <Typography variant="body2">
            This account should be activated by someone authorized to sign on
            your organization's behalf.
          </Typography>
          <Typography variant="body2" className={g.mb_xs}>
            If that's not you, please ask the right person to complete this form
          </Typography>
          <div className={css(classes.divider, g.mb_sm)} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="First name"
                fullWidth
                autoFocus
                margin="dense"
                value={first}
                onChange={(evt) => setFirst(evt.target.value)}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Last name"
                fullWidth
                margin="dense"
                value={last}
                onChange={(evt) => setLast(evt.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Job title"
                required
                fullWidth
                margin="dense"
                value={jobTitle}
                onChange={(evt) => setJobTitle(evt.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <PhoneInput
                variant="outlined"
                label="Phone number"
                required
                fullWidth
                margin="dense"
                value={phone}
                onChange={(evt) => setPhone(evt.target.value)}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                label="Date of birth"
                type="date"
                required
                fullWidth
                margin="dense"
                value={dob}
                onChange={(evt) => setDOB(evt.target.value)}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                variant="outlined"
                required
                name="ssn"
                label="SSN"
                fullWidth
                margin="dense"
                value={ssn}
                onChange={(evt) => setSSN(evt.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                label="Street address"
                fullWidth
                margin="dense"
                value={address}
                onChange={(evt) => setAddress(evt.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Street address 2"
                fullWidth
                margin="dense"
                value={address2}
                onChange={(evt) => setAddress2(evt.target.value)}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                margin="dense"
                required
                value={city}
                onChange={(evt) => setCity(evt.target.value)}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Autocomplete
                options={USStates}
                className={g.mb_zero}
                getOptionLabel={(option) => `${option.abbreviation}`}
                onChange={(_, val) => setState(val)}
                textInputProps={{
                  margin: 'dense',
                  label: 'State',
                  value: state,
                  required: true,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                variant="outlined"
                required
                label="Zip code"
                fullWidth
                margin="dense"
                value={zip}
                onChange={(evt) => setZip(evt.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToTerms}
                onChange={(evt) => setAgreeToTerms(evt.target.checked)}
                color="primary"
              />
            }
            label="I have read and agree to Creator Pay’s Terms of Service. "
          />
        </Grid>

        <Grid item xs={12} sm={6} className={g.textRight}>
          <Button
            loading={submitting}
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleCreateAccount}
            disabled={!agreeToTerms || isInputInvalid}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  createFundAcctState: createFundAcctSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  createFundAcct: (data, callback) =>
    dispatch(createFundAcctSlice(data, callback)),
  setBusinessAddress: (data) => dispatch(setBusinessAddressSlice(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFundsAcct);
