import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const createPaypalOauth = async (state, body) => {
  return createRequest(`/v1/paypal/oauth`, state, {
    method: 'POST',
    body,
  }).then((res) => handleError(res));
};


export const deletePaypalOauth = async (state, body) => {
  return createRequest(`/v1/paypal/oauth`, state, {
    method: 'DELETE',
  }).then((res) => handleError(res));
};



export const getPaypalOauth = async (state) => {
  return createRequest(`/v1/paypal/oauth`, state, {
    method: 'GET'
  }).then((res) => handleError(res));
};
