import React, { useState } from 'react';
import css from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ContractIcon from '@mui/icons-material/Assignment';
import PaymentIcon from '@mui/icons-material/Send';
import Card from 'components/Card';
import Button from 'components/Button';
import { todoListSelector } from 'slices/multiSliceSelectors';
import global from 'styles/global';
import { formatCurrency } from 'util/renderStrings';
import { orgsByIdSelector } from 'slices/org';

const todoIconMap = {
  contract: <ContractIcon />,
  payment: <PaymentIcon />,
};

const todoTitleMap = {
  contract: 'Unsigned Contract',
  payment: 'Payment needs payout method',
};

const getSubtitle = (todoItem, orgsDict) => {
  switch (todoItem.todoType) {
    case 'contract':
      return (
        <Link to={`/creator/contracts/${todoItem.id}`}>
          {(orgsDict[todoItem.org_id] || {}).name} -{todoItem.title}
        </Link>
      );
    case 'payment':
      return (
        <>
          {(orgsDict[todoItem.org_id] || {}).name} -
          {formatCurrency(todoItem.amount)}
        </>
      );
    default:
      return;
  }
};

function TodoList() {
  const g = global();
  const todoList = useSelector(todoListSelector);
  const orgsDict = useSelector(orgsByIdSelector);

  const [isExpanded, setIsExpanded] = useState(false);

  let filteredList = todoList;
  if (!isExpanded) {
    filteredList = todoList.slice(0, 5);
  }

  return (
    <Card title="To-do list">
      {todoList.length === 0 && (
        <Typography className={css(g.flexRow, g.centered)} variant="body1">
          No outstanding tasks
        </Typography>
      )}

      {filteredList.map((todoItem) => (
        <div className={css(g.alignCenter, g.flexRowSpacing, g.pb_sm)}>
          <div className={g.flexRow}>
            <div className={css(g.centerChildren, g.ph_sm, g.mg_xxs)}>
              {todoIconMap[todoItem.todoType]}
            </div>
            <div>
              <Typography variant="body1">
                {todoTitleMap[todoItem.todoType]}
              </Typography>
              <Typography variant="subtitle1">
                {getSubtitle(todoItem, orgsDict)}
              </Typography>
            </div>
          </div>
        </div>
      ))}

      {todoList.length > 5 && (
        <div className={css(g.flexRow, g.centered)}>
          <Button
            onClick={() => setIsExpanded(!isExpanded)}
            color="primary"
            variant="text"
          >
            {isExpanded ? 'See less' : 'See all'}
          </Button>
        </div>
      )}
    </Card>
  );
}

export default TodoList;
