import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Link, useParams } from 'react-router-dom';
import { Typography, Grid, Paper } from '@material-ui/core';
import css from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import PageHeader from 'components/PageHeader';
import global from 'styles/global';
import objectStates from 'constants/objectStates';

import { download } from 'util/download';
import { renderContractStatusChip } from 'util/table';

import {
  creatorsDictSelector,
  creatorsSelector,
  getCreators,
} from 'slices/user';
import {
  customersDictSelector,
  customersSelector,
  getCustomers,
} from 'slices/customers';
import {
  contractsSelector,
  getContracts,
  invalidateContractSelector,
  remindContractsSelector,
  invalidateContract as invalidateContractSlice,
  remindContracts as remindContractsSlice,
  getContractPreview as getContractPreviewSlice,
  contractPreviewSelector,
  contractSelector,
} from 'slices/contracts';
import {
  getPaymentGroups,
  paymentGroupDictSelector,
} from 'slices/paymentGroups';
import { setHeaderData } from 'slices/misc';
import { getDateAndTimeFromIso, getDelimitedDateFromIso } from 'util/time';
import { emptyCreatorData } from 'fixtures/creatorData';
import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import { contractTypes } from 'constants/contracts';
import { emptyCustomerData } from 'fixtures/customer';
import KeyValuePair from 'components/KeyValuePair';
import Card from 'components/Card';
import { formatCreatorName } from 'util/renderStrings';

const useStyles = makeStyles((theme) => ({
  flowRoot: {
    display: 'flow-root',
  },
}));

function Contract({
  contractsState,
  creatorsDict,
  creatorsState,
  getContractPreview,
  invalidateContract,
  invalidateContractState,
  remindContracts,
  remindContractsState,
  customersDict,
}) {
  const g = global();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contractId } = useParams();

  const { loading } = contractsState;
  const { data: contractPreview, loading: contractPreviewLoading } =
    useSelector((state) => contractPreviewSelector(state, contractId));

  const { loading: creatorsLoading } = creatorsState;
  const pageLoading = loading || creatorsLoading || contractPreviewLoading;
  const contractPreviewPending = contractPreview === 'PENDING';

  const { loading: remindLoading } = remindContractsState;

  const { loading: invalidateLoading } = invalidateContractState;

  const contract = useSelector((state) => contractSelector(state, contractId));
  const campaignsDict = useSelector(paymentGroupDictSelector);
  const campaign =
    campaignsDict[contract.payment_group_id] || emptyPaymentGroupData;

  const isCreatorRec = !!contract.creator_id;
  const recipient = isCreatorRec
    ? creatorsDict[contract.creator_id] || emptyCreatorData
    : customersDict[contract.customer_id] || emptyCustomerData;

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: contract.title,
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Contracts', link: '/contracts' },
          { label: contract.title },
        ],
      })
    );
  }, [contract]);

  useEffect(() => {
    dispatch(getCreators());
    dispatch(getCustomers());
    dispatch(getContracts());
    dispatch(getPaymentGroups());
    getContractPreview(contractId);
  }, []);

  const handleRemindEmail = () => {
    remindContracts([contractId], 'email');
  };
  const handleRemindSms = () => {
    remindContracts([contractId], 'sms');
  };
  const downloadContract = () => {
    download(contractPreview, `${contract.title}.pdf`);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const handleNextPage = () => {
    setPageNumber(Math.min(numPages, pageNumber + 1));
  };

  const handleBackPage = () => {
    setPageNumber(Math.max(1, pageNumber - 1));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  let status = 'Sent';
  if (contract.status === objectStates.canceled) {
    status = 'Invalid';
  } else if (!!contract.signautre_date) {
    status = 'Signed';
  }

  const isHelloSign = contract.type === contractTypes.HelloSign;

  return (
    <div>
      <LoadingOverlay open={pageLoading} />

      <div className={css(g.flexRow, g.justifyEnd, g.mb_sm)}>
        {isHelloSign && (
          <>
            <Button
              size="small"
              type="submit"
              variant="outlined"
              color="secondary"
              disabled={status !== 'Sent'}
              onClick={() => invalidateContract(contractId)}
              loading={invalidateLoading}
            >
              Invalidate
            </Button>
            <Button
              size="small"
              className={g.ml_xs}
              onClick={handleRemindSms}
              type="submit"
              variant="outlined"
              color="primary"
              // disabled={status !== 'Sent' || !recipient.phone_number}
              disabled
              loading={remindLoading}
            >
              Send SMS reminder
            </Button>
            <Button
              size="small"
              className={g.ml_xs}
              onClick={handleRemindEmail}
              type="submit"
              variant="outlined"
              color="primary"
              disabled={status !== 'Sent'}
              loading={remindLoading}
            >
              Send email reminder
            </Button>
          </>
        )}

        <Button
          type="submit"
          variant="outlined"
          color="primary"
          disabled={contract.status === objectStates.canceled}
          className={g.ml_xs}
          onClick={downloadContract}
        >
          Download
        </Button>
      </div>

      <Grid container spacing={1}>
        <Grid item md={5}>
          <Card customClassName={g.mb_xl} title="Details">
            <KeyValuePair label="Contract title" value={contract.title} />
            <KeyValuePair
              label="Campaign"
              value={
                !!campaign.id ? (
                  <Link to={`/campaigns/${campaign.id}`}>{campaign.title}</Link>
                ) : (
                  '-'
                )
              }
            />
            <KeyValuePair
              label="Recipient type"
              value={isCreatorRec ? 'Creator' : 'Customer'}
            />
            <KeyValuePair
              label="Recipient"
              value={
                isCreatorRec ? (
                  <Link to={`/creators/${recipient.id}`}>
                    {formatCreatorName(recipient)}
                  </Link>
                ) : (
                  <Link to={`/customers/${recipient.id}`}>
                    {recipient.name}
                  </Link>
                )
              }
            />
            <KeyValuePair
              label="Expiration date"
              value={
                contract.expiration_date
                  ? getDelimitedDateFromIso(contract.expiration_date)
                  : '-'
              }
            />
            <KeyValuePair
              label="Status"
              valueClasses={g.p_xs}
              value={renderContractStatusChip(contract.type, contract.status)}
            />
            {isHelloSign && (
              <KeyValuePair
                label="Signed on"
                valueClasses={g.p_xs}
                value={
                  !!contract.signature_date
                    ? getDateAndTimeFromIso(contract.signature_date)
                    : '-'
                }
              />
            )}
          </Card>
        </Grid>
        <Grid item md={7}>
          <Paper className={css(g.paper, g.mb_lg)}>
            {contract.status === objectStates.canceled && (
              <div className={css(g.alignCenter, g.flexRow)}>
                <Typography variant="body2" className={g.alignStart}>
                  This contract has been invalidated
                </Typography>
              </div>
            )}
            {contractPreviewPending && (
              <div className={css(g.centerChildren, g.p_lg)}>
                <Typography variant="h3">
                  This document is still being created
                </Typography>
                <Typography
                  variant="h5"
                  className={css(g.charcoal036, g.p_sm, g.mb_md)}
                >
                  Please refresh this page in a few moments
                </Typography>
                <Button
                  onClick={() =>
                    getContractPreview(contract.signature_request_id)
                  }
                  variant="outlined"
                >
                  Refresh
                </Button>
              </div>
            )}
            {contractPreview && !contractPreviewPending && (
              <>
                <div
                  className={css(
                    classes.pdfCtrBar,
                    g.flexRowSpacing,
                    g.alignCenter
                  )}
                >
                  <div className={css(g.alignCenter, g.flexRow)}>
                    <Typography variant="body2" className={g.alignStart}>
                      Page {pageNumber} of {numPages}
                    </Typography>
                    <Button
                      disabled={pageNumber === 1}
                      onClick={handleBackPage}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={handleNextPage}
                      disabled={pageNumber === numPages}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                <div className={classes.flowRoot}>
                  <Document
                    file={contractPreview}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={css(classes.docContainer, g.m_md, g.mt_zero)}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contractsState: contractsSelector(state),
  invalidateContractState: invalidateContractSelector(state),
  remindContractsState: remindContractsSelector(state),
  creatorsDict: creatorsDictSelector(state),
  creatorsState: creatorsSelector(state),
  customersDict: customersDictSelector(state),
  customersState: customersSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  invalidateContract: (id) => dispatch(invalidateContractSlice(id)),
  remindContracts: (ids, method) => dispatch(remindContractsSlice(ids, method)),
  getContractPreview: (id) => dispatch(getContractPreviewSlice(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
