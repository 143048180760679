import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import css from 'classnames';

import { getContracts } from 'slices/contracts';
import {
  paymentGroupsSelector,
  getPaymentGroups as getPaymentGroupsSlice,
  paymentGroupsSortedSelector,
} from 'slices/paymentGroups';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
} from 'slices/payments';
import {
  invoicesSelector,
  getInvoices as getInvoicesSlice,
  invoiceTotalsSelector,
} from 'slices/invoices';
import { miscSelector } from 'slices/misc';
import {
  getCreators as getCreatorsSlice,
  creatorsSelector,
  loginAs,
} from 'slices/user';
import LoadingOverlay from 'components/LoadingOverlay';
import TodoList from './TodoList';
import global from 'styles/global';
import { currentOrgSelector } from 'slices/org';
import ActivateModal from 'pages/Home/ActivateModal';
import Checklist from './Checklist';
import ActiveCampaigns from './ActiveCampaigns';
import MetricTile from 'components/MetricTile';
import WelcomeCard from './WelcomeCard';
import { abbreviateCurrency } from 'util/renderStrings';
import objectStates from 'constants/objectStates';
import { paymentGroupTypes } from 'constants/paymentGroups';
import {
  totalBalanceSelector,
  walletsSelector,
  getWallets as getWalletsSlice,
} from 'slices/wallets';
import { setHeaderData } from 'slices/misc';
import { currentUserSelector } from '../../slices/user';

function Home({
  creatorsState,
  getCreators,
  getPayments,
  getPaymentGroups,
  paymentGroupsState,
  paymentsState,
  currentBalance,
  orgState,
  paymentGroupsSorted,
  getInvoices,
  invoicesState,
  invoiceTotals,
  walletsState,
  getWallets,
}) {
  const dispatch = useDispatch();
  const g = global();

  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);

  const history = useHistory();

  const { isOnboardingDismissed } = useSelector(miscSelector);

  const { data: user } = useSelector(currentUserSelector);

  const { data: org, loading: orgLoading } = orgState;
  const { loading: walletsLoading } = walletsState;

  const { loading: paymentGroupsLoading, data: paymentGroups } =
    paymentGroupsState;
  const { loading: creatorsLoading, data: creators } = creatorsState;
  const { loading: paymentsLoading, data: payments } = paymentsState;
  const { loading: invoicesLoading } = invoicesState;
  const loading =
    orgLoading ||
    paymentGroupsLoading ||
    creatorsLoading ||
    paymentsLoading ||
    walletsLoading ||
    invoicesLoading;

  const hasTreasury = !!org.treasury_id;
  const hasRepresentative = !!org.has_representative;

  const { campaigns } = paymentGroups.reduce(
    (agg, pg) => {
      if (pg.type === paymentGroupTypes.campaign)
        return {
          ...agg,
          campaigns: agg.campaigns.concat(pg),
        };
      return agg;
    },
    { campaigns: [] }
  );

  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const onboardingPath = hasRepresentative
    ? '/createFundBusiness'
    : '/createFundAcct';

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Home',
        breadcrumbs: [{ label: 'Creator Pay' }],
      })
    );

    getCreators();
    getPayments();
    getPaymentGroups();
    getInvoices();
    dispatch(getContracts());
    getWallets();
    if (urlParams.has('override') && !user.is_external) {
      let user_id = urlParams.get('override');
      dispatch(loginAs({ user_id }, () => window.location.reload()));
    }
  }, []);

  const draftAmount = payments.reduce((agg, pmt) => {
    if (pmt.status === objectStates.draft) return agg + pmt.amount;
    return agg;
  }, 0);

  return (
    <div>
      <LoadingOverlay open={loading} />
      <ActivateModal
        open={activateModalVisible}
        onClose={() => setActivateModalVisible(false)}
        onboardingPath={onboardingPath}
      />
      <Grid container spacing={3}>
        {!loading && !hasTreasury && (
          <Grid item xs={12}>
            <WelcomeCard setActivateModalVisible={setActivateModalVisible} />
          </Grid>
        )}

        {!loading && hasTreasury && (
          <>
            {!isOnboardingDismissed && (
              <Grid item xs={12}>
                <Checklist
                  activeIndex={activeStep}
                  activateAction={() => setActivateModalVisible(true)}
                  setActiveStep={setActiveStep}
                  isCompletedMap={{
                    creators: creators.length > 0,
                    funds: currentBalance > 0,
                    campaigns: campaigns.length > 0,
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <div className={css(g.flexRowSpacing, g.gap_lg)}>
                <MetricTile
                  subtitle="Balance"
                  value={abbreviateCurrency(currentBalance)}
                />
                <MetricTile
                  subtitle="Receivable"
                  value={abbreviateCurrency(invoiceTotals.pending)}
                />
                <MetricTile
                  subtitle="Draft payments"
                  value={abbreviateCurrency(draftAmount)}
                />
                <MetricTile subtitle="Total creators" value={creators.length} />
              </div>
            </Grid>

            <Grid item xs={12}>
              <TodoList />
            </Grid>

            <Grid item xs={12}>
              <ActiveCampaigns
                paymentGroups={paymentGroupsSorted.filter(
                  (pg) => pg.type === 'campaign'
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentBalance: totalBalanceSelector(state),
  walletsState: walletsSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
  paymentsState: paymentsSelector(state),
  creatorsState: creatorsSelector(state),
  orgState: currentOrgSelector(state),
  paymentGroupsSorted: paymentGroupsSortedSelector(state),
  invoicesState: invoicesSelector(state),
  invoiceTotals: invoiceTotalsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCreators: () => dispatch(getCreatorsSlice()),
  getWallets: () => dispatch(getWalletsSlice()),
  getPayments: () => dispatch(getPaymentsSlice()),
  getPaymentGroups: () => dispatch(getPaymentGroupsSlice()),
  getInvoices: () => dispatch(getInvoicesSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
