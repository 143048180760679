import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'react-router-dom';

import objectStates from 'constants/objectStates';
import DataGrid from 'components/DataGrid';
import TableHeader from 'components/TableHeader';
import LoadingOverlay from 'components/LoadingOverlay';
import global from 'styles/global';
import {
  customersSelector,
  getCustomers as getCustomersSlice,
  sortedCustomersSelector,
} from 'slices/customers';
import { setHeaderData } from 'slices/misc';
import {
  invoicesSelector,
  getInvoices as getInvoicesSlice,
  invoicesByCustomerSelector,
} from 'slices/invoices';
import AddCustomerModal from 'components/AddCustomerModal';
import Button from 'components/Button';
import { formatCurrency } from '../../util/renderStrings';

function Customers({
  getCustomers,
  customersState,
  getInvoices,
  invoicesState,
  invoicesByCustomer,
  sortedCustomers,
}) {
  const g = global();
  const dispatch = useDispatch();

  const { loading: customersLoading } = customersState;
  const { loading: invoicesLoading } = invoicesState;
  const loading = customersLoading || invoicesLoading;

  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/customers/${params.row.id}`}>{params.value}</Link>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'invoiceVol',
      headerName: 'Invoice Volume',
      flex: 1,
      valueGetter: (params) => {
        const customerInvoices = invoicesByCustomer[params.row.id] || [];
        return customerInvoices
          .filter(invoice => invoice.status === objectStates.succeeded)
          .reduce((agg, invoice) => agg + invoice.amount, 0)
      },
      renderCell: (params) => {
        const customerInvoices = invoicesByCustomer[params.row.id] || [];
        const amt = customerInvoices
          .filter(invoice => invoice.status === objectStates.succeeded)
          .reduce((agg, invoice) => agg + invoice.amount, 0)
        return formatCurrency(amt)
      },
    },
    {
      field: 'invoices',
      headerName: 'Number of Invoices',
      flex: 1,
      valueGetter: (params) => {
        const customerInvoices = invoicesByCustomer[params.row.id];
        return customerInvoices?.length || 0;
      },
      renderCell: (params) => {
        const customerInvoices = invoicesByCustomer[params.row.id];
        return customerInvoices?.length || '-';
      },
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Customers',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Customers' },
        ],
      })
    );

    getCustomers();
    getInvoices();
  }, [getCustomers, getInvoices]);

  const [addCustomerModalVisible, setAddCustomerModalVisible] = useState(false);

  return (
    <div>
      <AddCustomerModal
        open={addCustomerModalVisible}
        onClose={() => setAddCustomerModalVisible(false)}
      />

      <LoadingOverlay open={loading} />

      <TableHeader title="Customers">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setAddCustomerModalVisible(true)}
        >
          New Customer
        </Button>
      </TableHeader>

      <div className={g.tableWrapper}>
        <DataGrid
          autoHeight
          autoPageSize
          rows={sortedCustomers}
          columns={columns}
          disableSelectionOnClick
          columnDependencies={[invoicesByCustomer]}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  customersState: customersSelector(state),
  invoicesState: invoicesSelector(state),
  invoicesByCustomer: invoicesByCustomerSelector(state),
  sortedCustomers: sortedCustomersSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(getCustomersSlice()),
  getInvoices: () => dispatch(getInvoicesSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
