import React, { useState, useEffect } from 'react';
import css from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography, IconButton } from '@material-ui/core';

import NewCreatorRow from './NewCreatorRow';
import AgentPayment from './AgentPayment';
import { activeCreatorsSelector } from 'slices/user';
import { paymentGroupDetailsSelector } from 'slices/paymentGroups';
import {
  createPayments,
  campaignPaymentsByRecipientDictSelector,
  paymentsForCampaignSelector,
  deletePayments,
  updatePayments,
  getPayments as getPaymentsSlice,
  agentPaymentForCampaignSelector,
  campaignPaymentsByManagedCreatorSelector,
  paymentRecipientTotalsSelector,
} from 'slices/payments';
import { currentOrgSelector } from 'slices/org';
import {
  agentsMngrsDictSelector,
  agentMngrDetailsSelector,
} from 'slices/agentsMngrs';
import Card from 'components/Card';
import Button from 'components/Button';
import global from 'styles/global';
import { formatCurrency } from 'util/renderStrings';
import { stringToNumberCents } from 'util/renderStrings';
import { creatorsInCampaignSelector } from 'slices/multiSliceSelectors';
import {
  getItemsToAdd,
  getItemsToDelete,
  getItemsToUpdate,
  getTotalWithholdingsForPayment,
} from './utils';

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.custom.payBlue036,
    },
  },
  addIcon: {
    color: theme.palette.shades.white,
  },
}));

export default function PaymentsStep({ handleStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const g = global();

  const query = new URLSearchParams(useLocation().search);
  const campaignId = query.get('campaignId');

  const campaign = useSelector((state) =>
    paymentGroupDetailsSelector(state, campaignId)
  );
  const agent = useSelector((state, campaignId) =>
    agentMngrDetailsSelector(state, campaign?.agent_id)
  );
  const activeCreators = useSelector(activeCreatorsSelector);
  const agentsMngrsDict = useSelector(agentsMngrsDictSelector);
  const { data: currentOrg } = useSelector(currentOrgSelector);
  const {
    agent_split_source: agentSplitSource,
    manager_split_source: managerSplitSource,
  } = currentOrg;
  const campaignCreators = useSelector((state) =>
    creatorsInCampaignSelector(state, campaignId)
  );
  const campaignPayments = useSelector((state) =>
    paymentsForCampaignSelector(state, campaignId)
  );
  const campaignPaymentsByRecipientDict = useSelector((state) =>
    campaignPaymentsByRecipientDictSelector(state, campaignId)
  );
  const { data: paymentRecipientTotals } = useSelector(
    paymentRecipientTotalsSelector
  );
  const managerPaymentsByCreator = useSelector((state) =>
    campaignPaymentsByManagedCreatorSelector(state, campaignId)
  );
  const agentPaymentForCampaign = useSelector((state) =>
    agentPaymentForCampaignSelector(state, campaignId)
  );

  const [rows, setRows] = useState([]);
  const [initialIds, setInitialIds] = useState([]);
  const [agentPayment, setAgentPayment] = useState(false);
  const [creatorTotal, setCreatorTotal] = useState(0);
  const [managerTotal, setManagerTotal] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);

  const addRow = () => {
    setRows([...rows, { id: uuid(), creator: undefined, payments: [] }]);
  };

  // add payment to a row
  const addPayment = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          payments: [
            ...row.payments,
            { id: uuid(), split: row.payments[0]?.split },
          ],
        };
      }
      return row;
    });
    setRows(newRows);
  };

  const getPayments = (payments, val, key, id) => {
    const newPayments = payments.map((pmt) => {
      if (pmt.id === id) {
        const newPayment = {
          ...pmt,
          [key]: val,
        };

        if (key === 'split' || key === 'earnings') {
          newPayment.amount = (newPayment.split * newPayment.earnings) / 100;
        }

        return newPayment;
      }
      return pmt;
    });

    return newPayments;
  };

  const getPaymentSplits = (
    payments,
    split,
    agentSplit = 0,
    managerSplit = 0
  ) => {
    return payments.map((pmt) => {
      // this is the split source for agents or managers if they exist, if there are no agents or managers then this is the creator's check
      const creatorsSplit = (split / 100) * pmt.earnings;

      let agentManagerFees = 0;

      if (agentSplitSource === 'creator' && agentSplit > 0) {
        agentManagerFees += creatorsSplit * (agentSplit / 100);
      }

      if (managerSplitSource === 'creator' && managerSplit > 0) {
        agentManagerFees += creatorsSplit * (managerSplit / 100);
      }

      return {
        ...pmt,
        split,
        amount: creatorsSplit - agentManagerFees,
      };
    });
  };

  const getManagerPayment = (
    creator,
    oldManagerPmt,
    creatorPmts,
    updatedSplit
  ) => {
    if (!creator?.manager_id) return false;

    const manager = agentsMngrsDict[creator.manager_id] || {};

    const budget = campaign?.deal_size || 0;
    const split = updatedSplit || creator.manager_split || 10;
    let retObj = {
      recipient: creator.manager_id,
      recipientData: manager,
      split,
      memo: oldManagerPmt?.memo || '',
    };

    if (managerSplitSource === 'org') {
      const newTotal = creatorPmts.reduce((agg, pmt) => {
        return agg + pmt.amount || 0;
      }, 0);

      const orgsSplit = budget / 100 - newTotal;
      const amount = orgsSplit * (split / 100);
      retObj.earnings = orgsSplit;
      retObj.amount = amount;
    }

    if (managerSplitSource === 'creator') {
      const creatorsSplit = creatorPmts.reduce(
        (agg, pmt) => agg + (pmt.amount || 0),
        0
      );
      const amount = creatorsSplit * (split / 100);

      retObj.earnings = creatorsSplit;
      retObj.amount = amount;
    }

    const { stateWithholdings, federalWithholdings } =
      getTotalWithholdingsForPayment(
        retObj,
        manager,
        paymentRecipientTotals[manager.id] || 0
      );

    if (stateWithholdings > 0 || federalWithholdings > 0) {
      retObj.amount = retObj.amount - stateWithholdings - federalWithholdings;
      retObj.state_withholdings = stateWithholdings;
      retObj.federal_withholdings = federalWithholdings;
    }

    return retObj;
  };

  const updateProp = (id, val, key, subKey, subId) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        let newRow;
        switch (key) {
          case 'creator': {
            const manager = agentsMngrsDict[val?.manager_id];
            const pmts = getPaymentSplits(
              row.payments,
              val?.split,
              agent?.split,
              !!manager ? val.manager_split : 0
            );

            newRow = {
              ...row,
              creator: val,
              payments: !!val ? pmts : [],
              managerPayment: getManagerPayment(val, row.managerPayment, pmts),
            };
            break;
          }

          case 'payments': {
            const pmts = getPayments(row.payments, val, subKey, subId);
            newRow = {
              ...row,
              payments: pmts,
              managerPayment: getManagerPayment(
                row.creator,
                row.managerPayment,
                pmts
              ),
            };
            break;
          }

          default:
            return row;
        }

        return newRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const deleteRow = (id) => {
    let newRows = rows.filter((row) => row.id !== id);
    if (newRows.length === 0) {
      newRows = [{ id: uuid(), creator: undefined, payments: [] }];
    }
    setRows(newRows);
  };

  const deletePayment = (rowId, pmtId) => {
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        const newRow = {
          ...row,
          payments: row.payments.filter((pmt) => pmt.id !== pmtId),
        };
        return newRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const addManagerPayment = (rowId) => {
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        const newRow = {
          ...row,
          managerPayment: getManagerPayment(row.creator, {}, row.payments),
        };
        return newRow;
      }
      return row;
    });

    setRows(newRows);
  };

  const updateManagerPayment = (rowId, val, key) => {
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        const updatedPmt = {
          ...row.managerPayment,
          [key]: val,
        };

        updatedPmt.amount = updatedPmt.earnings * (updatedPmt.split / 100);

        const { stateWithholdings, federalWithholdings } =
          getTotalWithholdingsForPayment(
            updatedPmt,
            updatedPmt.recipientData,
            paymentRecipientTotals[updatedPmt.recipient.id] || 0
          );

        if (stateWithholdings > 0 || federalWithholdings > 0) {
          updatedPmt.amount =
            updatedPmt.amount - stateWithholdings - federalWithholdings;
          updatedPmt.state_withholdings = stateWithholdings;
          updatedPmt.federal_withholdings = federalWithholdings;
        }

        const newRow = {
          ...row,
          managerPayment: updatedPmt,
        };
        return newRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const deleteManagerPayment = (rowId) => {
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        const newRow = {
          ...row,
          managerPayment: false,
        };
        return newRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const deleteAgentPayment = () => setAgentPayment(false);

  const handleSave = async () => {
    const validRows = rows.filter((row) => !!row.creator);

    // create payments
    const payments = validRows.reduce((agg, row) => {
      const paymentsForRow = row.payments
        .filter((pmt) => !!pmt.amount && !!pmt.split && !!pmt.earnings)
        .map((pmt) => ({
          id: pmt.id,
          payment_group_id: campaign.id,
          recipient: row.creator.id,
          earnings: stringToNumberCents(pmt.earnings),
          split: +pmt.split,
          amount: stringToNumberCents((pmt.earnings * pmt.split) / 100),
          memo: pmt.memo,
          recipient_role: 'creator',
        }));
      const { managerPayment } = row;
      if (!!managerPayment) {
        paymentsForRow.push({
          id: managerPayment.id,
          payment_group_id: campaign.id,
          recipient: managerPayment.recipient,
          earnings: stringToNumberCents(managerPayment.earnings),
          split: +managerPayment.split,
          amount: stringToNumberCents(managerPayment.amount),
          memo: managerPayment.memo,
          recipient_role: 'manager',
          state_withholdings: Math.floor(managerPayment.state_withholdings * 100),
          federal_withholdings: Math.floor(managerPayment.federal_withholdings * 100),
          managed_creator_id: row.creator.id,
        });
      }
      return agg.concat(paymentsForRow);
    }, []);

    if (!!agentPayment) {
      payments.push({
        id: agentPayment.id,
        payment_group_id: campaign.id,
        recipient: agentPayment.recipient,
        earnings: stringToNumberCents(agentPayment.earnings),
        split: +agentPayment.split,
        amount: stringToNumberCents(agentPayment.amount),
        memo: agentPayment.memo,
        recipient_role: 'agent',
        state_withholdings: Math.floor(agentPayment.state_withholdings * 100),
        federal_withholdings: Math.floor(
          agentPayment.federal_withholdings * 100
        ),
      });
    }

    const paymentsToAdd = getItemsToAdd(initialIds, payments);
    const paymentsToUpdate = getItemsToUpdate(initialIds, payments);
    const paymentsToDelete = getItemsToDelete(initialIds, payments);
    paymentsToAdd.length > 0 &&
      (await dispatch(createPayments(paymentsToAdd, null, true)));
    paymentsToUpdate.length > 0 &&
      (await dispatch(updatePayments(paymentsToUpdate, true, true)));
    paymentsToDelete.length > 0 &&
      (await dispatch(deletePayments(paymentsToDelete, true, true)));
    dispatch(getPaymentsSlice({ payment_group_id: campaignId }));

    handleStep(2);
  };

  useEffect(() => {
    let initialIds = Object.values(campaignPayments).map((del) => del.id);
    setInitialIds(initialIds);
  }, [campaignPayments]);

  useEffect(() => {
    let initialRows = Object.values(campaignCreators).map((creator) => {
      const row = {
        id: creator.id,
        creator,
        payments:
          (campaignPaymentsByRecipientDict[creator.id] || []).map((val) => ({
            ...val,
            earnings: val.earnings / 100,
            amount: val.amount / 100,
          })) || [],
      };
      if (!!managerPaymentsByCreator[creator.id]) {
        const paymentToManager = managerPaymentsByCreator[creator.id];
        row.managerPayment = {
          ...paymentToManager,
          earnings: paymentToManager.earnings / 100,
          amount: paymentToManager.amount / 100,
          state_withholdings: paymentToManager.state_withholdings / 100,
          federal_withholdings: paymentToManager.federal_withholdings / 100,
          recipientData: agentsMngrsDict[creator.manager_id] || {},
        };
      }
      return row;
    });

    if (initialRows.length === 0) {
      initialRows = [{ id: uuid(), creator: undefined, payments: [] }];
    }
    if (!!agentPaymentForCampaign) {
      setAgentPayment(agentPaymentForCampaign);
    }

    setRows(initialRows);
  }, [
    campaignCreators,
    campaignPaymentsByRecipientDict,
    agentPaymentForCampaign,
    managerPaymentsByCreator,
    agentsMngrsDict,
  ]);

  useEffect(() => {
    // ensure each row has at least 1 payment
    for (const row of rows) {
      if (row.payments.length === 0) addPayment(row.id);
    }
  }, [rows]);

  useEffect(() => {
    const creatorTotal = rows.reduce((agg, row) => {
      const totalForRow = row.payments.reduce(
        (rowAgg, pmt) => rowAgg + pmt.amount || 0,
        0
      );
      return agg + totalForRow || 0;
    }, 0);
    const managerTotal = rows.reduce((agg, row) => {
      if (!!row.managerPayment) {
        return agg + row.managerPayment.amount || 0;
      }
      return 0;
    }, 0);
    setCreatorTotal(creatorTotal);
    setManagerTotal(managerTotal);
  }, [rows]);

  useEffect(() => {
    setTotalPayments((agentPayment.amount || 0) + creatorTotal + managerTotal);
  }, [agentPayment, managerTotal, creatorTotal]);

  const addAgentPayment = () => {
    const split = agent.agent_split || 0;
    let earnings = 0;
    if (agentSplitSource === 'org') {
      const budget = campaign?.deal_size || 0;
      let orgsSplit = budget / 100 - creatorTotal;
      earnings = orgsSplit;
    } else if (agentSplitSource === 'creator') {
      earnings = creatorTotal;
    }
    const amount = earnings * (split / 100);
    const newAgentPayment = {
      earnings,
      split,
      amount,
      recipient: agent?.id,
      recipientData: agent,
    };
    const { stateWithholdings, federalWithholdings } =
      getTotalWithholdingsForPayment(
        newAgentPayment,
        agent,
        paymentRecipientTotals[agent.id] || 0
      ) || 0;
    setAgentPayment({
      ...newAgentPayment,
      amount: amount - stateWithholdings - federalWithholdings,
      state_withholdings: stateWithholdings,
      federal_withholdings: federalWithholdings,
    });
  };

  // update agent payment
  useEffect(() => {
    if (!!campaign.agent_id) {
      setAgentPayment((oldAgentPmt) => {
        const split = oldAgentPmt.split || agent.agent_split || 0;
        let earnings = 0;
        if (agentSplitSource === 'org') {
          const budget = campaign?.deal_size || 0;
          let orgsSplit = budget / 100 - creatorTotal;
          earnings = orgsSplit;
        } else if (agentSplitSource === 'creator') {
          earnings = creatorTotal;
        }
        const amount = earnings * (split / 100);
        const newAgentPayment = {
          id: oldAgentPmt.id,
          earnings,
          split,
          amount,
          recipient: agent?.id,
          recipientData: agent,
        };
        const { stateWithholdings, federalWithholdings } =
          getTotalWithholdingsForPayment(
            newAgentPayment,
            agent,
            paymentRecipientTotals[agent.id] || 0
          );
        return {
          ...newAgentPayment,
          amount: amount - stateWithholdings - federalWithholdings,
          state_withholdings: stateWithholdings,
          federal_withholdings: federalWithholdings,
        };
      });
    }
  }, [
    campaign,
    rows,
    agent,
    setAgentPayment,
    agentSplitSource,
    creatorTotal,
    managerSplitSource,
    managerTotal,
    paymentRecipientTotals,
  ]);

  const isValid =
    (!agentPayment || agentPayment.amount >= 0) &&
    rows.every((row) => !row.managerPayment || row.managerPayment.amount >= 0);

  return (
    <Card title="Payments">
      {rows.map((row, idx) => (
        <NewCreatorRow
          idx={idx}
          creators={activeCreators}
          key={`new-row-${row.id}`}
          vals={row}
          updateProp={updateProp}
          deleteRow={deleteRow}
          deletePayment={deletePayment}
          addManagerPayment={addManagerPayment}
          addPayment={addPayment}
          deleteManagerPayment={deleteManagerPayment}
          updateManagerPayment={updateManagerPayment}
          managerSplitSource={managerSplitSource}
          isPaymentStep
        />
      ))}

      <IconButton
        variant="outlined"
        color="primary"
        onClick={addRow}
        className={classes.addButton}
      >
        <AddIcon className={classes.addIcon} />
      </IconButton>

      {!!campaign.agent_id && (
        <AgentPayment
          agentPayment={agentPayment}
          setAgentPayment={setAgentPayment}
          agentSplitSource={agentSplitSource}
          deleteAgentPayment={deleteAgentPayment}
          addAgentPayment={addAgentPayment}
        />
      )}

      <div className={g.mt_xl}>
        <Card variant="outlined" noHeader>
          <Grid container>
            <Grid item md={3} sm={6} className={g.pr_md}>
              <Typography align="right">Creator payments total:</Typography>
              <Typography align="right">Manager payments total:</Typography>
              {!!agentPayment && (
                <Typography align="right">Agent payment total:</Typography>
              )}
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography>{formatCurrency(creatorTotal * 100)}</Typography>
              <Typography>{formatCurrency(managerTotal * 100)}</Typography>
              {!!agentPayment && (
                <Typography>
                  {formatCurrency(agentPayment.amount * 100)}
                </Typography>
              )}
            </Grid>
            <Grid item md={3} sm={6} className={g.pr_md}>
              <Typography align="right">Campaign budget:</Typography>

              <Typography align="right">
                {/* when they enter the payments, they're input in dollar format */}
                Total payments:
              </Typography>

              <Typography align="right">
                {/* when they enter the payments, they're input in dollar format */}
                <strong>Gross revenue:</strong>
              </Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography>{formatCurrency(campaign.deal_size)}</Typography>

              <Typography>
                {/* when they enter the payments, they're input in dollar format */}
                {formatCurrency(totalPayments * 100)}
              </Typography>

              <Typography>
                {/* when they enter the payments, they're input in dollar format */}
                <strong>
                  {formatCurrency(campaign.deal_size - totalPayments * 100)}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>

      <div className={css(g.flexRowEnd, g.mt_md)}>
        <Button variant="contained" onClick={handleSave} disabled={!isValid}>
          Save and continue
        </Button>{' '}
      </div>
    </Card>
  );
}
