import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  getAgentsMngrs as getAgentsMngrsAPI,
  createAgentMngr as createAgentMngrAPI,
  updateAgentMngr as updateAgentMngrAPI,
  deleteAgentMngr as deleteAgentMngrAPI,
} from 'api/agentsMngrs';
import { emptyAgentMngrData } from 'fixtures/agentsMngrs';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialAgentsMngrsState = {
  createAgentMngr: getGenericState(),
  updateAgentMngr: getGenericState(),
  agentsMngrs: getGenericState([]),
  deleteAgentMngr: getGenericState(),
};

export const agentsMngrsSlice = createSlice({
  name: 'agentsMngrs',
  initialState: initialAgentsMngrsState,
  reducers: {
    createAgentMngrStarted: getGenericStarted('createAgentMngr'),
    createAgentMngrSuccess: getGenericSuccess('createAgentMngr'),
    createAgentMngrFailure: getGenericFailure('createAgentMngr'),

    getAgentsMngrsStarted: getGenericStarted('agentsMngrs'),
    getAgentsMngrsSuccess: getPayloadSuccess('agentsMngrs'),
    getAgentsMngrsFailure: getGenericFailure('agentsMngrs'),

    updateAgentMngrStarted: getGenericStarted('updateAgentMngr'),
    updateAgentMngrSuccess: getGenericSuccess('updateAgentMngr'),
    updateAgentMngrFailure: getGenericFailure('updateAgentMngr'),

    deleteAgentMngrStarted: getGenericStarted('deleteAgentMngr'),
    deleteAgentMngrSuccess: getGenericSuccess('deleteAgentMngr'),
    deleteAgentMngrFailure: getGenericFailure('deleteAgentMngr'),
  },
});

export const {
  createAgentMngrStarted,
  createAgentMngrSuccess,
  createAgentMngrFailure,

  getAgentsMngrsStarted,
  getAgentsMngrsSuccess,
  getAgentsMngrsFailure,

  updateAgentMngrStarted,
  updateAgentMngrSuccess,
  updateAgentMngrFailure,

  deleteAgentMngrStarted,
  deleteAgentMngrSuccess,
  deleteAgentMngrFailure,
} = agentsMngrsSlice.actions;

export default agentsMngrsSlice.reducer;

export const getAgentsMngrs = () => async (dispatch, getState) => {
  dispatch(getAgentsMngrsStarted());
  try {
    const res = await getAgentsMngrsAPI(getState());
    dispatch(getAgentsMngrsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getAgentsMngrsFailure,
      'There was an issue retrieving your Agents/ Managers'
    );
  }
};

export const createAgentMngr = (data) => async (dispatch, getState) => {
  dispatch(createAgentMngrStarted());
  try {
    const res = await createAgentMngrAPI(getState(), data);
    dispatch(createAgentMngrSuccess(res));
    dispatch(getAgentsMngrs());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      createAgentMngrFailure,
      'There was an issue creating your Agent/ Manager'
    );
  }
};

export const updateAgentMngr = (data) => async (dispatch, getState) => {
  dispatch(updateAgentMngrStarted());
  try {
    const res = await updateAgentMngrAPI(getState(), data);
    dispatch(updateAgentMngrSuccess(res));
    dispatch(getAgentsMngrs());
    return res;
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateAgentMngrFailure,
      'There was an issue updating your Agent/ Manager'
    );
  }
};

export const deleteAgentMngr = (ids) => async (dispatch, getState) => {
  dispatch(deleteAgentMngrStarted());
  try {
    await Promise.all(ids.map((id) => deleteAgentMngrAPI(getState(), id)));
    dispatch(deleteAgentMngrSuccess());
    dispatch(getAgentsMngrs());
  } catch (err) {
    handleError(
      err,
      dispatch,
      deleteAgentMngrFailure,
      'There was an issue deleting this Agent/ Manager'
    );
  }
};

// selectors
const selectAgentsMngrs = (state) =>
  state.agentsMngrs || initialAgentsMngrsState;
const selectId = (_, id) => id;

export const createAgentMngrSelector = createSelector(
  selectAgentsMngrs,
  (agentsMngrsState = {}) =>
    agentsMngrsState.createAgentMngr || getGenericState()
);

export const updateAgentMngrSelector = createSelector(
  selectAgentsMngrs,
  (agentsMngrsState = {}) =>
    agentsMngrsState.updateAgentMngr || getGenericState()
);

export const agentsMngrsSelector = createSelector(
  selectAgentsMngrs,
  (agentsMngrsState = {}) => agentsMngrsState.agentsMngrs || getGenericState()
);

export const deleteAgentMngrSelector = createSelector(
  selectAgentsMngrs,
  (agentsMngrsState = {}) =>
    agentsMngrsState.deleteAgentMngr || getGenericState()
);

export const agentMngrDetailsSelector = createSelector(
  [agentsMngrsSelector, selectId],
  (agentsMngrsState, id) => {
    const { data: agentsMngrs } = agentsMngrsState;
    return (
      agentsMngrs.find((agentMngr) => agentMngr.id === id) || emptyAgentMngrData
    );
  }
);

export const agentsMngrsGroupedSelector = createSelector(
  agentsMngrsSelector,
  (agentsMngrsState) => {
    const { data: agentsMngrs = [] } = agentsMngrsState;
    return agentsMngrs.reduce(
      (agg, agentMngr) => {
        if (agentMngr.is_agent) agg.agents.push(agentMngr);
        if (agentMngr.is_manager) agg.managers.push(agentMngr);
        return agg;
      },
      { agents: [], managers: [] }
    );
  }
);

export const agentsMngrsDictSelector = createSelector(
  agentsMngrsSelector,
  (agentsMngrsState) => {
    const { data: agentsMngrs } = agentsMngrsState;
    return agentsMngrs.reduce((agg, agentMngr) => {
      agg[agentMngr.id] = { ...agentMngr, is_creator: false };
      return agg;
    }, {});
  }
);
