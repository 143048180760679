import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
} from '@material-ui/core';
import css from 'classnames';

import global from 'styles/global';
import EditCard from 'components/EditCard';
import {
  accountsSelector,
  accountsDictSelector,
  vendorsSelector,
  vendorsDictSelector,
} from 'slices/qb';
import KeyValuePair from 'components/KeyValuePair';
import Select from 'components/Select';

const QuickbooksAccount = ({ creator, updateCreator }) => {
  const g = global();
  const dispatch = useDispatch();

  const { qb_acct, qb_vendor } = creator;

  const { data: accounts = [] } = useSelector(accountsSelector);
  const { data: vendors = [] } = useSelector(vendorsSelector);

  const acctsDict = useSelector(accountsDictSelector);
  const vendorsDict = useSelector(vendorsDictSelector);

  const [newAcct, setNewAcct] = useState();
  const [newVendor, setNewVendor] = useState();

  useEffect(() => {
    if (!!qb_acct) {
      setNewAcct(JSON.parse(qb_acct).Id);
    }
    if (!!qb_vendor) {
      setNewVendor(JSON.parse(qb_vendor).Id);
    }
  }, [qb_acct, qb_vendor]);

  const handleUpdateCreator = async () => {
    const updateObj = {
      id: creator.id,
      qb_acct: JSON.stringify(acctsDict[newAcct]),
      qb_vendor: JSON.stringify(vendorsDict[newVendor]),
    };
    await dispatch(updateCreator(updateObj));
  };

  const cancelEditing = () => {
    setNewAcct(qb_acct);
    setNewVendor(qb_vendor);
  };

  return (
    <EditCard
      title="Quickbooks"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdateCreator}
      staticContent={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <KeyValuePair
              label="Income Account"
              value={JSON.parse(qb_acct || '{}').Name || 'No account'}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyValuePair
              label="Vendor Name"
              value={JSON.parse(qb_vendor || '{}').DisplayName || 'No vendor'}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl
            size="small"
            variant="outlined"
            className={css(g.mt_xs, g.full_width)}
          >
            <InputLabel id="income-account-label">Income Account</InputLabel>
            <Select
              labelId="income-account-label"
              label="Income Account"
              id="income-account"
              value={newAcct}
              onChange={(evt) => setNewAcct(evt.target.value)}
            >
              {accounts
                .filter((acct) => acct.Classification === 'Asset')
                .map((acct) => (
                  <MenuItem key={acct.Id} value={acct.Id}>
                    {acct.Name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            size="small"
            variant="outlined"
            className={css(g.mt_xs, g.full_width)}
          >
            <InputLabel id="vendor-label">Vendor</InputLabel>
            <Select
              labelId="vendor-label"
              label="Vendor Name"
              id="vendor"
              value={newVendor}
              onChange={(evt) => setNewVendor(evt.target.value)}
            >
              {vendors.map((acct) => (
                <MenuItem key={acct.Id} value={acct.Id}>
                  {acct.DisplayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </EditCard>
  );
};

export default QuickbooksAccount;
