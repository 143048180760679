import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Link } from 'react-router-dom';

import DollarInput from 'components/DollarInput';
import PercentInput from 'components/PercentInput';
import EditCard from 'components/EditCard';
import KeyValuePair from 'components/KeyValuePair';
import {
  formatCreatorName,
  formatCurrency,
  stringToNumberCents,
  renderRecipientRole,
} from 'util/renderStrings';
import Autocomplete from 'components/Autocomplete';
import { paymentGroupTypes } from 'constants/paymentGroups';
import objectStates from 'constants/objectStates';
import { paymentGroupDictSelector } from 'slices/paymentGroups';
import { paymentRecipientsDictSelector } from 'slices/multiSliceSelectors';
import global from 'styles/global';
import TextField from 'components/TextField';
import Select from 'components/Select';
import { getTotalWithholdingsForPayment } from 'pages/Campaigns/NewCampaign/utils';
import { paymentRecipientTotalsSelector } from 'slices/payments';

function Details({ payment, updatePayments, paymentGroups }) {
  const g = global();

  const {
    earnings,
    split,
    amount,
    memo,
    recipient,
    payment_group_id,
    recipient_role,
    state_withholdings,
    federal_withholdings,
  } = payment;

  const paymentRecipientsDict = useSelector(paymentRecipientsDictSelector);
  const paymentGroupsDict = useSelector(paymentGroupDictSelector);

  const [newSplit, setNewSplit] = useState(100);
  const [newEarnings, setNewEarnings] = useState(0);
  const [newAmount, setNewAmount] = useState(0);
  const [newMemo, setNewMemo] = useState('');
  const [newRecipient, setNewRecipient] = useState({});
  const [newPaymentGroup, setNewPaymentGroup] = useState({});
  const [newRecipientRole, setNewRecipientRole] = useState('');
  const [newStateWithholding, setNewStateWithholding] = useState(0);
  const [newFederalWithholding, setNewFederalWithholding] = useState(0);

  useEffect(() => {
    setNewEarnings(earnings / 100 || amount / 100);
    setNewSplit(split || 100);
    setNewAmount(amount / 100);
    setNewMemo(memo);
    setNewRecipient(paymentRecipientsDict[recipient] || {});
    setNewPaymentGroup(paymentGroupsDict[payment_group_id]);
    setNewRecipientRole(recipient_role);
    setNewStateWithholding(state_withholdings / 100);
    setNewFederalWithholding(federal_withholdings / 100);
  }, [
    earnings,
    split,
    amount,
    memo,
    paymentRecipientsDict,
    paymentGroupsDict,
    recipient,
    payment_group_id,
    recipient_role,
    state_withholdings,
    federal_withholdings,
  ]);

  const paymentRecipientTotals = useSelector(paymentRecipientTotalsSelector);

  useEffect(() => {
    const updatedAmount = (newEarnings * newSplit) / 100;
    const { stateWithholdings, federalWithholdings } =
      getTotalWithholdingsForPayment(
        { amount: updatedAmount },
        newRecipient,
        paymentRecipientTotals[newRecipient.id] || 0
      );
    setNewAmount(updatedAmount - stateWithholdings - federalWithholdings);
    setNewStateWithholding(stateWithholdings);
    setNewFederalWithholding(federalWithholdings);
  }, [newEarnings, newSplit, newRecipient, paymentRecipientTotals]);

  const cancelEditing = () => {
    setNewEarnings(earnings / 100 || amount / 100);
    setNewSplit(split || 100);
    setNewAmount(amount / 100);
    setNewMemo(memo);
    setNewRecipient(paymentRecipientsDict[payment.recipient]);
    setNewPaymentGroup(paymentGroupsDict[payment.payment_group_id]);
    setNewRecipientRole(recipient_role);
    setNewStateWithholding(state_withholdings / 100);
    setNewFederalWithholding(federal_withholdings / 100);
  };

  const handleUpdatePayment = async () => {
    const amtCents = stringToNumberCents((newEarnings * newSplit) / 100);
    const earningsCents = stringToNumberCents(newEarnings);
    const data = {
      id: payment.id,
      earnings: earningsCents,
      split: +newSplit,
      amount: amtCents,
      memo: newMemo,
      recipient: newRecipient.id,
      payment_group_id: newPaymentGroup?.id || null,
      recipient_role: newRecipientRole || '',
      state_withholdings: Math.floor(newStateWithholding * 100),
      federal_withholdings: Math.floor(newFederalWithholding * 100),
    };
    updatePayments([data]);
  };

  return (
    <EditCard
      title="Details"
      handleCancel={cancelEditing}
      handleSubmit={handleUpdatePayment}
      disabled={payment.status !== objectStates.draft}
      staticContent={
        <>
          <KeyValuePair
            label="Recipient"
            value={formatCreatorName(newRecipient)}
          />
          {!!payment.recipient_role && (
            <KeyValuePair
              label="Recipient role"
              value={renderRecipientRole(recipient_role)}
            />
          )}
          <KeyValuePair
            label={
              recipient_role === 'manager' || recipient_role === 'agent'
                ? 'Split Source'
                : 'Earnings'
            }
            value={formatCurrency(payment.earnings || payment.amount)}
          />
          <KeyValuePair label="Split" value={`${payment.split || 100}%`} />
          <KeyValuePair
            label="Payment amount"
            value={formatCurrency(payment.amount)}
          />
          <KeyValuePair label="Memo" value={payment.memo} />
          {!!newPaymentGroup?.id && (
            <KeyValuePair
              label={'Campaign'}
              value={
                <Link to={`/campaigns/${newPaymentGroup.id}`}>
                  {newPaymentGroup.title}
                </Link>
              }
            />
          )}
          {newRecipient?.is_w2 && (
            <>
              <KeyValuePair
                label="State withholding"
                value={formatCurrency(payment.state_withholdings)}
              />
              <KeyValuePair
                label="Federal withholding"
                value={formatCurrency(payment.federal_withholdings)}
              />
            </>
          )}
        </>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            options={Object.values(paymentRecipientsDict)}
            getOptionDisabled={(creator) =>
              creator.is_creator && !creator.is_active
            }
            getOptionLabel={formatCreatorName}
            value={newRecipient}
            onChange={(_, value) => setNewRecipient(value)}
            textInputProps={{
              label: 'Recipient',
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DollarInput
            value={newEarnings}
            margin="dense"
            variant="outlined"
            label="Earnings"
            onChange={(evt) => setNewEarnings(evt.target.value)}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl size="small" variant="outlined" className={g.full_width}>
            <InputLabel id="vendor-label">Recipient role</InputLabel>
            <Select
              labelId="rec-role-label"
              label="Recipient role"
              id="rec-role"
              value={newRecipientRole}
              onChange={(evt) => setNewRecipientRole(evt.target.value)}
            >
              {[undefined, 'creator', 'agent', 'manager'].map((val) => (
                <MenuItem key={val} value={val}>
                  {renderRecipientRole(val)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PercentInput
            label="Split"
            value={newSplit}
            onChange={(evt) => setNewSplit(evt.target.value)}
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <DollarInput
            value={newAmount}
            margin="dense"
            variant="outlined"
            disabled
            label="Amount"
            onChange={(evt) => setNewAmount(evt.target.value)}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Memo"
            variant="outlined"
            margin="dense"
            value={newMemo}
            onChange={(evt) => setNewMemo(evt.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={paymentGroups.filter(
              (x) => x.type === paymentGroupTypes.campaign
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.title}
            onChange={(_, value) => setNewPaymentGroup(value)}
            value={newPaymentGroup}
            textInputProps={{
              label: 'Campaign',
            }}
          />
        </Grid>

        {newRecipient?.is_w2 && (
          <>
            <Grid item xs={12}>
              <DollarInput
                value={newStateWithholding}
                margin="dense"
                variant="outlined"
                label="State withholdings"
                onChange={(evt) => setNewStateWithholding(evt.target.value)}
                required
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <DollarInput
                value={newFederalWithholding}
                margin="dense"
                variant="outlined"
                label="Federal withholdings"
                onChange={(evt) => setNewFederalWithholding(evt.target.value)}
                required
                fullWidth
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </EditCard>
  );
}

export default Details;
