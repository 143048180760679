import { createSlice, createSelector } from '@reduxjs/toolkit';

import { getUser, authTokenSelector } from 'slices/user';

import {
  updateOrg as updateOrgAPI,
  getOrgs as getOrgsAPI,
  getOrg as getOrgAPI,
  createOrg as createOrgAPI,
  createFundAcct as createFundAcctAPI,
  createFundBusiness as createFundBusinessAPI,
  changeOrgPlan as changeOrgPlanAPI,
  updateOrgLogo as updateOrgLogoAPI,
} from 'api/org';
import { emptyOrgData } from 'fixtures/org';
import {
  getGenericStarted,
  getGenericFailure,
  getGenericSuccess,
  getPayloadSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';

export const initialOrgState = {
  createOrg: getGenericState(),
  createFundAcct: getGenericState(),
  createFundBusiness: getGenericState(),
  updateOrg: getGenericState(),
  orgs: getGenericState([]),
  currentOrg: getGenericState(emptyOrgData),
  changeOrgPlan: getGenericState(),
  updateOrgLogo: getGenericState(),
};

export const orgSlice = createSlice({
  name: 'org',
  initialState: initialOrgState,
  reducers: {
    getOrgsStarted: getGenericStarted('orgs'),
    getOrgsSuccess: getPayloadSuccess('orgs'),
    getOrgsFailure: getGenericFailure('orgs'),

    createOrgStarted: getGenericStarted('createOrg'),
    createOrgSuccess: getGenericSuccess('createOrg'),
    createOrgFailure: getGenericFailure('createOrg'),

    createFundAcctStarted: getGenericStarted('createFundAcct'),
    createFundAcctSuccess: getGenericSuccess('createFundAcct'),
    createFundAcctFailure: getGenericFailure('createFundAcct'),

    createFundBusinessStarted: getGenericStarted('createFundBusiness'),
    createFundBusinessSuccess: getGenericSuccess('createFundBusiness'),
    createFundBusinessFailure: getGenericFailure('createFundBusiness'),

    getOrgStarted: getGenericStarted('currentOrg'),
    getOrgSuccess: getPayloadSuccess('currentOrg'),
    getOrgFailure: getGenericFailure('currentOrg'),

    updateOrgStarted: getGenericStarted('updateOrg'),
    updateOrgSuccess: getGenericSuccess('updateOrg', 'currentOrg'),
    updateOrgFailure: getGenericFailure('updateOrg'),

    changeOrgPlanStarted: getGenericStarted('changeOrgPlan'),
    changeOrgPlanSuccess: getGenericSuccess('changeOrgPlan'),
    changeOrgPlanFailure: getGenericFailure('changeOrgPlan'),

    updateOrgLogoStarted: getGenericStarted('updateOrgLogo'),
    updateOrgLogoSuccess: getGenericSuccess('updateOrgLogo'),
    updateOrgLogoFailure: getGenericFailure('updateOrgLogo'),
  },
  extraReducers: {
    'user/logout': (state, action) => {
      state.currentOrg = getGenericState(emptyOrgData);
      state.orgs = getGenericState([]);
    },
  },
});

export const {
  getOrgsStarted,
  getOrgsSuccess,
  getOrgsFailure,

  createOrgStarted,
  createOrgSuccess,
  createOrgFailure,

  getOrgStarted,
  getOrgSuccess,
  getOrgFailure,

  createFundAcctStarted,
  createFundAcctSuccess,
  createFundAcctFailure,

  createFundBusinessStarted,
  createFundBusinessSuccess,
  createFundBusinessFailure,

  updateOrgStarted,
  updateOrgSuccess,
  updateOrgFailure,

  changeOrgPlanStarted,
  changeOrgPlanSuccess,
  changeOrgPlanFailure,

  updateOrgLogoStarted,
  updateOrgLogoSuccess,
  updateOrgLogoFailure,
} = orgSlice.actions;

export default orgSlice.reducer;

export const createOrg = (data, callback) => async (dispatch, getState) => {
  dispatch(createOrgStarted());
  try {
    const res = await createOrgAPI(getState(), data);
    dispatch(createOrgSuccess(res));
    dispatch(getOrg());
    dispatch(getUser());
    callback();
  } catch (err) {
    handleError(
      err,
      dispatch,
      createOrgFailure,
      'There was an issue creating your organization'
    );
  }
};

export const createFundAcct =
  (data, callback) => async (dispatch, getState) => {
    dispatch(createFundAcctStarted());
    try {
      const res = await createFundAcctAPI(getState(), data);
      dispatch(createFundAcctSuccess(res));
      dispatch(getOrg());
      callback();
    } catch (err) {
      handleError(
        err,
        dispatch,
        createFundAcctFailure,
        'There was an issue creating your account'
      );
    }
  };

export const createFundBusiness =
  (data, callback) => async (dispatch, getState) => {
    dispatch(createFundBusinessStarted());
    try {
      const res = await createFundBusinessAPI(getState(), data);
      dispatch(createFundBusinessSuccess(res));
      dispatch(getOrg());
      callback();
    } catch (err) {
      handleError(
        err,
        dispatch,
        createFundBusinessFailure,
        'There was an issue updating your account'
      );
    }
  };

export const getOrg = () => async (dispatch, getState) => {
  const jwt = authTokenSelector(getState());
  if (!jwt) return;

  dispatch(getOrgStarted());
  try {
    const res = await getOrgAPI(getState());
    dispatch(getOrgSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getOrgFailure,
      'There was an issue retrieving your organization'
    );
  }
};

export const getOrgs = () => async (dispatch, getState) => {
  dispatch(getOrgsStarted());
  try {
    const res = await getOrgsAPI(getState());
    dispatch(getOrgsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getOrgsFailure,
      'There was an issue retrieving your organizations'
    );
  }
};

export const updateOrg = (data, callback) => async (dispatch, getState) => {
  dispatch(updateOrgStarted());
  try {
    const res = await updateOrgAPI(getState(), data);
    dispatch(updateOrgSuccess(res));
    dispatch(getOrg());
    callback();
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateOrgFailure,
      'There was an issue updating your organization'
    );
  }
};

export const changeOrgPlan = (data) => async (dispatch, getState) => {
  dispatch(changeOrgPlanStarted());
  try {
    const res = await changeOrgPlanAPI(getState(), data);
    dispatch(changeOrgPlanSuccess(res));
    dispatch(getOrg());
  } catch (err) {
    handleError(
      err,
      dispatch,
      changeOrgPlanFailure,
      'There was an issue updating your plan'
    );
  }
};

export const updateOrgLogo = (data) => async (dispatch, getState) => {
  dispatch(updateOrgLogoStarted());
  try {
    const res = await updateOrgLogoAPI(getState(), data);
    dispatch(updateOrgLogoSuccess(res));
    dispatch(getOrg());
  } catch (err) {
    handleError(
      err,
      dispatch,
      updateOrgLogoFailure,
      'There was an issue updating your logo'
    );
  }
};

// selectors
const selectOrg = (state) => state.org;

export const createOrgSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.createOrg || getGenericState()
);

export const orgsSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.orgs || getGenericState([])
);

export const orgsByIdSelector = createSelector([orgsSelector], (orgsState) => {
  const { data: orgs } = orgsState;
  return orgs.reduce((agg, org) => {
    agg[org.id] = org;
    return agg;
  }, {});
});

export const createFundAcctSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.createFundAcct || getGenericState()
);

export const createFundBusinessSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.createFundBusiness || getGenericState()
);

export const currentOrgSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.currentOrg || getGenericState(emptyOrgData)
);

// this selector is meant to be used with the BillingAuthModal to gate features based on whether an org is free or delinquent
export const billingAuthSelector = createSelector(
  selectOrg,
  (orgState = {}) => {
    return {
      isDelinquent: orgState.currentOrg.data.billing_status === 'delinquent',
      isFree: orgState.currentOrg.data.billing_plan === 'free',
    };
  }
);

export const hasTreasurySelector = createSelector(
  selectOrg,
  (orgState = {}) => !!(orgState.currentOrg.data || emptyOrgData).treasury_id
);

export const updateOrgSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.updateOrg || getGenericState()
);

export const changeOrgPlanSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.changeOrgPlan || getGenericState()
);

export const updateOrgLogoSelector = createSelector(
  selectOrg,
  (orgState = {}) => orgState.updateOrgLogo || getGenericState()
);
