import { createParams, handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getContracts = async (state, params) => {
  const qStr = createParams(params);
  return createRequest(`/v1/contracts${qStr}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const getContractPreview = async (state, params) => {
  const qStr = createParams(params);
  return createRequest(`/v1/contracts/preview${qStr}`, state, {
    method: 'GET',
  }).then((res) => handleError(res));
};

export const remindContract = async (state, ids, reminderType) => {
  return createRequest(`/v1/contracts/reminders`, state, {
    method: 'POST',
    body: {
      reminder_type: reminderType,
      ids: ids.join(','),
    },
  }).then((res) => handleError(res));
};

export const invalidateContract = async (state, id) => {
  return createRequest(`/v1/contracts/${id}/invalidate`, state, {
    method: 'PUT',
  }).then((res) => handleError(res));
};

export const createContract = async (state, body) => {
  return createRequest(`/v1/contracts`, state, {
    method: 'POST',
    body,
    contentType: 'multipart/form-data',
  }).then((res) => handleError(res));
};
