import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import {
  createContractTemplate as createContractTemplateAPI,
  createEmbeddedLink as createEmbeddedLinkAPI,
  getContractTemplates as getContractTemplatesAPI,
} from 'api/contractTemplates';
export const initialContractTemplatesState = {
  getContractTemplates: getGenericState([]),
  createEmbeddedLink: getGenericState(),
  createContractTemplate: getGenericState(),
};

export const contractsSlice = createSlice({
  name: 'contractTemplates',
  initialState: initialContractTemplatesState,
  reducers: {
    getContractTemplatesStarted: getGenericStarted('getContractTemplates'),
    getContractTemplatesSuccess: getPayloadSuccess('getContractTemplates'),
    getContractTemplatesFailure: getGenericFailure('getContractTemplates'),

    createEmbeddedLinkStarted: getGenericStarted('createEmbeddedLink'),
    createEmbeddedLinkSuccess: getGenericSuccess('createEmbeddedLink'),
    createEmbeddedLinkFailure: getGenericFailure('createEmbeddedLink'),

    createContractTemplateStarted: getGenericStarted('createContractTemplate'),
    createContractTemplateSuccess: getGenericSuccess('createContractTemplate'),
    createContractTemplateFailure: getGenericFailure('createContractTemplate'),
  },
});

export const {
  getContractTemplatesStarted,
  getContractTemplatesSuccess,
  getContractTemplatesFailure,

  createEmbeddedLinkStarted,
  createEmbeddedLinkSuccess,
  createEmbeddedLinkFailure,

  createContractTemplateStarted,
  createContractTemplateSuccess,
  createContractTemplateFailure,
} = contractsSlice.actions;

export default contractsSlice.reducer;

export const getContractTemplates = () => async (dispatch, getState) => {
  dispatch(getContractTemplatesStarted());
  try {
    const res = await getContractTemplatesAPI(getState());
    dispatch(getContractTemplatesSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getContractTemplatesFailure,
      'There was an issue fetching your templates'
    );
  }
};

export const createEmbeddedLink = (data, cb) => async (dispatch, getState) => {
  dispatch(createEmbeddedLinkStarted());
  try {
    const res = await createEmbeddedLinkAPI(getState(), data);
    dispatch(createEmbeddedLinkSuccess(res));
    !!cb && cb(res);
  } catch (err) {
    handleError(
      err,
      dispatch,
      createEmbeddedLinkFailure,
      'There was an issue generating the link'
    );
  }
};

export const createContractTemplate = (data, cb) => async (dispatch, getState) => {
  dispatch(createContractTemplateStarted());
  try {
    await createContractTemplateAPI(getState(), data);
    dispatch(createContractTemplateSuccess());
    toast.success(`Template has been created!`);
    !!cb && cb();
  } catch (err) {
    handleError(
      err,
      dispatch,
      createContractTemplateFailure,
      'There was an issue creating the contract template'
    );
  }
};

// selectors
const selectContractTemplates = (state) => state.contractTemplates;

export const contractTemplatesSelector = createSelector(
  selectContractTemplates,
  (contractTemplatesState = {}) =>
    contractTemplatesState.getContractTemplates || getGenericState([])
);

export const createContractTemplateSelector = createSelector(
  selectContractTemplates,
  (contractTemplatesState = {}) =>
    contractTemplatesState.createContractTemplate || getGenericState()
);

export const createEmbeddedLinkSelector = createSelector(
  selectContractTemplates,
  (contractTemplatesState = {}) =>
    contractTemplatesState.createEmbeddedLink || getGenericState()
);
