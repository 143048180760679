import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

import Card from '../Card';
import Button from '../Button';
import Select from '../Select';

import global from 'styles/global';

const CardPayoutForm = ({ userID, locations }) => {
  const g = global();

  const [payoutLocation, setPayoutLocation] = useState({
    val: '',
    dirty: false,
  });

  const isInputValid = !!payoutLocation.val;

  const savePaymentMethod = () => {
    alert('Saving cash payout location');
  };

  return (
    <Card title="OTC Cash Payout Location">
      <FormControl
        size="small"
        variant="outlined"
        className={g.full_width}
        style={{ marginBottom: 5 }}
      >
        <InputLabel id="select-available-banks-label">
          OTC Cash Location
        </InputLabel>
        <Select
          labelId="select-available-banks-label"
          label="OTC Cash Location"
          id="select-available-banks"
          value={payoutLocation.val}
          onChange={(evt) =>
            setPayoutLocation({ val: evt.target.value, dirty: true })
          }
        >
          {locations.map((location) => (
            <MenuItem key={location.name} value={location.name}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        loading={false}
        onClick={savePaymentMethod}
        color="primary"
        variant="contained"
        disabled={!isInputValid}
      >
        Save
      </Button>
    </Card>
  );
};

export default CardPayoutForm;
