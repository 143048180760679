import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createParams } from 'api/utils';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import BillingAuthModal from 'components/BillingAuthModal';
import AddCreatorModal from './AddCreatorModal';
import {
  creatorsSelector,
  addCreatorsSelector,
  getCreators as getCreatorsSlice,
  addCreators as addCreatorsSlice,
  sortedCreatorsSelector,
} from 'slices/user';
import { setHeaderData } from 'slices/misc';
import { billingAuthSelector } from 'slices/org';
import {
  getPayments as getPaymentsSlice,
  paymentsSelector,
} from 'slices/payments';
import global from 'styles/global';
import AddCreatorsBulkModal from 'components/AddCreatorsBulkModal';
import UsersTable from 'components/UsersTable';

function CreatorsHome({
  addCreators,
  getCreators,
  creatorsState,
  getPayments,
  addCreatorsState,
  paymentsState,
  sortedCreators,
}) {
  const history = useHistory();
  const g = global();
  const dispatch = useDispatch();

  const { loading: creatorsLoading } = creatorsState;
  const { loading: paymentsLoading } = paymentsState;
  const { isDelinquent, isFree } = useSelector(billingAuthSelector);

  const [selectedCreators, setSelectedCreators] = useState([]);
  const [billingModalVisible, setBillingModalVisible] = useState(false);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Creators',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Creators' },
        ],
      })
    );

    getCreators();
    getPayments();
  }, []);

  const navToPayGroup = () => {
    const qStr = createParams({
      creators: selectedCreators,
    });
    history.push(`/campaigns/new${qStr}`);
  };

  const handleAddBulk = () => {
    if (isDelinquent || isFree) {
      setBillingModalVisible(true);
      return;
    }
    setAddCreatorBulkModalVisible(true);
  };

  const handleAddCreator = () => {
    if (isFree && sortedCreators.length >= 5) {
      setBillingModalVisible(true);
      return;
    }
    setAddCreatorModalVisible(true);
  };

  const [addCreatorModalVisible, setAddCreatorModalVisible] = useState('');
  const [addCreatorBulkModalVisible, setAddCreatorBulkModalVisible] =
    useState(false);

  return (
    <div>
      <LoadingOverlay open={creatorsLoading || paymentsLoading} />

      <BillingAuthModal
        open={billingModalVisible}
        onClose={() => setBillingModalVisible(false)}
        isDelinquent={isDelinquent}
      />

      <AddCreatorModal
        open={addCreatorModalVisible}
        onClose={() => setAddCreatorModalVisible(false)}
        addCreators={addCreators}
        addCreatorsState={addCreatorsState}
      />

      <AddCreatorsBulkModal
        open={addCreatorBulkModalVisible}
        onClose={() => setAddCreatorBulkModalVisible(false)}
        addCreator={addCreators}
        addCreatorState={addCreatorsState}
      />

      <TableHeader title="Creators">
        <div>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={navToPayGroup}
            disabled={selectedCreators.length === 0}
          >
            New Campaign
          </Button>
          <Button
            size="small"
            variant="outlined"
            className={g.ml_xs}
            color="primary"
            onClick={handleAddBulk}
          >
            Bulk add Creators
          </Button>
          <Button
            size="small"
            variant="contained"
            className={g.ml_xs}
            color="primary"
            onClick={handleAddCreator}
          >
            New Creator
          </Button>
        </div>
      </TableHeader>
      <div className={g.tableWrapper}>
        <UsersTable
          users={sortedCreators}
          columnsToDisplay={[
            'emailCreator',
            'name',
            'tags',
            'payments',
            'paymentsAmt',
            'paymentMethod',
          ]}
          checkboxEnabled
          onSelectionModelChange={setSelectedCreators}
          isRowSelectable={(params) => params.row.is_active}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  creatorsState: creatorsSelector(state),
  addCreatorsState: addCreatorsSelector(state),
  paymentsState: paymentsSelector(state),
  sortedCreators: sortedCreatorsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  addCreators: (data) => dispatch(addCreatorsSlice(data)),
  getCreators: (data) => dispatch(getCreatorsSlice(data)),
  getPayments: () => dispatch(getPaymentsSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorsHome);
